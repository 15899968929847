import { TimeFrameValues, TimeFrameValue } from '@givelify/utils';
import dayjs from 'dayjs';
import { useFilterParams } from './useFilterParams';

const params = ['startDate', 'endDate'] as const;

const getTimeframeFilterFromValues = (values: {
    startDate: string;
    endDate: string;
}): TimeFrameValue => {
    const startValueValid =
        values.startDate && dayjs(values.startDate).isValid();
    const endValueValid = values.endDate && dayjs(values.endDate).isValid();
    if (!startValueValid && !endValueValid) {
        //no dates were provided
        return TimeFrameValues['thisYear'];
    }
    const startStr = startValueValid ? values.startDate : values.endDate;
    const endStr = endValueValid ? values.endDate : values.startDate;

    let startDate = dayjs.tz(`${startStr}T00:00:00`);
    if (startDate.isAfter(dayjs().tz().format('YYYY-MM-DD'))) {
        startDate = dayjs().tz();
    }
    let endDate = dayjs.tz(`${endStr}T00:00:00`);
    if (endDate.isAfter(dayjs().tz().format('YYYY-MM-DD'))) {
        endDate = dayjs().tz();
    }
    if (startDate.isAfter(endDate)) {
        const temp = startDate;
        startDate = endDate;
        endDate = temp;
    }

    startDate = startDate.startOf('day');
    endDate = endDate.endOf('day');

    for (const timeFrame of Object.values(TimeFrameValues)) {
        if (
            startDate.isSame(timeFrame.start, 'day') &&
            endDate.isSame(timeFrame.end, 'day')
        ) {
            return timeFrame;
        }
    }

    return {
        selector: 'custom',
        start: startDate,
        end: endDate,
    };
};

export const useTimeframeFilter = (
    timeframe?: TimeFrameValue,
    forceDefault?: boolean,
) => {
    const defualtTimeFrame = TimeFrameValues.thisYear;
    return useFilterParams<TimeFrameValue, typeof params>(
        forceDefault ? timeframe : timeframe ? timeframe : defualtTimeFrame,
        params,
        (state, paramName) => {
            if (state === undefined) return '';
            switch (paramName) {
                case 'startDate':
                    return state.start ? state.start.format('YYYY-MM-DD') : '';
                case 'endDate':
                    return state.end ? state.end.format('YYYY-MM-DD') : '';
            }
        },
        (values) => getTimeframeFilterFromValues(values),
    );
};

export const getTimeframeFilterFromUrlParams = (
    searchParams: URLSearchParams,
) => {
    let timeFrame = TimeFrameValues['thisMonth'];

    const values = {
        startDate: searchParams.get('startDate'),
        endDate: searchParams.get('endDate'),
    };

    const hasSearchParams = values.endDate || values.startDate;
    if (hasSearchParams) {
        timeFrame = getTimeframeFilterFromValues(values);
    }
    return timeFrame;
};
