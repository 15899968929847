import React from 'react';
import {
    GivelifyFormDatePicker,
    GivelifyFormTextField,
    GivelifyLabel,
} from '@givelify/ui';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

export type SetupF1GoFormData = {
    url: string;
    integrationUsername: string;
    integrationPassword: string;
    startDate: Dayjs | null;
};

export const F1goAuthForm = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            url: t('pages.integrations.settings.f1go.url'),
            username: t('pages.integrations.settings.f1go.username'),
            password: t('pages.integrations.settings.f1go.password'),
            startDate: t('pages.integrations.settings.f1go.startDate'),
            startDateDescription: t(
                'pages.integrations.settings.f1go.startDateDescription',
            ),
            submit: t('labels.submit'),
        }),
        [t],
    );
    return (
        <>
            <GivelifyFormTextField<SetupF1GoFormData>
                fullWidth
                aria-label="url"
                data-testid="f1go-form-url"
                id="f1go-form-url"
                label={copy.url}
                name="url"
                size="medium"
            />
            <GivelifyFormTextField<SetupF1GoFormData>
                fullWidth
                aria-label="integration-username"
                autoComplete="off"
                data-testid="f1go-form-username"
                id="f1go-form-username"
                label={copy.username}
                name="integrationUsername"
                size="medium"
            />
            <GivelifyFormTextField<SetupF1GoFormData>
                fullWidth
                passwordToggle
                aria-label="integratation-password"
                autoComplete="new-password"
                data-testid="f1go-form-password"
                id="f1go-form-password"
                label={copy.password}
                name="integrationPassword"
                size="medium"
                type="password"
            />
            <GivelifyLabel text={copy.startDateDescription} variant="body2" />
            <GivelifyFormDatePicker<SetupF1GoFormData>
                fullwidth
                aria-label="Export start date editor"
                data-testid="f1go-form-startDate"
                id="f1go-form-startDate"
                label={copy.startDate}
                name="startDate"
                placeholder={copy.startDate}
                size="large"
            />
        </>
    );
};
