import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import ContentCard from 'components/ContentCard';
import { useAllIntegrations } from 'pages/integrations/IntegrationSetup';
import { useSelector } from 'react-redux';
import { AppState, useAppDispatch } from 'store';
import { GetMatchProgressAsync } from 'store/integrations/thunks';
import { useAdvancedTranslation } from 'utils/i18n';
import { integrationWithoutEnvelope } from '../..';
import roleTypes from '../../../../../constants/roleTypes';
import { matchSelect } from '../../../utils/integrationFormatFilterUtils';
import MatchBottomContent from '../common/MatchBottomContent';
import MatchingHeader from '../common/MatchingHeader';
import EnvelopeMatchingHeader from './EnvelopeMatchingHeader';
import EnvelopeMatchingInfiniteLoader from './EnvelopeMatchingInfiniteLoader';
import { CampuseItem } from './types';

interface Prop {
    reportId?: number;
    doneMatch?: () => void;
    handleClose?: () => void;
}

/**
 * Desired Page size for pagination
 */
const pageSize = 15;

const TRANSLATION_KEY = 'pages.integration_envelope_matching.';

const EnvelopeMatching: React.FCC<Prop> = ({
    reportId,
    doneMatch,
    handleClose,
}) => {
    const dispatch = useAppDispatch();
    const { t, at } = useAdvancedTranslation();

    const scopedTranslate = useCallback(
        (key: string, args = undefined) => t(`${TRANSLATION_KEY}${key}`, args),
        [t],
    );
    const scopedATranslate = useCallback(
        (key: string, args = undefined) => at(`${TRANSLATION_KEY}${key}`, args),
        [at],
    );
    const { doneeId, reportType, campuses, userRole } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.campuses[0].id,
            reportType: state.ReportIntegration.scheduledReport.reportType,
            campuses: state.Donee.campuses,
            userRole: state.User.user.role,
        }),
    );

    const allIntegrations = useAllIntegrations();
    const integration = useMemo(
        () =>
            allIntegrations.find(
                (integration) => integration.integrationType === reportType,
            ),
        [reportType, allIntegrations],
    );

    const isCCB = integration?.integrationType === 'CCB';

    const [sortColumn, setSortColumn] = useState(isCCB ? 'name' : null);
    const [sortDirection, setSortDirection] =
        useState<SortingDirection>('desc');

    const [successful, setSuccessful] = useState<boolean>(false);

    const onSuccess = useCallback(() => {
        setSuccessful(true);
        setTimeout(() => {
            setSuccessful(false);
        }, 1500);
    }, []);

    const copy = useMemo(
        () => ({
            instructions: scopedATranslate('content.matchEnvelopeInstruction', {
                reportType: !integrationWithoutEnvelope.includes(
                    integration.integrationType,
                )
                    ? integration.abbreviation
                    : '',
                fundCodeLabel: integration.fundCodeLabel,
            }),
            acsInstruction: scopedATranslate(
                'content.acsMatchEnvelopeInstruction',
                {
                    reportType: integration.abbreviation,
                    fundLabel: integration.fundLabel,
                    fundCodeLabel: integration.fundCodeLabel,
                },
            ),
            tooltipMessage: scopedTranslate(
                'content.matching_tooltip.almost_done',
            ),
            tooltipInfoText: scopedTranslate(
                'content.matching_tooltip.info_text',
            ),
            doneLabel: t('labels.done'),
            envelopeSearchText: scopedATranslate(
                'content.searchPlaceholderText',
            ),
            success: t('pages.integration_envelope_matching.success', {
                reportType: integration.abbreviation,
                fundCodeLabel: integration.fundCodeLabel,
            }),
            sort: t('labels.sort'),
            allCampuses: scopedTranslate('content.allCampuses'),
        }),
        [scopedTranslate, integration, t, scopedATranslate],
    );

    const [filter, setFilter] = useState<string>('');
    const onChangeFilter = (value: matchSelect) => {
        setFilter(value);
    };

    const onSortingChange = (sorting: Sorting[]) => {
        setSortColumn(sorting[0].columnName);
        setSortDirection(sorting[0].direction);
    };

    const [matchFilter, setMatchFilter] = useState<matchSelect>('Unmatched');

    const campusesOptions = useMemo<CampuseItem[]>(
        () => [
            { id: -1, name: copy.allCampuses },
            ...campuses.map((c) => ({
                id: c.id,
                name: c.name,
                address: `${c.address} ${c.city}, ${c.state}`,
            })),
        ],
        [campuses, copy.allCampuses],
    );
    const hasCampuses = campuses?.length > 1;
    const [campusesFilter, setCampusesFilter] = useState<
        CampuseItem | undefined
    >(hasCampuses ? campusesOptions[0] : undefined);
    const allCampuses = campusesFilter?.id === -1;

    useEffect(() => {
        dispatch(
            GetMatchProgressAsync(
                'envelope',
                filter,
                doneeId,
                reportId,
                matchFilter === 'Unmatched',
                campusesFilter?.id,
            ),
        );
    }, [dispatch, filter, doneeId, reportId, campusesFilter?.id, matchFilter]);

    const url = useCallback(
        (pageNum: number) => {
            const requestDoneeId =
                campusesFilter?.id > -1 ? campusesFilter?.id : doneeId;

            const params = {
                pageSize: pageSize.toString(),
                page: pageNum.toString(),
                unmatched: (matchFilter === 'Unmatched').toString(),
                ...(filter?.length > 0 && { filter }),
                ...(sortColumn && {
                    sort: sortColumn,
                    [sortDirection]: sortDirection,
                }),
                ...(allCampuses && !reportId && { all: 'true' }),
            };
            const paramsString = new URLSearchParams(params).toString();

            const url = reportId
                ? `/reports/${reportId}/unmatched-envelopes`
                : `/donees/${requestDoneeId}/envelopes`;

            return `${url}?${paramsString}`;
        },
        [
            doneeId,
            reportId,
            filter,
            sortColumn,
            sortDirection,
            matchFilter,
            allCampuses,
            campusesFilter,
        ],
    );

    const handleDoneClick = () => {
        if (handleClose) {
            handleClose();
        } else if (doneMatch) {
            doneMatch();
        }
    };

    const isReadOnly =
        userRole !== roleTypes.ADMIN && userRole !== roleTypes.FINANCIAL;

    return (
        <>
            <MatchingHeader
                acsInstructions={copy.acsInstruction}
                campusesFilter={campusesFilter}
                campusesOptions={campusesOptions}
                hasCampuses={hasCampuses}
                instructions={copy.instructions}
                integrationType={integration.integrationType}
                matchEnvelope={true}
                matchFilter={matchFilter}
                onCampusesFilterChange={setCampusesFilter}
                onChangeFilter={onChangeFilter}
                onMatchFilterChange={setMatchFilter}
                reportId={reportId}
                searchPlaceholderText={copy.envelopeSearchText}
            />
            <ContentCard maxWidth="100%">
                <EnvelopeMatchingHeader
                    copy={{
                        sort: copy.sort,
                    }}
                    integration={integration}
                    onOrderClick={onSortingChange}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
                <EnvelopeMatchingInfiniteLoader
                    allCampuses={allCampuses}
                    integration={integration}
                    isReadOnly={isReadOnly}
                    onSuccess={onSuccess}
                    reportId={reportId}
                    url={url}
                />
                {!isCCB && (
                    <MatchBottomContent
                        doneClick={handleDoneClick}
                        doneLabel={copy.doneLabel}
                        infoText={copy.tooltipInfoText}
                        success={successful}
                        successText={copy.success}
                        tooltipMessage={copy.tooltipMessage}
                    />
                )}
            </ContentCard>
        </>
    );
};

export default EnvelopeMatching;
