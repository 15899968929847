import React from 'react';
import { GivelifyButton, GivelifyIcon } from '@givelify/ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DonationSummaryInfo } from '../components/donationSummaryInfo';
import { SkeletonEnvelopeSummary } from '../components/skeletonEnvelopeSummary';
import { FormattedDataType } from '../donationSummary/DonationSummary';
import {
    DonationSummaryCollapse,
    DonationSummaryResultWrapper,
} from './styles';

type DonationSummaryResultProps = {
    data: FormattedDataType[];
    linkClickHandler:
        | ((envelopeId: number) => void)
        | ((startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => void);
    isSuccess: boolean;
};

const DonationSummaryResultComponent: React.FCC<DonationSummaryResultProps> = ({
    data,
    linkClickHandler,
    isSuccess,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            viewMore: t('donationSummary.viewMore'),
            viewLess: t('donationSummary.viewLess'),
        }),
        [t],
    );

    const [showMoreCounter, setShowMoreCounter] = React.useState<number>(1);

    const onViewMoreButtonClick = React.useCallback(() => {
        if (showMoreCounter * 5 >= data.length) {
            setShowMoreCounter(1);
        } else {
            setShowMoreCounter((prev) => prev + 1);
        }
    }, [showMoreCounter, data.length]);

    return (
        <DonationSummaryResultWrapper>
            {!isSuccess ? (
                Array.from(Array(5).keys()).map((id) => (
                    <SkeletonEnvelopeSummary key={id} />
                ))
            ) : (
                <>
                    {data.slice(0, 5).map((envelope) => (
                        <DonationSummaryInfo
                            key={envelope.id}
                            amount={envelope.amount}
                            clickHandler={linkClickHandler}
                            donationsAmount={envelope.donationsAmount}
                            donorsAmount={envelope.donorsAmount}
                            endDate={envelope.endDate}
                            id={envelope.id}
                            name={envelope.name}
                            startDate={envelope.startDate}
                        />
                    ))}
                    {Array.from(Array(Math.floor(data.length / 5))).map(
                        (_, index) => (
                            <DonationSummaryCollapse
                                key={index}
                                unmountOnExit
                                in={
                                    showMoreCounter > 1 &&
                                    showMoreCounter >= index + 2
                                }
                                timeout="auto"
                            >
                                <DonationSummaryResultWrapper>
                                    {data
                                        .slice((index + 1) * 5, (index + 2) * 5)
                                        .map((envelope, _index) => (
                                            <DonationSummaryInfo
                                                key={envelope.id}
                                                amount={envelope.amount}
                                                clickHandler={linkClickHandler}
                                                donationsAmount={
                                                    envelope.donationsAmount
                                                }
                                                donorsAmount={
                                                    envelope.donorsAmount
                                                }
                                                endDate={envelope.endDate}
                                                id={envelope.id}
                                                name={envelope.name}
                                                startDate={envelope.startDate}
                                            />
                                        ))}
                                </DonationSummaryResultWrapper>
                            </DonationSummaryCollapse>
                        ),
                    )}
                    {data.length > 5 ? (
                        <GivelifyButton
                            data-testid="view-more-button"
                            endIcon={
                                <GivelifyIcon
                                    variant={
                                        showMoreCounter * 5 >= data.length
                                            ? 'up-chevron'
                                            : 'down-chevron'
                                    }
                                />
                            }
                            id="view-more-button"
                            name="View more"
                            onClick={onViewMoreButtonClick}
                            text={
                                showMoreCounter * 5 >= data.length
                                    ? copy.viewLess
                                    : copy.viewMore
                            }
                            variant="ghost"
                        />
                    ) : null}
                </>
            )}
        </DonationSummaryResultWrapper>
    );
};

export const DonationSummaryResult = React.memo(DonationSummaryResultComponent);
