import React from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLink,
} from '@givelify/ui';
import { TrackingProvider, mergeClassNames } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { SUPPORT_TERMS_OF_USE_URL } from 'api/constants';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
    PrimaryRepAddressForm,
    PrimaryRepInfoForm,
    PrimaryRepNameForm,
    primaryRepEditorViews,
} from '../../types';
import { PrimaryRepEditorFooter } from '../editorFooter';
import { Note, SummaryBox, SummaryRow, ValueRow } from '../styles';

type PrimaryRepEditorStepSummaryProps = {
    nameTitle: PrimaryRepNameForm;
    info: PrimaryRepInfoForm;
    address: PrimaryRepAddressForm;
    canSubmit: boolean;
    showBackButton: boolean;
    hideSsn: boolean;
    onBackClick: () => void;
    onCancelClick: () => void;
    onSubmitClick: () => void;
    onNameEditClick: () => void;
    onInfoEditClick: () => void;
    onAddressEditClick: () => void;
};

export const PrimaryRepEditorStepSummaryComponent: React.FCC<
    PrimaryRepEditorStepSummaryProps
> = ({
    nameTitle,
    info,
    address,
    canSubmit,
    showBackButton,
    hideSsn,
    onBackClick,
    onCancelClick,
    onSubmitClick,
    onNameEditClick,
    onAddressEditClick,
    onInfoEditClick,
}) => {
    const { t } = useTranslation();
    const TRANSLATION_KEY =
        'pages.settings.bank-info.customize-rep-tab.editor.stepSummary';
    const scopedTranslate = React.useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );
    const copy = React.useMemo(
        () => ({
            header: scopedTranslate('header'),
            headerShort: scopedTranslate('headerShort'),
            description: scopedTranslate('description'),
            note1: scopedTranslate('note1'),
            note2: scopedTranslate('note2'),
            note3: scopedTranslate('note3'),
            title: scopedTranslate('title'),
            phone: scopedTranslate('phone'),
            legalName: scopedTranslate('legalName'),
            address: scopedTranslate('address'),
            dateOfBirth: scopedTranslate('dateOfBirth'),
            ssn: scopedTranslate('socialSecurityNumber'),
            edit: t('labels.edit'),
            show: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.show',
            ),
            hide: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.hide',
            ),
        }),
        [scopedTranslate, t],
    );
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const onTermsClick = React.useCallback((event) => {
        event.preventDefault();
        window.open(SUPPORT_TERMS_OF_USE_URL, '_blank');
    }, []);
    const [birthDateVisible, setBirthDateVisible] = React.useState(false);
    const [ssnVisible, setSsnVisible] = React.useState(false);
    const data = React.useMemo(
        () => [
            {
                id: 'Legal name',
                label: copy.legalName,
                value: `${nameTitle.firstName} ${nameTitle.lastName}`,
                edit: onNameEditClick,
            },
            {
                id: 'Title',
                label: copy.title,
                value: nameTitle.title,
                edit: onNameEditClick,
            },
            {
                id: 'Address',
                label: copy.address,
                value: `${address.street} ${address.city}, ${address.state} ${address.zip}`,
                edit: onAddressEditClick,
            },
            {
                id: 'Phone number',
                label: copy.phone,
                value: address.phoneNumber,
                edit: onAddressEditClick,
            },
            {
                id: 'Date of birth',
                label: copy.dateOfBirth,
                value: birthDateVisible
                    ? dayjs.tz(info.dateOfBirth).format('MM/DD/YYYY')
                    : dayjs.tz(info.dateOfBirth).format('**/**/YYYY'),
                visibilityToggle: () => setBirthDateVisible(!birthDateVisible),
                edit: onInfoEditClick,
                visibile: birthDateVisible,
            },
            {
                id: 'Social Security Number',
                label: copy.ssn,
                value:
                    hideSsn && ssnVisible
                        ? info.ssn
                        : `***-**-${info.ssn.slice(-4)}`,
                visibilityToggle: hideSsn
                    ? () => setSsnVisible(!ssnVisible)
                    : undefined,
                edit: onInfoEditClick,
                visibile: ssnVisible,
            },
        ],
        [
            address.city,
            address.phoneNumber,
            address.state,
            address.street,
            address.zip,
            birthDateVisible,
            copy.address,
            copy.dateOfBirth,
            copy.legalName,
            copy.phone,
            copy.ssn,
            copy.title,
            hideSsn,
            info.dateOfBirth,
            info.ssn,
            nameTitle.firstName,
            nameTitle.lastName,
            nameTitle.title,
            onAddressEditClick,
            onInfoEditClick,
            onNameEditClick,
            ssnVisible,
        ],
    );
    return (
        <>
            <GivelifyLabel variant={isSmallScreen ? 'heading2S' : 'heading1S'}>
                {isMobile ? copy.headerShort : copy.header}
            </GivelifyLabel>
            <GivelifyLabel
                className="description"
                color={DesignTokens.color.textSecondary}
                variant="body2"
            >
                {copy.description}
            </GivelifyLabel>
            <SummaryBox>
                {data.map((item, index) => (
                    <SummaryRow
                        key={`sum-row-${index}`}
                        className={mergeClassNames(
                            index === data.length - 1 && 'sum-last',
                        )}
                        data-testid={`sum-row-${item.id}`}
                        id={`sum-row-${item.id}`}
                    >
                        <ValueRow
                            data-testid={`${item.id}-row`}
                            id={`${item.id}-row`}
                        >
                            <GivelifyLabel
                                className="sum-header"
                                data-testid={`${item.id}-label`}
                                id={`${item.id}-label`}
                                variant={isMobile ? 'body2B' : 'body1B'}
                            >
                                {item.label}
                            </GivelifyLabel>
                            <GivelifyLabel
                                className="sum-value"
                                data-testid={`${item.id}-value`}
                                id={`${item.id}-value`}
                                variant="body2"
                            >
                                {item.value}
                            </GivelifyLabel>
                        </ValueRow>
                        {item.visibilityToggle ? (
                            <GivelifyButton
                                className={mergeClassNames(
                                    'sum-edit',
                                    'sum-right',
                                )}
                                data-testid={`${item.id}-toggle`}
                                id={`${item.id}-toggle`}
                                name={
                                    item.visibile
                                        ? `Hide ${item.id}`
                                        : `Show ${item.id}`
                                }
                                onClick={item.visibilityToggle}
                                startIcon={
                                    <GivelifyIcon
                                        variant={
                                            item.visibile
                                                ? 'password-visible'
                                                : 'password-hidden'
                                        }
                                    />
                                }
                                text={item.visibile ? copy.hide : copy.show}
                                variant="ghost"
                            />
                        ) : null}
                        <GivelifyButton
                            className={mergeClassNames(
                                'sum-edit',
                                !item.visibilityToggle && 'sum-right',
                            )}
                            data-testid={`${item.id}-edit`}
                            id={`${item.id}-edit`}
                            name={`Edit ${item.id}`}
                            onClick={item.edit}
                            startIcon={<GivelifyIcon variant="edit" />}
                            text={copy.edit}
                            variant="ghost"
                        />
                    </SummaryRow>
                ))}
            </SummaryBox>
            <Note>
                <GivelifyLabel
                    color={DesignTokens.color.textSecondary}
                    variant="body2"
                >
                    {copy.note1}
                    <GivelifyLink
                        data-testid="terms-link"
                        href={SUPPORT_TERMS_OF_USE_URL}
                        id="terms-link"
                        name="Terms"
                        onClick={onTermsClick}
                        text={copy.note2}
                    />
                    {copy.note3}
                </GivelifyLabel>
            </Note>
            <PrimaryRepEditorFooter
                canSubmit={canSubmit}
                onBackClick={onBackClick}
                onCancelClick={onCancelClick}
                onSubmitClick={onSubmitClick}
                showBackButton={showBackButton}
            />
        </>
    );
};

export const PrimaryRepEditorStepSummary: React.FCC<
    PrimaryRepEditorStepSummaryProps
> = (props) => {
    return (
        <TrackingProvider
            trackPageVisit
            pageName={primaryRepEditorViews.summaryStep}
            viewType="other"
        >
            <PrimaryRepEditorStepSummaryComponent {...props} />
        </TrackingProvider>
    );
};
