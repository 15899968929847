import React, { useState, useMemo } from 'react';
import { GivelifyButton, GivelifyLabel } from '@givelify/givelify-ui';
import { formatWithTimezone } from '@givelify/utils';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { formatMoneyWithCommas } from 'utils/formatMoneyWithCommas';
import { DonationItemRowProps } from '../../../../donationActivity/components/DonationActivityInfiniteLoader/DonationActivityRow';
import RefundDetailsMobile from './RefundDetails/RefundDetailsMobile';

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    infoWrapper: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        justifyContent: 'space-between',
        gap: 4,
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
    },
    rightAlign: {
        justifySelf: 'end',
    },
}));

const DonationItemRow: React.FCC<DonationItemRowProps> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(props.openInitial || false);
    const { t } = useTranslation();

    const handleOpen = () => {
        setOpen(!open);
    };

    const RefundText = useMemo(
        () => ({
            viewDetail: t('refunds.text.viewDetail'),
            closeDetail: t('refunds.text.closeDetail'),
            refundStatus: t('refunds.text.refundStatus'),
        }),
        [t],
    );

    const refundDate = formatWithTimezone(props.latestStatusAt, 'MMM D, YYYY');

    const viewDetailText = RefundText.viewDetail;
    const closeDetailText = RefundText.closeDetail;

    return (
        <div className={classes.wrapper}>
            <div className={classes.infoWrapper} id={`donor-info-${props.id}`}>
                <GivelifyLabel text="Refund issued" variant="small" />
                <GivelifyLabel
                    className={classes.rightAlign}
                    text="Amount"
                    variant="small"
                />
                <GivelifyLabel
                    color="primaryDarkGray"
                    id={`donor-time-${props.id}`}
                    text={refundDate}
                    variant="body2"
                />
                <GivelifyLabel
                    className={classes.rightAlign}
                    color="primaryDarkGray"
                    id={`donation-total-${props.id}`}
                    text={`$${formatMoneyWithCommas(
                        props.donation - props.fees || 0,
                    )}`}
                    variant="body2"
                />
            </div>
            {!props.hideExpandButton && (
                <div className={classes.buttonWrapper}>
                    <GivelifyButton
                        endIconVariant={open ? 'chevron-up' : 'chevron-down'}
                        id={`view-detail-${props.id}`}
                        onClick={handleOpen}
                        size="small"
                        text={open ? closeDetailText : viewDetailText}
                        variant="link"
                    />
                </div>
            )}
            {open && (
                <div id={`donation-detail-${props.id}`}>
                    <RefundDetailsMobile
                        date={props.date}
                        donationDate={props.date}
                        donorName={props.name}
                        envelopes={props.envelopes}
                        fees={props.fees}
                        id={props.id}
                        receiptNumber={props.receiptNumber}
                        total={props.donation}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(DonationItemRow);
