import React from 'react';
import { ApiHandler, UpdateDoneeResponse } from '@givelify/api';
import { isSucceeded, useApiRequest } from '@givelify/utils';
import permissionTypes from 'constants/permissionTypes';
import { useSelector } from 'react-redux';
import { PATH } from 'router/routes';
import permissionsByPath from 'utils/permissionsByPath';
import { AppState, useAppDispatch } from '../../../../store';
import { setDoneeCustomReceipt } from '../../../../store/donee/actions';

export const useReceiptTab = () => {
    const dispatch = useAppDispatch();
    const [requestState, makeRequest] = useApiRequest<UpdateDoneeResponse>();
    const { doneeId, donee, userRole, doneeType, customReceipt } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            donee: state.Donee.donee,
            userRole: state.User.user.role,
            doneeType: state.Donee.donee.type,
            customReceipt: state.Donee.donee.customReceipt,
        }),
    );
    const [value, setValue] = React.useState(customReceipt === 1);
    const onChange = React.useCallback((isOn: boolean) => {
        setValue(isOn);
    }, []);
    const onCancel = React.useCallback(() => {
        setValue(customReceipt === 1);
    }, [customReceipt]);
    const onSave = React.useCallback(() => {
        if (value !== (customReceipt === 1)) {
            makeRequest(
                ApiHandler.instance.donees.updateReceiptState(doneeId, value),
            );
        }
    }, [value, customReceipt, makeRequest, doneeId]);
    React.useEffect(() => {
        if (isSucceeded(requestState)) {
            const { customReceipt, updatedAt } = requestState.response;
            dispatch(
                setDoneeCustomReceipt(
                    doneeId,
                    Boolean(customReceipt),
                    new Date(updatedAt),
                ),
            );
        }
    }, [requestState, dispatch, doneeId]);
    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.APP_PROFILE][userRole] ===
        permissionTypes.FULL_ACCESS;
    return {
        onCancel,
        onSave,
        onChange,
        hasFullAccess,
        error:
            requestState.type === 'REQUEST_ERROR'
                ? requestState.error.message
                : undefined,
        loading: requestState.type === 'REQUEST_START',
        dirty: value !== (customReceipt === 1),
        doneeName: donee.name,
        value,
        doneeType,
    } as const;
};
