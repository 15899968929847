import React, { useEffect } from 'react';
import { ApiHandler } from '@givelify/api';
import {
    GivelifyButton,
    GivelifyFormTextField,
    GivelifyLabel,
    GivelifyLink,
    GivelifyNotification,
} from '@givelify/ui';
import { copyToClipboard, useApiRequest } from '@givelify/utils';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import EmailIcon from '../../../../assets/icon/email.svg';
import LinkIcon from '../../../../assets/icon/link.svg';
import { useTranslations } from '../../../../utils/translations';
import useComponentData, { FormInput } from './hooks';
import { Container, FormContainer, RoundBox, Tools } from './style';

export type ShareActionResult = 'CopyLink' | 'SendEmail';

interface Props {
    onClose: (result: ShareActionResult) => void;
}

const ModalForm: React.FC<{ errorMessage: string; onCancel: () => void }> = ({
    errorMessage,
    onCancel,
}) => {
    const { shareModal } = useTranslations('onlineGiving.givelifyButton', {
        shareModal: ['back', 'send', 'receiptName', 'receiptEmail'],
    });
    const {
        formState: { isDirty, isValid },
    } = useFormContext<FormData>();
    return (
        <Stack display="flex" gap={2} width="100%">
            <GivelifyFormTextField
                fullWidth
                aria-label={shareModal.receiptName}
                id="name"
                inputProps={{
                    'data-testid': 'receipt-name-input',
                }}
                label={shareModal.receiptName}
                name="name"
                placeholder={shareModal.receiptName}
                size="large"
            />
            <GivelifyFormTextField
                fullWidth
                aria-label={shareModal.receiptEmail}
                id="email"
                inputProps={{
                    'data-testid': 'email-input',
                }}
                label={shareModal.receiptEmail}
                name="email"
                placeholder={shareModal.receiptEmail}
                size="large"
                type="email"
            />
            <GivelifyNotification
                open={!!errorMessage}
                text={errorMessage}
                transitionDuration={0}
                variant="error"
            />
            <Stack
                display="flex"
                flexDirection="row"
                gap={2}
                justifyContent="space-evenly"
            >
                <GivelifyButton
                    fullWidth
                    data-testid="back-to-main-button"
                    name="backToMain"
                    onClick={onCancel}
                    text={shareModal.back}
                    variant="secondary"
                />
                <GivelifyButton
                    fullWidth
                    data-testid="submit-button"
                    disabled={!isValid || !isDirty}
                    name="sendEmail"
                    text={shareModal.send}
                    type="submit"
                    variant="primary"
                />
            </Stack>
        </Stack>
    );
};

export const ShareModalContent: React.FCC<Props> = ({ onClose }: Props) => {
    const [isFormOpen, setIsFormOpen] = React.useState(false);
    const { publicPageLink, formSchema, errorMessage, setErrorMessage } =
        useComponentData();
    const { shareModal: sm } = useTranslations('onlineGiving.givelifyButton', {
        shareModal: ['title', 'copyLink', 'or', 'email'],
    });
    const [request, makeRequest] = useApiRequest<unknown>();

    const handleCopyLinkClick = () => {
        copyToClipboard(publicPageLink).then((isSuccess) => {
            if (isSuccess) onClose('CopyLink');
        });
    };
    const handleEmailClick = () => {
        setErrorMessage('');
        setIsFormOpen(true);
    };
    const onSubmit = (form: FormInput) => {
        makeRequest(
            ApiHandler.instance.common.sendButtonEmail(form.name, form.email),
        );
    };
    useEffect(() => {
        if (request.type === 'REQUEST_SUCCESS') {
            onClose('SendEmail');
        } else if (request.type === 'REQUEST_ERROR') {
            setErrorMessage(request.error.message);
        }
    }, [onClose, request, setErrorMessage]);

    return (
        <Container
            data-testid="online-giving-share-modal"
            id="online-giving-share-modal"
        >
            <GivelifyLabel text={sm.title} variant="heading2S">
                {sm.title}
            </GivelifyLabel>
            {!isFormOpen ? (
                <Tools id="share-online-giving">
                    <RoundBox
                        data-testid="copy-link-button"
                        onClick={handleCopyLinkClick}
                    >
                        <img alt="" src={LinkIcon} width={32} />
                        <GivelifyLink id="copy-link" text={sm.copyLink} />
                    </RoundBox>
                    <GivelifyLabel variant="body1">{sm.or}</GivelifyLabel>
                    <RoundBox
                        data-testid="email-share"
                        onClick={handleEmailClick}
                    >
                        <img alt="" src={EmailIcon} width={32} />
                        <GivelifyLink id="email-share" text={sm.email} />
                    </RoundBox>
                </Tools>
            ) : (
                <FormContainer
                    dataTestId="share-email-form"
                    onSubmit={onSubmit}
                    schema={formSchema}
                >
                    <ModalForm
                        errorMessage={errorMessage}
                        onCancel={() => setIsFormOpen(false)}
                    />
                </FormContainer>
            )}
        </Container>
    );
};
