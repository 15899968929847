import React, { useMemo, useRef, useState } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyLink,
    SubmitButtons,
} from '@givelify/ui';
import { Grid, Stack } from '@mui/material';
import { ValidationError, object, string } from 'yup';
import {
    FaithLeaderEditorInfo,
    ImageWithDimensions,
    RepresentativeWithImage,
} from '../../../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../../../consts';
import { ImageButton, ImageButtonRef } from '../../../imageCropper/ImageButton';
import { CustomizeProfileFaithLeader } from '../../../imageCropper/ImageUploadButtons';
import FaithLeaderEditor from './FaithLeaderEditor';
import { ListTileBase } from './ListTileBase';
import { ListTileNamed } from './ListTileNamed';
import { SlidingListTile } from './SlidingListTile';
import { AddPhotoButton } from './styles';
import { FaithLeaderValidationResult } from './types';

type FaithLeaderSectionProps = {
    userId: number;
    representatives: RepresentativeWithImage[];
    faithLeader: FaithLeaderEditorInfo;
    onChangeFaithLeader: (faithLeader: FaithLeaderEditorInfo) => unknown;
    faithLeaderImage: ImageWithDimensions | undefined;
    setFaithLeaderImage: (image?: ImageWithDimensions) => unknown;
    settingsUsersPath: string;
    initialFaithLeader: FaithLeaderEditorInfo;
    initialfaithLeaderImage: ImageWithDimensions | undefined;
    submitProps?: {
        onSave: () => void;
        isSaving?: boolean;
    };
};

const DEFAULT_ITEM_COUNT = 5;

export const ValidateFaithLeader = (formData: {
    firstName: string;
    lastName: string;
    title: string;
}) => {
    const faithLeaderSchema = object({
        firstName: string().min(1).max(55),
        lastName: string().min(1).max(55),
        title: string().max(25),
    });

    try {
        faithLeaderSchema.validateSync(formData, {
            abortEarly: false,
        });

        return {
            isValid: true,
        };
    } catch (err) {
        const errors: Record<string, string> = {};
        if ((err as ValidationError).inner) {
            (err as ValidationError).inner.forEach((e: ValidationError) => {
                if (e.path) {
                    errors[e.path] = e.message;
                }
            });
        }

        return {
            isValid: false,
            errors,
        };
    }
};

const FaithLeaderSection: React.FCC<FaithLeaderSectionProps> = ({
    userId,
    representatives,
    faithLeader,
    onChangeFaithLeader,
    faithLeaderImage,
    setFaithLeaderImage,
    settingsUsersPath,
    initialFaithLeader,
    initialfaithLeaderImage,
    submitProps,
}) => {
    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'customizeProfile.faithLeaderSection',
        namespace: I18N_NAMESPACE,
    });
    const imageButton1Ref = useRef<ImageButtonRef>(null);
    const imageButton2Ref = useRef<ImageButtonRef>(null);
    const [viewAll, setViewAll] = useState(false);
    const [dirty, setDirty] = useState(false);

    const onDoNotDisplayFaithLeaderClick = () => {
        onChangeFaithLeader({
            userId: 0,
            firstName: '',
            lastName: '',
            title: '',
            avatar: '',
            avatarCroppedCoordinates: '',
            avatarOriginal: '',
        });
        imageButton2Ref.current?.clear();
        if (initialFaithLeader !== undefined) {
            setDirty(true);
        } else {
            setDirty(false);
        }
    };

    const onAddNewFaithLeaderSelect = () => {
        if (faithLeader.userId === -1) return;
        onChangeFaithLeader({
            userId: -1,
            firstName: '',
            lastName: '',
            title: '',
            avatar: '',
            avatarCroppedCoordinates: '',
            avatarOriginal: '',
        });
        imageButton1Ref.current?.clear();
    };

    const onSelectListItem = React.useCallback(
        (faithLeader: FaithLeaderEditorInfo) => {
            onChangeFaithLeader(faithLeader);
            imageButton2Ref.current?.clear();
            setFaithLeaderImage({
                croppedUrl: faithLeader.avatarOriginal || '',
                url: faithLeader.avatarOriginal || '',
                dimensions: {
                    croppedArea: {
                        height: 200,
                        width: 200,
                        x: 0,
                        y: 0,
                    },
                    croppedAreaPixels: {
                        height: 200,
                        width: 200,
                        x: 0,
                        y: 0,
                    },
                },
            });
        },
        [imageButton2Ref, onChangeFaithLeader, setFaithLeaderImage],
    );

    React.useEffect(() => {
        if (faithLeader.userId === initialFaithLeader?.userId) {
            setFaithLeaderImage(initialfaithLeaderImage);
            imageButton1Ref.current?.reset();
            setDirty(false);
        } else {
            setDirty(true);
        }
        //eslint-disable-next-line
    }, [faithLeader, initialFaithLeader]);

    React.useEffect(() => {
        if (faithLeaderImage?.url === initialfaithLeaderImage?.url) {
            setDirty(false);
        } else {
            setDirty(true);
        }
        //eslint-disable-next-line
    }, [faithLeaderImage, initialfaithLeaderImage]);

    const onCancel = React.useCallback(() => {
        onSelectListItem(initialFaithLeader);
        setFaithLeaderImage(initialfaithLeaderImage);
        imageButton1Ref.current?.reset();
    }, [
        imageButton1Ref,
        initialfaithLeaderImage,
        initialFaithLeader,
        onSelectListItem,
        setFaithLeaderImage,
    ]);

    const {
        headingText,
        descriptionText,
        userSettings,
        sliderBottom,
        doNotDisplayFaithLeader: doNotDisplayFaithLeaderText,
        viewLess,
        viewAll: viewAllText,
    } = useMemo(
        () => ({
            headingText: scopedTranslate('heading'),
            descriptionText: scopedTranslate('description'),
            userSettings: scopedTranslate('userSettings'),
            sliderBottom: scopedTranslate('sliderBottom'),
            doNotDisplayFaithLeader: scopedTranslate('doNotDisplayFaithLeader'),
            viewLess: scopedTranslate('viewLess'),
            viewAll: scopedTranslate('viewAll'),
        }),
        [scopedTranslate],
    );

    const itemsToDisplay = useMemo(() => {
        // 1. Get list
        let items = representatives.map<FaithLeaderEditorInfo>(
            (representative) => ({
                userId: representative.officialId,
                firstName: representative.firstName,
                lastName: representative.lastName,
                title: representative.title,
                avatar: representative.avatar,
                avatarCroppedCoordinates:
                    representative.avatarCroppedCoordinates,
                avatarOriginal: representative.avatarOriginal,
            }),
        );

        // 2. Move user representative to the first position
        const userRepresentative = items.find((r) => r.userId === userId);
        if (userRepresentative) {
            items = items.filter(
                (faithLeader) => faithLeader !== userRepresentative,
            );
            items.unshift({ ...userRepresentative, isYou: true });
        }

        // 3. Remove selected representative
        items = items.filter(
            (representative) => representative.userId !== faithLeader.userId,
        );

        // 4.Display first n items
        if (!viewAll) items = items.slice(0, DEFAULT_ITEM_COUNT);

        return items;
    }, [representatives, viewAll, faithLeader, userId]);

    const validationResult: FaithLeaderValidationResult = useMemo(
        () =>
            faithLeader.userId === -1
                ? ValidateFaithLeader({
                      firstName: faithLeader.firstName || '',
                      lastName: faithLeader.lastName || '',
                      title: faithLeader.title || '',
                  })
                : {
                      isValid: true,
                  },
        [
            faithLeader.userId,
            faithLeader.firstName,
            faithLeader.lastName,
            faithLeader.title,
        ],
    );

    return (
        <div>
            <GivelifyLabel
                fontWeight="bold"
                marginBottom={2}
                text={headingText}
                variant="heading2S"
            />
            <GivelifyLabel variant="body1">
                {`${descriptionText} `}
                {process.env.STORYBOOK ? (
                    <a href={'/settings/users'} title={userSettings}>
                        {userSettings}
                    </a>
                ) : (
                    <GivelifyLink
                        data-testid="user-settings-link"
                        fontSize="inherit"
                        href={settingsUsersPath}
                        id="user-settings-link"
                        text={userSettings}
                        title={userSettings}
                    />
                )}
                .
            </GivelifyLabel>
            <Grid container mt={2} spacing={2}>
                {faithLeader.userId && faithLeader.userId > 0 ? (
                    <Grid item xs={12}>
                        <ListTileNamed
                            selected
                            faithLeader={faithLeader}
                            uploadButton={
                                <Stack display="flex" justifyContent="center">
                                    <ImageButton
                                        ref={imageButton1Ref}
                                        isRound
                                        Component={CustomizeProfileFaithLeader}
                                        aspectRatio={1}
                                        id="upload-faith-avatar"
                                        initialImage={faithLeaderImage}
                                        onChange={setFaithLeaderImage}
                                        title={headingText}
                                    />
                                </Stack>
                            }
                        />
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <ListTileBase
                        heading={doNotDisplayFaithLeaderText}
                        id="do-not-show"
                        onSelect={onDoNotDisplayFaithLeaderClick}
                        selected={faithLeader.userId === 0}
                    />
                </Grid>
                {itemsToDisplay.map((representative, index) => (
                    <Grid
                        key={index}
                        item
                        smallTablet={viewAll ? 12 : 6}
                        xs={12}
                    >
                        <ListTileNamed
                            faithLeader={representative}
                            onSelect={() => onSelectListItem(representative)}
                            selected={representative === faithLeader}
                        />
                    </Grid>
                ))}
            </Grid>
            <Stack
                alignItems="center"
                display="flex"
                my={representatives.length > DEFAULT_ITEM_COUNT ? 2 : 1}
            >
                {representatives.length > DEFAULT_ITEM_COUNT && (
                    <GivelifyButton
                        data-testid="view-all-faithleader"
                        id="view-all-faithleader"
                        onClick={() => setViewAll(!viewAll)}
                        text={
                            viewAll
                                ? viewLess
                                : `${viewAllText} (${representatives.length})`
                        }
                        variant="ghost"
                    />
                )}
            </Stack>
            <SlidingListTile
                heading={sliderBottom}
                onClick={onAddNewFaithLeaderSelect}
                open={faithLeader.userId === -1}
                selectId="add-new-faith-leader"
                sizeChanged={faithLeaderImage !== undefined}
            >
                {faithLeader.userId === -1 ? (
                    <>
                        <FaithLeaderEditor
                            faithLeader={faithLeader}
                            onChangeFaithLeader={onChangeFaithLeader}
                            validationResult={validationResult}
                        />
                        <AddPhotoButton>
                            <ImageButton
                                ref={imageButton2Ref}
                                isRound
                                Component={CustomizeProfileFaithLeader}
                                aspectRatio={1}
                                id="faith-new-avatar"
                                initialImage={faithLeaderImage}
                                onChange={setFaithLeaderImage}
                                title={headingText}
                            />
                        </AddPhotoButton>
                    </>
                ) : null}
            </SlidingListTile>
            {submitProps && dirty ? (
                <SubmitButtons
                    id="add-new-faith-leader"
                    loading={submitProps.isSaving}
                    onCancel={onCancel}
                    onSubmit={submitProps.onSave}
                    submitDisabled={!validationResult.isValid}
                />
            ) : null}
        </div>
    );
};

export default React.memo(FaithLeaderSection);
