import React, { useMemo, useState, useEffect } from 'react';
import {
    ApiHandler,
    PhysicalAddress,
    UpdateDoneeResponse,
} from '@givelify/api';
import {
    InvokeApiErrorResponse,
    isFailed,
    isSucceeded,
    TimezoneManager,
    useApiRequest,
} from '@givelify/utils';
import { isEqual } from 'lodash-es';
import { EditOption } from 'pages/settings/components/EditOption';
import { useAppDispatch } from 'store';
import { setDoneeAddress } from 'store/donee/actions';
import { useAdvancedTranslation } from 'utils/i18n';
import { Body, EditButtonContainer, Section, Subtitle } from '../style';
import { AddressSectionEditor } from './AddressSectionEditor';
import { AddressContent } from './styles';
import { TimeZoneNote } from './TimeZoneNote';

type AddressSectionProps = {
    doneeId: number;
    physicalAddress: PhysicalAddress;
    isReadOnly: boolean;
};

export const AddressSection: React.FCC<AddressSectionProps> = ({
    doneeId,
    physicalAddress,
    isReadOnly,
}) => {
    const { at, t } = useAdvancedTranslation();

    const copy = useMemo(
        () => ({
            title: at('pages.settings.organization-info.physical.title'),
            description1: t(
                'pages.settings.organization-info.physical.description-1',
            ),
            description2: t(
                'pages.settings.organization-info.physical.description-2',
            ),
        }),
        [t, at],
    );

    const dispatch = useAppDispatch();

    const [addressData, setAddressData] =
        useState<PhysicalAddress>(physicalAddress);
    const [editorEnabledState, setEditorEnabledState] = useState(false);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    const [updateRequestState, updateRequest] =
        useApiRequest<UpdateDoneeResponse>();

    const onSubmit = (newData: PhysicalAddress) => {
        if (!isEqual(addressData, newData)) {
            updateRequest(
                ApiHandler.instance.donees.updatePhysicalAddress(
                    doneeId,
                    newData,
                ),
            );
            setSaveError(undefined);
        } else {
            setEditorEnabledState(false);
        }
    };

    useEffect(() => {
        if (isSucceeded(updateRequestState)) {
            const {
                address,
                city,
                state,
                zip,
                phone,
                updatedAt,
                timezone,
                timezoneLong,
                timezoneShort,
            } = updateRequestState.response;
            dispatch(
                setDoneeAddress(
                    doneeId,
                    address,
                    city,
                    state,
                    zip,
                    phone,
                    new Date(updatedAt),
                    timezoneLong,
                    timezoneShort,
                    timezone,
                ),
            );
            setAddressData({
                street: address,
                city,
                state,
                postal: zip,
            });
            TimezoneManager.setDefault(timezone);
            setEditorEnabledState(false);
        }
        if (isFailed(updateRequestState)) {
            setSaveError(
                (updateRequestState as InvokeApiErrorResponse).error.message,
            );
        }
    }, [updateRequestState, dispatch, doneeId]);

    const onEditClick = () => {
        setSaveError(undefined);
        setEditorEnabledState(true);
    };

    const onCancel = () => {
        setEditorEnabledState(false);
    };

    const saving = updateRequestState.type === 'REQUEST_START';

    return (
        <Section>
            <Subtitle fontWeight="bold" text={copy.title} variant="heading2S" />
            <Body text={copy.description1} variant="body2" />
            <Body text={copy.description2} variant="body2" />
            {editorEnabledState ? (
                <AddressSectionEditor
                    data={addressData}
                    error={saveError}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    saving={saving}
                />
            ) : (
                <AddressContent>
                    <Body
                        data-testid="org-adr-str"
                        text={addressData.street}
                        variant="body1"
                    />
                    <Body
                        data-testid="org-adr-loc"
                        text={`${addressData.city}, ${addressData.state} ${addressData.postal}`}
                        variant="body1"
                    />
                </AddressContent>
            )}
            {!editorEnabledState ? <TimeZoneNote /> : null}
            <EditButtonContainer>
                {!isReadOnly && !editorEnabledState && (
                    <EditOption onClick={onEditClick} testId="address-edit" />
                )}
            </EditButtonContainer>
        </Section>
    );
};
