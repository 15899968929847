import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { Grid, styled } from '@mui/material';

export const HideOnMobile = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

export const appProfileStyle = makeStyles((theme: Theme) => {
    return createStyles({
        profileContainer: {
            display: 'flex',
            marginTop: 40,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 16,
                marginRight: 16,
            },
        },
        profileEditorSide: {
            maxWidth: 600,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                marginBottom: 75,
            },
        },
        profilePreviewSide: {
            marginLeft: 'auto',
            marginRight: 32,
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        descriptionBold: { lineHeight: '20px' },
        description: { lineHeight: '22px' },
        profileEditorContainer: { marginTop: 24 },
        profileEditorDivider: {
            marginTop: 32,
            marginBottom: 35,
        },
        repInputs: {
            marginTop: 32,
            marginLeft: 88,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
            },
        },
        repNameInputsHolder: {
            display: 'flex',
            marginBottom: 24,
        },
        repFirstNameInput: {
            marginRight: 8,
        },
        repLastNameInput: {
            marginLeft: 8,
        },
    });
});
