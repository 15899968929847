import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgDonor = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="225"
        width="225"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 225 225"
    >
        <path
            d="M150.834 106.772C153.505 108.375 156.604 110.084 159.595 112.007C175.62 122.264 189.937 134.336 200.62 150.148C205.641 157.626 209.594 165.746 210.235 174.934C210.983 185.19 206.069 193.31 196.667 197.476C191.753 199.613 186.625 200.361 181.283 200.681C178.291 200.895 175.193 200.895 172.202 200.895C170.92 200.895 168.89 201.643 168.783 199.72C168.676 197.369 171.027 198.117 172.309 198.01C176.048 197.69 179.787 197.583 183.526 197.369C187.693 197.156 191.753 196.301 195.599 194.592C204.146 190.852 208.419 183.053 207.244 173.759C206.389 167.455 204.146 161.793 200.94 156.344C194.53 145.127 185.77 135.939 176.048 127.605C167.928 120.661 159.061 114.785 149.766 109.55C147.95 108.589 146.775 108.695 144.958 109.764C122.523 123.225 100.087 123.652 77.6517 109.977C76.156 109.123 75.1945 108.802 73.5919 109.764C55.8571 119.806 39.9386 132.092 27.6524 148.652C22.8448 155.169 18.892 162.113 17.0757 170.019C13.7638 183.801 20.1739 193.737 34.0626 196.622C38.8702 197.583 43.6778 197.369 48.4855 197.904C49.8743 198.01 51.2633 198.117 52.6522 198.117C53.6137 198.117 54.7888 198.224 54.682 199.613C54.682 201.002 53.6136 201.002 52.5453 201.002C48.4855 200.895 44.5325 201.002 40.4727 200.681C36.6266 200.361 32.7807 199.827 29.1483 198.545C16.969 194.271 11.0929 184.335 13.5501 171.515C14.8321 164.784 17.61 158.588 21.1356 152.819C28.4004 140.96 38.1224 131.345 48.806 122.691C56.1777 116.815 63.9768 111.687 72.0963 107.093C72.4168 106.025 71.6689 105.704 71.2416 105.277C40.7933 78.4608 45.8145 29.5299 82.3524 8.37634C111.198 -8.29009 147.629 0.684125 164.616 28.4615C178.505 51.3244 174.979 81.2385 156.069 100.896C154.253 102.606 152.651 104.636 150.834 106.772ZM110.985 116.815C119.104 116.815 126.048 115.533 133.207 112.542C159.488 101.965 174.766 73.76 168.89 47.1578C162.159 16.8164 133.206 -2.0936 101.903 4.10289C68.7843 10.7267 49.1266 42.2434 55.5367 72.6917C60.9854 98.546 83.955 116.922 110.985 116.815Z"
            fill="#151515"
        />
        <path
            d="M123.27 157.733C135.984 152.285 145.706 158.588 146.027 172.37C146.24 180.383 143.783 187.754 140.364 194.699C135.557 204.634 129.147 213.609 119.959 220.126C119.531 220.446 119.104 220.873 118.676 221.194C111.412 226.215 107.672 226.322 100.514 221.194C85.771 210.404 76.797 195.874 73.485 177.925C72.6304 173.438 72.9508 168.844 74.767 164.464C77.3311 158.161 84.4892 154.742 91.113 156.344C97.0958 157.733 102.117 160.725 106.711 164.678C109.061 166.708 109.595 167.562 111.198 165.639C112.48 164.143 113.869 162.755 115.578 161.686C118.57 159.763 121.775 158.374 123.27 157.733Z"
            fill="#DBD6FF"
        />
        <path
            d="M151.582 163.075C150.62 178.246 144.424 190.318 135.343 201.215C130.642 206.878 125.193 211.685 118.57 215.211C115.471 216.92 112.693 216.814 109.595 215.104C103.399 211.792 98.2707 207.198 93.6767 201.963C85.664 192.669 79.8948 182.305 77.5444 170.126C76.5829 165.105 76.3693 159.977 77.9719 154.955C80.3223 147.691 86.5186 143.951 94.104 145.233C100.621 146.302 105.963 149.507 110.984 153.567C114.082 156.024 113.976 156.024 117.181 153.46C121.881 149.72 126.903 146.622 132.885 145.447C141.967 143.738 148.484 147.691 150.513 156.665C151.368 159.122 151.368 161.472 151.582 163.075ZM115.471 159.977C113.869 160.404 113.014 159.549 111.946 158.481C108.848 155.49 105.429 152.926 101.582 151.002C98.4842 149.4 95.2792 148.225 91.7536 148.225C86.5187 148.225 83.2067 150.575 81.2836 155.49C79.8948 159.015 79.5743 162.754 80.2153 166.494C83.2067 184.87 92.6084 199.292 107.565 210.403C112.48 214.036 116.54 213.822 121.24 210.297C134.168 200.895 142.608 188.502 147.095 173.224C148.377 168.844 149.125 164.25 148.377 159.656C146.881 150.041 140.685 146.088 131.39 149.079C126.475 150.682 122.202 153.46 118.356 156.985L115.471 159.977Z"
            fill="#151515"
        />
    </svg>
);

export const DonorIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgDonor}
        fontSize={fontSize}
    />
);
