import React from 'react';
import { Grid, Stack } from '@mui/material';
import { GivelifyIcon } from '../icon';
import { GivelifyLabel } from '../label';
import { MOCKUP_PATH } from './paths';
import {
    MobilePreviewBack,
    MobilePreviewCase,
    MobilePreviewContent,
    MobilePreviewCover,
    MobilePreviewCoverWrapper,
    MobilePreviewPagination,
    OrgTitle,
    Overlay,
    QuickGiveButtonBox,
} from './styles';

export type QuickGiveMobilePreviewProps = {
    options: string[];
    contentTitle: string;
    imageUrl?: string;
    doneeName: string;
};

export const QuickGiveMobilePreview: React.FCC<QuickGiveMobilePreviewProps> = ({
    imageUrl,
    doneeName,
    options,
    contentTitle,
}) => {
    return (
        <MobilePreviewCase>
            <MobilePreviewContent>
                <MobilePreviewCoverWrapper>
                    <MobilePreviewCover
                        src={imageUrl}
                        title="Organisation cover picture"
                    />
                    <Overlay />
                    <OrgTitle color="white">{doneeName}</OrgTitle>
                </MobilePreviewCoverWrapper>
                <GivelifyLabel
                    color="gray"
                    fontSize={14}
                    lineHeight="14px"
                    marginBottom={5}
                    marginTop={6}
                    text={contentTitle}
                    textAlign="center"
                />
                <Stack paddingX={3}>
                    <Grid
                        container
                        columnSpacing={1.5}
                        justifyContent="center"
                        rowSpacing={3}
                    >
                        {options.map((value) => (
                            <Grid
                                key={'default_' + value}
                                item
                                alignItems="center"
                                justifyContent="center"
                                xs={4}
                            >
                                <QuickGiveButtonBox>
                                    <GivelifyLabel
                                        text={value}
                                        variant="body1"
                                    />
                                </QuickGiveButtonBox>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
                <MobilePreviewBack>
                    <GivelifyIcon variant="left-arrow" />
                </MobilePreviewBack>
                <MobilePreviewPagination>
                    <img
                        alt="pagination dots"
                        src={MOCKUP_PATH.PAGINATION_DOTS}
                        title="Pagination dots"
                    />
                </MobilePreviewPagination>
            </MobilePreviewContent>
        </MobilePreviewCase>
    );
};
