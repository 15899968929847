import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { pushEvent, isTest } from './clevertap';
import { useTrackingConfigContext } from './TrackingConfigProvider';

interface TrackingProvider {
    pageName?: string;
    trackPageVisit: boolean;
    hideInChild?: boolean;
    viewType?: 'page' | 'tab' | 'modal' | 'other';
}

type TrackingContext = {
    /**
     * Logs warning if no provider exist for the tracking context
     */
    trackEvent: (
        eventName: string,
        eventPayload?: Record<string, unknown>,
        eventType?: 'view' | 'close' | 'click',
    ) => void;
    trackEventRaw: (
        event: string,
        eventPayload?: Record<string, unknown>,
    ) => void;
    trackOpenCloseEvent: (open: boolean, location?: string) => void;
    pageName?: string;
    hideInChild?: boolean;
};

const TrackingContext = createContext<TrackingContext>({
    trackEvent: () => {
        if (!isTest) console.warn('Tracking context is not initialized');
    },
    trackEventRaw: () => {
        if (!isTest) console.warn('Tracking context is not initialized');
    },
    pageName: '',
    trackOpenCloseEvent: () => {
        if (!isTest) console.warn('Tracking context is not initialized');
    },
});

export const useTrackingContext = () => useContext(TrackingContext);

/**
 * Provides page name for consumer components
 *
 * You can stack multiple TrackingProviders insed each other to combine page names
 *
 * By default it will send `Page_Visit` event when component is mounted
 * @param pageName - name of the page
 * @param trackPageVisit - send `Page_Visit` event
 * @example
 * <TrackingProvider pageName="parentPage">
 *     <TrackingProvider pageName="subPage">
 *         const { pageName, trackEvent } = useTrackingContext();
 *         // pageName is `parentPage_subPage` here
 *
 *         trackEvent('myEvent')
 *         // this will track 'parentPage_subPage_myEvent' event
 *     </TrackingProvider>
 * </TrackingProvider>
 */
export const TrackingProvider: React.FCC<TrackingProvider> = ({
    children,
    pageName,
    trackPageVisit,
    hideInChild,
    viewType = 'page',
}) => {
    const { getPayload } = useTrackingConfigContext();
    const parentCtx = useTrackingContext();
    const eventLocation = useMemo(() => {
        if (!pageName) return '';
        let pageIdentifier = '';
        if (parentCtx.pageName && !parentCtx.hideInChild) {
            switch (viewType) {
                case 'page':
                    pageIdentifier = `${parentCtx.pageName} (${pageName})`;
                    break;
                case 'tab':
                    pageIdentifier = `${parentCtx.pageName} (${pageName} Tab)`;
                    break;
                case 'modal':
                    pageIdentifier = `${parentCtx.pageName} [${pageName} Modal]`;
                    break;
                case 'other':
                    pageIdentifier = `${parentCtx.pageName} [${pageName}]`;
                    break;
            }
        } else {
            switch (viewType) {
                case 'page':
                    pageIdentifier = `${pageName} Page`;
                    break;
                case 'tab':
                    pageIdentifier = `${pageName} Tab`;
                    break;
                case 'modal':
                    pageIdentifier = `${pageName} Modal`;
                    break;
                case 'other':
                    pageIdentifier = `[${pageName}]`;
                    break;
            }
        }
        return pageIdentifier;
    }, [pageName, parentCtx.hideInChild, parentCtx.pageName, viewType]);

    const trackEvent = useCallback(
        (
            eventName: string,
            payload?: Record<string, unknown>,
            eventType: 'view' | 'close' | 'click' = 'click',
        ) => {
            const extraPayload = getPayload();
            const eventIdentifier =
                eventType === 'view'
                    ? 'View'
                    : eventType === 'close'
                    ? 'Close'
                    : 'Click';
            const eventPrefix = eventLocation
                ? `${eventIdentifier} ${eventLocation}`
                : `${eventIdentifier}`;
            const event = eventName
                ? `${eventPrefix} ${eventName}`
                : eventPrefix;
            pushEvent(event, Object.assign({}, payload, extraPayload));
        },
        [eventLocation, getPayload],
    );

    const trackEventRaw = useCallback(
        (event: string, payload?: Record<string, unknown>) => {
            const extraPayload = getPayload();
            pushEvent(event, Object.assign({}, payload, extraPayload));
        },
        [getPayload],
    );

    const trackOpenCloseEvent = useCallback(
        (open: boolean, location?: string) => {
            if (open) {
                trackEvent(location || '', undefined, 'view');
            } else {
                trackEvent(location || '', undefined, 'close');
            }
        },
        [trackEvent],
    );

    useMemo(() => {
        if (!trackPageVisit || !pageName?.trim()) return;
        trackOpenCloseEvent(true);
        //track page visit only on load
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TrackingContext.Provider
            value={{
                trackEvent,
                trackEventRaw,
                pageName: eventLocation,
                trackOpenCloseEvent,
                hideInChild,
            }}
        >
            {children}
        </TrackingContext.Provider>
    );
};

/* eslint @typescript-eslint/no-duplicate-enum-values: 0 */
export enum PAGE_NAME {
    Login = 'Login',
    ResetPassword = 'Resest Password',
    RecoverPassword = 'Recover Password',
    Root = 'Root',
    Overview = 'Overview',
    Confirm = 'Confirm',
    Invite = 'Invite',
    Resend = 'Resend',
    Donations = 'Donations',
    DonationActivity = 'Donation Activity Tab',
    DonationSummary = 'Donation Summary Tab',
    BankDeposits = 'Bank Deposits',
    Refunds = 'Refunds',
    Donors = 'Donors',
    DonorsNew = 'New Donors',
    DonorProfile = 'Donor Profile',
    DonorProfileHistoryTab = 'Donor Profile History tab',
    DonorProfileMessageTab = 'Donor Profile Message tab',
    InviteDonor = 'Invite Donor',
    Integrations = 'Integrations',
    IntegrationsHomeTab = 'Home Tab',
    IntegrationsDonorsTab = 'Donors Tab',
    IntegrationsEnvelopesTab = 'Envelopes Tab',
    IntegrationsExportedTab = 'Exported Donations Tab',
    IntegrationsSettingsTab = 'Settings Tab',
    IntegrationsCustomReports = 'Custom Reports',
    Navigation = 'Navigation',
    PrimaryRepresentativeNotification = 'Primary Representative Notification',
    PrimaryRepresentativeNonAdminNotification = 'Primary Representative Non Admin Notification',
    ReportGenerate = 'Generate Report Page',
    Reports = 'Reports',
    ReportsRoot = 'Root',
    ReportsGenerateTab = 'Generate Reports Tab',
    ReportsOldDashboardTab = 'Old Dashboard Reports Tab',
    ReportsHistory = 'Reports History Tab',
    ReportsReconciliation = 'Reconciliation',
    Settings = 'Settings',
    SettingsRoot = 'Root',
    SettingsAppProfile = 'App Profile',
    SettingsAppProfileCustomizeProfile = 'Customize Profile Tab',
    SettingsAppProfileCustomizeReceipt = 'Customize Receipt Tab',
    SettingsCampuses = 'Campuses',
    SettingsQuickGive = 'Quick Give',
    SettingsEnvelopes = 'Envelopes',
    SettingsEnvelopesActive = 'Active',
    SettingsEnvelopesInactive = 'Inactive',
    SettingsUsers = 'Users',
    SettingsBankInfo = 'Bank Info',
    SettingsBankInfoPrimaryRepTab = 'Primary representative Tab',
    SettingsBankInfoBankAccountTab = 'Next-day deposit Tab',
    SettingsOrganizationInfo = 'Organziation Info',
    SettingsOrganizationInfoPhysicalAddress = 'Physical Address',
    SettingsOrganizationInfoMailingAddress = 'Mailing Address',
    SettingsOrganizationInfoContactInfo = 'Contact Info',
    SettingsOrganizationInfoSocialInfo = 'Social Info',
    SettingsMissionStatement = 'Mission Statement',
    SocialMediaGiving = 'Social Media Giving',
    FacebookGiving = 'FacebookGiving',
    TwitterGiving = 'TwitterGiving',
    InstagramGiving = 'InstagramGiving',
    YouTubeGiving = 'YouTubeGiving',
    OnlineGiving = 'Online Giving',
    GivingTools = 'Giving Tools',
    Givelithon = 'Givelithon',
    GivelithonLaunch = 'GivelithonLaunch',
    GivelifyButtonPage = 'GivelifyButtonPage',
    GivelifyButtonPagePublic = 'GivelifyButtonPagePublic',
    EditProfile = 'Edit Profile',
    Datepicker = 'Datepicker',
    CaptivePortal = 'Captive Portal',
}

export enum MODAL_NAME {
    DepositDetails = '[Deposit Details Modal]',
    DonationDetails = '[Donation Details Modal]',
    GivingStyleHelp = '[Giving Style Help Modal]',
    ConfirmCancelBox = '[Confirm Cancel Box]',
    ConfirmDeleteBox = '[Confirm Delete Box]',
    CanNotDeleteBox = '[Can Not Delete Box]',
    ConfirmShowBox = '[Confirm Show Box]',
    ConfirmHideBox = '[Confirm Hide Box]',
    CreateEnvelope = '[Create Envelope Modal]',
    EditEnvelope = '[Edit Envelope Modal]',
    AddPrimaryRepresentative = '[Add Primary Representative]',
    AddPrimaryRepresentativeNonAdmin = '[Add Primary Representative Non Admin]',
}
