import React from 'react';
import { GivelifyLabel, GivelifyRadio } from '@givelify/ui';
import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    background: 'inherit',
    boxShadow: '0px 0px 8px rgba(152, 162, 173, 0.4)',
    borderRadius: 10,
    display: 'flex',
    minHeight: 64,
    cursor: 'pointer',
}));

const RadioWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const HeaderContentWrapper = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    flex: 1,
    margin: theme.spacing(1.25, 1),
    overflow: 'hidden',
}));

const Heading = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export type ListTileBaseProps = {
    id?: string;
    selected: boolean;
    onSelect?: (selected: boolean) => void;
    heading?: string;
    headerContent?: JSX.Element;
};

export const ListTileBase: React.FCC<ListTileBaseProps> = ({
    id,
    selected,
    onSelect,
    heading,
    headerContent,
    children,
}) => {
    return (
        <Wrapper
            data-testid={`${id}-tile`}
            id={`${id}-tile`}
            onClick={() => onSelect && onSelect(!selected)}
        >
            <RadioWrapper>
                <GivelifyRadio
                    aria-label="open"
                    checked={selected}
                    color="primary"
                    data-testid={`${id}-radio`}
                    id={`${id}-radio`}
                    name="open"
                />
            </RadioWrapper>
            <HeaderContentWrapper>
                {heading && (
                    <Heading>
                        <GivelifyLabel
                            text={heading}
                            textOverflow="ellipsis"
                            variant="body2B"
                        />
                    </Heading>
                )}
                {headerContent && <Heading>{headerContent}</Heading>}
                {children}
            </HeaderContentWrapper>
        </Wrapper>
    );
};
