import { useDispatch } from 'react-redux';
import {
    applyMiddleware,
    combineReducers,
    createStore,
    compose,
    Store,
} from 'redux';
import { thunk, ThunkDispatch } from 'redux-thunk';

import F1CCBIntegration from './automatedIntegration/reducer';
import {
    AutoIntegrationState,
    IntegrationSecondActions,
} from './automatedIntegration/types';
import { DonationsActions } from './dashboard/donations/types';
import { DonorsActions } from './dashboard/donors/types';
import { EnvelopesActions } from './dashboard/envelopes/types';
import { LifeTimeActions } from './dashboard/lifeTime/types';
// TODO: change naming after package @givelify/utils is used everywhere
import { LifeTimeActions as NewLifeTimeActions } from './dashboard/newLifeTime/types';
import Dashboard from './dashboard/reducers';
import { DashboardState } from './dashboard/types';
import { DonationsPageActions } from './donations/donation/types';
import Donations, { DonationReducers } from './donations/reducer';
import Donee from './donee/reducer';
import { DoneeState, DoneeActions } from './donee/types';
import DoneeNotifications from './doneeNotification/reducer';
import {
    DoneeNotificationActions,
    DoneeNotificationState,
} from './doneeNotification/types';
import ReportIntegration from './integration/reducer';
import { IntegrationState, IntegrationActions } from './integration/types';
import Integrations from './integrations/reducer';
import { IntegrationsState, IntegrationsActions } from './integrations/types';
import Notifications from './notifications/reducer';
import {
    NotificationsActions,
    NotificationsState,
} from './notifications/types';
import Report from './report/reducer';
import { ReportStoreState, ReportActions } from './report/types';
import { OfficersActions, OfficersState } from './settings/officers/types';
import Settings from './settings/reducers';
import System from './system/reducer';
import { SystemActions, SystemState } from './system/types';
import User from './user/reducer';
import { UserState, UserActions } from './user/types';

export type AppState = {
    System: SystemState;
    User: UserState;
    Report: ReportStoreState;
    ReportIntegration: IntegrationState;
    F1CCBIntegration: AutoIntegrationState;
    Dashboard: DashboardState;
    Donations: DonationReducers;
    Donee: DoneeState;
    Integrations: IntegrationsState;
    Settings: {
        Officers: OfficersState;
    };
    Notifications: NotificationsState;
    DoneeNotifications: DoneeNotificationState;
};

export type AppActions =
    | SystemActions
    | UserActions
    | ReportActions
    | IntegrationActions
    | IntegrationSecondActions
    | DonationsActions
    | DonorsActions
    | EnvelopesActions
    | LifeTimeActions
    | NewLifeTimeActions
    | DonationsPageActions
    | IntegrationsActions
    | DoneeActions
    | NotificationsActions
    | DoneeNotificationActions
    | OfficersActions;

const createRootReducer = () =>
    combineReducers({
        System,
        User,
        Report,
        ReportIntegration,
        F1CCBIntegration,
        Dashboard,
        Donations,
        Integrations,
        Donee,
        Settings,
        Notifications,
        DoneeNotifications,
    });
const composeEnhancers =
    //eslint-disable-next-line
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const initializeStore = (initialState: Partial<AppState> = undefined) =>
    createStore(
        createRootReducer(),
        initialState,
        process.env.NODE_ENV === 'development'
            ? composeEnhancers(applyMiddleware(thunk))
            : applyMiddleware(thunk),
    );

let store: Store<AppState, AppActions>;
const GetStore = (initialState?: Partial<AppState>, createNew?: boolean) => {
    if (!store || createNew) {
        store = initializeStore(initialState);
    }
    return store;
};

export const getDispatch = () =>
    GetStore().dispatch as ThunkDispatch<AppState, unknown, AppActions>;

export const useAppDispatch = () =>
    useDispatch() as ThunkDispatch<AppState, unknown, AppActions>;

export default GetStore;
