import { Pagination } from '../../types/paginationTypes';
import { Report } from '../report/types';
import {
    LoadReportsStart as LoadReportsStartActionType,
    LoadReportsSuccess as LoadReportsSuccessActionType,
    UpdateReport as UpdateReportActionType,
    OnMatchDonor as OnMatchDonorActionType,
    OnMatchEnvelope as OnMatchEnvelopeActionType,
    LoadTotalMatchStateStart as LoadTotalMatchStateStartActionType,
    LoadTotalMatchStateSuccess as LoadTotalMatchStateSuccessActionType,
    LoadMatchProgressStart as LoadMatchProgressStartActionType,
    LoadDonorsMatchProgressSuccess as LoadDonorsMatchProgressSuccessActionType,
    LoadEnvelopesMatchProgressSuccesss as LoadEnvelopesMatchProgressSuccesssActionType,
    DownloadReport as DonwloadReportActionType,
    ClearMatchNotification as CloseMatchNotificationActionType,
    SetIntegrationSettingsFlag,
    ShowReportGeneratingDialog,
} from './types';

export const LoadReportsStart = (page: number): LoadReportsStartActionType => ({
    type: 'LOAD_REPORTS_START',
    data: {
        page,
    },
});

export const LoadReportsSuccess = (
    data: Report[],
    pagination: Pagination,
): LoadReportsSuccessActionType => ({
    type: 'LOAD_REPORTS_SUCCESS',
    data: {
        data,
        pagination,
    },
});

export const UpdateReport = (
    report: Report,
    oldReportId: number,
): UpdateReportActionType => ({
    type: 'UPDATE_REPORT',
    data: {
        report,
        oldReportId,
    },
});

export const OnMatchDonor = (
    matchCreated: boolean,
    matchRemoved: boolean,
    reportId?: number,
    donorId?: number,
): OnMatchDonorActionType => ({
    type: 'ON_MATCH_DONOR',
    data: { reportId, matchCreated, matchRemoved, donorId },
});

export const OnMatchEnvelope = (
    matchCreated: boolean,
    matchRemoved: boolean,
    reportId?: number,
): OnMatchEnvelopeActionType => ({
    type: 'ON_MATCH_ENVELOPE',
    data: { reportId, matchCreated, matchRemoved },
});

export const LoadTotalMatchStateStart =
    (): LoadTotalMatchStateStartActionType => ({
        type: 'LOAD_TOTAL_MATCH_STATE_START',
    });

export const LoadTotalMatchStateSuccess = (
    type: 'donors' | 'envelopes',
    count: number,
): LoadTotalMatchStateSuccessActionType => ({
    type: 'LOAD_TOTAL_MATCH_STATE_SUCCESS',
    data: {
        count,
        type,
    },
});

export const LoadMatchProgressStart = (): LoadMatchProgressStartActionType => ({
    type: 'LOAD_MATCH_PROGRESS_START',
});

export const LoadDonorsMatchProgressSuccess = (
    total: number,
    totalMatched: number,
    reportId?: number,
): LoadDonorsMatchProgressSuccessActionType => ({
    type: 'LOAD_DONORS_MATCH_PROGRESS_SUCCESS',
    data: {
        total,
        totalMatched,
        reportId,
    },
});

export const LoadEnvelopesMatchProgressSuccess = (
    total: number,
    totalMatched: number,
    reportId?: number,
): LoadEnvelopesMatchProgressSuccesssActionType => ({
    type: 'LOAD_ENVELOPES_MATCH_PROGRESS_SUCCESS',
    data: {
        total,
        totalMatched,
        reportId,
    },
});

export const DownloadReport = (
    reportId: number,
    userName: string,
    date: string,
): DonwloadReportActionType => ({
    type: 'DOWNLOAD_REPORT',
    data: {
        reportId,
        userName,
        date,
    },
});

export const showReportGeneratingDialog = (
    show = true,
): ShowReportGeneratingDialog => ({
    type: 'SHOW_REPORT_GENERATING_DIALOG',
    data: { show },
});

export const CloseMatchNotification = (
    reportId: number,
): CloseMatchNotificationActionType => ({
    type: 'CLOSE_MATCH_NOTIFICATION',
    data: {
        reportId,
    },
});

export const setIntegrationSettingsFunc = (
    saved = false,
): SetIntegrationSettingsFlag => ({
    type: 'SET_INTEGRATION_SETTINGS',
    data: { saved },
});
