import {
    GivelifyButton,
    GivelifyCheckbox,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLink,
    GivelifyNotification,
} from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const legacyReportsAccentColor = '#0288CB';
export const legacyReportsAccentTextColor = '#0088CC';

export const ReportFormContainer = styled('div')({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
});

export const ReportDateRow = styled('div')({
    maxWidth: 592,
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gap: 16,
    '& > :first-child': {
        gridColumn: 'span 3',
    },
});

export const DefineNormal = styled(GivelifyLabel)({
    display: 'inline',
});

export const Define = styled(GivelifyLabel)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(1),
        paddingRight: theme.spacing(6),
    },
}));

export const GenerateButton = styled(GivelifyButton, {
    shouldForwardProp: (prop) => prop !== 'isLoading',
})<{ isLoading: boolean }>(({ theme, isLoading }) => ({
    marginRight: theme.spacing(2),
    padding: theme.spacing(0, 5),
    height: 52,
    background: isLoading
        ? `${legacyReportsAccentColor} !important`
        : legacyReportsAccentColor,
    '&:not(:disabled):hover': {
        background: legacyReportsAccentColor,
    },
    '&.btnPrimaryLoading': {
        backgroundColor: legacyReportsAccentColor,
    },
}));

export const FormButtons = styled('div')({
    display: 'flex',
});

export const BackToReportsIcon = styled(GivelifyIcon)({
    marginRight: 16,
});

export const NavigationLinks = styled(Grid)(({ theme }) => ({
    margin: '0 0 32px 0',
    '& a': {
        color: legacyReportsAccentColor,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
    '& a:hover': {
        color: legacyReportsAccentColor,
    },
    [theme.breakpoints.down(817)]: {
        marginTop: '16px !important',
    },
    [theme.breakpoints.down('mobile')]: {
        padding: '0 8px',
    },
}));

export const BackToReportsLink = styled(GivelifyLink)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
    },
}));

export const ViewPreviousReportsLink = styled(GivelifyLink)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: '12px',
    },
}));

export const ReportItemContent = styled('div')(({ theme }) => ({
    background: theme.palette.background.paper,
    marginTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
    },
    [theme.breakpoints.down('mobile')]: {
        padding: '0 8px',
    },
}));

export const ReportIcon = styled(GivelifyIcon)(({ theme }) => ({
    fontSize: '40px !important',
    color: legacyReportsAccentColor + ' !important',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('mobile')]: {
        fontSize: '32px !important',
    },
}));

export const ReportInfoHeader = styled(Grid)(({ theme }) => ({
    flexBasis: 'content',
    padding: 0,
    [theme.breakpoints.down('mobile')]: {
        padding: '0 8px',
    },
}));

export const InfoIcon = styled(GivelifyIcon)({
    fill: legacyReportsAccentColor,
});

export const InfoIconWrapper = styled('div')({
    color: legacyReportsAccentColor,
    fill: legacyReportsAccentColor,
});

export const LegacyReportTooltipWrapper = styled(Grid)(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: '#717171',
        padding: theme.spacing(2),
    },
}));

export const ExcludeEnvelopes = styled(GivelifyCheckbox)({
    marginLeft: '2px',
});

export const ExcludeEnvelopesWrapper = styled(Grid)(({ theme }) => ({
    '& .MuiButtonBase-root.MuiCheckbox-root': {
        marginRight: '2px',
        marginLeft: 0,
    },
    '& .MuiStack-root': {
        '& .MuiStack-root': {
            width: 'calc(100% - 18px)',
        },
    },
    '& .MuiTypography-root': {
        fontSize: 14,
        fontWeight: 600,
        color: '#4a4a4a',
        whiteSpace: 'nowrap',
        textOverflow: 'initial',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
}));

export const ReportItemContainer = styled(Grid)(({ theme }) => ({
    borderRadius: '10px',
    boxShadow: '0 0 8px rgba(152,162,173,0.4)',
    width: 248,
    marginRight: '12px',
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
    [theme.breakpoints.down(1432)]: {
        width: 228,
    },
    [theme.breakpoints.down(1252)]: {
        width: 248,
    },
    [theme.breakpoints.down('mobile')]: {
        marginRight: 0,
        width: '100%',
        minHeight: 211,
    },
    '&:last-child': {
        marginRight: 0,
    },
    '& .MuiTooltip-tooltip': {
        backgroundColor: '#717171',
        padding: theme.spacing(2),
    },
}));

export const ReportItemHeader = styled('div')(({ theme }) => ({
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 3, 1),
    borderBottom: '1px solid #d8d8d8',
    [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(2),
    },
}));

export const GetReport = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    marginTop: 'auto',
});

export const ReportItemDefine = styled('div')({
    flexGrow: 1,
    marginBottom: 6,
});

export const GetReportLink = styled(GivelifyButton)({
    padding: 0,
    fontSize: 14,
    fontWeight: 600,
    color: legacyReportsAccentTextColor,
    borderRadius: 0,
    '&:hover': {
        textDecoration: 'underline',
        color: legacyReportsAccentTextColor,
    },
});

export const ReportItemBody = styled('div')(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(2, 2, 3),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 156,
    [theme.breakpoints.down('xs')]: {
        minHeight: 92,
    },
}));

export const ReportNotification = styled(GivelifyNotification)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export const LegacyReportsHeaderWrapper = styled(Grid)(({ theme }) => ({
    marginBottom: '32px',
    [theme.breakpoints.down('mobile')]: {
        marginTop: '16px',
        marginBottom: '24px',
        padding: '0 8px',
    },
}));

export const LegacyReportsSubHeaderWrapper = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        padding: '0 8px',
    },
}));

export const ViewPreviousReports = styled(Grid)(({ theme }) => ({
    '& a': {
        color: legacyReportsAccentColor,
    },
    '& a:hover': {
        color: legacyReportsAccentColor,
    },
    [theme.breakpoints.down(817)]: {
        marginTop: '16px !important',
    },
}));

export const LegacyReportsHeader = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down(1024)]: {
        fontSize: 18,
    },
}));

export const ReportRow = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(4),
}));

export const ReportMainContent = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
    },
    [theme.breakpoints.down('mobile')]: {
        padding: '0 8px',
    },
}));

export const ReportList = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
    },
    [theme.breakpoints.down('mobile')]: {
        padding: '0 8px',
    },
}));
