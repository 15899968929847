import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgComputer = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="234"
        width="239"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 239 234"
    >
        <path
            d="M223.012 179.629H15.9885C7.16724 179.629 0 172.472 0 163.662V15.9671C0 7.15765 7.16724 0 15.9885 0H223.012C231.833 0 239 7.15765 239 15.9671V163.662C239 172.472 231.833 179.629 223.012 179.629ZM15.9885 2.40882C8.47664 2.40882 2.41205 8.4653 2.41205 15.9671V163.662C2.41205 171.164 8.47664 177.221 15.9885 177.221H223.012C230.523 177.221 236.588 171.164 236.588 163.662V15.9671C236.588 8.4653 230.523 2.40882 223.012 2.40882H15.9885Z"
            fill="black"
        />
        <path
            d="M179.594 234H53.4089C49.4807 234 46.3105 230.834 46.3105 226.911C46.3105 222.988 49.4807 219.822 53.4089 219.822H179.594C183.522 219.822 186.692 222.988 186.692 226.911C186.692 230.834 183.522 234 179.594 234Z"
            fill="#DBD6FF"
        />
        <path
            d="M116.193 172.403C122.282 172.403 127.219 167.473 127.219 161.391C127.219 155.31 122.282 150.379 116.193 150.379C110.103 150.379 105.166 155.31 105.166 161.391C105.166 167.473 110.103 172.403 116.193 172.403Z"
            fill="#DBD6FF"
        />
        <path
            d="M171.256 217.482H67.7442C67.3996 217.482 67.055 217.345 66.8483 217.069C66.6415 216.794 66.5037 216.45 66.5726 216.106L72.2926 178.253C72.3615 177.634 72.9129 177.221 73.4642 177.221H165.467C166.087 177.221 166.569 177.634 166.638 178.253L172.358 216.106C172.427 216.45 172.289 216.794 172.083 217.069C171.945 217.345 171.6 217.482 171.256 217.482ZM69.1225 215.074H169.808L164.433 179.629H74.5668L69.1225 215.074Z"
            fill="black"
        />
        <path
            d="M223.012 179.629H15.9885C7.16724 179.629 0 172.472 0 163.662V138.886C0 138.198 0.551326 137.647 1.24048 137.647H237.828C238.518 137.647 239.069 138.198 239.069 138.886V163.662C239 172.472 231.833 179.629 223.012 179.629ZM2.41205 140.056V163.662C2.41205 171.164 8.47664 177.221 15.9885 177.221H223.012C230.523 177.221 236.588 171.164 236.588 163.662V140.056H2.41205Z"
            fill="black"
        />
        <path
            d="M221.082 131.866H17.9875C13.508 131.866 9.85547 128.218 9.85547 123.745V17.4123C9.85547 12.9388 13.508 9.29114 17.9875 9.29114H221.082C225.562 9.29114 229.214 12.9388 229.214 17.4123V123.745C229.146 128.218 225.493 131.866 221.082 131.866ZM17.9875 11.7C14.8174 11.7 12.2675 14.2464 12.2675 17.4123V123.745C12.2675 126.911 14.8174 129.457 17.9875 129.457H221.082C224.253 129.457 226.802 126.911 226.802 123.745V17.4123C226.802 14.2464 224.253 11.7 221.082 11.7H17.9875Z"
            fill="black"
        />
        <path
            d="M182.627 229.32H56.4421C52.5139 229.32 49.3438 226.154 49.3438 222.231C49.3438 218.308 52.5139 215.142 56.4421 215.142H182.627C186.555 215.142 189.725 218.308 189.725 222.231C189.725 226.154 186.555 229.32 182.627 229.32ZM56.4421 217.482C53.8233 217.482 51.7558 219.616 51.7558 222.162C51.7558 224.709 53.8922 226.842 56.4421 226.842H182.627C185.246 226.842 187.313 224.709 187.313 222.162C187.313 219.616 185.177 217.482 182.627 217.482H56.4421Z"
            fill="black"
        />
        <path
            d="M119.499 170.889C112.746 170.889 107.232 165.383 107.232 158.638C107.232 151.894 112.746 146.388 119.499 146.388C126.253 146.388 131.766 151.894 131.766 158.638C131.766 165.383 126.253 170.889 119.499 170.889ZM119.499 148.797C114.055 148.797 109.644 153.201 109.644 158.638C109.644 164.075 114.055 168.48 119.499 168.48C124.944 168.48 129.354 164.075 129.354 158.638C129.354 153.201 124.944 148.797 119.499 148.797Z"
            fill="black"
        />
        <path
            d="M203.99 44.9417H21.9837C19.9163 44.9417 18.1934 43.29 18.1934 41.1564V31.5211C18.1934 29.4564 19.8473 27.7358 21.9837 27.7358H203.99C206.058 27.7358 207.781 29.3876 207.781 31.5211V41.2253C207.781 43.29 206.058 44.9417 203.99 44.9417Z"
            fill="#DBD6FF"
        />
        <path
            d="M101.305 121.336H23.4304C20.6049 121.336 18.2617 119.065 18.2617 116.174V56.9859C18.2617 54.1641 20.5359 51.8241 23.4304 51.8241H101.305C104.131 51.8241 106.474 54.0953 106.474 56.9859V116.174C106.474 119.065 104.131 121.336 101.305 121.336Z"
            fill="#DBD6FF"
        />
        <path
            d="M155.061 84.1711L115.503 84.24C114.125 84.24 113.022 83.1388 113.022 81.7623L112.953 55.3341C112.953 53.9576 114.056 52.8564 115.434 52.8564L154.992 52.7876C156.37 52.7876 157.473 53.8888 157.473 55.2652L157.542 81.6935C157.473 83.07 156.37 84.1711 155.061 84.1711Z"
            fill="#DBD6FF"
        />
        <path
            d="M204.68 84.1023L166.776 84.1711C165.466 84.1711 164.364 83.1388 164.364 81.7623L164.295 55.5405C164.295 54.2329 165.329 53.1317 166.707 53.1317L204.611 53.0629C205.92 53.0629 207.023 54.0952 207.023 55.4717L207.092 81.6935C207.092 83.0011 205.989 84.1023 204.68 84.1023Z"
            fill="#DBD6FF"
        />
        <path
            d="M155.061 121.955L115.71 122.024C114.332 122.024 113.229 120.923 113.229 119.615L113.16 93.187C113.16 91.8105 114.263 90.7093 115.572 90.7093L154.923 90.6405C156.301 90.6405 157.404 91.7417 157.404 93.0493L157.473 119.478C157.473 120.854 156.37 121.955 155.061 121.955Z"
            fill="#DBD6FF"
        />
        <path
            d="M204.266 121.886L166.362 121.955C165.052 121.955 163.95 120.923 163.95 119.546L163.881 93.3935C163.881 92.0859 164.915 90.9847 166.293 90.9847L204.197 90.9159C205.506 90.9159 206.609 91.9482 206.609 93.3247L206.678 119.478C206.609 120.785 205.575 121.818 204.266 121.886Z"
            fill="#DBD6FF"
        />
    </svg>
);

export const ComputerIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgComputer}
        fontSize={fontSize}
    />
);
