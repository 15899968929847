import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useInvokeApi } from '@givelify/utils';
import roleTypes from 'constants/roleTypes';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { CampusesContext, AddCampusItem } from './CampusesContext';
import { CampusItem } from './CampusList/types';

export const useCampusesContext = () => useContext(CampusesContext);

interface AddNewCampus {
    name: string;
    nickname: string;
    address: string;
    city: string;
    phone: string;
    state: string;
    zip: string;
    ypId?: string;
}

interface AddSelectedCampus {
    campusId: number;
    nickname: string;
    ypId?: string;
}

export const CampusesProvider: React.FCC = ({ children }) => {
    const { doneeId, userRole } = useSelector((state: AppState) => ({
        doneeId: state.Donee.campuses[0].id,
        userRole: state.User.user?.role,
    }));

    const isEditable = userRole === roleTypes.ADMIN;

    const [showAddCampusModal, setShowAddCampusModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showCampusSubmittedModal, setShowCampusSubmittedModal] =
        useState(false);

    const [items, setItems] = useState<CampusItem[]>();

    const [getCampusListRequestState, makeGetCampusListRequest] = useInvokeApi<{
        data: CampusItem[];
    }>();
    const [addCampusRequestState, makeAddCampusRequest] = useInvokeApi<
        { data: CampusItem },
        AddNewCampus | AddSelectedCampus
    >();

    const isLoading = getCampusListRequestState.type === 'REQUEST_START';
    const hasItems =
        getCampusListRequestState.type === 'REQUEST_SUCCESS' &&
        items?.length > 0;
    const noItems =
        getCampusListRequestState.type === 'REQUEST_SUCCESS' &&
        items?.length === 0;

    const getList = useCallback(() => {
        makeGetCampusListRequest('GET', `/donees/${doneeId}/campus`);
    }, [makeGetCampusListRequest, doneeId]);

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(() => {
        if (getCampusListRequestState.type === 'REQUEST_SUCCESS') {
            setItems(getCampusListRequestState.response.data);
        }
    }, [getCampusListRequestState]);

    const addCampus = (data: AddCampusItem) => {
        // if campusId ends with "_yp" then it means it comes from yellow pages and we dont have it in the db
        // in this case we need to send full campus data to the backend to create a campus
        const validCampusId = !!Number(data?.campusId);

        makeAddCampusRequest(
            'POST',
            `/donees/${doneeId}/campus`,
            validCampusId
                ? {
                      campusId: Number(data.campusId),
                      nickname: data.nickname,
                      ...(data?.ypId && { ypId: data?.ypId }),
                  }
                : {
                      name: data.name,
                      nickname: data.nickname,
                      address: data.address,
                      city: data.city,
                      phone: data.phone,
                      state: data.state,
                      zip: data.zip,
                      ...(data?.ypId && { ypId: data?.ypId }),
                  },
        );
    };

    useEffect(() => {
        if (addCampusRequestState.type === 'REQUEST_SUCCESS') {
            getList();
            setShowAddCampusModal(false);
            setShowConfirmModal(false);
            setShowCampusSubmittedModal(true);
        }
    }, [addCampusRequestState, getList]);

    return (
        <CampusesContext.Provider
            value={{
                doneeId,
                isEditable,
                campuses: items,
                getList,
                addCampus,

                hasItems,
                isLoading,
                noItems,

                showAddCampusModal,
                setShowAddCampusModal,

                showConfirmModal,
                setShowConfirmModal,

                showCampusSubmittedModal,
                setShowCampusSubmittedModal,

                addCampusRequestState,
            }}
        >
            {children}
        </CampusesContext.Provider>
    );
};
