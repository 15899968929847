import React, { useState, useMemo } from 'react';
import { Donee, User } from '@givelify/api';
import { isValidAddress } from '@givelify/givelify-ui';
import { AppProfile } from '../../../@types/assets/onboarding';
import CardProfile from '../../../cards/v2/CardProfile';
import { CustomizeProfileModal } from '../../../customizeProfile';
import { Step } from '../../../customizeProfile/CustomizeProfileModalComponents';
import { convertDoneeToOnboardingInfo } from '../../../utils/convertDoneeToOnboardingInfo';

export const ProfileCard: React.FCC<{
    donee: Donee;
    user: User;
    settingsUsersPath: string;
    onClose: () => void;
    setButtonBringToFront: (value: boolean) => void;
    onDoneeUpdate:(donee:Donee)=>void
}> = ({
    donee,
    settingsUsersPath,
    user,
    onClose,
    setButtonBringToFront,
    onDoneeUpdate
}) => {
    const onboarding = convertDoneeToOnboardingInfo(donee);

    const [appProfile, setAppProfile] = useState<AppProfile>(
        onboarding.appProfile,
    );

    const [openProfileInfoModal, setOpenProfileInfoModal] = useState(false);

    const customizeProfileStarted = useMemo(
        () =>
            !!appProfile.bannerImage ||
            !!appProfile.faithLeaderImage ||
            !!appProfile.organizationLogo ||
            isValidAddress(appProfile.organizationAddress, true),
        [appProfile],
    );

    const requiredPartIsCompleted = useMemo(() => {
        if (!appProfile.bannerImage) return false;
        if (
            onboarding.organizationType === 'church' &&
            !appProfile.faithLeaderImage?.url &&
            appProfile.faithLeader &&
            appProfile.faithLeader.userId
        )
            return false;
        if (!appProfile.organizationLogo) return false;
        if (!isValidAddress(appProfile.organizationAddress, true)) return false;
        return true;
    }, [appProfile, onboarding.organizationType]);

    const custmizeProfileInitialStep = useMemo((): Step => {
        const step1Finished =
            !!appProfile.bannerImage &&
            (onboarding.organizationType === 'nonprofit' ||
                (appProfile.faithLeader &&
                    appProfile.faithLeader.userId &&
                    appProfile.faithLeaderImage?.url)) &&
            isValidAddress(appProfile.organizationAddress, true);
        return step1Finished ? 'mission' : 'main';
    }, [appProfile, onboarding.organizationType]);

    return (
        <>
            <CardProfile
                customizeProfileStarted={customizeProfileStarted}
                onCloseClick={onClose}
                onCustomizeClick={() => setOpenProfileInfoModal(true)}
                organizationType={onboarding.organizationType}
                requiredPartIsComplete={requiredPartIsCompleted}
            />

            {openProfileInfoModal && (
                <CustomizeProfileModal
                    appProfile={appProfile}
                    doneeId={donee.id}
                    initialStep={custmizeProfileInitialStep}
                    onClose={() => setOpenProfileInfoModal(false)}
                    onDoneeUpdate={onDoneeUpdate}
                    onSubmit={setAppProfile}
                    open={true}
                    organizationName={onboarding.organizationName}
                    organizationType={onboarding.organizationType}
                    setButtonBringToFront={setButtonBringToFront}
                    settingsUsersPath={settingsUsersPath}
                    step="main"
                    userId={user.id}
                />
            )}
        </>
    );
};
