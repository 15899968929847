import React from 'react';
import { pushEvent } from '@givelify/utils';
import { Grid } from '@material-ui/core';

import CopyGivingLink from '../../../components/CopyGivingLink';
import Label from '../../../components/Label';
import Page from '../../../components/Page';

import SMGivingTip from '../../../components/SMGivingTip';

import { useConnectorState } from '../../../provider/Connector';
import { IG_GIVING_LOADED } from '../../../utils/cleverTapEvents';
import { useTranslations } from '../../../utils/translations';
import Preview from './Preview';
import { useStyle } from './style';

export const InstagramGiving: React.FCC = () => {
    const cs = useStyle();
    const { onlineGivingURLs } = useConnectorState();

    const givingLink =
        onlineGivingURLs.find((x) => x.channel === 'INST')?.shortLink || '';

    const { pageTitle, increaseGiving, copyOrgLink } = useTranslations(
        'socialMediaGiving.instagram.page',
        {
            pageTitle: 'title',
            increaseGiving: ['title', 'desc'],
            copyOrgLink: ['title', 'desc1', 'desc2'],
        },
    );

    React.useEffect(() => {
        pushEvent(IG_GIVING_LOADED);
    }, []);

    return (
        <Page
            useBreadcrumb
            useDivider
            landingPageName="socialMediaGiving"
            name="Instagram"
            title={pageTitle}
        >
            <div className={cs.container} data-testid="instagram-giving">
                <Grid container spacing={5}>
                    <Grid item className={cs.fullContent} md={6} sm={12}>
                        <Label variant="heading4">{increaseGiving.title}</Label>
                        <Label className={cs.shareBody} variant="body1">
                            {increaseGiving.desc}
                        </Label>

                        <Label
                            className={cs.givingLinkTitle}
                            variant="heading4"
                        >
                            {copyOrgLink.title}
                        </Label>
                        <Label className={cs.shareBody} variant="body1">
                            {copyOrgLink.desc1}
                        </Label>
                        <Label className={cs.shareBody} variant="body1">
                            {copyOrgLink.desc2}
                        </Label>

                        <CopyGivingLink givingLink={givingLink} />
                    </Grid>

                    <Grid item className={cs.fullContent} md={6} sm={12}>
                        <Preview />
                    </Grid>
                </Grid>

                <SMGivingTip className={cs.tipContainer} media="instagram" />
            </div>
        </Page>
    );
};
