import React, { useMemo } from 'react';
import { GivelifyForm } from '@givelify/ui';
import { SchemaGuard, TrackingProvider, UnwrapSchema } from '@givelify/utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { I18N_NAMESPACE } from '../../../../consts';
import { PrimaryRepInfoFormType, PrimaryRepInfoProps } from '../types';
import View from './view';

export const PrimaryRepInfo: React.FCC<PrimaryRepInfoProps> = ({
    data,
    onCancelClick,
    onContinueClick,
    titles,
    user,
    iAmPrimaryRep,
    onRadioChange,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);

    const copy = useMemo(
        () => ({
            invalidDate: t('primaryRepresentative.invalidDate'),
        }),
        [t],
    );

    const schema = yup.object().shape({
        firstName: yup.string().required(''),
        lastName: yup.string().required(''),
        title: yup.string().required(''),
        socialSecurityNumber: yup.string().length(11, '').required(''),
        birthDate: yup
            .mixed<dayjs.Dayjs>()
            .nullable()
            .required('')
            .typeError('')
            .test({
                test: (val) => dayjs(dayjs()).diff(val, 'year') >= 18,
                message: copy.invalidDate,
            }),
        iAmPrimaryRep: yup.boolean(),
    });

    SchemaGuard<UnwrapSchema<typeof schema, PrimaryRepInfoFormType>>();

    return (
        <TrackingProvider trackPageVisit pageName="Primary Rep Info">
            <GivelifyForm<PrimaryRepInfoFormType>
                defaultValues={{
                    firstName: data.firstName,
                    lastName: data.lastName,
                    title: data.title,
                    socialSecurityNumber: data.socialSecurityNumber,
                    birthDate: data.birthDate ? dayjs(data.birthDate) : undefined,
                    iAmPrimaryRep: iAmPrimaryRep,
                }}
                onSubmit={(data) => onContinueClick(data)}
                schema={schema as unknown as yup.SchemaOf<PrimaryRepInfoFormType>}
            >
                <View
                    data={data}
                    iAmPrimaryRep={iAmPrimaryRep}
                    onCancelClick={onCancelClick}
                    onContinueClick={onContinueClick}
                    onRadioChange={onRadioChange}
                    titles={titles}
                    user={user}
                />
            </GivelifyForm>
        </TrackingProvider>
    );
};
