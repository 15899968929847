import React from 'react';
import {
    BasicPageWrapper,
    GivelifyLabel,
    GivelifyLink,
    GivelifyPaper,
} from '@givelify/ui';
import { pushEvent } from '@givelify/utils';
import { Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MdiBuildIcon from '../../assets/icon/mdi-build.svg';
import GiveNowButtonLongPreview from '../../assets/online-giving/givelify-button-long-preview.png';
import GiveNowButtonPreview from '../../assets/online-giving/givelify-button-preview.png';
import GiveNowLinkTopArrow from '../../assets/online-giving/givenow-link-arrow-top.svg';
import GiveNowLinkRightArrow from '../../assets/online-giving/givenow-link-arrow.svg';
import CopyGivingLink from '../../components/CopyGivingLink';
import Config from '../../config';
import { useConnectorState } from '../../provider/Connector';
import { ONLINE_GIVING_LANDED } from '../../utils/cleverTapEvents';
import { useTranslations } from '../../utils/translations';
import { useShareModal } from './Modals/useShareModal';
import {
    BuildIcon,
    HideOnTablet,
    IntroBox,
    LineDivider,
    PreviewArrowsContainer,
    PreviewArrowText,
    PreviewImage,
    PreviewImageBox,
    ShareButton,
    ShowOnTablet,
    TopArrow,
} from './style';

export const OnlineGiving: React.FCC = () => {
    const { routeUrls } = useConnectorState();
    const { onlineGivingURLs } = useConnectorState();
    const navigate = useNavigate();
    const givingLink =
        onlineGivingURLs.find((x) => x.channel === 'OGA')?.shortLink || '';

    const { intro, givelifyButton } = useTranslations(
        'onlineGiving.landingPage',
        {
            intro: ['title', 'desc', 'link'],
            givelifyButton: [
                'title',
                'desc',
                'getButton',
                'sendToDevelopers',
                'shareDesc',
                'linkDesc',
            ],
        },
    );

    React.useEffect(() => {
        pushEvent(ONLINE_GIVING_LANDED);
    }, []);

    const [openShareModal, ShareModal] = useShareModal();

    return (
        <BasicPageWrapper
            showDivider
            contentWrapper="simple"
            title="Online giving"
        >
            <ShareModal />
            <IntroBox
                data-testid="online-giving-content"
                overflow="hidden"
                padding={4}
            >
                <div>
                    <GivelifyLabel
                        data-testid="description-title"
                        variant="heading1S"
                    >
                        {intro.title}
                    </GivelifyLabel>
                    <GivelifyLabel
                        data-testid="description-text"
                        marginBottom={3}
                        marginTop={2}
                        variant="body1"
                    >
                        {intro.desc}&nbsp;
                        <GivelifyLink
                            fontSize="inherit"
                            href={Config.onlineGivingKbUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                            text={intro.link}
                        />
                    </GivelifyLabel>
                    <CopyGivingLink
                        buttonVariant="primary"
                        givingLink={givingLink}
                    />
                </div>
            </IntroBox>
            <GivelifyPaper marginTop={3} padding={4}>
                <Grid container spacing={5}>
                    <Grid item sm={6} width="100%" xs={12}>
                        <GivelifyLabel
                            text={givelifyButton.title}
                            variant="body1B"
                        />
                        <GivelifyLabel
                            marginBottom={2}
                            marginTop={1}
                            text={givelifyButton.desc}
                            variant="body1"
                        />
                        <GivelifyLink
                            data-testid="get-givelify-button"
                            href={routeUrls.givelifyButton || ''}
                            id="givelify-button"
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(routeUrls.givelifyButton || '');
                            }}
                            text={
                                <>
                                    <BuildIcon alt="" src={MdiBuildIcon} />
                                    {givelifyButton.getButton}
                                </>
                            }
                            variant="body1"
                        />
                        <LineDivider />
                        <GivelifyLabel
                            text={givelifyButton.shareDesc}
                            variant="body1"
                        />
                        <ShareButton
                            data-testid="send-to-developers-button"
                            name="sendToDevelopers"
                            onClick={openShareModal}
                            size="large"
                            text={givelifyButton.sendToDevelopers}
                            variant="secondary"
                        />
                    </Grid>
                    <PreviewImageBox item sm={6} width="100%" xs={12}>
                        <Stack bottom={-32} position="relative">
                            <HideOnTablet>
                                <div>
                                    <PreviewImage
                                        alt="button preview"
                                        src={GiveNowButtonPreview}
                                    />
                                    <PreviewArrowsContainer>
                                        <div>
                                            <PreviewArrowText
                                                color="neutral-grey"
                                                variant="caption1"
                                            >
                                                {givelifyButton.linkDesc}
                                            </PreviewArrowText>
                                            <img
                                                alt={givelifyButton.linkDesc}
                                                src={GiveNowLinkRightArrow}
                                            />
                                        </div>
                                    </PreviewArrowsContainer>
                                </div>
                            </HideOnTablet>
                            <ShowOnTablet>
                                <Stack
                                    alignItems="center"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                >
                                    <PreviewArrowText
                                        color="neutral-grey"
                                        variant="caption1"
                                    >
                                        {givelifyButton.linkDesc}
                                    </PreviewArrowText>
                                    <TopArrow
                                        alt={givelifyButton.linkDesc}
                                        src={GiveNowLinkTopArrow}
                                    />
                                    <PreviewImage
                                        alt="button preview"
                                        src={GiveNowButtonLongPreview}
                                    />
                                </Stack>
                            </ShowOnTablet>
                        </Stack>
                    </PreviewImageBox>
                </Grid>
            </GivelifyPaper>
            <Stack marginBottom={6} marginTop={8} textAlign="center">
                <GivelifyLink
                    href={Config.supportUrl}
                    rel="noreferrer"
                    target="_blank"
                    text="Need help? Contact support"
                />
            </Stack>
        </BasicPageWrapper>
    );
};
