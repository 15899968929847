import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            clipRule="evenodd"
            d="M16 17H11C8.79086 17 7 15.2091 7 13V7C7 4.79086 8.79086 3 11 3H17C19.2091 3 21 4.79086 21 7V12C21 14.7614 18.7614 17 16 17Z"
            fill="transparent"
            fillRule="evenodd"
            stroke="#5F47F6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M12 17H13.4444C14.3036 17 15 16.3036 15 15.4444V14.1111C15 12.3929 16.3929 11 18.1111 11H19.4444C19.857 11 20.2527 10.8361 20.5444 10.5444C20.8361 10.2527 21 9.857 21 9.44444V8"
            stroke="#5F47F6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
        <path
            d="M17 16.8999V17V17C17 19.2091 15.2091 21 13 21H7C4.79086 21 3 19.2091 3 17V11C3 8.79086 4.79086 7 7 7V7"
            fill="transparent"
            stroke="#5F47F6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

export const CopyIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon {...props} color={color} component={SvgCopy} fontSize={fontSize} />
);
