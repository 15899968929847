import React from 'react';
import { RADIO_BUTTON_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import { Radio, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { GivelifyRadioProps } from './types';

const GivelifyRadioView = styled(Radio)(() => ({
    '&.MuiRadio-root': {
        padding: 0,
        fontSize: DesignTokens.measurement.iconDefaultSize,
        lineHeight: DesignTokens.measurement.iconDefaultSize,
    },
    '&.MuiRadio-colorPrimary': {
        color: DesignTokens.color.iconPrimary,
        '&:hover': {
            color: DesignTokens.color.iconHover,
            background: DesignTokens.color.borderComponentWhite,
        },
    },
    '&.Mui-checked': {
        '&.MuiRadio-colorPrimary': {
            color: DesignTokens.color.iconAccentDefault,
        },
    },
    '&.Mui-disabled': {
        color: DesignTokens.color.iconDisabled,
        background: DesignTokens.color.borderComponentWhite,
        '&.Mui-checked': {
            color: DesignTokens.color.iconDisabled,
        },
    },
}));

const GivelifyLabelEllipsis = styled(GivelifyLabel)({
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
});

const GivelifyRadioLabel: React.FCC<GivelifyRadioProps> = ({
    label,
    secondLabel,
    multipleLine,
    width = '100%',
    ...props
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    if (multipleLine) {
        return (
            <Stack alignItems="start" direction="row" spacing={0} width={width}>
                <GivelifyRadioView {...props} />
                <Stack
                    alignItems="start"
                    direction="column"
                    spacing={1}
                    width="calc(100% - 24px)"
                >
                    <GivelifyLabelEllipsis
                        text={label}
                        title={label}
                        variant={isMobile ? 'body2' : 'body1'}
                    />
                    {secondLabel && (
                        <GivelifyLabelEllipsis
                            color={DesignTokens.color.textSecondary}
                            text={secondLabel}
                            title={secondLabel}
                            variant={isMobile ? 'body2' : 'body1'}
                        />
                    )}
                </Stack>
            </Stack>
        );
    }
    return (
        <Stack alignItems="center" direction="row" spacing={0} width={width}>
            <GivelifyRadioView {...props} />
            <Stack
                alignItems="center"
                direction="row"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onClick={(e) => props.onChange?.(e, !!props.checked)}
                spacing={1}
                width="calc(100% - 24px)"
            >
                <GivelifyLabelEllipsis
                    text={label}
                    title={label}
                    variant={isMobile ? 'body2' : 'body1'}
                />
                {secondLabel && (
                    <GivelifyLabelEllipsis
                        color={DesignTokens.color.textSecondary}
                        text={secondLabel}
                        title={secondLabel}
                        variant={isMobile ? 'body2' : 'body1'}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export const GivelifyRadio: React.FCC<GivelifyRadioProps> = ({
    label,
    secondLabel,
    multipleLine,
    size = 'small',
    color = 'primary',
    track = true,
    ...props
}) => {
    const { trackEvent } = useTrackingContext();
    const propsInternal = {
        ...props,
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            const name = props.trackingName || props.name;
            if (name && track) {
                trackEvent(`<${name}>_${RADIO_BUTTON_IDENTIFIER}`);
            }
            props.onClick?.(event);
        },
    };
    if (label) {
        return (
            <GivelifyRadioLabel
                label={label}
                multipleLine={multipleLine}
                secondLabel={secondLabel}
                {...propsInternal}
            />
        );
    }

    return <GivelifyRadioView {...props} />;
};
