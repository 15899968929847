import React from 'react';
import { GivelifyForm, GivelifyLabel } from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRepAddressSchema } from '../../schema';
import { PrimaryRepAddressForm, primaryRepEditorViews } from '../../types';
import { AddressStepForm, AddressStepFormRef } from './AddressStepForm';

export type PrimaryRepEditorStepAddressProps = {
    value: PrimaryRepAddressForm;
    onSubmit: (data: PrimaryRepAddressForm) => void;
    onCancelClick: () => void;
    onBackClick: (data: PrimaryRepAddressForm) => void;
    formRef: React.RefObject<AddressStepFormRef>;
};

export const PrimaryRepEditorStepAddress: React.FCC<
    PrimaryRepEditorStepAddressProps
> = ({ value, onSubmit, onCancelClick: onCancel, onBackClick, formRef }) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t(
                'pages.settings.bank-info.customize-rep-tab.editor.stepAddress.title',
            ),
        }),
        [t],
    );
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const schema = useRepAddressSchema(t);
    return (
        <TrackingProvider
            trackPageVisit
            pageName={primaryRepEditorViews.addressStep}
            viewType="other"
        >
            <GivelifyLabel variant={isSmallScreen ? 'heading2S' : 'heading1S'}>
                {copy.title}
            </GivelifyLabel>
            <GivelifyForm<PrimaryRepAddressForm>
                defaultValues={{
                    ...value,
                }}
                onSubmit={onSubmit}
                schema={schema}
            >
                <AddressStepForm
                    ref={formRef}
                    onBackClick={onBackClick}
                    onCancelClick={onCancel}
                />
            </GivelifyForm>
        </TrackingProvider>
    );
};
