import React, { useMemo } from 'react';
import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core';
import { Slice } from './Slice';
import { formatDigit } from './utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        digitRollStyles: {
            display: 'flex',
            justifyContent: 'center',
            fontFamily: theme.gas?.font,
            fontWeight: 'bold',
            color: GivelifyColorPalette.primary,
            '& .digit-roll-wrapper': {
                display: 'flex',
                flexDirection: 'row-reverse',
                overflow: 'hidden',
            },
        },
        digitDividerStyles: {
            padding: '0 4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 80,
        },
    }),
);

export interface DigitRollProps {
    num: number;
    /**
     * height of each digit (rem)
     */
    height: number;
    /**
     * width of each digit (rem)
     */
    width: number;
    /**
     * delay to show number, in seconds
     */
    delay: number;
    /**
     * for example, ',' for `10,000`
     */
    divider?: string;
    /**
     * how many digits, e.g: length is 3 for `200`
     * length is 5 for `00200`
     */
    className?: string;
    /**
     * is component displayed on mobile device
     */
    isMobile?: boolean;
    /**
     * is component displayed on small tablet device
     */
    isSmallTablet?: boolean;
}

const DESKTOP_FONT_SIZE = 120;
const TABLET_FONT_SIZE = 90;
const MOBILE_FONT_SIZE = 36;
const DESKTOP_DIVIDER_FONT_SIZE = 80;

const DigitRollComponent: React.FCC<DigitRollProps> = ({
    num,
    height,
    width,
    divider = ',',
    delay,
    className,
    isMobile,
    isSmallTablet,
}) => {
    const amountNumber = num.toString().split('.');
    const actualLength = amountNumber[0].toString().length;

    const numArr = formatDigit(
        parseInt(amountNumber[0]),
        actualLength,
        divider,
    );

    let oddNumArr;

    if (amountNumber[1] && amountNumber[1].toString().length === 1) {
        oddNumArr = formatDigit(parseInt(amountNumber[1] + '0'), 2, divider);
    } else if (amountNumber[1] && amountNumber[1].toString().length === 2) {
        oddNumArr = formatDigit(parseInt(amountNumber[1]), 2, divider);
    } else if (amountNumber[1] && amountNumber[1].toString().length > 2) {
        oddNumArr = formatDigit(
            parseInt(amountNumber[1].substring(0, 2)),
            2,
            divider,
        );
    } else {
        oddNumArr = formatDigit(parseInt('00'), 2, divider);
    }

    const fontSizes = useMemo(
        () => ({
            slice: isMobile
                ? MOBILE_FONT_SIZE
                : isSmallTablet
                ? TABLET_FONT_SIZE
                : DESKTOP_FONT_SIZE,
            divider: isMobile ? MOBILE_FONT_SIZE : DESKTOP_DIVIDER_FONT_SIZE,
        }),
        [isMobile, isSmallTablet],
    );

    const { digitRollStyles } = useStyles();
    return (
        <div className={digitRollStyles}>
            <div
                className={`digit-roll-wrapper ${className || ''}`}
                style={{ height: height + 'rem' }}
            >
                {oddNumArr.map((d, index) => {
                    return (
                        <Slice
                            key={index}
                            delay={delay}
                            digit={parseInt(d, 10)}
                            fontSize={fontSizes.slice}
                            height={height}
                            id={`digit-odd-number-${index}`}
                            width={width}
                        />
                    );
                })}
                <Divider
                    divider="."
                    fontSize={fontSizes.divider}
                    height={height}
                />
                {numArr.map((d, index) => {
                    if (index % 4 === 3) {
                        return (
                            <Divider
                                key={index}
                                divider={divider}
                                fontSize={fontSizes.divider}
                                height={height}
                            />
                        );
                    }
                    return (
                        <Slice
                            key={index}
                            delay={delay}
                            digit={parseInt(d, 10)}
                            fontSize={fontSizes.slice}
                            height={height}
                            id={`digit-number-${index}`}
                            width={width}
                        />
                    );
                })}
                <Divider
                    divider="$"
                    fontSize={fontSizes.slice}
                    height={height}
                />
            </div>
        </div>
    );
};

export const DigitRoll = React.memo(DigitRollComponent);

interface DividerProps {
    divider: string;
    height: number;
    fontSize?: number;
}

const Divider: React.FCC<DividerProps> = ({
    divider,
    height,
    fontSize = 60,
}) => {
    const { digitDividerStyles } = useStyles();
    return (
        <div
            className={digitDividerStyles}
            style={{ height: height + 'rem', fontSize: `${fontSize}px` }}
        >
            {divider}
        </div>
    );
};
