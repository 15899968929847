import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import {
    getPagedDonationsByDateApiEndpoint,
    getPagedDonationsByStartEndDateEndpoint,
    getPagedDonationsMessagesEndpoint,
} from 'api/client/endpoints';
import { Dayjs } from 'dayjs';
import { IDonationsService } from '../interfaces';
import {
    GetPagedDonationsByDateResponse,
    GetPagedDonationsByStartEndDateResponse,
} from '../responses/donations';
import { GetPagedDonationsMessagesResponse } from '../responses/donations/GetPagedDonationsMessagesResponse';

export class DonationsService implements IDonationsService {
    private getDonationsAbortController: AbortController;
    private getDonationsMessagesAbortController: AbortController;

    public async getDonations(
        doneeId: number,
        donorId: string,
        startDate: Dayjs,
        endDate: Dayjs,
        page: number,
    ): Promise<ApiResponse<GetPagedDonationsByStartEndDateResponse>> {
        this.getDonationsAbortController?.abort();
        this.getDonationsAbortController = new AbortController();

        const url = getPagedDonationsByStartEndDateEndpoint(
            doneeId,
            donorId,
            startDate,
            endDate,
            page,
        );
        const httpRequest = getAxiosClient().get(url, {
            signal: this.getDonationsAbortController.signal,
        });
        return makeApiRequest<GetPagedDonationsByStartEndDateResponse>(
            httpRequest,
        );
    }

    public async getDonationsMessages(
        doneeId: number,
        donorId: string,
        startDate: Dayjs,
        endDate: Dayjs,
        page: number,
    ): Promise<ApiResponse<GetPagedDonationsMessagesResponse>> {
        this.getDonationsMessagesAbortController?.abort();
        this.getDonationsMessagesAbortController = new AbortController();

        const url = getPagedDonationsMessagesEndpoint(
            doneeId,
            donorId,
            startDate,
            endDate,
            page,
        );
        const httpRequest = getAxiosClient().get(url, {
            signal: this.getDonationsMessagesAbortController.signal,
        });

        return makeApiRequest<GetPagedDonationsMessagesResponse>(httpRequest);
    }

    public async getPagedDonationsByDate(
        doneeId: number,
        date: string,
        page: number,
        pending?: boolean,
    ): Promise<ApiResponse<GetPagedDonationsByDateResponse>> {
        const url = getPagedDonationsByDateApiEndpoint(
            doneeId,
            date,
            page,
            pending,
        );
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetPagedDonationsByDateResponse>(
            httpRequest,
        );
        return result;
    }
}
