import React from 'react';
import { GivelifyBreadcrumbs } from '@givelify/ui';
import { ConfirmDialog } from '../confirmDialog';
import { LoadingDialog } from '../loadingDialog';
import { RepInfoBox } from '../repInfoBox';
import { PrimaryRepEditorStepAddress } from './addressStep';
import { PrimaryRepEditorStepInfo } from './infoStep';
import { PrimaryRepEditorStepName } from './nameStep';
import { Box, FormBox } from './styles';
import { PrimaryRepEditorStepSummary } from './summaryStep';
import {
    PrimaryRepEditorProps,
    PrimaryRepEditorRef,
    usePrimaryRepEditor,
} from './usePrimaryRepEditor';

export const PrimaryRepEditor = React.forwardRef<
    PrimaryRepEditorRef,
    PrimaryRepEditorProps
>((props, ref) => {
    const {
        activeStep,
        addressStepData,
        addresFormRef,
        addressIsDirty,
        copy,
        infoFormRef,
        infoIsDirty,
        infoStepData,
        isInitialSsn,
        isSmallScreen,
        loadingState,
        nameFormRef,
        nameIsDirty,
        nameStepData,
        onAddressEditClick,
        onAddressStepBack,
        onAddressSubmitClick,
        onCancelConfirm,
        onConfirmDialogClose,
        onFormCancelClick,
        onInfoEditClick,
        onInfoStepBack,
        onInfoSubmitClick,
        onNameEditClick,
        onNameSubmitClick,
        onSubmit,
        onSummaryCancelClick,
        onSummaryStepBack,
        requestState,
        showConfirmDialog,
    } = usePrimaryRepEditor(props, ref);
    return (
        <>
            <GivelifyBreadcrumbs
                disableNavigation
                active={`step-${activeStep}`}
                options={[
                    {
                        id: 'step-name',
                        label: copy.name,
                        isCompleted: activeStep !== 'name',
                    },
                    {
                        id: 'step-address',
                        label: copy.address,
                        isCompleted:
                            activeStep === 'information' ||
                            activeStep === 'summary',
                    },
                    {
                        id: 'step-information',
                        label: copy.infomation,
                        isCompleted: activeStep === 'summary',
                    },
                    {
                        id: 'step-summary',
                        label: copy.summary,
                    },
                ]}
            />
            <Box>
                <FormBox>
                    {activeStep === 'name' ? (
                        <PrimaryRepEditorStepName
                            formRef={nameFormRef}
                            onCancelClick={onFormCancelClick}
                            onSubmit={onNameSubmitClick}
                            userDetails={props.userDetails}
                            value={nameStepData}
                        />
                    ) : null}
                    {activeStep === 'address' ? (
                        <PrimaryRepEditorStepAddress
                            formRef={addresFormRef}
                            onBackClick={onAddressStepBack}
                            onCancelClick={onFormCancelClick}
                            onSubmit={onAddressSubmitClick}
                            value={addressStepData}
                        />
                    ) : null}
                    {activeStep === 'information' ? (
                        <PrimaryRepEditorStepInfo
                            formRef={infoFormRef}
                            onBackClick={onInfoStepBack}
                            onCancelClick={onFormCancelClick}
                            onSubmit={onInfoSubmitClick}
                            replace={isInitialSsn}
                            value={infoStepData}
                        />
                    ) : null}
                    {activeStep === 'summary' ? (
                        <PrimaryRepEditorStepSummary
                            address={addressStepData}
                            canSubmit={
                                nameIsDirty() ||
                                infoIsDirty() ||
                                addressIsDirty()
                            }
                            hideSsn={!isInitialSsn}
                            info={infoStepData}
                            nameTitle={nameStepData}
                            onAddressEditClick={onAddressEditClick}
                            onBackClick={onSummaryStepBack}
                            onCancelClick={onSummaryCancelClick}
                            onInfoEditClick={onInfoEditClick}
                            onNameEditClick={onNameEditClick}
                            onSubmitClick={onSubmit}
                            showBackButton={props.mode === 'add'}
                        />
                    ) : null}
                </FormBox>
                <ConfirmDialog
                    onClose={onConfirmDialogClose}
                    onConfirm={onCancelConfirm}
                    open={showConfirmDialog}
                />
                {isSmallScreen || activeStep === 'summary' ? null : (
                    <RepInfoBox />
                )}
            </Box>
            <LoadingDialog
                disableBackdropClose
                open={
                    requestState === 'REQUEST_START' ||
                    requestState === 'REQUEST_SUCCESS'
                }
                status={loadingState === 'loading' ? 'loading' : 'success'}
            />
        </>
    );
});
