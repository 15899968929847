import React from 'react';
import { GivelifyNotificationProps } from '@givelify/ui';
import {
    DirectDepositInfo,
    PrimaryRepresentativeInfo,
    TaxIdInfo,
} from '../@types/assets/onboarding';

interface CaptivePortalContextProps {
    onboardingState?:
        | 'started'
        | 'inReview'
        | 'showOverview'
        | 'failed'
        | 'actionNeeded'
        | 'finished';
    showCaptivePortal: boolean;
    showLoader: boolean;
    inviteOfficialEnabled: boolean;
    goToSettingsClick: () => void;
    goToOverviewClick: () => void;
    skipOnboardingClick: () => void;
    onboardingNotification: GivelifyNotificationProps | null;
    setCaptivePortalNotification: (
        notification: GivelifyNotificationProps | null,
    ) => void;
    captivePortalNotification: GivelifyNotificationProps | null;
    directDeposit: DirectDepositInfo;
    setDirectDeposit: (directDeposit: DirectDepositInfo) => void;
    setTaxId: (taxInfo: TaxIdInfo) => void;
    taxId: TaxIdInfo;
    setPrimaryRepresentative: (
        primaryRepresentative: PrimaryRepresentativeInfo,
    ) => void;
    primaryRepresentative: PrimaryRepresentativeInfo;
    navigateToUsers: (invite?: boolean) => void;
    goToCaptivePortal: (showSuccessMessage?: boolean) => void;
    headerDropdowns: React.ReactNode;
}

const NOT_INITIALIZED = () => {
    throw new Error('Context not initialized');
};

export const CaptivePortalContext =
    React.createContext<CaptivePortalContextProps>({
        onboardingState: undefined,
        showCaptivePortal: false,
        showLoader: false,
        inviteOfficialEnabled: false,
        onboardingNotification: null,
        captivePortalNotification: null,
        setCaptivePortalNotification: NOT_INITIALIZED,
        goToSettingsClick: NOT_INITIALIZED,
        goToOverviewClick: NOT_INITIALIZED,
        skipOnboardingClick: NOT_INITIALIZED,
        setTaxId: NOT_INITIALIZED,
        setDirectDeposit: NOT_INITIALIZED,
        setPrimaryRepresentative: NOT_INITIALIZED,
        navigateToUsers: NOT_INITIALIZED,
        goToCaptivePortal: NOT_INITIALIZED,
        directDeposit: {
            accountNumber: '',
            routingNumber: '',
            bankChequeFile: undefined,
            bankingAddress: {
                street: '',
                zip: '',
                state: '',
                city: '',
            },
            submittedAt: null,
            submittedByOfficialId: null,
            status: 'unchecked',
        },
        taxId: {
            hasTaxId: false,
            taxId: '',
            organizationYear: '',
            einFile: undefined,
            submittedAt: null,
            submittedByOfficialId: null,
            status: 'unchecked',
        },
        primaryRepresentative: {
            isPrimaryRepresentative: false,
            firstName: '',
            lastName: '',
            title: '',
            birthDate: null,
            socialSecurityNumber: '',
            primaryRepresentativeAddress: {
                phone: '',
                street: '',
                zip: '',
                state: '',
                city: '',
            },
            beneficiaryIdFile: undefined,
            submittedAt: null,
            submittedByOfficialId: null,
            status: 'unchecked',
        },
        // eslint-disable-next-line react/jsx-no-useless-fragment
        headerDropdowns: <></>,
    });
