import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { ApiEndpoints } from '../../endpoints';
import {
    AddDenominationsResponse,
    GetBankNameResponse,
    GetDenominationsResponse,
    GetEmailResponse,
    GetEnabledFeaturesResponse,
    GetOfficerTitlesResponse,
    GetTaxIdDetailsResponse,
} from '../../responses';
import { ICommonService } from '../interfaces';

export class CommonService implements ICommonService {
    public async getEnabledFeatures(): Promise<
        ApiResponse<GetEnabledFeaturesResponse>
    > {
        const url = ApiEndpoints.common.enabledFeatures();
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetEnabledFeaturesResponse>(
            httpRequest,
        );
        return result;
    }

    public async getEmail(
        token: string,
    ): Promise<ApiResponse<GetEmailResponse>> {
        const url = ApiEndpoints.common.email(token);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetEmailResponse>(httpRequest);
        return result;
    }

    public async getOfficerTitles(): Promise<
        ApiResponse<GetOfficerTitlesResponse>
    > {
        const url = ApiEndpoints.common.titles();
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetOfficerTitlesResponse>(
            httpRequest,
        );
        return result;
    }

    public async getTaxIdDetails(
        taxId: string,
    ): Promise<ApiResponse<GetTaxIdDetailsResponse>> {
        const url = ApiEndpoints.common.irs(taxId);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetTaxIdDetailsResponse>(
            httpRequest,
        );
        return result;
    }

    public async getDenominations(): Promise<
        ApiResponse<GetDenominationsResponse>
    > {
        const url = ApiEndpoints.common.denominations();
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetDenominationsResponse>(
            httpRequest,
        );
        return result;
    }

    public async addDenomination(
        name: string,
    ): Promise<ApiResponse<AddDenominationsResponse>> {
        const url = ApiEndpoints.common.denominations();
        const httpRequest = getAxiosClient().post(url, { name });
        const result = await makeApiRequest<AddDenominationsResponse>(
            httpRequest,
        );
        return result;
    }

    public async getBankName(
        routingNumber: string,
    ): Promise<ApiResponse<GetBankNameResponse>> {
        const url = ApiEndpoints.common.bankNames(routingNumber);
        const httpRequest = getAxiosClient().get<GetBankNameResponse>(url);
        const result = await makeApiRequest<GetBankNameResponse>(httpRequest);
        return result;
    }

    public async sendButtonEmail(
        recipientName: string,
        recipientEmail: string,
    ): Promise<ApiResponse<undefined>> {
        const url = ApiEndpoints.common.sendButtonEmail();
        const httpRequest = getAxiosClient().post(url, {
            recipientName,
            recipientEmail,
        });
        const result = await makeApiRequest<undefined>(httpRequest);
        return result;
    }
}
