import React, { useMemo } from 'react';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { NoResultContent, NoResultDefine } from '../components/style';

const ReportHistoryNoResult: React.FCC = () => {
    const { PATH } = useGasRouterContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const copy = useMemo(
        () => ({
            noReport: t('reportHistory.noReport'),
            noReportDefine: t('reportHistory.noReportDefine'),
            goReport: t('reportHistory.goReport'),
        }),
        [t],
    );

    return (
        <NoResultContent>
            <FontAwesomeIcon icon={faFileAlt} />
            <GivelifyLabel text={copy.noReport} variant="heading1M" />
            <NoResultDefine text={copy.noReportDefine} />

            <GivelifyLink
                fontSize={16}
                onClick={() => navigate(PATH.REPORTS.GENERATE_REPORTS())}
                text={copy.goReport}
            />
        </NoResultContent>
    );
};

export default ReportHistoryNoResult;
