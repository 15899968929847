import React, { useEffect, useMemo, useState } from 'react';
import { GivelifyButton } from '@givelify/givelify-ui';
import {
    ImageWithDimensions,
    ImageButton,
    ImageUploadButtonProps,
} from '@givelify/onboarding';
import { GivelifyAvatar } from '@givelify/ui';
import { getInitialsFromName } from '@givelify/utils';
import { isBase64Image } from '@givelify/utils';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { editUserAvatar } from 'store/user/thunks';
import { AppState, useAppDispatch } from '../../../store';
import EditProfileActions from './EditProfileActions';

const width = '100%';
const height = 200;

export interface UserProfileResponse {
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        column: {
            '& .avatar': {
                width,
                height,
            },
            '& .full-name': {
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(3),
            },
        },
        avatarContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 16,
        },
        avatar: {
            width: 120,
            height: 120,
            fontSize: '40px',
        },
        cropperAvatar: {
            width: 130,
            height: 130,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            width: 120,
            height: 120,
            overflow: 'hidden',
            backgroundColor: 'transparent',
            borderRadius: '200px',
        },
        updateButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            marginTop: 82,
            opacity: 0.5,
            width: 120,
            height: 38,
            backgroundColor: theme.colors.primaryDarkGray,
        },
        updateTextBox: {
            position: 'absolute',
            marginTop: 82,
            cursor: 'pointer',
        },
        noDisplay: {
            display: 'none',
        },
    }),
);

export const ImageUploadButtonRender: React.FCC<ImageUploadButtonProps> =
    // eslint-disable-next-line react/display-name
    React.memo((props) => {
        const { user } = useSelector((state: AppState) => ({
            user: state.User.user,
        }));
        const { t } = useTranslation();
        const copy = useMemo(
            () => ({
                update: t('labels.update'),
                upload: t('labels.upload'),
            }),
            [t],
        );
        const avatarActionText = user.avatar ? copy.update : copy.upload;
        return (
            <GivelifyButton
                color="primaryWhite"
                disabled={props.disabled}
                onClick={props.onUploadClick}
                text={avatarActionText}
                variant="ghost"
            />
        );
    });

type LeftColumnProps = {
    disabled: boolean;
};

const LeftColumn: React.FCC<LeftColumnProps> = ({ disabled }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { user } = useSelector((state: AppState) => ({
        user: state.User.user,
    }));
    const [currentUserAvatar, setCurrentUserAvatar] =
        useState<ImageWithDimensions>({
            url: isBase64Image(user.avatarOriginal)
                ? user.avatar
                : user.avatarOriginal,
            dimensions: {
                croppedArea: {
                    width: undefined,
                    height: undefined,
                    x: undefined,
                    y: undefined,
                },
                croppedAreaPixels: {
                    width: undefined,
                    height: undefined,
                    x: undefined,
                    y: undefined,
                },
            },
            croppedUrl: isBase64Image(user.avatarOriginal)
                ? user.avatarOriginal
                : user.avatar,
        });

    const [open, setOpen] = useState<boolean>(true);

    useEffect(() => {
        if (isBase64Image(user.avatar)) {
            setOpen(false);
        }
    }, [user.avatar, user.avatarOriginal]);

    const copy = useMemo(
        () => ({
            replaceAvatar: t('editProfile.replaceAvatar'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );

    const onChangeAvatar = (image: ImageWithDimensions) => {
        if (image) {
            setCurrentUserAvatar(image);
            dispatch(editUserAvatar(image));
            setOpen(true);
        }
    };

    return (
        <div className={classes.column}>
            <div>
                <div className={classes.avatarContent}>
                    <GivelifyAvatar
                        className={classes.avatar}
                        size="xLarge"
                        src={currentUserAvatar?.croppedUrl}
                        text={getInitialsFromName(user.fullName)}
                    />
                    <div className={classes.buttonContainer}>
                        <div className={classes.updateButton} />
                        <div className={classes.updateTextBox}>
                            <ImageButton
                                keepOpen
                                Component={(props) => (
                                    <ImageUploadButtonRender
                                        {...props}
                                        disabled={disabled}
                                    />
                                )}
                                applyButtonText={copy.save}
                                aspectRatio={1}
                                deleteButtonText={copy.cancel}
                                disabled={disabled}
                                id="replace-avatar"
                                initialImage={currentUserAvatar}
                                isBackdropClickDisabled={false}
                                isOpen={open}
                                isRound={true}
                                isShowingUploadState={false}
                                onChange={onChangeAvatar}
                                title={copy.replaceAvatar}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <EditProfileActions disabled={disabled} />
        </div>
    );
};

export default LeftColumn;
