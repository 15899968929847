import { ApiHandler, mapSignedUserToLocalUser } from '@givelify/api';
import { ImageWithDimensions } from '@givelify/onboarding';
import {
    ApiResponse,
    getAxiosClient,
    makeApiRequest,
    Logger,
} from '@givelify/utils';
import { AxiosResponse } from 'axios';
import mixpanel from 'mixpanel-browser';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppActions, AppState } from 'store';
import { EditUserInfo } from 'types/userTypes';
import { httpDelete, httpGet, httpPatch } from '../thunks';
import {
    mapUserInfoToRequest,
    setAccessToken,
    setUser,
    setUserAvatar,
    setUserEmail,
} from './actions';
import { UserActions, UserState } from './types';

export const noEditUserError = new Error('Edit user not found');

/**
 * Dispatcher to logout User
 */
export const logoutUser =
    (): ThunkDispatch<UserState, undefined, UserActions> =>
    async (dispatch) => {
        const response = await ApiHandler.instance.auth.logout();
        if (response.success) {
            dispatch(setAccessToken(undefined));
            if (window.mixpanel?.initialized) {
                mixpanel.reset();
            }
            return true;
        }
        return undefined;
    };

/**
 * Dispatcher to get email
 */
export const getEmail =
    (token): ThunkDispatch<UserState, undefined, UserActions> =>
    async (dispatch) => {
        try {
            const response: AxiosResponse = await httpGet(
                '/email?token=' + token,
            )(dispatch);

            if (response) {
                dispatch(setUserEmail(response.data.email));
                return response.data.email;
            }
        } catch (error) {
            return error;
        }
    };

export const loadUser =
    (): ThunkAction<Promise<boolean>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const state = getState();
        const isSuperUser = state.User?.user?.isSuper;

        const userResponse = await ApiHandler.instance.users.getCurrentUser();
        if (!userResponse.success) return false;

        if (!isSuperUser) {
            const userData = mapSignedUserToLocalUser(
                userResponse.response.data,
            );
            dispatch(setUser(userData));
            Logger.configureUserData(userData.doneeId, userData.id);
        }

        return true;
    };

export const editUser =
    (
        isEmailSame: boolean,
        data: EditUserInfo,
    ): ThunkAction<
        Promise<ApiResponse<unknown>>,
        AppState,
        undefined,
        AppActions
    > =>
    async (dispatch, getState) => {
        const requestData = mapUserInfoToRequest(data, isEmailSame);
        const state = getState();
        const doneeId = state.Donee?.donee?.id;

        const url = `/donees/${doneeId}/users/${data.id}`;
        const httpRequest = getAxiosClient().patch(url, requestData);

        return makeApiRequest(httpRequest);
    };

export const deleteUser =
    (
        data: EditUserInfo,
    ): ThunkAction<Promise<void>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const state = getState();
        const doneeId = state.Donee?.donee?.id;
        await httpDelete(`/donees/${doneeId}/users/${data.id}`)(dispatch);
    };

export const editUserAvatar =
    (
        image: ImageWithDimensions,
    ): ThunkAction<Promise<void>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const requestData = {
            avatar: image.url,
            // eslint-disable-next-line
            avatar_original: image.croppedUrl,
            // eslint-disable-next-line
            avatar_cropped_coordinates: image.dimensions.croppedAreaPixels,
        };
        const state = getState();
        const doneeId = state.Donee?.donee?.id;
        const userId = state.User?.user?.id;
        await httpPatch(
            `/donees/${doneeId}/users/${userId}`,
            requestData,
        )(dispatch);

        dispatch(setUserAvatar(image));
    };
