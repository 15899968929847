import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { GivelifyBreadcrumbsStyles } from './styles';
import { GivelifyButton } from '../button';
import { GivelifyLabel } from '../label';
import { GivelifyIcon } from '../components/GivelifyIcon';

export interface GivelifyBreadcrumbsProps extends GivelifyBoxMarginProps {
    options: { label: string; onClick?: () => void; url?: string }[];
    className?: string;
}
export const GivelifyBreadcrumbs: React.FCC<GivelifyBreadcrumbsProps> = (
    props,
) => {
    const { options, className, ...marginProps } = props;
    const { boxMargin, root } = GivelifyBreadcrumbsStyles(marginProps);
    const rootClassName = mergeClassNames(boxMargin, root, className);

    const content = React.useMemo(() => {
        const list = options.map((item, index) => {
            if (item.onClick && !item.url) {
                return (
                    <GivelifyButton
                        key={`brd-${index}`}
                        text={item.label}
                        onClick={item.onClick}
                        variant="link"
                        className="button"
                    />
                );
            }
            if (item.url) {
                return (
                    <a
                        key={`brd-${index}`}
                        onClick={(e) => {
                            if (item.onClick) {
                                e.preventDefault();
                                item.onClick();
                            }
                        }}
                        href={item.url}
                    >
                        {item.label}
                    </a>
                );
            }
            return (
                <GivelifyLabel
                    key={`brd-${index}`}
                    text={item.label}
                    variant="body2"
                />
            );
        });
        const result: JSX.Element[] = [];
        list.forEach((item, index) => {
            result.push(item);
            if (index < list.length - 1) {
                result.push(
                    <GivelifyIcon
                        key={`sep-${index}`}
                        color="grey600"
                        variant="chevron-right"
                        size={13}
                        marginLeft={9}
                        marginRight={9}
                    />,
                );
            }
        });
        return result;
    }, [options]);
    return <div className={rootClassName}>{content}</div>;
};
