import { styled } from '@mui/material';
import { GivelifyLabel } from '../label';
import { GivelifyPaper } from '../paper';
import { DesignTokens } from '../specify';

export const MobilePreviewCase = styled(GivelifyPaper)(({ theme }) => ({
    width: 413.75,
    height: 873.33,
    position: 'relative',
    backgroundColor: 'rgba(228, 227, 232, 0.5)',
    borderRadius: 50,
    transform: `scale(0.75)`,
    transformOrigin: 'top center',
}));

export const MobilePreviewContent = styled('div')(({ theme }) => ({
    position: 'absolute',
    transformOrigin: 'top left',
    left: 10,
    right: 10,
    top: 10,
    bottom: 10,
    border: '1px solid #EEEEEE',
    overflow: 'hidden',
    borderRadius: 50,
    backgroundColor: theme.palette.background.default,
}));

export const MobilePreviewCoverWrapper = styled('div')({
    position: 'relative',
    width: 393.75,
    height: 199.16,
    overflow: 'hidden',
});

export const MobilePreviewCover = styled('img')({
    width: '100%',
    height: '100%',
});

export const Overlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
}));

export const OrgTitle = styled(GivelifyLabel)({
    position: 'absolute',
    transformOrigin: 'bottom left',
    bottom: 36,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 20,
    lineHeight: '20px',
    fontWeight: 700,
});

export const MobilePreviewPagination = styled('div')({
    position: 'absolute',
    transformOrigin: 'bottom left',
    top: 189,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const QuickGiveButtonBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: DesignTokens.measurement.borderRadiusL,
    boxShadow: DesignTokens.shadow.shadowHigh,
    padding: theme.spacing(1, 0),
    height: 48,
}));

export const MobilePreviewBack = styled('div')({
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: DesignTokens.color.backgroundSecondary,
    fontSize: 24,
    left: 16,
    top: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const MobilePreviewShare = styled('div')({
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: DesignTokens.color.backgroundSecondary,
    fontSize: 24,
    right: 16,
    top: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const MobilePreviewGreyContent = styled('div')({
    backgroundColor: '#F9FAFC',
    height: '100%',
});

export const MobilePreviewDetailsRowLined = styled('div')({
    display: 'flex',
    borderTop: '0.5px solid #EEEDF3',
    height: 44,
    boxSizing: 'border-box',
    alignItems: 'center',
});

export const MobilePreviewDetails = styled('div')(({ theme }) => ({
    backgroundColor: '#F9FAFC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& .white': {
        backgroundColor: theme.palette.background.default,
    },
    '& .inner': {
        padding: theme.spacing(2, 2.5, 0, 2.5),
    },
}));

export const MobilePreviewDetails2 = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    padding: theme.spacing(1.25, 2.5),
    marginTop: 12,
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
}));

export const MobilePreviewTabBar = styled('div')({
    boxSizing: 'border-box',
    height: 60,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    position: 'absolute',
    bottom: 1,
    background: '#FFFFFF',
    paddingTop: 7.25,
    paddingBottom: 6,
    marginTop: 'auto',
});

export const MobilePreviewVisa = styled('img')({
    width: 37,
    height: 13,
    marginRight: 8,
});

export const MobilePreviewBackArrow = styled('img')({
    position: 'absolute',
    left: 16,
});

export const MobilePreviewTabBarIconNormal = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#95939E',
    height: 47.75,
    '& .text': {
        marginTop: 'auto',
        fontSize: 11,
        lineHeight: '13.2px',
        fontWeight: 700,
    },
});

export const MobilePreviewTabBarIconActive = styled(
    MobilePreviewTabBarIconNormal,
)({
    color: '#F85520',
});
