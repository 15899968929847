import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { pushEvent } from '@givelify/utils';
import { Stack, Divider, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SocialMediaName } from '../types';
import {
    CLICK_FB_GIVING_CARD,
    CLICK_IG_GIVING_CARD,
    CLICK_TW_GIVING_CARD,
    CLICK_YT_GIVING_CARD,
} from '../utils/cleverTapEvents';

export const TileBox = styled('a')(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(3),
    borderRadius: DesignTokens.measurement.borderRadiusL,
    backgroundColor: DesignTokens.color.backgroundComponentPrimaryDefault,
    boxShadow: DesignTokens.shadow.shadowMediumLow,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '& .tile-title': {
        color: DesignTokens.color.backgroundButtonPrimaryHover,
        alignItems: 'center',
        display: 'flex',
        marginTop: 'auto',
    },
    '&:hover': {
        backgroundColor: DesignTokens.color.backgroundComponentPrimaryHover,
        '& .tile-title': {
            textDecoration: 'underline',
        },
    },
    height: '100%',
    textDecoration: 'none',
}));

export const TextDivider = styled(Divider)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

export const ImgIcon = styled('img')(({ theme }) => ({
    width: 32,
    height: 32,
    marginRight: theme.spacing(2),
}));

type SocialMediaCardProps = {
    title: string;
    description: string;
    actionUrl: string;
    assetName: SocialMediaName;
};

const CLEVERTAP_EVENTS: { [x in SocialMediaName]: string } = {
    facebook: CLICK_FB_GIVING_CARD,
    twitter: CLICK_TW_GIVING_CARD,
    instagram: CLICK_IG_GIVING_CARD,
    youtube: CLICK_YT_GIVING_CARD,
};

const SocialMediaCard: React.FCC<SocialMediaCardProps> = ({
    actionUrl,
    title,
    description,
    assetName,
}: SocialMediaCardProps) => {
    const navigate = useNavigate();
    const handleLinkClick = React.useCallback(
        (ev: React.MouseEvent<HTMLAnchorElement>) => {
            ev.preventDefault();
            navigate(actionUrl);
            pushEvent(CLEVERTAP_EVENTS[assetName]);
        },
        [actionUrl, assetName, navigate],
    );
    return (
        <TileBox
            data-testid={`social-media-card-${title}`}
            href={actionUrl}
            onClick={handleLinkClick}
        >
            <Stack alignItems="center" display="flex" flexDirection="row">
                <ImgIcon alt={title} src={`/social-media/${assetName}.svg`} />
                <GivelifyLabel
                    data-testid={`social-media-card-title-${title}`}
                    variant="heading2S"
                >
                    {title}
                </GivelifyLabel>
            </Stack>
            <TextDivider />
            <GivelifyLabel
                color={DesignTokens.color.grey600}
                data-testid={`social-media-card-description-${title}`}
                fontSize={14}
                variant="body1"
            >
                {description}
            </GivelifyLabel>
            <GivelifyLabel className="tile-title" variant="body1">
                Learn more
            </GivelifyLabel>
        </TileBox>
    );
};

export default React.memo(SocialMediaCard);
