import React, { useMemo } from 'react';
import { OrganizationType } from '@givelify/api';
import {
    GivelifyInfo,
    GivelifyLabel as GivelifyLabelOld,
    GivelifyLink as GivelifyLinkOld,
} from '@givelify/givelify-ui';
import {
    GivelifyLabel,
    GivelifyButton,
    TrashIcon,
    GivelifyFormTextField,
    GivelifyFormDatePicker,
    GivelifyFormRadio,
    GivelifyIcon,
    GivelifyTooltip,
    DesignTokens,
} from '@givelify/ui';
import { InputAdornment, Stack, ClickAwayListener } from '@mui/material';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { useAdvancedTranslation } from 'utils/i18n';
import useStyles from './style';
import { FormEnvelope, hasGoalValues, isAlwaysValues } from './types';

interface EnvelopeEditorProps {
    onCancelClick: () => void;
    canDelete: boolean;
    onDeleteClick: () => void;
    organizationType: OrganizationType;
}

const View: React.FCC<EnvelopeEditorProps> = (props) => {
    const { onCancelClick, canDelete, onDeleteClick, organizationType } = props;

    const {
        textFieldsWrapper,
        datesWrapper,
        dateFieldsWrapper,
        extraMarginBottom,
        amountControlsWrapper,
        buttonsWrapper,
        dateFieldLabelWrapper,
        radioButtonsWrapper,
        deleteButtonWrapper,
        formWrapper,
        goalHintWrapper,
        goalHintLink,
        goalHintTooltip,
        goalHelperText,
    } = useStyles();

    const { t, scopedTranslate, scopedATranslate } = useAdvancedTranslation(
        'pages.settings.envelopes2.envelopes-editor',
    );
    const envelopeTitle = useMemo(
        () => ({
            church: t('general.donee.envelope.church'),
            nonprofit: t('general.donee.envelope.nonprofit'),
        }),
        [t],
    );

    const copy = useMemo(
        () => ({
            create: scopedATranslate('create'),
            edit: scopedATranslate('edit'),
            namePlaceholder: scopedATranslate('name-placeholder'),
            descriptionPlaceholder: scopedTranslate('description-placeholder'),
            fundIdPlaceholder: scopedTranslate('fund-id-placeholder'),
            fundIdTip: scopedTranslate('fund-id-tip'),
            fundIdTooltip: scopedTranslate('fund-id-tooltip'),
            rangeTip: scopedATranslate('range-tip'),
            always: scopedTranslate('always'),
            custom: scopedTranslate('custom'),
            startDate: scopedTranslate('start-date'),
            to: scopedTranslate('to'),
            from: scopedTranslate('from'),
            endDate: scopedTranslate('end-date'),
            other: scopedATranslate('other'),
            fundName: scopedTranslate('fundName'),
            bindFund: scopedTranslate('bindFind', {
                envelope: envelopeTitle[organizationType],
            }),
            bindFindTip: scopedTranslate('bindFindTip', {
                envelope: envelopeTitle[organizationType],
            }),
            setGoal: scopedTranslate('set-goal'),
            setGoalYes: scopedTranslate('set-goal-yes'),
            setGoalNo: scopedTranslate('set-goal-no'),
            dollarAmount: scopedTranslate('dollar-amount'),
            goalHelperText: scopedTranslate('goal-helper-text'),
            givelithons: scopedTranslate('givelithons'),
            tooltip: scopedTranslate('tooltip'),
            inactiveEvnelope: scopedATranslate('inactive-envelope'),
            inactiveEvnelopeTooltip: scopedATranslate(
                'inactive-envelope-tooltip',
            ),
            delete: scopedATranslate('delete'),
            deleteConfirm: scopedATranslate('delete-confirm'),
            deleteConfirmTip: scopedTranslate('delete-confirm-tip'),
            deleteError: scopedATranslate('delete-error'),
            requireName: scopedTranslate('require-envelope-name'),
            duplicatedName: scopedATranslate('duplicated-envelope-name'),
            requireGoal: scopedTranslate('require-goal-amount'),
            invalidGoal: scopedTranslate('invalid-goal-amount'),
            requireStartDate: scopedTranslate('require-start-date'),
            requireEndDate: scopedTranslate('require-end-date'),
            startDateAfterToday: scopedTranslate('start-date-after-today'),
            startDateBeforeEndDate: scopedTranslate(
                'start-date-before-end-date',
            ),
            endDateAfterStartDate: scopedTranslate('end-date-after-start-date'),
            internalDescription: scopedTranslate('internal-description'),
            publicDescription: scopedTranslate('public-description'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
            required: t('labels.required'),
            yes: t('labels.yes'),
            no: t('labels.no'),
        }),
        [t, scopedTranslate, scopedATranslate, organizationType, envelopeTitle],
    );
    const [openTooltip, setOpenTooltip] = React.useState(false);

    const { formState, watch } = useFormContext<FormEnvelope>();

    const [
        isAlways,
        hasGoal,
        id,
        startDate,
        endDate,
        isDefault,
        hasExternalId,
    ] = watch([
        'isAlways',
        'hasGoal',
        'id',
        'startDate',
        'endDate',
        'isDefault',
        'hasExternalId',
    ]);
    const isLoading = formState.isSubmitting;
    const goalError = formState.errors.goal;

    const datesDisabled = isAlways === isAlwaysValues.ALWAYS;
    const amountDisabled = hasGoal === hasGoalValues.NO;
    const today = dayjs().tz().startOf('day');
    const minStartDate = startDate ? dayjs.min([startDate, today]) : today;
    const maxStartDate =
        endDate && endDate.isValid() ? endDate.subtract(1, 'day') : undefined;
    const minEndDate = startDate ? startDate.add(1, 'day') : today;
    const isDirty = formState.isDirty;

    return (
        <div className={formWrapper}>
            {canDelete && (
                <div className={deleteButtonWrapper}>
                    <GivelifyButton
                        data-testid="envelope-delete"
                        onClick={onDeleteClick}
                        startIcon={<TrashIcon />}
                        text={copy.delete}
                        variant="ghost"
                    />
                </div>
            )}
            <div className={textFieldsWrapper}>
                <GivelifyFormTextField<FormEnvelope>
                    autoFocus
                    countLength
                    fullWidth
                    data-testid="envelope-name"
                    disabled={isLoading || isDefault}
                    id="envelope-name"
                    label={copy.namePlaceholder}
                    maxLength={55}
                    name="name"
                    placeholder={copy.namePlaceholder}
                />
                <GivelifyFormTextField<FormEnvelope>
                    countLength
                    fullWidth
                    multiline
                    data-testid="description-name"
                    disabled={isLoading || isDefault}
                    id="description-name"
                    label={copy.descriptionPlaceholder}
                    maxLength={1000}
                    name="description"
                    placeholder={copy.descriptionPlaceholder}
                    rows={3}
                />
            </div>
            <GivelifyLabel text={copy.rangeTip} variant="body1" />
            <div className={datesWrapper}>
                <div className={radioButtonsWrapper}>
                    <div className={extraMarginBottom}>
                        <GivelifyFormRadio<FormEnvelope>
                            disabled={isLoading || isDefault}
                            id="data-mode-always"
                            label={copy.always}
                            name="isAlways"
                            value={isAlwaysValues.ALWAYS}
                        />
                    </div>
                    <div className={extraMarginBottom}>
                        <GivelifyFormRadio<FormEnvelope>
                            disabled={isLoading || isDefault}
                            id="date-mode-custom"
                            label={copy.custom}
                            name="isAlways"
                            value={isAlwaysValues.TIMED}
                        />
                    </div>
                </div>
                <div className={dateFieldsWrapper}>
                    <div className={dateFieldLabelWrapper}>
                        <GivelifyLabel text={copy.from} variant="body1" />
                    </div>
                    <GivelifyFormDatePicker<FormEnvelope>
                        fullwidth
                        ariaLabel="from date picker"
                        disabled={isLoading || isDefault || datesDisabled}
                        helperText="MM/DD/YYYY"
                        id="from-date"
                        label={copy.startDate}
                        maxDate={maxStartDate}
                        minDate={minStartDate}
                        name="startDate"
                        placeholder="mm/dd/yyyy"
                    />
                    <div className={dateFieldLabelWrapper}>
                        <GivelifyLabel text={copy.to} variant="body1" />
                    </div>
                    <GivelifyFormDatePicker<FormEnvelope>
                        fullwidth
                        ariaLabel="to date picker"
                        disabled={isLoading || isDefault || datesDisabled}
                        helperText="MM/DD/YYYY"
                        id="to-date"
                        label={copy.endDate}
                        minDate={minEndDate}
                        name="endDate"
                        placeholder="mm/dd/yyyy"
                    />
                </div>
            </div>
            <GivelifyLabel text={copy.other} variant="body1" />
            <div className={amountControlsWrapper}>
                <div className={radioButtonsWrapper}>
                    <GivelifyFormRadio<FormEnvelope>
                        className={extraMarginBottom}
                        disabled={isLoading || isDefault}
                        id="set-goal-yes"
                        label={copy.setGoalNo}
                        name="hasGoal"
                        value={hasGoalValues.NO}
                    />
                    <GivelifyFormRadio<FormEnvelope>
                        className={extraMarginBottom}
                        disabled={isLoading || isDefault}
                        id="set-goal-no"
                        label={copy.setGoalYes}
                        name="hasGoal"
                        value={hasGoalValues.YES}
                    />
                </div>
                <div>
                    <GivelifyFormTextField<FormEnvelope>
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                        aria-label="goal-editor"
                        disabled={isLoading || isDefault || amountDisabled}
                        id="goal"
                        label=""
                        maskOptions={{
                            mask: Number,
                            scale: 2,
                            thousandsSeparator: ',',
                            radix: '.',
                        }}
                        name="goal"
                        placeholder={copy.dollarAmount}
                        type="text"
                    />
                    {!goalError && (
                        <div className={goalHintWrapper}>
                            <GivelifyLabelOld
                                className={goalHelperText}
                                text={`${copy.goalHelperText} `}
                                variant="small"
                            />
                            <GivelifyInfo
                                classes={{
                                    root: goalHintTooltip,
                                }}
                                renderIcon={
                                    <GivelifyLinkOld>
                                        <GivelifyLabelOld
                                            className={goalHintLink}
                                            text={copy.givelithons}
                                            variant="small"
                                        />
                                    </GivelifyLinkOld>
                                }
                                text={copy.tooltip}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Stack alignItems="center" direction="row" mt={2}>
                <GivelifyLabel text={copy.bindFund} variant="body1" />
                <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                    <div>
                        <GivelifyTooltip
                            arrow
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            PopperProps={{
                                disablePortal: true,
                                open: openTooltip,
                            }}
                            placement="top-end"
                            title={copy.bindFindTip}
                            variant="large"
                        >
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                ml={1}
                                onClick={() =>
                                    setOpenTooltip(
                                        (openTooltip) => !openTooltip,
                                    )
                                }
                                sx={{ cursor: 'pointer' }}
                            >
                                <GivelifyIcon
                                    color={DesignTokens.color.iconPrimary}
                                    fontSize={24}
                                    variant="info"
                                />
                            </Stack>
                        </GivelifyTooltip>
                    </div>
                </ClickAwayListener>
            </Stack>
            <div className={amountControlsWrapper}>
                <div className={radioButtonsWrapper}>
                    <GivelifyFormRadio<FormEnvelope>
                        className={extraMarginBottom}
                        disabled={isLoading || isDefault}
                        id="set-fund-no"
                        label={copy.no}
                        name="hasExternalId"
                        value={false}
                    />
                    <GivelifyFormRadio<FormEnvelope>
                        className={extraMarginBottom}
                        disabled={isLoading || isDefault}
                        id="set-fund-yes"
                        label={copy.yes}
                        name="hasExternalId"
                        value={true}
                    />
                </div>
                <div>
                    <GivelifyFormTextField<FormEnvelope>
                        countLength
                        fullWidth
                        disabled={isLoading || isDefault || !hasExternalId}
                        id="fund-name"
                        label={copy.fundName}
                        leftHelperText={copy.fundIdTip}
                        maxLength={55}
                        name="externalId"
                        placeholder={copy.fundIdPlaceholder}
                    />
                </div>
            </div>
            <div className={buttonsWrapper}>
                <GivelifyButton
                    data-testid="envelope-cancel"
                    disabled={isLoading}
                    name="cancelEditEnvelope"
                    onClick={onCancelClick}
                    size="large"
                    text={copy.cancel}
                    variant="secondary"
                />
                <GivelifyButton
                    data-testid="envelope-save"
                    disabled={!isDirty}
                    isLoading={isLoading}
                    name={id ? 'editEnvelope' : 'createEnvelope'}
                    size="large"
                    text={copy.save}
                    type="submit"
                    variant="primary"
                />
            </div>
        </div>
    );
};

export default View;
