import React from 'react';
import { ApiHandler, GetQuickGiveResponse } from '@givelify/api';
import {
    BasicPageWrapper,
    GivelifyLabel,
    GivelifyRadio,
    QuickGiveMobilePreview,
} from '@givelify/ui';
import {
    PAGE_NAME,
    TrackingProvider,
    isSucceeded,
    mergeClassNames,
    useApiRequest,
} from '@givelify/utils';
import { Grid, Stack } from '@mui/material';
import permissionTypes from 'constants/permissionTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PATH } from 'router/routes';
import permissionsByPath from 'utils/permissionsByPath';
import { ErrorText } from '../../../components/ErrorText';
import LoadingBar from '../../../components/system/LoadingBar';
import { AppState } from '../../../store';
import HandleServerErrorText from '../../../theme/components/HandleServerErrorText';
import {
    HideOnMobile,
    QuickGiveButtonBox,
    QuickGiveOptionsContainer,
} from './style';

const quickGiveTypeDefault = 'Default';
const qucikGiveTypeChai = 'Chai';

const QuickGivePage: React.FCC = () => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t('pages.settings.quick-give.page-title'),
            caption: t('pages.settings.quick-give.caption'),
            descriptionLong: t('pages.settings.quick-give.description-long'),
            descriptionShort: t('pages.settings.quick-give.description-short'),
            defualtTitle: t('pages.settings.quick-give.default-title'),
            defaultCaption: t('pages.settings.quick-give.default-caption'),
            chaiTitle: t('pages.settings.quick-give.chai-title'),
            chaiCaption: t('pages.settings.quick-give.chai-caption'),
            other: t('pages.settings.quick-give.other'),
            selectGift: t('pages.settings.quick-give.select-gift'),
            pageLoadFail: t('labels.page-load-fail'),
        }),
        [t],
    );
    const { user, doneeId, doneeName, imageUrl } = useSelector(
        (state: AppState) => ({
            user: state.User.user,
            doneeId: state.Donee.donee.id,
            doneeName: state.Donee.donee.name,
            imageUrl: state.Donee.donee.photo,
        }),
    );
    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.QUICK_GIVE][user?.role] ===
        permissionTypes.FULL_ACCESS;
    const quickGiveDefaultOptions = React.useMemo(
        () => [
            '$10',
            '$25',
            '$50',
            '$100',
            '$250',
            '$500',
            '$1,000',
            '$2,500',
            copy.other,
        ],
        [copy],
    );
    const quickGiveChaiOptions = React.useMemo(
        () => [
            '$18',
            '$36',
            '$72',
            '$180',
            '$450',
            '$900',
            '$1,800',
            '$3,600',
            copy.other,
        ],
        [copy],
    );
    const [selectedValue, setSelectedValue] =
        React.useState(quickGiveTypeDefault);
    const [getQuickGiveState, getQuickGiveRequest] =
        useApiRequest<GetQuickGiveResponse>();
    const [updateQuickGiveState, updateQuickGiveRequest] =
        useApiRequest<unknown>();
    React.useEffect(() => {
        getQuickGiveRequest(ApiHandler.instance.donees.getQuickGive(doneeId));
    }, [getQuickGiveRequest, doneeId]);
    const onDefaultOptionSelect = () => {
        if (selectedValue !== quickGiveTypeDefault) {
            setSelectedValue(quickGiveTypeDefault);
            updateQuickGiveRequest(
                ApiHandler.instance.donees.updateQuickGive(doneeId, {
                    quickgiveId: 1,
                }),
            );
        }
    };
    const onChaiOptionSelect = () => {
        if (!hasFullAccess) return;
        if (selectedValue !== qucikGiveTypeChai) {
            setSelectedValue(qucikGiveTypeChai);
            updateQuickGiveRequest(
                ApiHandler.instance.donees.updateQuickGive(doneeId, {
                    quickgiveId: 3,
                }),
            );
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === quickGiveTypeDefault)
            onDefaultOptionSelect();
        else onChaiOptionSelect();
    };
    React.useEffect(() => {
        if (
            isSucceeded(getQuickGiveState) &&
            getQuickGiveState.response.data.quickgiveId === 3
        ) {
            setSelectedValue(qucikGiveTypeChai);
        }
    }, [getQuickGiveState]);

    if (
        getQuickGiveState.type === 'REQUEST_INIT' ||
        getQuickGiveState.type === 'REQUEST_START'
    ) {
        return <LoadingBar show />;
    }

    return (
        <BasicPageWrapper
            showDivider
            contentWrapper="simple"
            description={copy.caption}
            title={copy.title}
        >
            {getQuickGiveState.type === 'REQUEST_ERROR' ? (
                <ErrorText text={copy.pageLoadFail} />
            ) : (
                <>
                    <HandleServerErrorText
                        requestState={updateQuickGiveState}
                    />
                    <Grid container justifyContent="space-between">
                        <Grid item mobile={6.6} smallMobile={12}>
                            <div>
                                <GivelifyLabel
                                    text={copy.descriptionShort}
                                    variant="body1B"
                                />
                                <GivelifyLabel
                                    text={copy.descriptionLong}
                                    variant="body2"
                                />
                            </div>
                            <Stack gap={3} mt={3}>
                                <QuickGiveOptionsContainer
                                    active={
                                        selectedValue === quickGiveTypeDefault
                                    }
                                    className={mergeClassNames(
                                        selectedValue ===
                                            quickGiveTypeDefault && 'selected',
                                    )}
                                    data-testid="defaultOptionSelect"
                                    disabled={!hasFullAccess}
                                    onClick={onDefaultOptionSelect}
                                >
                                    <GivelifyLabel
                                        text={copy.defualtTitle}
                                        variant="body1B"
                                    />
                                    <GivelifyLabel
                                        text={copy.defaultCaption}
                                        variant="body2"
                                    />
                                    <Grid
                                        container
                                        justifyContent="center"
                                        marginBottom={3}
                                        paddingTop={3}
                                        spacing={3}
                                    >
                                        {quickGiveDefaultOptions.map(
                                            (value) => (
                                                <Grid
                                                    key={'default_' + value}
                                                    item
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    xs={4}
                                                >
                                                    <QuickGiveButtonBox>
                                                        <GivelifyLabel
                                                            text={value}
                                                            variant="body1"
                                                        />
                                                    </QuickGiveButtonBox>
                                                </Grid>
                                            ),
                                        )}
                                    </Grid>
                                    {hasFullAccess && (
                                        <Stack
                                            alignItems="center"
                                            data-testid="radio-select"
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <GivelifyRadio
                                                checked={
                                                    selectedValue ===
                                                    quickGiveTypeDefault
                                                }
                                                inputProps={{
                                                    'aria-label':
                                                        'QuickGive Default',
                                                }}
                                                name="quick-give-default"
                                                onChange={handleChange}
                                                value={quickGiveTypeDefault}
                                            />
                                        </Stack>
                                    )}
                                </QuickGiveOptionsContainer>
                                <QuickGiveOptionsContainer
                                    active={selectedValue === qucikGiveTypeChai}
                                    className={mergeClassNames(
                                        selectedValue === qucikGiveTypeChai &&
                                            'selected',
                                    )}
                                    data-testid="chaiOptionSelect"
                                    disabled={!hasFullAccess}
                                    onClick={onChaiOptionSelect}
                                >
                                    <GivelifyLabel
                                        text={copy.chaiTitle}
                                        variant="body1B"
                                    />
                                    <GivelifyLabel
                                        text={copy.chaiCaption}
                                        variant="body2"
                                    />
                                    <Grid
                                        container
                                        justifyContent="center"
                                        marginBottom={3}
                                        paddingTop={3}
                                        spacing={3}
                                    >
                                        {quickGiveChaiOptions.map((value) => (
                                            <Grid
                                                key={'chai' + value}
                                                item
                                                alignItems="center"
                                                justifyContent="center"
                                                xs={4}
                                            >
                                                <QuickGiveButtonBox>
                                                    <GivelifyLabel
                                                        text={value}
                                                        variant="body1"
                                                    />
                                                </QuickGiveButtonBox>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {hasFullAccess && (
                                        <Stack
                                            alignItems="center"
                                            data-testid="radio-select"
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <GivelifyRadio
                                                checked={
                                                    selectedValue ===
                                                    qucikGiveTypeChai
                                                }
                                                inputProps={{
                                                    'aria-label':
                                                        'QuickGive Chai',
                                                }}
                                                name="quick-give-chai"
                                                onChange={handleChange}
                                                value={qucikGiveTypeChai}
                                            />
                                        </Stack>
                                    )}
                                </QuickGiveOptionsContainer>
                            </Stack>
                        </Grid>
                        <HideOnMobile
                            container
                            item
                            justifyContent="center"
                            mobile={5.4}
                            smallMobile={0}
                        >
                            <QuickGiveMobilePreview
                                contentTitle={copy.selectGift}
                                doneeName={doneeName}
                                imageUrl={imageUrl}
                                options={
                                    selectedValue === quickGiveTypeDefault
                                        ? quickGiveDefaultOptions
                                        : quickGiveChaiOptions
                                }
                            />
                        </HideOnMobile>
                    </Grid>
                </>
            )}
        </BasicPageWrapper>
    );
};

export const QuickGive: React.FCC = () => {
    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.SettingsQuickGive}>
            <QuickGivePage />
        </TrackingProvider>
    );
};
