import React from 'react';
import { Divider, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { GivelifyIcon } from '../icon';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { GivelifyTab, GivelifyTabProps } from '../tab';
import {
    BasicPageLegacyPaper,
    PageBox,
    PageHeader,
    PageTitle,
    BasicPageSimplePaper,
} from './styles';

type BasicPageWrapperProps = {
    title: string;
    description?: string;
    tabProps?: GivelifyTabProps;
    showSecure?: boolean;
    contentWrapper?: 'legacy' | 'simple' | 'none';
    showDivider?: boolean;
};

export const BasicPageWrapper: React.FCC<BasicPageWrapperProps> = ({
    title,
    description = title,
    tabProps,
    showSecure,
    contentWrapper = 'none',
    showDivider,
    children,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            secure: t('labels.secure'),
        }),
        [t],
    );
    const ContentWrapper = React.useMemo(
        () =>
            contentWrapper === 'none'
                ? React.Fragment
                : contentWrapper === 'legacy'
                ? BasicPageLegacyPaper
                : BasicPageSimplePaper,
        [contentWrapper],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    return (
        <PageBox>
            <Helmet>
                <title>{title}</title>
                <meta content={description} name="description" />
            </Helmet>
            <PageHeader>
                <PageTitle
                    data-testid="page-title"
                    role="heading"
                    variant="heading2"
                >
                    {title}
                    {showSecure ? (
                        <Stack
                            alignItems="center"
                            color={DesignTokens.color.textSecondary}
                            display="flex"
                            flexDirection="row"
                            gap={1}
                        >
                            <GivelifyIcon
                                color="inherit"
                                fontSize={isMobile ? 12 : 24}
                                variant="lock"
                            />
                            <GivelifyLabel
                                color="inherit"
                                data-testid="safe-and-secure"
                                text={copy.secure}
                                variant={isMobile ? 'caption1' : 'body1'}
                            />
                        </Stack>
                    ) : null}
                </PageTitle>
                {tabProps ? <GivelifyTab {...tabProps} /> : null}
            </PageHeader>
            {showDivider ? <Divider /> : null}
            <ContentWrapper>{children}</ContentWrapper>
        </PageBox>
    );
};
