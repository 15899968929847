import React, { useMemo, useEffect } from 'react';
import {
    ApiHandler,
    mapSignedUserToLocalUser,
    UpdateUserResponse,
} from '@givelify/api';
import { emailRegex } from '@givelify/givelify-ui';
import {
    GivelifyButton,
    GivelifyForm,
    GivelifyFormCheckbox,
    GivelifyFormTextField,
} from '@givelify/ui';
import { useApiRequest } from '@givelify/utils';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { boolean, string } from 'yup';
import * as yup from 'yup';
import { AppState, useAppDispatch } from '../../../store';
import { setUser } from '../../../store/user/actions';
import HandleServerErrorText from '../../../theme/components/HandleServerErrorText';
import { phoneRegExp } from '../../../utils/validationRegExp';

const removePhoneNumberFormatting = (phone: string) =>
    phone.replace(/\D+/g, '');

export const addPhoneNumberFormatting = (phone: string | undefined) => {
    if (!phone) return '';
    const array = phone
        .toString()
        .replace(/\D+/g, '')
        .match(/^(\d{3})(\d{3})(\d{4})$/);
    if (!array || array.length < 4) return '';
    return `${array[1]}-${array[2]}-${array[3]}`;
};

type EditProfile = {
    title: string;
    firstName: string;
    lastName: string;
    profilePicUrl?: string;
    email: string;
    phoneNumber: string;
    isNotification: boolean;
};

export const ButtonContent = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

export const CancelButton = styled(GivelifyButton)(({ theme }) => ({
    marginRight: 24,
    [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: 24,
    },
}));

export const endPoint = (userId: string | number) => `users/${userId}`;

type EditProfileFormProps = {
    disabled: boolean;
};

export const EditProfileForm: React.FCC<EditProfileFormProps> = ({
    disabled,
}) => {
    const { PATH } = useGasRouterContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [requestState, makeRequest] = useApiRequest<UpdateUserResponse>();
    const user = useSelector((state: AppState) => state.User.user);
    const copy = useMemo(
        () => ({
            save: t('labels.save'),
            cancel: t('labels.cancel'),

            title: t('editProfile.form.title'),
            firstName: t('editProfile.form.firstName'),
            lastName: t('editProfile.form.lastName'),
            email: t('editProfile.form.email'),
            phoneNumber: t('editProfile.form.phoneNumber'),
            isNotification: t('editProfile.form.isNotification'),

            emailRequired: t('error.signup.email'),
            phoneRequired: t('error.signup.phone'),
            titleRequired: t('editProfile.errorMessage.titleRequired'),
            firstNameRequired: t('editProfile.errorMessage.firstNameRequired'),
            lastNameRequired: t('editProfile.errorMessage.lastNameRequired'),
            titleLength: t('editProfile.errorMessage.titleLength'),
            firstNameLength: t('editProfile.errorMessage.firstNameLength'),
            lastNameLength: t('editProfile.errorMessage.lastNameLength'),
            firstNameShort: t('editProfile.errorMessage.shortError', {
                nameItem: t('editProfile.form.firstName'),
            }),
            lastNameShort: t('editProfile.errorMessage.shortError', {
                nameItem: t('editProfile.form.lastName'),
            }),
        }),
        [t],
    );

    useEffect(() => {
        if (requestState.type === 'REQUEST_SUCCESS') {
            dispatch(
                setUser(mapSignedUserToLocalUser(requestState.response.data)),
            );
            navigate(PATH.OVERVIEW);
        }
    }, [requestState, navigate, dispatch, PATH.OVERVIEW]);

    const handleSubmit = async (values: EditProfile) => {
        makeRequest(
            ApiHandler.instance.users.updateUser(user.id, {
                title: values.title,
                name: values.firstName,
                lname: values.lastName,
                email: values.email,
                phone: removePhoneNumberFormatting(values.phoneNumber),
                notify: values.isNotification,
            }),
        );
    };
    const onClick = () => navigate(PATH.OVERVIEW);
    const loading = requestState.type === 'REQUEST_START';
    return (
        <GivelifyForm<EditProfile>
            defaultValues={{
                title: user.title,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: addPhoneNumberFormatting(user.phone),
                isNotification: user.notify,
            }}
            onSubmit={handleSubmit}
            schema={yup.object().shape({
                title: string()
                    .required(copy.titleRequired)
                    .max(25, copy.titleLength),
                firstName: string()
                    .required(copy.firstNameRequired)
                    .max(55, copy.firstNameLength)
                    .min(3, copy.firstNameShort),
                lastName: string()
                    .required(copy.lastNameRequired)
                    .max(55, copy.lastNameLength)
                    .min(1, copy.lastNameShort),
                email: string().matches(emailRegex, copy.emailRequired),
                phoneNumber: string().matches(phoneRegExp, copy.phoneRequired),
                isNotification: boolean(),
                profilePicUrl: string().optional(),
            })}
        >
            <Stack spacing={2}>
                <GivelifyFormTextField<EditProfile>
                    fullWidth
                    disabled={disabled}
                    id="title-input"
                    name="title"
                    placeholder={copy.title}
                    type="text"
                />
                <GivelifyFormTextField<EditProfile>
                    fullWidth
                    disabled={disabled}
                    id="firstName-input"
                    name="firstName"
                    placeholder={copy.firstName}
                    type="text"
                />
                <GivelifyFormTextField<EditProfile>
                    fullWidth
                    disabled={disabled}
                    id="lastName-input"
                    name="lastName"
                    placeholder={copy.lastName}
                    type="text"
                />
                <GivelifyFormTextField<EditProfile>
                    fullWidth
                    disabled={disabled}
                    id="email-input"
                    name="email"
                    placeholder={copy.email}
                    type="text"
                />
                <GivelifyFormTextField<EditProfile>
                    fullWidth
                    disabled={disabled}
                    id="phoneNumber-input"
                    name="phoneNumber"
                    placeholder={copy.phoneNumber}
                    type="text"
                />
                <GivelifyFormCheckbox<EditProfile>
                    aria-label="Notify checkbox"
                    disabled={disabled}
                    label={copy.isNotification}
                    name="isNotification"
                />
            </Stack>
            <HandleServerErrorText requestState={requestState} />
            <ButtonContent>
                <CancelButton
                    name="cancel"
                    onClick={onClick}
                    size="large"
                    text={copy.cancel}
                    variant="secondary"
                />
                <GivelifyButton
                    disabled={disabled || loading}
                    isLoading={loading}
                    name="save"
                    size="large"
                    text={copy.save}
                    type="submit"
                    variant="primary"
                />
            </ButtonContent>
        </GivelifyForm>
    );
};
