import { GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material';

export const LinkContainer = styled('div')(({ theme }) => ({
    background: '#e3e3e34c',
    borderRadius: theme.spacing(0.75),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopRightRadius: theme.spacing(4),
    borderBottomRightRadius: theme.spacing(4),
    [theme.breakpoints.down('mobile')]: {
        borderTopRightRadius: theme.spacing(0.75),
        borderBottomRightRadius: theme.spacing(0.75),
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
    },
}));

export const UrlText = styled(GivelifyLabel)(({ theme }) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
        width: '100%',
        background: '#e3e3e34c',
        borderRadius: '6px',
        padding: '15px 16px',
        marginBottom: '4px',
    },
}));

export const CopyLinkButton = styled(GivelifyButton)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
    },
}));

export const HideOnMobile = styled('div')(({ theme }) => ({
    display: 'initial',
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

export const ShowOnMobile = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('mobile')]: {
        display: 'initial',
    },
}));
