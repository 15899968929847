import {
    DesignTokens,
    GivelifyButton,
    GivelifyLabel,
    GivelifyModal,
    GivelifyTextField,
    GivelifyNotification,
} from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const MemberIdBox = styled(Grid)(({ theme }) => ({
    backgroundColor: DesignTokens.color.globalNeutral50,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
}));

export const CopyEditButton = styled(GivelifyButton)({
    color: DesignTokens.color.textAccentDefault,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    padding: 0,
});

export const MemberIdLabel = styled(GivelifyLabel)(({ theme }) => ({
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
}));

export const MemberIdInput = styled(GivelifyTextField)({
    fontSize: '14px',
    fontWeight: 600,
    '& .MuiInputBase-root': {
        padding: 0,
    },
});

export const AddMemberIdButton = styled(GivelifyButton)(({ theme }) => ({
    fontSize: '12px',
    padding: 0,
    fontWeight: 700,
    [theme.breakpoints.down('mobile')]: {
        marginRight: 'auto',
    },
}));

export const AddEditModal = styled(GivelifyModal)({
    '& .MuiPaper-root': {
        paddingBottom: '48px',
    },
});

export const SuccessNotification = styled(GivelifyNotification)(
    ({ theme }) => ({
        [theme.breakpoints.down('mobile')]: {
            bottom: '86px',
            '& .MuiPaper-root': {
                flexWrap: 'nowrap',
            },
        },
    }),
);

export const CancelSaveButton = styled(GivelifyButton)({
    width: '120px',
});
