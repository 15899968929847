import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Config from '../../../../config';
import { useDonee } from '../../../../provider/Connector';
import { I18N_NAMESPACE } from '../../../../types/const';
import { encodeDoneeId } from '../../../../utils/donee';
import { useTranslations } from '../../../../utils/translations';

export type FormInput = {
    name: string;
    email: string;
};

export default function useComponentData() {
    const [errorMessage, setErrorMessage] = React.useState('');
    const { id: doneeId } = useDonee();
    const { t } = useTranslation(I18N_NAMESPACE);
    const { validation } = useTranslations('general.errors', {
        validation: ['required', 'email'],
    }) as { validation: { required: string; email: string } };
    const formSchema = yup.object().shape({
        name: yup
            .string()
            .required(validation.required)
            .min(3, t('general.errors.validation.minLength', { count: 3 }))
            .max(
                101,
                t('general.errors.validation.minLength', {
                    count: 101,
                }),
            ),
        email: yup
            .string()
            .required(validation.required)
            .email(validation.email)
            .max(
                255,
                t('general.errors.validation.maxLength', {
                    count: 255,
                }),
            ),
    });

    // Get Givelify button public page
    const publicPageLink = React.useMemo(
        () =>
            `${Config.analyticsBaseUrl}/givelify-button/${encodeDoneeId(
                doneeId,
            )}`,
        [doneeId],
    );

    React.useEffect(() => {
        // pushEvent(ONLINE_GIVING_LANDED);

        return () => {
            setErrorMessage('');
        };
    }, []);

    return {
        publicPageLink,
        formSchema,
        errorMessage,
        setErrorMessage,
    };
}
