import React from 'react';
import { AddressWithPhone } from '@givelify/givelify-ui';
import {
    AppProfile,
    FaithLeaderEditorInfo,
    ImageWithDimensions,
    OrganizationType,
} from '../@types/assets/onboarding';
import { CustomizeProfileMobilePreview } from './CustomizeProfileMobilePreview';
import { CustomizeProfileRef } from './CustomizeProfileRef';
import { CustomizeProfileStepOne } from './CustomizeProfileStepOne';
import { AddressEditorFormRef } from './CustomizeProfileStepOne/AddressEditor';
import { CustomizeProfileStepTwo } from './CustomizeProfileStepTwo';

export type Step = 'main' | 'mission' | 'phone';

export interface CustomizeProfileComponentsProps {
    userId: number;
    doneeId: number;
    step: Step;
    organizationName: string;
    organizationType: OrganizationType;
    appProfile: AppProfile;
    onMobilePreviewRequest: () => void;
    settingsUsersPath: string;
    formProps?: {
        bunnerImageFormSubmitProps?: {
            onSave: (bannerImage: ImageWithDimensions | undefined) => void;
            isSaving?: boolean;
            disable?: boolean;
        };
        addressFormSubmitProps?: {
            onSave: (address: AddressWithPhone | undefined) => void;
            isSaving?: boolean;
            disable?: boolean;
            formRef?: React.Ref<AddressEditorFormRef>;
        };
        faithLeadeFormSubmitProps?: {
            onSave: (
                faithLeader: FaithLeaderEditorInfo | undefined,
                faithLeaderImage: ImageWithDimensions | undefined,
            ) => void;
            isSaving?: boolean;
            disable?: boolean;
        };
    };
}

const CustomizeProfileComponents: React.FCC<
    CustomizeProfileComponentsProps & {
        customizeProfileRef: React.RefObject<CustomizeProfileRef>;
        setIsValid?: (isValid: boolean) => unknown;
    }
> = ({
    userId,
    doneeId,
    organizationName,
    step,
    organizationType,
    appProfile,
    customizeProfileRef,
    setIsValid,
    onMobilePreviewRequest,
    settingsUsersPath,
    formProps,
}) => {
    switch (step) {
        default:
        case 'main':
            return (
                <CustomizeProfileStepOne
                    ref={customizeProfileRef}
                    appProfile={appProfile}
                    doneeId={doneeId}
                    formProps={formProps}
                    onMobilePreviewRequest={onMobilePreviewRequest}
                    organizationName={organizationName}
                    organizationType={organizationType}
                    setIsValid={setIsValid}
                    settingsUsersPath={settingsUsersPath}
                    userId={userId}
                />
            );
        case 'mission':
            return (
                <CustomizeProfileStepTwo
                    ref={customizeProfileRef}
                    appProfile={appProfile}
                    doneeId={doneeId}
                    formProps={formProps}
                    onMobilePreviewRequest={onMobilePreviewRequest}
                    organizationName={organizationName}
                    organizationType={organizationType}
                    setIsValid={setIsValid}
                    settingsUsersPath={settingsUsersPath}
                    userId={userId}
                />
            );
        case 'phone':
            return (
                <CustomizeProfileMobilePreview
                    appProfile={appProfile}
                    doneeId={doneeId}
                    formProps={formProps}
                    organizationName={organizationName}
                    organizationType={organizationType}
                    userId={userId}
                />
            );
    }
};

export const CustomizeProfileModalComponents = React.memo(
    CustomizeProfileComponents,
);
