import { styled } from '@mui/material';
import { GivelifyButton } from '../button';

export const FooterSubmit = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column-reverse',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: theme.spacing(1),
        marginTop: theme.spacing(3.5),
    },
}));

export const BackButton = styled(GivelifyButton)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: 'auto',
}));
