import React, { useMemo } from 'react';
import { PhysicalAddress } from '@givelify/api';
import { GivelifyFormTextField } from '@givelify/ui';
import { containsPOBox, STATE_DROPDOWN_OPTIONS } from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { ErrorText } from 'components/ErrorText';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zipRegExp } from 'utils/validationRegExp';
import { object, string, AnySchema } from 'yup';
import EditorButtons from '../EditorButtons';
import {
    AddressEditorForm,
    EditorInputContainer,
    StateSelector,
} from './styles';
import { TimeZoneNote } from './TimeZoneNote';

interface AddressSectionEditorProps {
    data?: PhysicalAddress;
    onCancel: () => void;
    onSubmit: (data: PhysicalAddress) => void;
    saving?: boolean;
    error?: string;
}

export const AddressSectionEditor: React.FCC<AddressSectionEditorProps> = (
    props: AddressSectionEditorProps,
) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            addressPlaceholder: t(
                'pages.settings.organization-info.physical.editor.addressPlaceholder',
            ),
            cityPlaceholder: t(
                'pages.settings.organization-info.physical.editor.cityPlaceholder',
            ),
            statePlaceholder: t(
                'pages.settings.organization-info.physical.editor.statePlaceholder',
            ),
            zipPlaceholder: t(
                'pages.settings.organization-info.physical.editor.zipPlaceholder',
            ),
            zipValid: t(
                'pages.settings.organization-info.physical.editor.zipValid',
            ),
            addressValid: t(
                'pages.settings.organization-info.physical.editor.addressValid',
            ),
            addressMaxlen: t(
                'pages.settings.organization-info.physical.editor.addressMaxlen',
            ),
            cityMaxlen: t(
                'pages.settings.organization-info.physical.editor.cityMaxlen',
            ),
            required: t('labels.required'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );

    const schema = object<Record<keyof PhysicalAddress, AnySchema>>({
        street: string()
            .test('PO Box', copy.addressValid, (value) => {
                return !containsPOBox(value);
            })
            .required(copy.required)
            .max(200, copy.addressMaxlen),
        postal: string()
            .max(5)
            .matches(zipRegExp, copy.zipValid)
            .required(copy.required),
        state: string().required(copy.required),
        city: string().required(copy.required).max(55, copy.cityMaxlen),
    });

    const form = useForm<PhysicalAddress>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: {
            street: props.data.street || '',
            postal: props.data.postal || '',
            state: props.data.state || '',
            city: props.data.city || '',
        },
    });

    const handleSubmit: SubmitHandler<PhysicalAddress> = (formValues) => {
        props.onSubmit(formValues);
    };

    return (
        <FormProvider {...form}>
            <AddressEditorForm onSubmit={form.handleSubmit(handleSubmit)}>
                <Grid container spacing={2}>
                    <EditorInputContainer item md={6} sm={12} xs={12}>
                        <GivelifyFormTextField
                            fullWidth
                            aria-label="address-input"
                            data-testid="address"
                            disabled={props.saving}
                            id="address"
                            maxLength={200}
                            name="street"
                            placeholder={copy.addressPlaceholder}
                        />
                    </EditorInputContainer>
                    <EditorInputContainer item md={6} sm={12} xs={12}>
                        <GivelifyFormTextField
                            fullWidth
                            aria-label="city-input"
                            data-testid="city"
                            disabled={props.saving}
                            id="city"
                            maxLength={55}
                            name="city"
                            placeholder={copy.cityPlaceholder}
                        />
                    </EditorInputContainer>
                    <EditorInputContainer item md={6} sm={12} xs={12}>
                        <StateSelector<PhysicalAddress>
                            aria-label="stateSelector"
                            data-testid="stateSelector"
                            disabled={props.saving}
                            id="stateSelector"
                            name="state"
                            options={STATE_DROPDOWN_OPTIONS}
                            placeholder={copy.statePlaceholder}
                            shape="input"
                            width="fullwidth"
                        />
                    </EditorInputContainer>
                    <EditorInputContainer item md={6} sm={12}>
                        <GivelifyFormTextField
                            fullWidth
                            aria-label="zip-input"
                            data-testid="zip"
                            disabled={props.saving}
                            id="zip"
                            maxLength={5}
                            name="postal"
                            placeholder={copy.zipPlaceholder}
                        />
                    </EditorInputContainer>
                </Grid>
                <TimeZoneNote />
                {props.error && <ErrorText text={props.error} />}
                <EditorButtons
                    onCancel={props.onCancel}
                    saving={props.saving}
                />
            </AddressEditorForm>
        </FormProvider>
    );
};
