import React, { useMemo } from 'react';
import { GivelifyRadio } from '@givelify/ui';
import { RADIO_BUTTON_IDENTIFIER, useTrackingContext } from '@givelify/utils';
import {
    FORMAT,
    getFormatFilter,
    LegacyXlsFormatVisible,
} from 'pages/reports/utils/reportFormatFilterUtils';
import { useAdvancedTranslation } from 'utils/i18n';
import { LegacyReportItemRadioWrapper } from '../../../components/style';

interface LegacyFileFormatProps {
    type: 'custom' | 'quickbooks';
    onChange: (value: boolean) => unknown;
    fileType: string;
    className?: string;
    pageName?: string;
}

const LegacyFileFormat: React.FCC<LegacyFileFormatProps> = ({
    type,
    onChange,
    fileType,
    pageName,
}) => {
    const { t } = useAdvancedTranslation();
    const { trackEvent } = useTrackingContext();
    const copy = useMemo(
        () => ({
            csv: t('reports.formatFile.csv'),
            excel: t('reports.formatFile.excel'),
        }),
        [t],
    );

    const handleFormat = (format: FORMAT) => {
        onChange(format === FORMAT.CSV);
        trackEvent(
            format === FORMAT.CSV
                ? `<CSV>_${RADIO_BUTTON_IDENTIFIER}`
                : `<XLSX>_${RADIO_BUTTON_IDENTIFIER}`,
        );
    };

    return (
        <div id="select-format">
            {type === 'custom' && LegacyXlsFormatVisible.includes(pageName) && (
                <LegacyReportItemRadioWrapper>
                    <GivelifyRadio
                        aria-label="Format"
                        checked={fileType === getFormatFilter(FORMAT.EXCEL)}
                        id="select-format-xlsx"
                        inputProps={{
                            'data-testid': 'select-format-xlsx',
                        }}
                        label={copy.excel}
                        name="format"
                        onChange={() => handleFormat(FORMAT.EXCEL)}
                        value="excel"
                    />
                </LegacyReportItemRadioWrapper>
            )}
            <LegacyReportItemRadioWrapper>
                <GivelifyRadio
                    aria-label="Format"
                    checked={fileType === getFormatFilter(FORMAT.CSV)}
                    id="select-format-csv"
                    inputProps={{
                        'data-testid': 'select-format-csv',
                    }}
                    label={copy.csv}
                    name="format"
                    onChange={() => handleFormat(FORMAT.CSV)}
                    value="csv"
                />
            </LegacyReportItemRadioWrapper>
        </div>
    );
};

export default LegacyFileFormat;
