import React from 'react';
import { MESSAGE_FILTER_VALUE } from '@givelify/api';
import { GivelifyPillProps } from '@givelify/ui';
import { PageFilterRow, PageFilterRowComponentProps } from 'components';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { generateDateRange } from 'utils/strings/dateRange';
import { DonationsFilter, DonationsFilterForm } from '../donationsFilterForm';

export type DonationsFilterRowProps = Omit<
    PageFilterRowComponentProps,
    | 'onFilterSubmit'
    | 'isFilterBtnActive'
    | 'canClear'
    | 'canSubmit'
    | 'canUndo'
    | 'onFilterClear'
    | 'onFilterUndo'
    | 'onFilterPanelClose'
> & {
    filters: DonationsFilter;
    onFilterSubmit: (filter: DonationsFilter, isSubmitButton?: boolean) => void;
    doneeSignupDate?: Date;
};

export const DonationsFilterRow: React.FCC<DonationsFilterRowProps> = ({
    isFilterPanelOpen,
    onFilterButtonClick,
    onFilterSubmit,
    onSearchSubmit,
    search,
    filterPanelSubmitText,
    filters,
    doneeSignupDate,
    disabled,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            dateRange: t('filters.dateRange'),
            messages: t('filters.messages'),
            envelopes: t('filters.envelopes'),
            REPLIED: t('donationsActivity.messages.repliedShort'),
            UN_REPLIED: t('donationsActivity.messages.unRepliedShort'),
            NO_MESSAGES: t('donationsActivity.messages.noMessages'),
        }),
        [t],
    );
    const [value, setValue] = React.useState<DonationsFilter>(filters);
    const [expand, setExpand] = React.useState(false);
    const onSubmitFilter = React.useCallback(
        (isSubmitButton?: boolean) => {
            onFilterSubmit(value, isSubmitButton);
        },
        [value, onFilterSubmit],
    );
    const onClearFilter = React.useCallback(() => {
        setValue({
            envelopes: {
                ...value.envelopes,
                values: [],
            },
            messages: [],
            timeFrame: undefined,
        });
    }, [value]);
    const onFilterUndo = React.useCallback(() => {
        setValue(filters);
    }, [filters]);
    const canUndo = React.useCallback(() => {
        return !isEqual(filters, value);
    }, [filters, value]);
    const onFilterPillRemove = React.useCallback(
        (key: string | string[] | number) => {
            if (key === 'timeFrame') {
                onFilterSubmit({
                    ...value,
                    timeFrame: undefined,
                });
                return;
            }
            if (key === MESSAGE_FILTER_VALUE.NO_MESSAGES) {
                onFilterSubmit({
                    ...value,
                    messages: value.messages.filter(
                        (m) => m !== MESSAGE_FILTER_VALUE.NO_MESSAGES,
                    ),
                });
                return;
            }
            if (key === MESSAGE_FILTER_VALUE.REPLIED) {
                onFilterSubmit({
                    ...value,
                    messages: value.messages.filter(
                        (m) => m !== MESSAGE_FILTER_VALUE.REPLIED,
                    ),
                });
                return;
            }
            if (key === MESSAGE_FILTER_VALUE.UN_REPLIED) {
                onFilterSubmit({
                    ...value,
                    messages: value.messages.filter(
                        (m) => m !== MESSAGE_FILTER_VALUE.UN_REPLIED,
                    ),
                });
                return;
            }
            const envelopeId = parseInt(key.toString());
            if (value.envelopes.values.includes(envelopeId)) {
                onFilterSubmit({
                    ...value,
                    envelopes: {
                        ...value.envelopes,
                        values: value.envelopes.values.filter(
                            (v) => v !== envelopeId,
                        ),
                    },
                });
            }
        },
        [onFilterSubmit, value],
    );

    React.useEffect(() => {
        setValue(filters);
    }, [filters]);

    const isFilterBtnActive =
        filters.envelopes.values.length > 0 ||
        filters.messages.length > 0 ||
        filters.timeFrame !== undefined;
    const canClear =
        value.envelopes.values.length > 0 ||
        value.messages.length > 0 ||
        value.timeFrame !== undefined;

    const filterPills = React.useMemo(() => {
        const pills: GivelifyPillProps[] = [];
        if (filters.timeFrame && filters.timeFrame.start !== null) {
            pills.push({
                dataTestId: 'timeFrame-pill',
                onClick: () => onFilterPillRemove('timeFrame'),
                text: copy.dateRange,
                value: generateDateRange({
                    start: filters.timeFrame.start,
                    end: filters.timeFrame.end,
                }),
            });
        }
        if (filters.messages.length > 0) {
            filters.messages.forEach((message) => {
                pills.push({
                    dataTestId: `message-${message}`,
                    onClick: () => onFilterPillRemove(message),
                    text: copy.messages,
                    value: copy[message],
                });
            });
        }
        if (filters.envelopes.values.length > 0) {
            filters.envelopes.values.forEach((value) => {
                const label = filters.envelopes.options.find(
                    (option) => option.id === value,
                )?.label;
                if (!label) return;
                pills.push({
                    dataTestId: `envelopes-${value}`,
                    onClick: () => onFilterPillRemove(value),
                    text: copy.envelopes,
                    value:
                        label.length <= 25 ? label : `${label.slice(0, 25)}...`,
                });
            });
        }
        return pills;
    }, [
        copy,
        filters.envelopes.options,
        filters.envelopes.values,
        filters.messages,
        filters.timeFrame,
        onFilterPillRemove,
    ]);

    return (
        <PageFilterRow
            canSubmit
            canClear={canClear}
            canUndo={canUndo()}
            disabled={disabled}
            filterPanelSubmitText={filterPanelSubmitText}
            filterPillProps={filterPills}
            isFilterBtnActive={isFilterBtnActive}
            isFilterPanelOpen={isFilterPanelOpen}
            onFilterButtonClick={onFilterButtonClick}
            onFilterClear={onClearFilter}
            onFilterPanelClose={onSubmitFilter}
            onFilterSubmit={() => onSubmitFilter(true)}
            onFilterUndo={onFilterUndo}
            onSearchSubmit={onSearchSubmit}
            search={search}
        >
            <DonationsFilterForm
                doneeSignupDate={doneeSignupDate}
                expand={expand}
                filter={value}
                setExpand={setExpand}
                setFilter={setValue}
            />
        </PageFilterRow>
    );
};
