import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import {
    ActionButton,
    MessageDivider,
    MessageIcon,
    MessageRow1,
    MessageRow1Left,
    MessageRow1Right,
    MessageRow2,
    MessageRow3,
    MessageTipBox,
    MessageTipMarker,
    MessageTitle,
    StyledMessage,
    IdentedLabel,
} from './styles';

type PrimaryRepJan1stContentProps = {
    adminPrefix: string;
    onActionClick?: () => void;
};
export const PrimaryRepJan1stContent: React.FC<
    PrimaryRepJan1stContentProps
> = ({ adminPrefix, onActionClick }): JSX.Element => {
    const { t } = useTranslation();
    const copy = React.useMemo(() => {
        return {
            title: t(`notifications.addPrimaryRep.jan1st.title`),
            description: t(`notifications.addPrimaryRep.jan1st.description`),
            title2: t(`notifications.addPrimaryRep.jan1st.tipTitle`),
            tip1: t(`notifications.addPrimaryRep.jan1st.tip1`),
            tip2: t(`notifications.addPrimaryRep.jan1st.tip2`),
            tip3: t(`notifications.addPrimaryRep.jan1st.tip3`),
            action: t(
                `notifications.addPrimaryRep.common.${adminPrefix}.buttonText`,
            ),
        };
    }, [adminPrefix, t]);
    return (
        <StyledMessage data-testid="gvl-ntf-message-root">
            <MessageRow1>
                <MessageRow1Left>
                    <MessageTitle data-testid="gvl-ntf-text" variant="body2B">
                        <MessageIcon variant="warning-box-icon" />
                        {copy.title}
                    </MessageTitle>
                    <IdentedLabel
                        data-testid="gvl-ntf-description"
                        variant="body2"
                    >
                        {copy.description}
                    </IdentedLabel>
                </MessageRow1Left>
                {onActionClick ? (
                    <MessageRow1Right>
                        <ActionButton
                            data-testid="gvl-ntf-action-btn"
                            onClick={onActionClick}
                            text={copy.action}
                            variant="primary"
                        />
                    </MessageRow1Right>
                ) : null}
            </MessageRow1>
            <MessageDivider />
            <MessageRow2>
                <IdentedLabel data-testid="gvl-ntf-tip-title" variant="body2B">
                    {copy.title2}
                </IdentedLabel>
                <MessageTipBox data-testid="gvl-ntf-tips">
                    <GivelifyLabel data-testid="gvl-ntf-tip-1" variant="body2">
                        <MessageTipMarker> &#8226;</MessageTipMarker>
                        {copy.tip1}
                    </GivelifyLabel>
                    <GivelifyLabel data-testid="gvl-ntf-tip-2" variant="body2">
                        <MessageTipMarker> &#8226;</MessageTipMarker>
                        {copy.tip2}
                    </GivelifyLabel>
                    <GivelifyLabel data-testid="gvl-ntf-tip-3" variant="body2">
                        <MessageTipMarker> &#8226;</MessageTipMarker>
                        {copy.tip3}
                    </GivelifyLabel>
                </MessageTipBox>
            </MessageRow2>
            {onActionClick ? (
                <MessageRow3>
                    <ActionButton
                        fullWidth
                        data-testid="gvl-ntf-action-btn"
                        onClick={onActionClick}
                        text={copy.action}
                        variant="primary"
                    />
                </MessageRow3>
            ) : null}
        </StyledMessage>
    );
};
