import {
    ApiResponse,
    getAxiosClient,
    makeApiRequest,
    toISODate,
} from '@givelify/utils';
import {
    DirectDepositInfo,
    PrimaryRepresentativeInfo,
    RepresentativeWithImage,
} from '../../@types/assets/onboarding';
import { YodleeBankAccountInfo } from '../../yodlee/yodleeResponseInterfaces';
import {
    directDepositUrl,
    getPrimaryRepresentativeApiEndpoint,
    getRepresentativesUrl,
    yodleeAccessTokenUrl,
    yodleeAccountsUrl,
} from '../consts/endpoints';
import { toFormData } from '../utils';

export interface DirectDepositDTO {
    accountNumber: string | number;
    routingNumber: string | number;
    billingAddress: string;
    billingCity: string;
    billingCountry: string;
    billingState: string;
    billingZip: string;
    voidedCheckFile: File | undefined;
}

const directDepositDTO = (data: DirectDepositInfo) => {
    const values: DirectDepositDTO = {
        accountNumber: data.accountNumber,
        routingNumber: data.routingNumber,
        billingAddress: data.bankingAddress.street,
        billingCity: data.bankingAddress.city,
        billingCountry: 'US',
        billingState: data.bankingAddress.state,
        billingZip: data.bankingAddress.zip,
        voidedCheckFile: data.bankChequeFile,
    };
    return values;
};

export const directDepositAPI = (doneeId: number, data: DirectDepositInfo) => {
    const url = directDepositUrl(doneeId);
    const formData = toFormData(directDepositDTO(data));
    const httpRequest = getAxiosClient().post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

export const yodleeAccessTokenAPI = (
    doneeId: number,
): Promise<ApiResponse<string>> => {
    const httpRequest = getAxiosClient().get(yodleeAccessTokenUrl(doneeId));
    const result = makeApiRequest<string>(httpRequest);
    return result;
};

export const yodleeAccountsAPI = (
    doneeId: number,
    accountId: string,
    providerAccountId: string,
    yodleeAccessToken: string,
): Promise<ApiResponse<YodleeBankAccountInfo>> => {
    const httpRequest = getAxiosClient().get(
        yodleeAccountsUrl(
            doneeId,
            accountId,
            providerAccountId,
            yodleeAccessToken,
        ),
    );
    const result = makeApiRequest<YodleeBankAccountInfo>(httpRequest);
    return result;
};

export interface PrimaryRepresentativeDTO {
    phoneNumber: string | undefined;
    state: string | undefined;
    city: string | undefined;
    zip: string | undefined;
    address: string | undefined;
    ssn: string | undefined;
    dateOfBirth: string | undefined;
    title: string | undefined;
    lastName: string | undefined;
    firstName: string | undefined;
    beneficiaryIdFile: File | undefined;
}

const primaryRepresentativeDTO = (
    data: PrimaryRepresentativeInfo,
): PrimaryRepresentativeDTO => ({
    phoneNumber: data.primaryRepresentativeAddress.phone,
    state: data.primaryRepresentativeAddress.state,
    city: data.primaryRepresentativeAddress.city,
    zip: data.primaryRepresentativeAddress.zip,
    address: data.primaryRepresentativeAddress.street,
    ssn: data.socialSecurityNumber,
    dateOfBirth: data.birthDate ? toISODate(data.birthDate) : undefined,
    title: data.title,
    lastName: data.lastName,
    firstName: data.firstName,
    beneficiaryIdFile: data.beneficiaryIdFile,
});

export const primaryRepresentativeAPI = (
    doneeId: number,
    data: PrimaryRepresentativeInfo,
): Promise<ApiResponse<unknown>> => {
    const url = getPrimaryRepresentativeApiEndpoint(doneeId);
    const formData = toFormData(primaryRepresentativeDTO(data));
    const httpRequest = getAxiosClient().post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    const result = makeApiRequest<unknown>(httpRequest);
    return result;
};

export const getRepresentativesAPI = (
    doneeId: number,
): Promise<ApiResponse<{ data: RepresentativeWithImage[] }>> => {
    const url = getRepresentativesUrl(doneeId);
    const httpRequest = getAxiosClient().get(url);
    const result = makeApiRequest<{ data: RepresentativeWithImage[] }>(
        httpRequest,
    );
    return result;
};
