import React from 'react';
import { useTranslation } from 'react-i18next';
import { GivelifyButton } from '../button';
import { FooterSubmit } from './styles';

type PrimaryRepEditorFooterProps = {
    id?: string;
    loading?: boolean;
    submitDisabled?: boolean;
    cancelDisabled?: boolean;
    onCancel: () => void;
    onSubmit?: () => void;
};

export const SubmitButtons: React.FCC<PrimaryRepEditorFooterProps> = ({
    id,
    loading,
    submitDisabled,
    cancelDisabled,
    onCancel,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );
    return (
        <FooterSubmit>
            <GivelifyButton
                data-testid={id ? `${id}-cancel` : undefined}
                disabled={loading || cancelDisabled}
                id={id ? `${id}-cancel` : undefined}
                name="cancel"
                onClick={onCancel}
                size="large"
                text={copy.cancel}
                variant="secondary"
            />
            <GivelifyButton
                data-testid={id ? `${id}-sbt` : undefined}
                disabled={loading || submitDisabled}
                id={id ? `${id}-sbt` : undefined}
                name="submit"
                onClick={onSubmit}
                size="large"
                text={copy.save}
                type="button"
                variant="primary"
            />
        </FooterSubmit>
    );
};
