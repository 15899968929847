import React from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { DesignTokens } from '@givelify/ui';
import useStyles from './styles';

interface EnvelopeProgressTooltipProps {
    value: number;
    valueText: string;
    percentige: string;
    id?: number;
    disabled: boolean;
}

const EnvelopeProgressTooltip: React.FCC<EnvelopeProgressTooltipProps> = (
    props,
) => {
    const classes = useStyles({
        position: props.value > 100 ? 100 : props.value,
        disabled: props.disabled,
    });
    return (
        <div className={classes.wrapper}>
            <div
                aria-label="Active envelopes progress tip"
                className={classes.progressTooltipContainer}
                id={`progress-tooltip-${props.id}`}
            >
                <GivelifyLabel
                    color={props.disabled && DesignTokens.color.textDisabled}
                    id={`envelope-value-${props.id}`}
                    text={props.valueText}
                    variant="body2"
                />
                <GivelifyLabel
                    color={props.disabled && DesignTokens.color.textDisabled}
                    text="|"
                    variant="body2"
                />
                <GivelifyLabel
                    color={props.disabled && DesignTokens.color.textDisabled}
                    dataTestId={`progress-bar-percent-${props.id}`}
                    id={`envelope-percent-${props.id}`}
                    text={props.percentige}
                    variant="body2"
                />
            </div>
        </div>
    );
};

export default EnvelopeProgressTooltip;
