import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgInfoFilled = (props: SVGProps<SVGSVGElement>) => (
    <svg
        color="rgb(113, 90, 255)"
        height="14"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 14 14"
    >
        <path
            d="M7.33366 0.666748C3.65366 0.666748 0.666992 3.65341 0.666992 7.33341C0.666992 11.0134 3.65366 14.0001 7.33366 14.0001C11.0137 14.0001 14.0003 11.0134 14.0003 7.33341C14.0003 3.65341 11.0137 0.666748 7.33366 0.666748ZM8.00033 10.6667H6.66699V6.66675H8.00033V10.6667ZM8.00033 5.33341H6.66699V4.00008H8.00033V5.33341Z"
            fill="current"
        />
    </svg>
);

export const InfoFilledIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgInfoFilled}
        fontSize={fontSize}
    />
);
