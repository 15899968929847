export default {
    givelifyBaseUrl: process.env.REACT_APP_SITE_URL || 'https://givelify.com',
    analyticsBaseUrl:
        process.env.REACT_APP_DASHBOARD_BASE_URL ||
        'https://analytics.givelify.com',
    signupBaseUrl:
        process.env.REACT_APP_SIGNUP_BASE_URL || 'https://signup.givelify.com',
    imageBaseUrl:
        process.env.REACT_APP_IMAGE_BASE_URL || 'https://images.givelify.com',
    fileServiceBaseUrl:
        process.env.REACT_APP_FILE_SERVICE_BASE_URL ||
        'https://file-service.givelify.com',
    onlineGivingKbUrl:
        'https://support.givelify.com/hc/en-us/articles/1500002996101-How-to-add-Givelify-online-giving-links-to-emails-and-social-media-',
    supportUrl: 'https://support.givelify.com',
};
