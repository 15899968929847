import React, { useMemo, useState, useEffect } from 'react';
import {
    ApiHandler,
    Donee,
    UpdateDoneeResponse,
    UpdateSocialInfoRequest,
} from '@givelify/api';
import {
    InvokeApiErrorResponse,
    isFailed,
    isSucceeded,
    useApiRequest,
} from '@givelify/utils';
import { Grid } from '@mui/material';
import { isEqual } from 'lodash-es';
import { EditOption } from 'pages/settings/components/EditOption';
import { SETTINGS_ASSETS_PATH } from 'pages/settings/SettingsAssetsPath';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { setDoneeSocial } from 'store/donee/actions';
import { EditButtonContainer, Section, Subtitle } from '../style';
import SocialItem from './SocialItem';
import SocialSectionEditor from './SocialSectionEditor';
import { WebsiteCol } from './styles';

interface SocialSectionProps {
    donee: Donee;
    isReadOnly: boolean;
}

const SocialSection: React.FCC<SocialSectionProps> = ({
    donee,
    isReadOnly,
}) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            socialMedia: t(
                'pages.settings.organization-info.social.socialMedia',
            ),
            website: t('pages.settings.organization-info.social.website'),
        }),
        [t],
    );

    const dispatch = useAppDispatch();

    const [socialData, setSocialData] = useState({
        facebook: '',
        instagram: '',
        twitter: '',
        website: '',
    });

    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    useEffect(() => {
        setSocialData({
            facebook: donee.facebook || '',
            instagram: donee.instagram || '',
            twitter: donee.twitter || '',
            website: donee.website || '',
        });
    }, [donee]);

    const [updateRequestState, updateRequest] =
        useApiRequest<UpdateDoneeResponse>();

    const [editorEnabledState, setEditorEnabledState] = useState(false);

    const onSubmit = (data: UpdateSocialInfoRequest) => {
        if (!isEqual(socialData, data)) {
            updateRequest(
                ApiHandler.instance.donees.updateSocialInfo(donee.id, data),
            );
        } else {
            setEditorEnabledState(false);
        }
    };

    useEffect(() => {
        if (isSucceeded(updateRequestState)) {
            dispatch(
                setDoneeSocial(
                    donee.id,
                    updateRequestState.response.facebook,
                    updateRequestState.response.instagram,
                    updateRequestState.response.twitter,
                    updateRequestState.response.website,
                    new Date(updateRequestState.response.updatedAt),
                ),
            );
            setEditorEnabledState(false);
        }
        if (isFailed(updateRequestState)) {
            setSaveError(
                (updateRequestState as InvokeApiErrorResponse).error.message,
            );
        }
    }, [updateRequestState, dispatch, donee.id]);

    const onEditClick = () => {
        setSaveError(undefined);
        setEditorEnabledState(true);
    };

    const onCancel = () => {
        setEditorEnabledState(false);
        setSaveError(undefined);
    };

    const saving = updateRequestState.type === 'REQUEST_START';

    return (
        <Section>
            {editorEnabledState ? (
                <SocialSectionEditor
                    data={socialData}
                    error={saveError}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    saving={saving}
                />
            ) : (
                <Grid container>
                    <Grid item md={6} sm={12} xs={12}>
                        <Subtitle text={copy.socialMedia} variant="heading2S" />
                        <SocialItem
                            imgAlt="facebook link"
                            imgSrc={SETTINGS_ASSETS_PATH.FACEBOOK_ICON}
                            isReadOnly={isReadOnly}
                            link={socialData?.facebook}
                            testId="fb"
                        />
                        <SocialItem
                            imgAlt="twitter link"
                            imgSrc={SETTINGS_ASSETS_PATH.TWITTER_ICON}
                            isReadOnly={isReadOnly}
                            link={socialData?.twitter}
                            testId="twt"
                        />
                        <SocialItem
                            imgAlt="instagram link"
                            imgSrc={SETTINGS_ASSETS_PATH.INSTAGRAM_ICON}
                            isReadOnly={isReadOnly}
                            link={socialData?.instagram}
                            testId="insta"
                        />
                    </Grid>
                    <WebsiteCol item md={6} sm={12} xs={12}>
                        <Subtitle text={copy.website} variant="heading2S" />
                        <SocialItem
                            imgAlt="web link"
                            imgSrc={SETTINGS_ASSETS_PATH.WEB_ICON}
                            isReadOnly={isReadOnly}
                            link={socialData?.website}
                            testId="web"
                        />
                    </WebsiteCol>
                </Grid>
            )}
            <EditButtonContainer>
                {!isReadOnly && !editorEnabledState && (
                    <EditOption
                        onClick={onEditClick}
                        testId="edit-social-info"
                    />
                )}
            </EditButtonContainer>
        </Section>
    );
};

export default SocialSection;
