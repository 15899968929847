import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { TimeFrameValue } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { formatMoneyWithCommasAndDolarSign } from 'utils/formatMoneyWithCommas';
import { Arrow, GraphToolTip, LinkText, TooltipLink } from './styles';

export type ChartTooltipProps = {
    x: number;
    y: number;
    label: string;
    value: number;
    timeFrame: TimeFrameValue;
};

const ChartTooltipComponent: React.FCC<ChartTooltipProps> = ({
    x,
    y,
    label,
    value,
    timeFrame,
}) => {
    const { PATH } = useGasRouterContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            totalReceivedShort: t('overview.totalReceivedShort'),
        }),
        [t],
    );

    return (
        <GraphToolTip
            data-testid="bar-chart-tooltip"
            style={{
                left: x,
                top: y - 20,
            }}
        >
            <TooltipLink
                data-testid="bar-chart-tooltip-link"
                name="Trend chart tooltip total received"
                onClick={() => {
                    navigate(
                        PATH.DONATIONS.DONATIONS_ACTIVITY({
                            params: {
                                startDate: timeFrame.start.format('YYYY-MM-DD'),
                                endDate: timeFrame.end.format('YYYY-MM-DD'),
                            },
                        }),
                    );
                }}
                text={
                    <LinkText
                        color={DesignTokens.color.textAccentDefault}
                        data-testid="chart-tooltip-value"
                        variant="body1B"
                    >
                        {formatMoneyWithCommasAndDolarSign(value)}
                    </LinkText>
                }
            />
            <GivelifyLabel
                color={DesignTokens.color.textSecondary}
                fontWeight="bold"
                variant="caption1B"
            >
                {copy.totalReceivedShort}
            </GivelifyLabel>
            <GivelifyLabel
                color={DesignTokens.color.textSecondary}
                data-testid="chart-tooltip-label"
                variant="caption1"
            >
                {label}
            </GivelifyLabel>
            <Arrow />
        </GraphToolTip>
    );
};

export const ChartTooltip = React.memo(ChartTooltipComponent);
