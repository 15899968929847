import React, { useCallback, useMemo } from 'react';
import { GivelifyLabel, GivelifyNotification } from '@givelify/ui';
import { formatWithTimezone } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { CloseMatchNotification } from 'store/integrations/actions';
import { AllItemsMatchedNotification } from 'store/integrations/types';
import { useRegenerateRequest } from '../useRegenerateRequest';

interface NotificationItemProps {
    item: AllItemsMatchedNotification;
}

const NotificationItem: React.FCC<NotificationItemProps> = ({ item }) => {
    const dispatch = useAppDispatch();
    const regenerateReportRequest = useRegenerateRequest(item.id)[1];

    const notification = useMemo(
        () => ({
            id: item.id,
            date: `${formatWithTimezone(item.startDate)} - ${formatWithTimezone(
                item.endDate,
            )}`,
        }),
        [item],
    );

    const { t } = useTranslation();
    const TRANSLATION_KEY = 'pages.integrations.notifications';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );

    const copy = useMemo(
        () => ({
            allItemsMatched: scopedTranslate('allItemsMatched'),
            regenerateReport: scopedTranslate('regenerateReport'),
        }),
        [scopedTranslate],
    );

    const onCloseClick = () => dispatch(CloseMatchNotification(item.id));

    const onDownloadClick = () => {
        regenerateReportRequest();
        dispatch(CloseMatchNotification(item.id));
    };

    return (
        <GivelifyNotification
            open
            showDismiss
            actionButtonProps={{
                text: copy.regenerateReport,
                onClick: onDownloadClick,
                name: 'regenerateReport',
            }}
            onClose={onCloseClick}
            text={
                <GivelifyLabel display="flex" gap={1} variant="body2B">
                    {copy.allItemsMatched}
                    <GivelifyLabel text={notification.date} variant="body2" />
                </GivelifyLabel>
            }
            variant="info"
        />
    );
};

export default NotificationItem;
