import React, { useMemo } from 'react';
import { useScrollToCenterActiveItem } from '../utils';
import {
    BreadcrumbLabel,
    BreadcrumbsContainer,
    BreadcrumbsItem,
    Separator,
} from './styles';
import { GivelifyBreadcrumbsOption, GivelifyBreadcrumbsProps } from './types';

export const GivelifyBreadcrumbs: React.FCC<GivelifyBreadcrumbsProps> = ({
    disableNavigation,
    active,
    options,
    className,
}) => {
    const activeItem = useMemo(
        () => options.findIndex((option) => option.id === active),
        [options, active],
    );
    const scrollableList = React.useRef<HTMLDivElement | null>(null);
    useScrollToCenterActiveItem(scrollableList, activeItem, options.length);
    return (
        <BreadcrumbsContainer ref={scrollableList} className={className}>
            {options.map((option: GivelifyBreadcrumbsOption, index: number) => (
                <BreadcrumbsItem key={option.id}>
                    <BreadcrumbLabel
                        className={
                            index === activeItem
                                ? 'active'
                                : index < activeItem || option.isCompleted
                                ? 'completed'
                                : ''
                        }
                        onClick={
                            disableNavigation ? () => null : option?.onClick
                        }
                    >
                        <div className="badge">{index + 1}</div>
                        <div>{option.label}</div>
                    </BreadcrumbLabel>
                    {index < options.length - 1 && (
                        <Separator variant="right-chevron" />
                    )}
                </BreadcrumbsItem>
            ))}
        </BreadcrumbsContainer>
    );
};
