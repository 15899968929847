import React, { useMemo, useState } from 'react';
import {
    GivelifyButton,
    GivelifyLabel,
    GivelifyTable,
    GivelifyTableColumn,
    GivelifyPaper,
} from '@givelify/givelify-ui';
import { GivelifyAvatar } from '@givelify/ui';
import { mergeClassNames } from '@givelify/utils';
import { MenuItem, MenuList, Paper, Popover } from '@material-ui/core';
import { SortDirection } from '@mui/material';
import permissionTypes from 'constants/permissionTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PATH } from 'router/routes';
import { AppState, useAppDispatch } from 'store';
import { resendInviteEmail } from 'store/donee/thunks';
import { Officer } from 'store/settings/officers/types';
import { editUser } from 'store/user/thunks';
import permissionsByPath from 'utils/permissionsByPath';
import { compareUsers } from 'utils/strings/compare';
import { validateEmailAddress } from 'utils/strings/validations';
import RoleTypes from '../../../../../constants/roleTypes';
import roleTypes from '../../../../../constants/roleTypes';
import UsersTable from '../../UsersTable';
import { usersTableStyle } from '../../usersTableStyle';
import ResendInvitationModal from '../InvitationModals/ResendInvitationModal';
import InviteToGivelifyModal from '../InviteToGivelifyModal/InviteToGivelifyModal';

interface FaithLeaderTableProps {
    users: Officer[];
    sortDirection: SortDirection;
    onSortClick: (newSortDirection: SortDirection) => void;
    handleUserActionClick: (user) => (event) => void;
    actionPopoverOpen: boolean;
    actionAnchorPosition: { top: number; left: number } | null;
    handleActionPopoverClose: () => void;
    onEditClick: () => void;
    onDeleteClick: () => void;
    onMakeFaithLeaderClick: () => void;
    onChangeAccountOwnerClick: () => void;
    isOwnUser: boolean;
    userId: number;
    isFaithLeader: boolean;
    onTableRefresh?: () => void;
    isAccountOwner: boolean;
}

const FaithLeaderTable: React.FCC<FaithLeaderTableProps> = (props) => {
    const {
        users,
        sortDirection,
        handleUserActionClick,
        actionPopoverOpen,
        actionAnchorPosition,
        handleActionPopoverClose,
        onEditClick,
        onDeleteClick,
        onMakeFaithLeaderClick,
        onChangeAccountOwnerClick,
        isOwnUser,
        userId,
        isFaithLeader,
        isAccountOwner,
        onSortClick,
        onTableRefresh,
    } = props;

    const style = usersTableStyle();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { userRole, donee } = useSelector((state: AppState) => ({
        userRole: state.User.user.role,
        donee: state.Donee.donee,
    }));
    const copy = useMemo(
        () => ({
            name: t('labels.name'),
            persimissions: t('labels.persimissions'),
            actions: t('labels.actions'),
            editUser: t('pages.settings.users.edit-user'),
            deleteUser: t('pages.settings.users.delete-user'),
            makeFaithLeader: t('pages.settings.users.make-faith-leader'),
            faithLeader: t('pages.settings.users.faithLeader'),
            faithLeaderWillDisplayed: t(
                'pages.settings.users.faithLeaderWillDisplayed',
            ),
            otherUsers: t('pages.settings.users.otherUsers'),
            changeAccountOwner: t('pages.settings.users.make-account-owner'),
            financialCoordinator: t(
                'pages.settings.users.financialCoordinator',
            ),
            accountOwner: t('pages.settings.users.accountOwner'),
            inviteToGivelify: t('pages.settings.users.inviteToGivelify'),
            waitingForActivation: t(
                'pages.settings.users.waitingForActivation',
            ),
            resendInvitation: t('pages.settings.users.resendInvitation'),
        }),
        [t],
    );
    const faithLeader = useMemo(() => {
        const userId = donee?.onboarding.appProfile.faithLeader.userId;
        return users.filter((user) => user.id === userId);
    }, [users, donee]);

    const otherUsers = useMemo(() => {
        const userId = donee?.onboarding.appProfile.faithLeader.userId;
        const otherUsers = users
            .filter((user) => user.id !== userId)
            .sort((a, b) => compareUsers(a, b, sortDirection));
        return [...otherUsers];
    }, [sortDirection, users, donee]);

    const [resendInvitationModalOpen, setResendInvitationModalOpen] =
        useState(false);
    const [inviteToGivelifyModalOpen, setInviteToGivelifyModalOpen] =
        useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [emailError, setEmailError] = useState(false);

    const onResendInvitationClick = (user) => {
        setResendInvitationModalOpen(true);
        setSelectedUser(user);
    };

    const onResendInvitationModalClose = () => {
        setResendInvitationModalOpen(false);
        setSelectedUser(null);
        setEmailError(false);
    };

    const onInviteToGivelifyClick = (user) => {
        setInviteToGivelifyModalOpen(true);
        setSelectedUser(user);
    };

    const onInviteToGivelifyModalClose = () => {
        setInviteToGivelifyModalOpen(false);
        setSelectedUser(null);
        setEmailError(false);
    };

    const handleUserInfoChange = ({ name, value }) => {
        setSelectedUser({ ...selectedUser, [name]: value });
    };

    const handleEmailChange = (email) => {
        if (validateEmailAddress(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        setSelectedUser({ ...selectedUser, email });
    };

    const handleEmailSend = async () => {
        await dispatch(resendInviteEmail(donee.id, selectedUser.id));
        setResendInvitationModalOpen(false);
    };

    const handleInviteUser = async () => {
        try {
            await dispatch(editUser(false, selectedUser));
            await dispatch(resendInviteEmail(donee.id, selectedUser.id));
            onInviteToGivelifyModalClose();
            onTableRefresh();
        } catch (e) {
            onInviteToGivelifyModalClose();
            onTableRefresh();
        }
    };

    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.USERS][userRole] ===
        permissionTypes.FULL_ACCESS;

    const isChangeAccountOwnerAvailable =
        donee?.onboarding.appProfile.accountOwner.userId === userId;

    const columns: GivelifyTableColumn<Officer>[] = useMemo(
        () => [
            {
                label: copy.name,
                width: 210,
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => {
                    const isUserGreyOut = row.email === '' || !row.active;
                    const fullName = `${row.firstName} ${row.lastName}`;
                    return (
                        <div
                            aria-label="User"
                            className={style.usernameContainer}
                            id={`faith-leader-name-${row.id}`}
                        >
                            <div className={style.userAvatarWrapper}>
                                <GivelifyAvatar
                                    className={mergeClassNames(
                                        isUserGreyOut && style.userAvatarGrey,
                                    )}
                                    color="grey"
                                    size="xSmall"
                                    src={row.avatar}
                                    text={''}
                                />
                            </div>
                            <div className={style.userNameContent}>
                                <div className={style.userFullName}>
                                    <GivelifyLabel
                                        bold
                                        className={mergeClassNames(
                                            style.fullName,
                                            isUserGreyOut &&
                                                style.userDetailGreyText,
                                        )}
                                        text={fullName}
                                        variant="heading5"
                                    />
                                    {row.isAccountHolder && (
                                        <div className={style.accountOwnerBox}>
                                            <GivelifyLabel
                                                bold
                                                className={style.accountOwner}
                                                text={copy.accountOwner}
                                                variant="body2"
                                            />
                                        </div>
                                    )}
                                </div>
                                <GivelifyLabel
                                    className={mergeClassNames(
                                        style.title,
                                        isUserGreyOut &&
                                            style.userDetailGreyText,
                                    )}
                                    text={row.title}
                                    variant="small"
                                />
                            </div>
                        </div>
                    );
                },
            },
            {
                label: copy.persimissions,
                width: 180,
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => {
                    const isUserGreyOut = row.email === '' || !row.active;
                    return (
                        <GivelifyLabel
                            className={
                                isUserGreyOut ? style.userDetailGreyText : null
                            }
                            id={`faith-leader-role-${row.id}`}
                            text={
                                row.role === roleTypes.FINANCIAL
                                    ? copy.financialCoordinator
                                    : row.role
                            }
                            variant="body2"
                        />
                    );
                },
            },
            {
                label: '',
                headerAlign: 'right',
                cellAlign: 'right',
                width: 210,
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => {
                    const isUserEmailExist = !!row.email;
                    const isUserActive = !!row.active;
                    return (
                        <div className={style.invitationContent}>
                            <div className={style.invitationBody}>
                                {isUserEmailExist && !isUserActive && (
                                    <>
                                        <GivelifyLabel
                                            className={style.greyText}
                                            text={copy.waitingForActivation}
                                            variant="body3"
                                        />
                                        {hasFullAccess ? (
                                            <GivelifyButton
                                                className={style.sendButton}
                                                id={`faith-leader-action-${row.id}`}
                                                onClick={() => {
                                                    onResendInvitationClick(
                                                        row,
                                                    );
                                                }}
                                                size="xLarge"
                                                text={copy.resendInvitation}
                                                variant="ghost"
                                            />
                                        ) : null}
                                    </>
                                )}
                                {!isUserEmailExist && hasFullAccess && (
                                    <GivelifyButton
                                        className={style.sendButton}
                                        id={`faith-leader-invite-${row.id}`}
                                        onClick={() => {
                                            onInviteToGivelifyClick(row);
                                        }}
                                        size="xLarge"
                                        text={copy.inviteToGivelify}
                                        variant="ghost"
                                    />
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                label: copy.actions,
                headerAlign: 'right',
                cellAlign: 'right',
                width: 96,
                // eslint-disable-next-line react/display-name
                renderCell: ({ row }) => {
                    if (!hasFullAccess) return null;
                    return (
                        <div className={style.moreAction}>
                            <GivelifyButton
                                iconVariant="more"
                                id={`user-action-${row.id}`}
                                onClick={handleUserActionClick(row)}
                                size="small"
                                variant="icon"
                            />
                        </div>
                    );
                },
            },
        ],
        [handleUserActionClick, hasFullAccess, copy, style],
    );
    return (
        <div>
            <div
                className={style.faithLeaderTableContent}
                id="faith-leader-content"
            >
                <GivelifyLabel
                    marginBottom={8}
                    text={copy.faithLeader}
                    variant="heading4"
                />
                <GivelifyLabel
                    marginBottom={16}
                    text={copy.faithLeaderWillDisplayed}
                    variant="body1"
                />
                <GivelifyPaper>
                    <GivelifyTable
                        className={style.userTable}
                        columns={columns}
                        data={faithLeader}
                        keySelector={(item) => item.id}
                        rowId="faith-leader"
                        useVirtualList={faithLeader.length > 50}
                    />
                    <Popover
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        anchorPosition={actionAnchorPosition}
                        anchorReference="anchorPosition"
                        id={
                            actionPopoverOpen
                                ? 'user-action-popover'
                                : undefined
                        }
                        onClose={handleActionPopoverClose}
                        open={actionPopoverOpen}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Paper className={style.popoverPaper}>
                            <MenuList>
                                <MenuItem onClick={onEditClick}>
                                    {copy.editUser}
                                </MenuItem>
                                {isChangeAccountOwnerAvailable && (
                                    <MenuItem
                                        onClick={onChangeAccountOwnerClick}
                                        style={{
                                            display: isAccountOwner
                                                ? 'none'
                                                : 'block',
                                        }}
                                    >
                                        {copy.changeAccountOwner}
                                    </MenuItem>
                                )}
                                {userRole === RoleTypes.ADMIN && (
                                    <MenuItem
                                        onClick={onDeleteClick}
                                        style={{
                                            display: isOwnUser
                                                ? 'none'
                                                : 'block',
                                        }}
                                    >
                                        {copy.deleteUser}
                                    </MenuItem>
                                )}
                            </MenuList>
                        </Paper>
                    </Popover>
                </GivelifyPaper>
            </div>
            <div>
                <GivelifyLabel
                    marginBottom={16}
                    text={copy.otherUsers}
                    variant="heading4"
                />
                <UsersTable
                    key={sortDirection as string} //TODO: why?
                    actionAnchorPosition={actionAnchorPosition}
                    actionPopoverOpen={actionPopoverOpen}
                    donee={donee}
                    handleActionPopoverClose={handleActionPopoverClose}
                    handleUserActionClick={handleUserActionClick}
                    isAccountOwner={isAccountOwner}
                    isFaithLeader={isFaithLeader}
                    isOwnUser={isOwnUser}
                    onChangeAccountOwnerClick={onChangeAccountOwnerClick}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    onMakeFaithLeaderClick={onMakeFaithLeaderClick}
                    onSortClick={onSortClick}
                    onTableRefresh={onTableRefresh}
                    sortDirection={sortDirection}
                    userId={userId}
                    userRole={userRole}
                    users={otherUsers}
                />
            </div>
            {resendInvitationModalOpen && (
                <ResendInvitationModal
                    email={selectedUser.email}
                    emailError={emailError}
                    handleEmailChange={handleEmailChange}
                    onClose={onResendInvitationModalClose}
                    onSend={handleEmailSend}
                    open={resendInvitationModalOpen}
                />
            )}
            {inviteToGivelifyModalOpen && (
                <InviteToGivelifyModal
                    emailError={emailError}
                    handleEmailChange={handleEmailChange}
                    handleInviteUser={handleInviteUser}
                    handleUserInfoChange={handleUserInfoChange}
                    onClose={onInviteToGivelifyModalClose}
                    open={inviteToGivelifyModalOpen}
                    user={selectedUser}
                />
            )}
        </div>
    );
};

export default FaithLeaderTable;
