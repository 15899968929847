import { GivelifyFormCheckbox } from '@givelify/ui';
import { styled } from '@mui/material';

export const StyledCheckbox = styled(GivelifyFormCheckbox)(({ theme }) => ({
    '& .MuiTypography-root': {
        '@media (max-width: 767px)': {
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    '& .MuiSvgIcon-root': {
        '@media (max-width: 767px)': {
            fontSize: '27px',
        },
    },
})) as typeof GivelifyFormCheckbox;
