import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { GivelifyTextField, GivelifyTextFieldProps } from '../textField';
import { OmitFields } from './types';

export type GivelifyFormTextFieldProps<Form = Record<string, unknown>> = Omit<
    GivelifyTextFieldProps,
    OmitFields
> & {
    name: keyof Form;
};

export const GivelifyFormTextField = <Form,>(
    props: GivelifyFormTextFieldProps<Form>,
) => {
    const {
        control,
        formState: { errors },
        trigger,
        clearErrors,
    } = useFormContext();
    const error = errors[props.name] as { message: string };

    const onInputClearHandler = React.useCallback(
        (name: string) => {
            trigger(name).then((res) => {
                if (!res) {
                    clearErrors(name);
                }
            });
        },
        [trigger, clearErrors],
    );

    return (
        <Controller
            control={control}
            name={props.name}
            render={({
                field: { name, onBlur, onChange, value, ref },
                fieldState: { invalid },
            }) => {
                return (
                    <GivelifyTextField
                        {...props}
                        inputRef={ref}
                        label={props.label}
                        leftHelperText={
                            invalid ? error?.message : props.leftHelperText
                        }
                        name={name}
                        onBlur={(event) => {
                            if (props.onBlur) {
                                props.onBlur(event);
                            }
                            onBlur();
                        }}
                        onChange={(ev) => onChange(ev.target.value)}
                        onClearClick={() => {
                            if (props.onClearClick) {
                                props.onClearClick();
                            }
                            onInputClearHandler(name);
                        }}
                        state={invalid ? 'error' : 'idle'}
                        value={value || ''}
                    />
                );
            }}
        />
    );
};
