import React from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import {
    PAGE_NAME,
    TrackingProvider,
    useTrackingContext,
} from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { NotificationProvider, useNotification } from 'components';
import { CalendlyCTAButtonProvider } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { PATH } from 'router/routes';
import { NAVIGATION_CLICK_LINK } from 'utils/clevertapEvents';
import { LayoutView } from './LayoutView';
import { useMenu } from './useMenu';

const LayoutComponent: React.FCC = ({ children }) => {
    const location = useLocation();
    const { trackEvent } = useTrackingContext();
    const { notification, notificationHeight } = useNotification();
    const { showCaptivePortal } = useCaptivePortalContext();
    const [openMoreMenu, setOpenMoreMenu] = React.useState(false);
    const { menuItems, toggleMenuItem } = useMenu();
    const filterdMenuItems = React.useMemo(() => {
        const result = menuItems.map((x) => {
            return {
                ...x,
                nested:
                    x.nested === undefined
                        ? undefined
                        : x.nested.filter((x) => !x.hideInMenu),
            };
        });
        return result;
    }, [menuItems]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const navigate = useNavigate();
    const onClick = React.useCallback(
        (link: string, label: string) => {
            trackEvent(NAVIGATION_CLICK_LINK.replace('TARGET', label));
            navigate(link);
            setOpenMoreMenu(false);
        },
        [trackEvent, setOpenMoreMenu, navigate],
    );
    const onMoreToggle = React.useCallback(() => {
        setOpenMoreMenu(!openMoreMenu);
    }, [openMoreMenu]);
    React.useEffect(() => {
        if (!isMobile) {
            setOpenMoreMenu(false);
        }
    }, [isMobile]);
    //if new pages containe current location path then set new page state
    const isLegacyPage = React.useMemo(() => {
        const newPages = [
            PATH.OVERVIEW,
            PATH.DONORS.ROOT,
            PATH.DONATIONS.DONATION_SUMMARY.BY_ENVELOPES,
            PATH.DONATIONS.DONATION_SUMMARY.BY_DATE,
            PATH.DONATIONS.DONATIONS_ACTIVITY,
            PATH.SETTINGS.ROOT,
            PATH.SETTINGS.BANK_INFO,
            PATH.SETTINGS.ORG_INFO,
            PATH.SETTINGS.QUICK_GIVE,
            PATH.SETTINGS.USERS,
            PATH.DONATIONS.BANK_DEPOSITS,
            PATH.DONATIONS.REFUNDS,
            PATH.SETTINGS.MISSION_STATEMENT,
            PATH.REPORTS.GENERATE_REPORTS,
            PATH.REPORTS.LEGACY_REPORTS,
            PATH.REPORTS.LEGACY_REPORTS_DONATIONS,
            PATH.REPORTS.LEGACY_REPORTS_DISBURSMENTS,
            PATH.REPORTS.LEGACY_REPORTS_DONORS,
            PATH.REPORTS.LEGACY_REPORTS_IMPORT_FRIENDLY,
            PATH.REPORTS.LEGACY_REPORTS_BY_ENVELOPE,
            PATH.REPORTS.LEGACY_REPORTS_BY_DONOR,
            PATH.REPORTS.REPORT_HISTORY,
            PATH.REPORTS.REPORT_HISTORY_DOWNLOAD,
            PATH.REPORTS.REPORT_DONATIONS,
            PATH.REPORTS.REPORT_BANK_DEPOSITS,
            PATH.REPORTS.REPORT_DONORS,
            PATH.REPORTS.REPORT_DISBURSEMENTS,
            PATH.REPORTS.REPORT_DONATIONS_LEGACY,
            PATH.REPORTS.DONATION_BY_ENVELOPE,
            PATH.REPORTS.DONATIONS_BY_DONOR,
            PATH.SETTINGS.APP_PROFILE,
            PATH.EDIT_PROFILE,
            PATH.FUNDRAISING_TOOLS.ONLINE_GIVING,
            PATH.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING,
        ];
        if (
            matchPath(
                {
                    path: PATH.DONORS.PROFILE,
                    end: false,
                },
                location.pathname,
            )
        ) {
            return false;
        }
        return !newPages.includes(location.pathname);
    }, [location.pathname]);
    const notificationPosition =
        notification?.meta === 'content' ? 'content' : 'main';
    return (
        <LayoutView
            legacyPage={isLegacyPage}
            navigationProps={{
                items: filterdMenuItems,
                onClick: onClick,
                onExpandClick: toggleMenuItem,
                notificationHeight: notificationHeight,
                notificationPosition: notificationPosition,
            }}
            onMoreToggle={onMoreToggle}
            openMoreMenu={openMoreMenu}
            showCaptivePortal={showCaptivePortal}
        >
            {children}
        </LayoutView>
    );
};

export const Layout: React.FCC = ({ children }) => {
    return (
        <TrackingProvider
            hideInChild
            pageName={PAGE_NAME.Navigation}
            trackPageVisit={false}
        >
            <NotificationProvider>
                <CalendlyCTAButtonProvider>
                    <LayoutComponent>{children}</LayoutComponent>
                </CalendlyCTAButtonProvider>
            </NotificationProvider>
        </TrackingProvider>
    );
};
