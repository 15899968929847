import React, { useEffect, useState } from 'react';
import { debounce, createStyles, Theme, makeStyles } from '@material-ui/core';
import { GivelifyLabel } from '../label';
import {
    Address,
    AddressWithPhone,
    getStateName,
    isValidAddress,
} from '../utils/addressUtils';
import { phoneFormatter, phoneValidation } from '../utils/phoneNumberUtils';
import { stringToCamelCase } from '../utils/stringToCamelCase';
import { CityField } from './CityField';
import { PhoneNumberField } from './PhoneNumberField';
import { StateField } from './StateField';
import { StreetField } from './StreetField';
import { ZipField } from './ZipField';

type BaseAddress = Address & Partial<AddressWithPhone>;

interface AddressPlaceholders {
    streetPlaceholder: string;
    cityPlaceholder: string;
    statePlaceholder: string;
    zipPlaceholder: string;
    /**
     * undefined here AND `phone` in address object, will not show Phone Number field
     */
    phonePlaceholder: string | undefined;
    /**
     * undefined will not show
     */
    poBoxNotAllowedText: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editAddressStyles: {
            marginTop: theme.spacing(1),
            '& .street-field, & .city-state-field, & #zip-root, & #phone-root':
                {
                    marginBottom: theme.spacing(3),
                },
            '& .city-state-field': {
                display: 'flex',
            },
            '& .state': {
                width: '100%',
                marginLeft: theme.spacing(2),
            },
            '& .city': {
                width: '100%',
                marginRight: theme.spacing(2),
            },
            '& .change-button': {
                marginRight: theme.spacing(3),
            },
        },
        inlineStyle: {
            display: 'inline-block',
        },
    }),
);

const ShowAddress: React.FC<{
    address: BaseAddress;
}> = ({ address }) => {
    const { inlineStyle } = useStyles();
    const { phone } = address;
    return (
        <>
            <GivelifyLabel
                className={inlineStyle}
                text={address.street}
                variant="body1"
            />
            {(address.city || address.state || address.zip) && ','}
            <div>
                <GivelifyLabel
                    className={inlineStyle}
                    data-testid="address-city"
                    id="address-city"
                    text={address.city}
                    variant="body1"
                />
                {address.city && (address.state || address.zip) && ', '}
                <GivelifyLabel
                    className={inlineStyle}
                    data-testid="address-state"
                    id="address-state"
                    text={getStateName(address.state)}
                    variant="body1"
                />
                {address.state && ' '}
                <GivelifyLabel
                    className={inlineStyle}
                    data-testid="address-zip"
                    id="address-zip"
                    text={address.zip}
                    variant="body1"
                />
            </div>
            {phone && (
                <GivelifyLabel
                    data-testid="address-phone"
                    id="address-phone"
                    text={phoneFormatter(phone) || phone}
                    variant="body1"
                />
            )}
        </>
    );
};

interface EditAddressProps extends AddressPlaceholders {
    name?: string;
    address: BaseAddress;
    onChange: (address: Address | AddressWithPhone) => unknown;
    setIsAddressValid?: (isValid: boolean) => unknown;
    zipErrorMessage?: string;
    streetErrorMessage?: string;
    cityErrorMessage?: string;
    phoneErrorMessage?: string;
}

export interface AddressEditorRef {
    validate: () => void;
    isValid: () => boolean;
}

const EditAddress: React.FC<EditAddressProps> = ({
    name,
    address: passedAddress,
    onChange,
    streetPlaceholder,
    cityPlaceholder,
    statePlaceholder,
    zipPlaceholder,
    phonePlaceholder,
    poBoxNotAllowedText,
    setIsAddressValid,
    zipErrorMessage,
    streetErrorMessage,
    cityErrorMessage,
    phoneErrorMessage,
}) => {
    const [address, setAddress] = useState(passedAddress);

    const isValid = React.useCallback(() => {
        return (
            isValidAddress(address, poBoxNotAllowedText !== undefined) &&
            (address.phone === undefined || phoneValidation(address.phone))
        );
    }, [address, poBoxNotAllowedText]);

    const setStreet = (street: string) => {
        setAddress({ ...address, street });
    };
    const setState = (state: string) => {
        setAddress({ ...address, state });
    };
    const setZip = (zip: string) => {
        setAddress({ ...address, zip });
    };
    const setCity = (city: string) => {
        setAddress({ ...address, city });
    };
    const setPhone = (phone: string) => {
        setAddress({ ...address, phone });
    };

    const handleChangeWithDebounce = debounce(() => {
        const valid = isValid();
        if (valid) {
            onChange(address);
        }

        setIsAddressValid && setIsAddressValid(valid);
    }, 500);

    useEffect(() => {
        handleChangeWithDebounce();
    }, [address, handleChangeWithDebounce]);

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 16,
            }}
        >
            <div
                style={{
                    gridColumn: 'span 2',
                }}
            >
                <StreetField
                    name={`${name}_street`}
                    onChange={setStreet}
                    placeholder={streetPlaceholder}
                    poBoxNotAllowedText={poBoxNotAllowedText}
                    street={address.street}
                    streetErrorMessage={streetErrorMessage}
                />
            </div>

            <CityField
                city={address.city}
                cityErrorMessage={cityErrorMessage}
                name={`${name}_city`}
                onChange={setCity}
                placeholder={cityPlaceholder}
            />

            <StateField
                name={`${name}_state`}
                onChange={setState}
                placeholder={statePlaceholder}
                state={address.state}
            />
            <div
                style={{
                    gridColumn: 'span 2',
                }}
            >
                <ZipField
                    name={`${name}_zip`}
                    onChange={setZip}
                    placeholder={zipPlaceholder}
                    zip={address.zip}
                    zipErrorMessage={zipErrorMessage}
                />
            </div>
            {address.phone !== undefined && phonePlaceholder !== undefined && (
                <div
                    style={{
                        gridColumn: 'span 2',
                    }}
                >
                    <PhoneNumberField
                        name={`${name}_phone`}
                        onChange={setPhone}
                        phone={phoneFormatter(address.phone)}
                        phoneErrorMessage={phoneErrorMessage}
                        placeholder={phonePlaceholder}
                    />
                </div>
            )}
        </div>
    );
};

export interface ShowAndEditAddressProps extends AddressPlaceholders {
    name: string;
    address: BaseAddress;
    onChange?: (address: Address | AddressWithPhone) => unknown;
    isEdit: boolean;
    setIsAddressValid?: (isValid: boolean) => unknown;
    zipErrorMessage?: string;
    streetErrorMessage?: string;
    cityErrorMessage?: string;
    phoneErrorMessage?: string;
}

export const ShowAndEditAddress: React.FC<ShowAndEditAddressProps> = ({
    name,
    address,
    isEdit,
    onChange,
    streetPlaceholder,
    cityPlaceholder,
    statePlaceholder,
    zipPlaceholder,
    phonePlaceholder,
    poBoxNotAllowedText,
    setIsAddressValid,
    zipErrorMessage,
    streetErrorMessage,
    cityErrorMessage,
    phoneErrorMessage,
}) => {
    const handleChange = React.useCallback(
        (address: Address | AddressWithPhone) => {
            address.street = stringToCamelCase(address.street);
            address.city = stringToCamelCase(address.city);
            if (onChange) onChange(address);
        },
        [onChange],
    );

    if (isEdit) {
        return (
            <EditAddress
                address={address}
                cityErrorMessage={cityErrorMessage}
                cityPlaceholder={cityPlaceholder}
                name={name}
                onChange={handleChange}
                phoneErrorMessage={phoneErrorMessage}
                phonePlaceholder={phonePlaceholder}
                poBoxNotAllowedText={poBoxNotAllowedText}
                setIsAddressValid={setIsAddressValid}
                statePlaceholder={statePlaceholder}
                streetErrorMessage={streetErrorMessage}
                streetPlaceholder={streetPlaceholder}
                zipErrorMessage={zipErrorMessage}
                zipPlaceholder={zipPlaceholder}
            />
        );
    }
    return <ShowAddress address={address} />;
};
