import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiHandler, GetBankNameResponse } from '@givelify/api';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyFormTextField,
    GivelifyLabel,
    GivelifyLink,
    GivelifyLoading,
    GivelifyModal,
} from '@givelify/ui';
import {
    TrackingProvider,
    isFailed,
    isLoading,
    isSucceeded,
    useApiRequest,
    useDebounce,
} from '@givelify/utils';
import { Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../../../../consts';
import { useCaptivePortalContext } from '../../../../CaptivePortalProvider';
import { OnboardingNote } from '../../../components/OnboardingNote';
import { OnboardingActions } from '../../../OnboardingActions';
import OnboardingPage from '../../../OnboardingPage';
import checkAccountNumber from '../../images/check-accountNumber.png';
import checkRoutingNumber from '../../images/check-routingNumber.png';
import { RequiredBankInfoHelp } from './AccountInfoHelp';
import { AccountInfoFormProps, FormSchema } from './types';

const StyledForm = styled('div')(({ theme }) => ({
    '& .page-description': {
        color: DesignTokens.color.textSecondary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: '14px',
        },
    },
    '& .input-field': {
        marginTop: '16px',
    },
}));

const StyledModal = styled(GivelifyModal)(({ theme }) => ({
    '& img': {
        marginBottom: '32px',
    },
}));

const useCheckImage = () => {
    const { watch } = useFormContext<FormSchema>();
    const { showAccountNumber } = watch();

    return showAccountNumber ? checkAccountNumber : checkRoutingNumber;
};

const AccountInfoForm: React.FCC<{
    onCancelClick: (skipModal: boolean) => void;
}> = ({ onCancelClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { inviteOfficialEnabled, navigateToUsers } =
        useCaptivePortalContext();

    const {
        watch,
        formState: { isDirty, errors, isValid, dirtyFields },
        setValue,
        register,
        trigger,
    } = useFormContext<FormSchema>();

    useEffect(() => {
        register('bankName');
        register('showAccountNumber');
    }, [register]);

    const {
        routingNumber,
        bankName,
        showAccountNumber,
        accountNumber,
        retypeAccountNumber,
    } = watch();

    useEffect(() => {
        if (!!retypeAccountNumber && errors.retypeAccountNumber)
            trigger('retypeAccountNumber');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountNumber, retypeAccountNumber, trigger]);

    useEffect(() => {
        if (!!accountNumber && errors.accountNumber) trigger('accountNumber');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retypeAccountNumber, accountNumber, trigger]);

    const deboucedRoutingNumber = useDebounce(routingNumber);
    const [showCopyWarning, setShowCopyWarning] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const [getBankRequestState, makeGetBankRequest] =
        useApiRequest<GetBankNameResponse>();

    const { t } = useTranslation(I18N_NAMESPACE);
    const TRANSLATION_KEY = 'bankInfo';
    const scopedTranslate = useCallback(
        (key: string, args = undefined) => t(`${TRANSLATION_KEY}.${key}`, args),
        [t],
    );

    const copy = useMemo(
        () => ({
            accountInfo: scopedTranslate('accountInfo'),
            accountInfoDescription: scopedTranslate('accountInfoDescription'),
            accountInfoDescriptionMobile: scopedTranslate(
                'accountInfoDescriptionMobile',
            ),
            seeExample: scopedTranslate('seeExample'),
            routingNumberTitle: scopedTranslate('routingNumberTitle'),
            routingNumber: scopedTranslate('routingNumber'),
            accountNumber: scopedTranslate('accountNumber'),
            retypeAccountNumber: scopedTranslate('retypeAccountNumber'),
            accountNumberDescription: scopedTranslate(
                'accountNumberDescription',
            ),
            copyWarning: scopedTranslate('copyWarning'),
            unknownBank: scopedTranslate('unknownBank'),
            inviteOfficialNote: scopedTranslate('inviteOfficialNote'),
            inviteOfficialLink: scopedTranslate('inviteOfficialLink'),
        }),
        [scopedTranslate],
    );

    const onCancelClickInternal = () => {
        onCancelClick(!isDirty);
    };

    useEffect(() => {
        if (errors?.routingNumber) {
            setValue('bankName', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routingNumber]);

    useEffect(() => {
        if (
            errors?.routingNumber ||
            !deboucedRoutingNumber ||
            deboucedRoutingNumber !== routingNumber ||
            !dirtyFields.routingNumber
        ) {
            return;
        }

        makeGetBankRequest(
            ApiHandler.instance.common.getBankName(
                deboucedRoutingNumber.toString(),
            ),
        );
    }, [
        deboucedRoutingNumber,
        makeGetBankRequest,
        errors?.routingNumber,
        routingNumber,
        dirtyFields.routingNumber,
    ]);

    useEffect(() => {
        if (isLoading(getBankRequestState)) {
            setValue('bankName', '');
        }
        if (isFailed(getBankRequestState)) {
            setValue('bankName', copy.unknownBank);
            return;
        }
        if (isSucceeded(getBankRequestState)) {
            setValue('bankName', getBankRequestState.response.bankName);
            return;
        }
    }, [getBankRequestState, setValue, copy.unknownBank]);

    const checkImage = useCheckImage();

    return (
        <StyledForm>
            <GivelifyLabel
                style={{
                    marginBottom: isMobile ? 8 : 12,
                }}
                text={copy.accountInfo}
                variant="heading1S"
            />
            {isMobile ? (
                <GivelifyLabel
                    style={{
                        color: DesignTokens.color.textSecondary,
                    }}
                    variant="body2"
                >
                    {copy.accountInfoDescriptionMobile} (
                    <GivelifyLink
                        onClick={() => setOpen(true)}
                        text={copy.seeExample}
                    />
                    )
                </GivelifyLabel>
            ) : (
                <GivelifyLabel
                    style={{
                        color: DesignTokens.color.textSecondary,
                    }}
                    text={copy.accountInfoDescription}
                    variant="body1"
                />
            )}
            {inviteOfficialEnabled && (
                <OnboardingNote
                    linkAction={() => navigateToUsers(true)}
                    linkName="Invite Official"
                    linkText={copy.inviteOfficialLink}
                    text={copy.inviteOfficialNote}
                />
            )}
            <div>
                <GivelifyLabel
                    style={{
                        marginTop: 24,
                        marginBottom: 12,
                    }}
                    text={copy.routingNumberTitle}
                    variant="body2"
                />
                <GivelifyFormTextField<FormSchema>
                    fullWidth
                    InputProps={{
                        endAdornment:
                            !isMobile &&
                            (getBankRequestState.type === 'REQUEST_START' ? (
                                <GivelifyLoading size={24} type="circular" />
                            ) : errors?.routingNumber ? (
                                ''
                            ) : (
                                bankName
                            )),
                    }}
                    id="routingNumber"
                    inputProps={{
                        'data-testid': 'routingNumber',
                    }}
                    label={copy.routingNumber}
                    leftHelperText={
                        isMobile
                            ? errors?.routingNumber
                                ? ''
                                : bankName
                            : undefined
                    }
                    maxLength={9}
                    name="routingNumber"
                />
                {showAccountNumber && (
                    <>
                        <GivelifyLabel
                            style={{
                                marginTop: 24,
                                marginBottom: 12,
                            }}
                            text={copy.accountNumberDescription}
                            variant="body2"
                        />
                        <Stack mb={isMobile ? 1.5 : 2}>
                            <GivelifyFormTextField<FormSchema>
                                fullWidth
                                id="accountNumber"
                                inputProps={{
                                    'data-testid': 'accountNumber',
                                }}
                                label={copy.accountNumber}
                                leftHelperText={
                                    showCopyWarning
                                        ? copy.copyWarning
                                        : undefined
                                }
                                name="accountNumber"
                                onCopy={(e) => {
                                    e.preventDefault();
                                    setShowCopyWarning(true);
                                }}
                            />
                        </Stack>
                        <GivelifyFormTextField<FormSchema>
                            fullWidth
                            id="retypeAccountNumber"
                            inputProps={{
                                'data-testid': 'retypeAccountNumber',
                            }}
                            label={copy.retypeAccountNumber}
                            leftHelperText={
                                showCopyWarning ? copy.copyWarning : undefined
                            }
                            name="retypeAccountNumber"
                            onCopy={(e) => {
                                e.preventDefault();
                                setShowCopyWarning(true);
                            }}
                        />
                    </>
                )}
            </div>
            <StyledModal open={open}>
                <img alt="" src={checkImage} width="100%" />
                <GivelifyButton
                    onClick={() => setOpen(false)}
                    text="Close"
                    variant="primary"
                />
            </StyledModal>
            <OnboardingActions
                disableContinue={!isValid}
                onBack={onCancelClickInternal}
                onCancel={onCancelClickInternal}
            />
        </StyledForm>
    );
};

const AccountFormWrapper: React.FCC<AccountInfoFormProps> = (props) => {
    const checkImage = useCheckImage();

    const { watch } = useFormContext<FormSchema>();
    const { showAccountNumber } = watch();

    return (
        <TrackingProvider
            trackPageVisit
            pageName={
                showAccountNumber
                    ? 'SetUpCheckingAcc AccNum'
                    : 'SetUpCheckingAcc Routing'
            }
        >
            <OnboardingPage
                content={<AccountInfoForm {...props} />}
                informationContent={
                    <RequiredBankInfoHelp imgSrc={checkImage} />
                }
            />
        </TrackingProvider>
    );
};

export default AccountFormWrapper;
