export const MOCKUP_PATH = {
    BASE: '/mockups/mobile-preview.svg',
    STATUS_BAR: '/mockups/mobile-preview-status-bar.svg',
    PAGINATION_DOTS: '/mockups/mobile-preview-pagination-dots.svg',
    COVER_NARROW: '/mockups/mobile-preview-cover-narrow.svg',
    COVER_NORMAL: '/mockups/mobile-preview-cover-normal.svg',
    AVATAR: '/mockups/mobile-preview-avatar.svg',
    MAP_NARROW: '/mockups/map-narrow.png',
    MAP_NORMAL: '/mockups/map-normal.png',
    HEAD_DARK: '/mockups/mobile-head-dark.svg',
    FAVOURITE: '/mockups/icon-fav.svg',
    ICONS: {
        BACK_ARROW: '/mockups/back-icon.svg',
        BACK_ARROW_ACTIVE: '/mockups/back-active-icon.svg',
        HOME: '/mockups/home-icon.svg',
        LOCATION: '/mockups/location-icon.svg',
        PHONE: '/mockups/phone-icon.svg',
        PROFILE: '/mockups/profile-icon.svg',
        SEARCH: '/mockups/search-icon.svg',
        GIVE: '/mockups/give-icon.svg',
        SHARE: '/mockups/share-icon.svg',
        VERIFIED: '/mockups/verified-icon.svg',
        VISA: '/mockups/visa-icon.svg',
        AMOUNT: '/mockups/amount.svg',
    },
};
