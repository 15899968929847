import React from 'react';
import { GivelifyForm, GivelifyLabel } from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import { useTheme, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRepInfoSchema } from '../../schema';
import { PrimaryRepInfoForm, primaryRepEditorViews } from '../../types';
import { InfoStepForm, InfoStepFormRef } from './InfoStepForm';

export type PrimaryRepEditorStepInfoProps = {
    value: PrimaryRepInfoForm;
    replace: boolean;
    onSubmit: (data: PrimaryRepInfoForm) => void;
    onCancelClick: () => void;
    onBackClick: (data: PrimaryRepInfoForm) => void;
    formRef: React.RefObject<InfoStepFormRef>;
};

export const PrimaryRepEditorStepInfo: React.FCC<
    PrimaryRepEditorStepInfoProps
> = ({ value, replace, onCancelClick, onBackClick, onSubmit, formRef }) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t(
                'pages.settings.bank-info.customize-rep-tab.editor.stepInfo.title',
            ),
        }),
        [t],
    );
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const schemaA = useRepInfoSchema(t);
    return (
        <TrackingProvider
            trackPageVisit
            pageName={primaryRepEditorViews.infoStep}
            viewType="other"
        >
            <GivelifyLabel variant={isSmallScreen ? 'heading2S' : 'heading1S'}>
                {copy.title}
            </GivelifyLabel>
            <GivelifyForm<PrimaryRepInfoForm>
                defaultValues={{
                    ...value,
                    dateOfBirth: value.dateOfBirth
                        ? dayjs(value.dateOfBirth)
                        : null,
                }}
                onSubmit={onSubmit}
                schema={schemaA}
            >
                <InfoStepForm
                    ref={formRef}
                    onBackClick={onBackClick}
                    onCancelClick={onCancelClick}
                    replace={replace}
                />
            </GivelifyForm>
        </TrackingProvider>
    );
};
