export const getPrimaryRepresentativeApiEndpoint = (doneeId: number) =>
    `/donees/${doneeId}/beneficiary`;

export const getDoneeOnboardingApiEndpoint = (doneeId: number) =>
    `/donees/${doneeId}/onboarding`;

export const directDepositUrl = (id: number) => `/donees/${id}/bank-info`;

export const yodleeAccessTokenUrl = (id: number) =>
    `/donees/${id}/yodlee-token`;

export const yodleeAccountsUrl = (
    id: number,
    accountId: string,
    providerAccountId: string,
    yodleeAccessToken: string,
) =>
    `/donees/${id}/yodlee-accounts?token=${yodleeAccessToken}&account_id=${accountId}&provider_account_id=${providerAccountId}`;

export const getRepresentativesUrl = (id: number) =>
    `/donees/${id}/representatives`;
