import React from 'react';
import {
    CloseInCircleIcon,
    CopyIcon,
    DesignTokens,
    EditIcon,
    GivelifyLabel,
} from '@givelify/ui';
import { copyToClipboard } from '@givelify/utils';
import { SnackbarCloseReason } from '@mui/base';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    AddEditModal,
    AddMemberIdButton,
    CancelSaveButton,
    CopyEditButton,
    MemberIdBox,
    MemberIdInput,
    MemberIdLabel,
    SuccessNotification,
} from './styles';

type MemberIdProps = {
    handleSaveAddEditMemberId: (newMemberId: string) => void;
    memberId: string | null;
    isMemberIdSaved: boolean;
    nonprofit?: boolean;
};

const MemberIdComponent: React.FC<MemberIdProps> = ({
    handleSaveAddEditMemberId,
    memberId,
    isMemberIdSaved,
    nonprofit,
}) => {
    const { t } = useTranslation();
    const prefix = nonprofit ? 'nonprofit' : 'church';
    const copy = React.useMemo(
        () => ({
            memberId: t(`donors.memberId.${prefix}.id`),
            copy: t('donors.memberId.copy'),
            edit: t('donors.memberId.edit'),
            successCopy: t(`donors.memberId.${prefix}.successCopy`),
            successAddEdit: t(`donors.memberId.${prefix}.successAddEdit`),
            editIdModalTitle: t(`donors.memberId.${prefix}.editIdModalTitle`),
            addIdModalTitle: t(`donors.memberId.${prefix}.addIdModalTitle`),
            editIdModalDescription: t('donors.memberId.editIdModalDescription'),
            editIdModalInputLabel: t(
                `donors.memberId.${prefix}.editIdModalInputLabel`,
            ),
            cancel: t('donors.memberId.cancel'),
            save: t('donors.memberId.save'),
            addMemberId: t(`donors.memberId.${prefix}.addMemberId`),
            inputIdPlaceholder: t('donors.memberId.inputIdPlaceholder'),
        }),
        [t, prefix],
    );
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [isEditModal, setIsEditModal] = React.useState<boolean | null>(null);
    const [editAddMemberIdModalOpen, setEditAddMemberIdModalOpen] =
        React.useState(false);
    const [newMemberId, setNewMemberId] = React.useState<string>(memberId);

    const handleEditMemberIdModalOpen = React.useCallback(() => {
        setShowSnackbar(false);
        setEditAddMemberIdModalOpen(true);
        setIsEditModal(true);
    }, []);

    const handleAddMemberIdModalOpen = React.useCallback(() => {
        setEditAddMemberIdModalOpen(true);
        setIsEditModal(false);
    }, []);

    const handleEditAddMemberIdModalClose = React.useCallback(() => {
        setEditAddMemberIdModalOpen(false);
        setNewMemberId(memberId);
    }, [memberId]);

    const handleCopy = React.useCallback(async () => {
        const isSuccess = await copyToClipboard(memberId);
        if (isSuccess) {
            setIsEditModal(null);
            setShowSnackbar(true);
        }
    }, [memberId]);

    const handleSaveEdit = React.useCallback(() => {
        setEditAddMemberIdModalOpen(false);
        setIsEditModal(true);
        handleSaveAddEditMemberId(newMemberId);
    }, [newMemberId, handleSaveAddEditMemberId]);

    React.useEffect(() => {
        if (isMemberIdSaved) {
            setShowSnackbar(true);
        }
    }, [isMemberIdSaved]);

    React.useEffect(() => {
        setNewMemberId(memberId);
    }, [memberId]);

    return (
        <>
            <SuccessNotification
                global
                showDismiss
                TransitionProps={{
                    exit: false,
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={5000}
                date-testid="notification"
                onClose={(
                    _: React.SyntheticEvent<unknown, Event>,
                    reason: SnackbarCloseReason,
                ) => {
                    if (reason === 'clickaway') return;
                    setShowSnackbar(false);
                }}
                open={showSnackbar}
                text={
                    isEditModal === null
                        ? copy.successCopy
                        : copy.successAddEdit
                }
                variant="success"
            />
            <AddEditModal
                showCloseButton
                CloseButton={<CloseInCircleIcon />}
                maxWidth="xs"
                name={
                    isEditModal ? copy.editIdModalTitle : copy.addIdModalTitle
                }
                onClose={handleEditAddMemberIdModalClose}
                open={editAddMemberIdModalOpen}
            >
                <Grid
                    container
                    alignItems="center"
                    data-testid="add-edit-modal-content"
                    gap="16px"
                    justifyContent="center"
                >
                    <GivelifyLabel
                        data-testid="add-edit-modal-title"
                        text={
                            isEditModal
                                ? copy.editIdModalTitle
                                : copy.addIdModalTitle
                        }
                        textAlign="center"
                        variant="heading2S"
                    />
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.editIdModalDescription}
                        textAlign="center"
                    />
                    <MemberIdInput
                        fullWidth
                        multiline
                        data-testid="memberid-input"
                        defaultValue={memberId}
                        label={copy.editIdModalInputLabel}
                        onChange={(e) => setNewMemberId(e.target.value)}
                        placeholder={copy.inputIdPlaceholder}
                        value={newMemberId}
                    />
                    <Grid
                        container
                        alignItems="center"
                        gap="16px"
                        justifyContent="center"
                        marginTop="16px"
                    >
                        <CancelSaveButton
                            data-testid="cancel-memberid-button"
                            onClick={handleEditAddMemberIdModalClose}
                            text={copy.cancel}
                            variant="secondary"
                        />
                        <CancelSaveButton
                            data-testid="save-memberid-button"
                            disabled={memberId === newMemberId}
                            onClick={handleSaveEdit}
                            text={copy.save}
                            variant="primary"
                        />
                    </Grid>
                </Grid>
            </AddEditModal>
            {memberId ? (
                <MemberIdBox>
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        text={copy.memberId}
                        variant="caption1"
                    />
                    <MemberIdLabel
                        data-testid="memberid"
                        fontWeight="bold"
                        text={memberId}
                        variant="caption1"
                    />
                    <Grid container gap="16px" justifyContent="flex-end">
                        <CopyEditButton
                            disableRipple
                            data-testid="copy-memberid"
                            name="copy"
                            onClick={handleCopy}
                            size="small"
                            variant="icon"
                        >
                            <>
                                <CopyIcon />
                                <GivelifyLabel
                                    color={DesignTokens.color.textAccentDefault}
                                    fontWeight="bold"
                                    text={copy.copy}
                                    variant="caption1"
                                />
                            </>
                        </CopyEditButton>
                        <CopyEditButton
                            disableRipple
                            data-testid="edit-memberid"
                            name="edit"
                            onClick={handleEditMemberIdModalOpen}
                            size="small"
                            variant="icon"
                        >
                            <>
                                <EditIcon />
                                <GivelifyLabel
                                    color={DesignTokens.color.textAccentDefault}
                                    fontWeight="bold"
                                    text={copy.edit}
                                    variant="caption1"
                                />
                            </>
                        </CopyEditButton>
                    </Grid>
                </MemberIdBox>
            ) : (
                <AddMemberIdButton
                    disableRipple
                    data-testid="add-memberid"
                    onClick={handleAddMemberIdModalOpen}
                    text={copy.addMemberId}
                    variant="ghost"
                />
            )}
        </>
    );
};

export const MemberId = React.memo(MemberIdComponent);
