import React from 'react';
import { ApiHandler, UpdateBankInfoRequest } from '@givelify/api';
import { DirectDepositInfo } from '@givelify/onboarding';
import { BasicPageLegacyPaper } from '@givelify/ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import LoadingBar from 'components/system/LoadingBar';
import { useCalendlyCTAButtonContext } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import {
    AccountContent,
    CustomizeAccountTabScreen,
} from './components/AccountContent';

export const CustomizeAccountTab: React.FCC = () => {
    const { donee, yodleeEnabled } = useSelector((state: AppState) => {
        return {
            donee: state.Donee.campuses[0],
            yodleeEnabled: state.System.yodleeEnabled,
        };
    });

    const { setButtonBringToFront } = useCalendlyCTAButtonContext();

    const queryClient = useQueryClient();
    const { data: getAccountsResponse } = useQuery(
        [ApiHandler.instance.donees.getBankAccounts.name, donee.id],
        () => ApiHandler.instance.donees.getBankAccounts(donee.id),
    );
    const updateRequest = useMutation(
        async ({
            doneeId,
            payload,
        }: {
            doneeId: number;
            payload: UpdateBankInfoRequest;
        }) => {
            const response = await ApiHandler.instance.donees.updateBankInfo(
                doneeId,
                payload,
            );
            if (!response.success) {
                throw new Error(response.error.message);
            }
            return response;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    ApiHandler.instance.donees.getBankAccounts.name,
                );
            },
        },
    );
    const [bankingScreen, setBankingScreen] =
        React.useState<CustomizeAccountTabScreen>('list');
    const [lastBankingScreen, setLastBankingScreen] =
        React.useState<CustomizeAccountTabScreen>('list');
    const onBankingInfoChange = React.useCallback(
        (data: DirectDepositInfo) => {
            if (data) {
                updateRequest.mutate({
                    doneeId: donee.id,
                    payload: {
                        accountNumber: data.accountNumber,
                        routingNumber: data.routingNumber,
                        billingAddress: data.bankingAddress.street,
                        billingCity: data.bankingAddress.city,
                        billingCountry: 'US',
                        billingState: data.bankingAddress.state,
                        billingZip: data.bankingAddress.zip,
                        voidedCheckFile: data.bankChequeFile,
                    },
                });
            }
        },
        [updateRequest, donee.id],
    );
    const onRetryClick = React.useCallback(() => {
        setLastBankingScreen(bankingScreen);
        setBankingScreen('retry');
    }, [setBankingScreen, setLastBankingScreen, bankingScreen]);
    const onReplaceClick = React.useCallback(() => {
        setLastBankingScreen(bankingScreen);
        setBankingScreen('replace');
    }, [setBankingScreen, setLastBankingScreen, bankingScreen]);
    const onRetryCancel = React.useCallback(() => {
        setBankingScreen(lastBankingScreen);
    }, [lastBankingScreen, setBankingScreen]);
    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsBankInfoBankAccountTab}
        >
            {getAccountsResponse === undefined ||
            !getAccountsResponse.success ? (
                <LoadingBar show />
            ) : (
                <BasicPageLegacyPaper>
                    <AccountContent
                        data={getAccountsResponse.response.data}
                        donee={donee}
                        error={
                            updateRequest.isError &&
                            updateRequest.error instanceof Error
                                ? updateRequest.error.message
                                : undefined
                        }
                        isUpdating={updateRequest.isLoading}
                        onBankingInfoChange={onBankingInfoChange}
                        onReplaceClick={onReplaceClick}
                        onRetryCancel={onRetryCancel}
                        onRetryClick={onRetryClick}
                        screen={bankingScreen}
                        setButtonBringToFront={setButtonBringToFront}
                        updateHasError={updateRequest.isError}
                        yodleeEnabled={yodleeEnabled}
                    />
                </BasicPageLegacyPaper>
            )}
        </TrackingProvider>
    );
};
