import { Features } from './featureGating';

export default {
    Basic: [
        Features.OVERVIEW,
        Features.DONATIONS,
        Features.DONORS,
        Features.FUNDRAISING_TOOLS,
        Features.FUNDRAISING_TOOLS_ONLINE_GIVING,
        Features.FUNDRAISING_TOOLS_SM_GIVING,
        Features.FUNDRAISING_TOOLS_SNAP_GIVE,
        Features.FUNDRAISING_TOOLS_GIVELITHON,
        Features.SOCIAL_MEDIA_FACEBOOK,
        Features.SOCIAL_MEDIA_TWITTER,
        Features.SOCIAL_MEDIA_INSTAGRAM,
        Features.SOCIAL_MEDIA_YOUTUBE,
        Features.SOCIAL_MEDIA_SNAPCHAT,
        Features.SOCIAL_MEDIA_TIKTOK,
        Features.SOCIAL_MEDIA_LINKEDIN,
        Features.INTEGRATIONS,
        Features.INTEGRATION_ACS_REALM,
        Features.INTEGRATION_F1,
        Features.INTEGRATION_F1_GO,
        Features.INTEGRATION_CCB,
        Features.INTEGRATION_SCHEDULING,
        Features.INTEGRATION_PC,
        Features.REPORTS,
        Features.REPORTS_BANK_DEPOSITS,
        Features.REPORTS_DONATIONS,
        Features.REPORTS_DONORS,
        Features.REPORTS_DISBURSEMENTS,
        Features.REPORTS_IMPORT_FRIENDLY,
        Features.REPORTS_QUICKBOOKS_DONOR,
        Features.REPORTS_QUICKBOOKS_ENVELOPE,
        Features.LEGACY_REPORTS_DASHBOARD,
        Features.SETTINGS_ALL,
        Features.SETTINGS_APP_PROFILE,
        Features.SETTINGS_MISSION,
        Features.SETTINGS_ORGANIZATION_INFO,
        Features.SETTINGS_ENVELOPES,
        Features.SETTINGS_USERS,
        Features.SETTINGS_CAMPUSES,
        Features.SETTINGS_QUICK_GIVE,
        Features.SETTINGS_NEED_HELP,
        Features.ONBOARDING,
        Features.SHOW_YODLEE,
        Features.ENVELOPES_PUBLIC_DETAIL,
        Features.OVERVIEW_VIDEO_CARDS,
        Features.NEW_ONBOARDING,
        Features.OVERVIEW_QUICK_POLL,
        Features.OVERVIEW_HIDE_OLD_DASHBOARD_LINK,
        Features.CAPTIVE_PORTAL,
        Features.TIMEZONES,
        Features.PRIMARY_REP_ESCALATED_NOTIFICATION,
        Features.PRIMARY_REP_SEVERE_NOTIFICATION,
        Features.PRIMARY_REP_JAN_1ST_NOTIFICATION,
    ],
    Financial: [
        Features.OVERVIEW,
        Features.DONATIONS,
        Features.DONORS,
        Features.FUNDRAISING_TOOLS,
        Features.FUNDRAISING_TOOLS_ONLINE_GIVING,
        Features.FUNDRAISING_TOOLS_SM_GIVING,
        Features.FUNDRAISING_TOOLS_SNAP_GIVE,
        Features.FUNDRAISING_TOOLS_GIVELITHON,
        Features.SOCIAL_MEDIA_FACEBOOK,
        Features.SOCIAL_MEDIA_TWITTER,
        Features.SOCIAL_MEDIA_INSTAGRAM,
        Features.SOCIAL_MEDIA_YOUTUBE,
        Features.SOCIAL_MEDIA_SNAPCHAT,
        Features.SOCIAL_MEDIA_TIKTOK,
        Features.SOCIAL_MEDIA_LINKEDIN,
        Features.INTEGRATIONS,
        Features.INTEGRATION_ACS_REALM,
        Features.INTEGRATION_F1,
        Features.INTEGRATION_F1_GO,
        Features.INTEGRATION_CCB,
        Features.INTEGRATION_SCHEDULING,
        Features.INTEGRATION_PC,
        Features.REPORTS,
        Features.REPORTS_BANK_DEPOSITS,
        Features.REPORTS_DONATIONS,
        Features.REPORTS_DONORS,
        Features.REPORTS_DISBURSEMENTS,
        Features.REPORTS_IMPORT_FRIENDLY,
        Features.REPORTS_QUICKBOOKS_DONOR,
        Features.REPORTS_QUICKBOOKS_ENVELOPE,
        Features.LEGACY_REPORTS_DASHBOARD,
        Features.SETTINGS_ALL,
        Features.SETTINGS_APP_PROFILE,
        Features.SETTINGS_MISSION,
        Features.SETTINGS_ORGANIZATION_INFO,
        Features.SETTINGS_ENVELOPES,
        Features.SETTINGS_USERS,
        Features.SETTINGS_CAMPUSES,
        Features.SETTINGS_QUICK_GIVE,
        Features.SETTINGS_NEED_HELP,
        Features.ONBOARDING,
        Features.SHOW_YODLEE,
        Features.ENVELOPES_PUBLIC_DETAIL,
        Features.OVERVIEW_VIDEO_CARDS,
        Features.NEW_ONBOARDING,
        Features.OVERVIEW_QUICK_POLL,
        Features.OVERVIEW_HIDE_OLD_DASHBOARD_LINK,
        Features.CAPTIVE_PORTAL,
        Features.TIMEZONES,
        Features.GIVING_TUESDAY_2023,
        Features.PRIMARY_REP_ESCALATED_NOTIFICATION,
        Features.PRIMARY_REP_SEVERE_NOTIFICATION,
        Features.PRIMARY_REP_JAN_1ST_NOTIFICATION,
    ],
    Admin: [
        Features.OVERVIEW,
        Features.DONATIONS,
        Features.DONORS,
        Features.FUNDRAISING_TOOLS,
        Features.FUNDRAISING_TOOLS_ONLINE_GIVING,
        Features.FUNDRAISING_TOOLS_SM_GIVING,
        Features.FUNDRAISING_TOOLS_SNAP_GIVE,
        Features.FUNDRAISING_TOOLS_GIVELITHON,
        Features.SOCIAL_MEDIA_FACEBOOK,
        Features.SOCIAL_MEDIA_TWITTER,
        Features.SOCIAL_MEDIA_INSTAGRAM,
        Features.SOCIAL_MEDIA_YOUTUBE,
        Features.SOCIAL_MEDIA_SNAPCHAT,
        Features.SOCIAL_MEDIA_TIKTOK,
        Features.SOCIAL_MEDIA_LINKEDIN,
        Features.INTEGRATIONS,
        Features.INTEGRATION_ACS_REALM,
        Features.INTEGRATION_F1,
        Features.INTEGRATION_F1_GO,
        Features.INTEGRATION_CCB,
        Features.INTEGRATION_SCHEDULING,
        Features.INTEGRATION_PC,
        Features.REPORTS,
        Features.REPORTS_BANK_DEPOSITS,
        Features.REPORTS_DONATIONS,
        Features.REPORTS_DONORS,
        Features.REPORTS_DISBURSEMENTS,
        Features.REPORTS_IMPORT_FRIENDLY,
        Features.REPORTS_QUICKBOOKS_DONOR,
        Features.REPORTS_QUICKBOOKS_ENVELOPE,
        Features.LEGACY_REPORTS_DASHBOARD,
        Features.SETTINGS_ALL,
        Features.SETTINGS_APP_PROFILE,
        Features.SETTINGS_ORGANIZATION_INFO,
        Features.SETTINGS_BANK_INFO,
        Features.SETTINGS_USERS,
        Features.SETTINGS_ENVELOPES,
        Features.SETTINGS_CAMPUSES,
        Features.SETTINGS_MISSION,
        Features.SETTINGS_QUICK_GIVE,
        Features.SETTINGS_NEED_HELP,
        Features.ONBOARDING,
        Features.SHOW_YODLEE,
        Features.ENVELOPES_PUBLIC_DETAIL,
        Features.OVERVIEW_VIDEO_CARDS,
        Features.NEW_ONBOARDING,
        Features.OVERVIEW_QUICK_POLL,
        Features.OVERVIEW_HIDE_OLD_DASHBOARD_LINK,
        Features.CAPTIVE_PORTAL,
        Features.TIMEZONES,
        Features.GIVING_TUESDAY_2023,
        Features.ONBOARDING_INVITE_OFFICIAL,
        Features.SCHEDULE_CALL_WITH_SUPPORT,
        Features.PRIMARY_REP_ESCALATED_NOTIFICATION,
        Features.PRIMARY_REP_SEVERE_NOTIFICATION,
        Features.PRIMARY_REP_JAN_1ST_NOTIFICATION,
    ],
    'Admin Panel User': [
        Features.OVERVIEW,
        Features.DONATIONS,
        Features.DONORS,
        Features.FUNDRAISING_TOOLS,
        Features.FUNDRAISING_TOOLS_ONLINE_GIVING,
        Features.FUNDRAISING_TOOLS_SM_GIVING,
        Features.FUNDRAISING_TOOLS_SNAP_GIVE,
        Features.FUNDRAISING_TOOLS_GIVELITHON,
        Features.SOCIAL_MEDIA_FACEBOOK,
        Features.SOCIAL_MEDIA_TWITTER,
        Features.SOCIAL_MEDIA_INSTAGRAM,
        Features.SOCIAL_MEDIA_YOUTUBE,
        Features.SOCIAL_MEDIA_SNAPCHAT,
        Features.SOCIAL_MEDIA_TIKTOK,
        Features.SOCIAL_MEDIA_LINKEDIN,
        Features.INTEGRATIONS,
        Features.INTEGRATION_ACS_REALM,
        Features.INTEGRATION_F1,
        Features.INTEGRATION_F1_GO,
        Features.INTEGRATION_CCB,
        Features.INTEGRATION_SCHEDULING,
        Features.INTEGRATION_PC,
        Features.REPORTS,
        Features.REPORTS_BANK_DEPOSITS,
        Features.REPORTS_DONATIONS,
        Features.REPORTS_DONORS,
        Features.REPORTS_DISBURSEMENTS,
        Features.REPORTS_IMPORT_FRIENDLY,
        Features.REPORTS_QUICKBOOKS_DONOR,
        Features.REPORTS_QUICKBOOKS_ENVELOPE,
        Features.LEGACY_REPORTS_DASHBOARD,
        Features.SETTINGS_ALL,
        Features.SETTINGS_APP_PROFILE,
        Features.SETTINGS_MISSION,
        Features.SETTINGS_ORGANIZATION_INFO,
        Features.SETTINGS_ENVELOPES,
        Features.SETTINGS_USERS,
        Features.SETTINGS_CAMPUSES,
        Features.SETTINGS_QUICK_GIVE,
        Features.SETTINGS_NEED_HELP,
        Features.ONBOARDING,
        Features.SHOW_YODLEE,
        Features.ENVELOPES_PUBLIC_DETAIL,
        Features.OVERVIEW_VIDEO_CARDS,
        Features.NEW_ONBOARDING,
        Features.OVERVIEW_HIDE_OLD_DASHBOARD_LINK,
        Features.CAPTIVE_PORTAL,
        Features.TIMEZONES,
        Features.ONBOARDING_INVITE_OFFICIAL,
        Features.SCHEDULE_CALL_WITH_SUPPORT,
        Features.PRIMARY_REP_ESCALATED_NOTIFICATION,
        Features.PRIMARY_REP_SEVERE_NOTIFICATION,
        Features.PRIMARY_REP_JAN_1ST_NOTIFICATION,
    ],
};
