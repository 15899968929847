import { useMemo } from 'react';
import { GivelifyLabel, useAdvancedTranslation } from '@givelify/givelify-ui';
import {
    GivelifyFormCheckbox,
    GivelifyFormDropDown,
    GivelifyFormTextField,
} from '@givelify/ui';
import { STATE_DROPDOWN_OPTIONS } from '@givelify/utils';
import { webConfig } from 'webConfig';
import { FormProps } from '../types';
import useStyles from './styles';

const PHONE_MASK = '(000) 000-0000';

const ManualEntry = () => {
    const { checkboxRow, link, formWrapper, column, agreementStyle } =
        useStyles();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.addCampusModal.addForm',
    });

    const copy = useMemo(
        () => ({
            campusDetails: scopedTranslate('campusDetails'),
            officialName: scopedTranslate('officialName'),
            allFieldsRequired: scopedTranslate('allFieldsRequired'),
            text1: scopedTranslate('text1'),
            nameLabel: scopedTranslate('nameLabel'),
            nameMaxLength: scopedTranslate('nameMaxLength'),
            internalName: scopedTranslate('internalName'),
            text4: scopedTranslate('text4'),
            nicknameLabel: scopedTranslate('nicknameLabel'),
            nicknameUnique: scopedTranslate('nicknameUnique'),
            nicknameHelperText: scopedTranslate('nicknameHelperText'),
            campusLocation: scopedTranslate('campusLocation'),
            addressLabel: scopedTranslate('addressLabel'),
            addressMaxLength: scopedTranslate('addressMaxLength'),
            zipLabel: scopedTranslate('zipLabel'),
            invalidZip: scopedTranslate('invalidZip'),
            cityLabel: scopedTranslate('cityLabel'),
            cityMaxLength: scopedTranslate('cityMaxLength'),
            stateLabel: scopedTranslate('stateLabel'),
            phoneLabel: scopedTranslate('phoneLabel'),
            haveAuthority: scopedTranslate('haveAuthority'),
            iAgree: scopedTranslate('iAgree'),
            tos: scopedTranslate('tos'),
            required: scopedTranslate('required'),
        }),
        [scopedTranslate],
    );

    return (
        <>
            <GivelifyLabel
                marginBottom={8}
                text={copy.campusDetails}
                variant="heading3"
            />
            <GivelifyLabel
                color="neutralGrey"
                marginBottom={16}
                text={copy.allFieldsRequired}
                variant="small"
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.officialName}
                variant="heading5"
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.text1}
                variant="body1"
            />
            <div
                style={{
                    marginBottom: 26,
                }}
            >
                <GivelifyFormTextField<FormProps>
                    countLength
                    fullWidth
                    id="nameame"
                    label={copy.nameLabel}
                    maxLength={255}
                    name="name"
                />
            </div>
            <GivelifyLabel
                marginBottom={16}
                text={copy.internalName}
                variant="heading5"
            />
            <GivelifyLabel
                marginBottom={16}
                text={copy.text4}
                variant="body1"
            />
            <div
                style={{
                    marginBottom: 32,
                }}
            >
                <GivelifyFormTextField<FormProps>
                    countLength
                    fullWidth
                    id="nickname"
                    label={copy.nicknameLabel}
                    leftHelperText={copy.nicknameHelperText}
                    maxLength={50}
                    name="nickname"
                />
            </div>
            <GivelifyLabel
                marginBottom={8}
                text={copy.campusLocation}
                variant="heading3"
            />
            <GivelifyLabel
                color="neutralGrey"
                marginBottom={16}
                text={copy.allFieldsRequired}
                variant="small"
            />
            <div className={formWrapper}>
                <GivelifyFormTextField<FormProps>
                    fullWidth
                    id="address"
                    label={copy.addressLabel}
                    name="address"
                />
                <GivelifyFormTextField<FormProps>
                    fullWidth
                    id="zip"
                    label={copy.zipLabel}
                    name="zip"
                />
                <div className={column}>
                    <GivelifyFormTextField<FormProps>
                        fullWidth
                        id="city"
                        label={copy.cityLabel}
                        name="city"
                    />
                </div>
                <div className={column}>
                    <GivelifyFormDropDown
                        aria-label="state"
                        id="state"
                        label={copy.stateLabel}
                        name="state"
                        options={STATE_DROPDOWN_OPTIONS}
                        shape="input"
                        width="fullwidth"
                    />
                </div>
                <GivelifyFormTextField<FormProps>
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    id="phone"
                    label={copy.phoneLabel}
                    maskOptions={{
                        mask: PHONE_MASK,
                        lazy: false,
                    }}
                    name="phone"
                />
            </div>
            <div className={checkboxRow}>
                <GivelifyFormCheckbox
                    aria-label="authorized"
                    name="authorized"
                    style={{
                        marginRight: 12,
                    }}
                />
                <div className={agreementStyle}>
                    {copy.haveAuthority}
                    <div className="row2">
                        {`${copy.iAgree} `}
                        <a
                            className={link}
                            href={webConfig.termsAndConditionsUrl}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            {copy.tos}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManualEntry;
