import React from 'react';
import { mergeClassNames } from '@givelify/utils';
import { useScrollToActiveItem } from '../utils';
import { TabBadge, TabBox, TabItem, TabMarker } from './styles';
import { GivelifyTabProps } from './types';

export const GivelifyTab: React.FCC<GivelifyTabProps> = ({
    value = 0,
    onChange,
    options,
    className,
}) => {
    const scrollableTabs = React.useRef<HTMLDivElement | null>(null);
    useScrollToActiveItem(scrollableTabs, value, options.length);
    const hasBadges = React.useMemo(
        () => options.some((item) => item.badgeText),
        [options],
    );
    return (
        <TabBox
            ref={scrollableTabs}
            className={className}
            hasBadges={hasBadges}
        >
            {options.map((item, index) => (
                <TabItem
                    key={`tab-${index}`}
                    active={index === value}
                    className={mergeClassNames(
                        'tab-item',
                        index === value && 'tab-selected',
                    )}
                    data-testid={item.id || 'tab'}
                    href={item.tabHref}
                    id={item.id || ''}
                    onClick={(ev) => {
                        ev.preventDefault();
                        onChange(index);
                    }}
                >
                    <span className="tab-label">{item.label}</span>
                    {index === value && <TabMarker className="tab-marker" />}
                    {item.badgeText && (
                        <TabBadge className="tab-badge">
                            {item.badgeText}
                        </TabBadge>
                    )}
                </TabItem>
            ))}
        </TabBox>
    );
};
