import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyNotification,
} from '@givelify/ui';
import { Grid, styled } from '@mui/material';

export const ReportNotification = styled(GivelifyNotification)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export const ReportRow = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(4),
}));

export const ReportMainContent = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
    },
}));

export const ReportItemHeader = styled('div')(({ theme }) => ({
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 1),
    borderBottom: '1px solid #d8d8d8',
    '& .MuiTooltip-tooltip': {
        backgroundColor: '#717171',
        padding: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
    },
}));

export const ReportTooltipIconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const ReportItemBody = styled('div')(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(2, 2, 3),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 156,
    [theme.breakpoints.down('xs')]: {
        minHeight: 92,
    },
}));

export const GetReport = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: 'auto',
});

export const ReportItemDefine = styled('div')({
    flexGrow: 1,
    marginBottom: 6,
});

export const GetReportLink = styled(GivelifyButton)({
    padding: 0,
    fontSize: 14,
    '&:hover': {
        textDecoration: 'underline',
    },
});

export const DefineLabel = styled(GivelifyLabel)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(1),
        paddingRight: theme.spacing(6),
    },
}));

export const ReportList = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
    },
}));

export const QuickbookIcon = styled('img')(({ theme }) => ({
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 85,
    [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(3),
    },
}));

export const ReportItemContainer = styled(Grid)(({ theme }) => ({
    borderRadius: '10px',
    boxShadow: '0 0 8px rgba(152,162,173,0.4)',
    width: 248,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
    [theme.breakpoints.down('mobile')]: {
        marginRight: 0,
        width: '100%',
    },
    '&:last-child': {
        marginRight: 0,
    },
    '&:hover': {
        background: DesignTokens.color.neutralHoverGrey,
        cursor: 'pointer',
        '& .reportLink': {
            textDecoration: 'underline',
            color: DesignTokens.color.chartViolet700,
        },
    },
}));

export const ReportIcon = styled(GivelifyIcon)(({ theme }) => ({
    fontSize: 40,
    [theme.breakpoints.down('xs')]: {
        fontSize: 32,
    },
}));

export const ReportInformationContent = styled('div')(({ theme }) => ({
    width: 336,
    minWidth: 336,
    padding: '48px 24px 40px',
    marginLeft: '104px',
    border: '1px solid #e3e3e3',
    borderTop: '8px solid ' + DesignTokens.color.globalGuidingViolet400,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        order: `-1`,
        width: '100%',
        marginLeft: 0,
        marginBottom: theme.spacing(4),
        padding: theme.spacing(2),
    },
}));

export const ReportImage = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export const DefineBold = styled(GivelifyLabel)(({ theme }) => ({
    display: 'inline',
    [theme.breakpoints.down('sm')]: {
        display: 'block',
    },
}));

export const DefineNormal = styled(GivelifyLabel)({
    display: 'inline',
});

export const ReportDefinition = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        alignSelf: 'flex-start',
    },
}));

export const DetailBtn = styled(GivelifyButton)(({ theme }) => ({
    fontSize: 14,
    padding: 0,
    marginTop: theme.spacing(1),
}));

export const ReportItemContent = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
    },
    [theme.breakpoints.down('mobile')]: {
        flexWrap: 'wrap',
        padding: '0 8px',
    },
}));

export const LegacyReportItemRadioWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    '& .MuiTypography-root': {
        fontSize: '14px',
    },
}));

export const NoResultContent = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '670px',
    margin: '0 auto',
    '& svg': {
        fontSize: 80,
        marginBottom: theme.spacing(4),
    },
}));

export const NoResultDefine = styled(GivelifyLabel)(({ theme }) => ({
    textAlign: 'center',
    lineHeight: '22px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
}));

export const ReportHistoryHeader = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'start',
    },
    [theme.breakpoints.down('mobile')]: {
        padding: '0 8px',
    },
}));

export const RefreshButton = styled(GivelifyButton)({
    padding: 0,
    '& svg': {
        fontSize: '18px',
    },
});

export const GenerateReportsWrapper = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        padding: '0 8px',
    },
}));
