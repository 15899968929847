import React from 'react';
import { TimeFrameValue } from '@givelify/utils';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { ReportItemContent } from '../../../components/style';
import { ReportsHeaderPageName } from '../models';
import { ReportForm } from './ReportForm';
import ReportInformation from './ReportInformation';

interface ReportItemContextProps {
    type: 'custom' | 'quickbooks';
    reportName: string;
    name: string;
    reportInclude: string;
    definition?: string;
    image: JSX.Element | string;
    format: string;
    onFormatFileChange: (value: boolean) => void;
    hasFormatFile: boolean;
    onSubmit: (timeFrame: TimeFrameValue) => void;
    onRangeChange?: (start?: Date, end?: Date) => void;
    isLoading?: boolean;
    page?: ReportsHeaderPageName;
}

const ReportItemContext: React.FCC<ReportItemContextProps> = ({
    type,
    name,
    image,
    reportInclude,
    definition,
    format,
    onFormatFileChange,
    hasFormatFile,
    onSubmit,
    onRangeChange,
    isLoading,
    page,
}) => {
    const { PATH } = useGasRouterContext();

    const navigate = useNavigate();

    const backToReportGenerate = () => {
        navigate(PATH.REPORTS.GENERATE_REPORTS());
    };

    return (
        <ReportItemContent>
            <ReportForm
                backToReports={backToReportGenerate}
                format={format}
                hasFormatFile={hasFormatFile}
                isLoading={isLoading}
                name={name}
                onFormatFileChange={onFormatFileChange}
                onRangeChange={onRangeChange}
                onSubmit={onSubmit}
                type={type}
            />
            <ReportInformation
                definition={definition}
                image={image}
                reportInclude={reportInclude}
            />
        </ReportItemContent>
    );
};

export default ReportItemContext;
