import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { snakeCase } from 'lodash-es';
import { GetDonorsRequest } from '../../../../api/services/requests';
import { DonorsQueryModel } from '../../DonorsPage';

dayjs.extend(utc);

export type SerializerData = Partial<DonorsQueryModel>;

export const requestSerializer = (data: SerializerData): GetDonorsRequest => {
    const requestData: GetDonorsRequest = {};
    const donationStatuses = [];
    if (data.filter.givingStyles.new) {
        donationStatuses.push(4);
    }
    if (data.filter.givingStyles.occasional) {
        donationStatuses.push(6);
    }
    if (data.filter.givingStyles.consistent) {
        donationStatuses.push(2);
    }
    if (data.filter.givingStyles.decreasing) {
        donationStatuses.push(7);
    }
    if (data.filter.givingStyles.inactive) {
        donationStatuses.push(8);
        donationStatuses.push(9);
    }

    if (donationStatuses.length > 0) {
        requestData.donationStatuses = donationStatuses;
    }
    if (data.filter.givenAmount.totalGivenMin) {
        requestData.minLifetimeDonations =
            data.filter.givenAmount.totalGivenMin;
    }
    if (data.filter.givenAmount.totalGivenMax) {
        requestData.maxLifetimeDonations =
            data.filter.givenAmount.totalGivenMax;
    }
    if (data.filter.givenAmount.lastGivenMin) {
        requestData.minLastDonationAmount =
            data.filter.givenAmount.lastGivenMin;
    }
    if (data.filter.givenAmount.lastGivenMax) {
        requestData.maxLastDonationAmount =
            data.filter.givenAmount.lastGivenMax;
    }
    if (data.page) {
        requestData.page = data.page;
    }
    if (data.search) {
        requestData.filter = data.search;
    }
    if (data.sort) {
        requestData.sort = snakeCase(data.sort.split('-')[0]);
    }
    if (
        data.filter.timeFrame &&
        data.filter.timeFrame.start &&
        data.filter.timeFrame.end
    ) {
        const start = data.filter.timeFrame.start.toISOString();
        const end = data.filter.timeFrame.end.endOf('day').toISOString();
        requestData.lastDonationStartDate = start;
        requestData.lastDonationEndDate = end;
    }
    return requestData;
};
