import React, { useMemo, useState } from 'react';
import { GivelifyTooltip, GivelifyLabel } from '@givelify/ui';
import { Grid } from '@mui/material';
import {
    legacyReportsAccentColor,
    InfoIcon,
    InfoIconWrapper,
    LegacyReportTooltipWrapper,
    ExcludeEnvelopes,
    ExcludeEnvelopesWrapper,
} from 'pages/reports/LegacyReports/components/style';
import { useTranslation } from 'react-i18next';

interface ExcludeEmptyEnvelopesProps {
    onChange: (value: boolean) => void;
}

export const ExcludeEmptyEnvelopes: React.FCC<ExcludeEmptyEnvelopesProps> = ({
    onChange,
}) => {
    const [exclude, setExclude] = useState(false);
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            label: t('reports.legacyReportsDashboard.excludeEmptyEnvelopes'),
            description: t(
                'reports.legacyReportsDashboard.excludeEmptyEnvelopesDescription',
            ),
            new: t('reports.legacyReportsDashboard.new'),
        }),
        [t],
    );

    const handleChange = () => {
        onChange(!exclude);
        setExclude(!exclude);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <GivelifyLabel
                    color={legacyReportsAccentColor}
                    fontWeight={800}
                    text={copy.new}
                />
            </Grid>
            <ExcludeEnvelopesWrapper item>
                <ExcludeEnvelopes
                    aria-label="exclude-empty-envelopes"
                    checked={exclude}
                    label={copy.label}
                    name="exclude-empty-envelopes"
                    onChange={handleChange}
                />
            </ExcludeEnvelopesWrapper>
            <LegacyReportTooltipWrapper item>
                <GivelifyTooltip
                    arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    placement="top"
                    title={copy.description}
                >
                    <InfoIconWrapper>
                        <InfoIcon fontSize={20} variant="info-filled" />
                    </InfoIconWrapper>
                </GivelifyTooltip>
            </LegacyReportTooltipWrapper>
        </Grid>
    );
};
