import React, { useMemo } from 'react';
import { GivelifyLabel } from '@givelify/givelify-ui';
import { DesignTokens } from '@givelify/ui';
import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EnvelopeProgressTooltip from './EnvelopesProgressTooltip';
import useStyles from './styles';

interface EnvelopeProgressProps {
    id?: number;
    value: number;
    goal: number;
    valueText: string;
    percentigeText: string;
    disabled: boolean;
}

const EnvelopeProgress: React.FCC<EnvelopeProgressProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            goal: t('labels.goal'),
        }),
        [t],
    );

    return (
        <div
            aria-label="Active envelopes progress status"
            className={classes.progressContainer}
            data-testid={props.id}
        >
            <div className={classes.progressHeader}>
                <GivelifyLabel
                    color={props.disabled && DesignTokens.color.textSecondary}
                    text={copy.goal}
                    variant="body2"
                />
                <GivelifyLabel
                    color={
                        props.disabled
                            ? DesignTokens.color.textDisabled
                            : DesignTokens.color.textSecondary
                    }
                    id={`goal-value-${props.id}`}
                    text={`$${props.goal.toLocaleString()}`}
                    variant="body2"
                />
            </div>
            <LinearProgress
                aria-label="Active envelopes progress bar"
                classes={{
                    barColorPrimary: props.disabled
                        ? classes.progressPrimaryDisabled
                        : classes.progressPrimary,
                    colorPrimary: props.disabled
                        ? classes.progressSecondaryDisabled
                        : classes.progressSecondary,
                    root: classes.root,
                    bar: classes.bar,
                }}
                value={props.value > 100 ? 100 : props.value}
                variant="determinate"
            />
            <EnvelopeProgressTooltip
                disabled={props.disabled}
                id={props.id}
                percentige={props.percentigeText}
                value={props.value}
                valueText={props.valueText}
            />
        </div>
    );
};

export default EnvelopeProgress;
