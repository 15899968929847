import React from 'react';
import { GivelifyTextField, GivelifyTextFieldProps } from '../textField';
import { emailValidation } from '../utils/emailUtils';

export type GivelifyEmailFieldProps = GivelifyTextFieldProps & {
    onValidation?: (valid: boolean) => void;
};

export const GivelifyEmailField: React.FCC<GivelifyEmailFieldProps> = (
    props,
) => {
    const {
        value: passedValue,
        onChange,
        onEnterSubmit,
        submitting,
        defaultValue,
        onValidation,
        ...inputProps
    } = props;
    const [value, setValue] = React.useState<string>(
        defaultValue ? defaultValue : '',
    );

    const onInputValueChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
            if (submitting) return;
            setValue(value);
            if (onChange) {
                onChange(event, value);
            }
            if (value) {
                if (onValidation) onValidation(emailValidation(value));
            } else {
                if (onValidation) onValidation(false);
            }
        },
        [submitting, onChange, setValue, onValidation],
    );
    React.useEffect(() => {
        if (passedValue !== undefined) {
            setValue(passedValue.toString());
        }
    }, [passedValue, setValue]);

    return (
        <GivelifyTextField
            {...inputProps}
            type="text"
            inputMode="email"
            submitting={submitting}
            value={value}
            defaultValue={defaultValue ? defaultValue.toString() : undefined}
            onChange={onInputValueChange}
        />
    );
};
