import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { GivelifyDatePicker, GivelifyDatePickerProps } from '../datePicker';
import { OmitFields } from './types';

export type GivelifyFormDatePickerProps<Form = Record<string, unknown>> = Omit<
    GivelifyDatePickerProps,
    OmitFields
> & {
    name: keyof Form;
};

export const GivelifyFormDatePicker = <Form,>(
    props: GivelifyFormDatePickerProps<Form>,
) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const error = errors[props.name] as { message: string };

    return (
        <Controller
            control={control}
            name={String(props.name)}
            render={({
                field: { name, onChange, value },
                fieldState: { invalid },
            }) => {
                return (
                    <GivelifyDatePicker
                        {...props}
                        helperText={invalid ? error?.message : props.helperText}
                        label={props.label || ''}
                        name={name}
                        onDateChange={onChange}
                        pickerMode="date"
                        state={invalid ? 'error' : 'idle'}
                        value={value}
                    />
                );
            }}
        />
    );
};
