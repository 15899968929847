import React from 'react';
import { DesignTokens } from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LabelProps } from 'recharts';
import { formatMoney } from 'utils/formatMoneyWithCommas';
import { TrendData } from '../../types';

const HighestLabelComponent = React.forwardRef(
    (
        props: LabelProps & {
            index?: number;
            highestLabelIndex?: number | null;
            data: TrendData;
            labelWidth: number;
        },
        ref: React.MutableRefObject<SVGTextElement>,
    ) => {
        const {
            x,
            y,
            value,
            width,
            index,
            data,
            labelWidth,
            highestLabelIndex,
        } = props;
        const { t } = useTranslation();
        const copy = React.useMemo(
            () => ({
                highest: t('overview.highest'),
            }),
            [t],
        );
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
        const isSmallTablet = useMediaQuery(
            theme.breakpoints.down('smallTablet'),
        );

        const labelXPosition =
            Number(x) +
            (Number(width) - labelWidth) / 2 +
            (index === data.length - 1 && isSmallTablet ? -20 : 0);

        if (highestLabelIndex === index) {
            return (
                <text
                    ref={ref}
                    data-testid="highest-amount-label"
                    dy={-10}
                    fill={DesignTokens.color.textPrimary}
                    fontFamily={
                        DesignTokens.textStyle.globalCaption1.font.family
                    }
                    fontSize={DesignTokens.textStyle.globalCaption1.font.size}
                    x={isMobile ? 5 : labelXPosition}
                    y={y}
                >
                    {copy.highest}:{' '}
                    <tspan fontWeight="bold">{formatMoney(value)}</tspan>
                </text>
            );
        }

        return null;
    },
);

export const HighestLabel = React.memo(HighestLabelComponent);
