import {
    isValidPhoneNumber,
    isValidSSN,
    isValidStreet,
    SchemaGuard,
    UnwrapSchema,
} from '@givelify/utils';
import dayjs from 'dayjs';
import * as yup from 'yup';
import {
    PrimaryRepAddressForm,
    PrimaryRepInfoForm,
    PrimaryRepNameForm,
    apiHiddenSsnRegex,
    apiHiddenSsnRegex11Digits,
} from './types';

export const useRepNameSchema: (
    t: (key: string, obj?: { [key: string]: number }) => string,
) => yup.SchemaOf<PrimaryRepNameForm> = (t) =>
    yup.object().shape({
        isPrimaryRepresentative: yup.boolean().required(),
        firstName: yup.string().required(t('labels.required')),
        lastName: yup.string().required(t('labels.required')),
        title: yup.string().required(t('labels.required')),
    });

export const useRepInfoSchema: (
    t: (key: string, obj?: { [key: string]: number }) => string,
) => yup.SchemaOf<PrimaryRepInfoForm> = (t) => {
    const schema = yup.object().shape({
        ssn: yup
            .string()
            .required(t('labels.required'))
            .test({
                test: (textFieldValue) => {
                    // textFieldValue might have 2 different formats
                    // a) 123-45-6789
                    // b) XXX-XX-6789

                    // First try validate fully visible SSN, for example 123-45-6789
                    const fullSSN = textFieldValue.replace(/[^0-9]/g, '');
                    if (isValidSSN(fullSSN)) return true;

                    // Then try validate masked SSN
                    const apiFormatSSN = textFieldValue.replaceAll('-', '');
                    return (
                        apiHiddenSsnRegex.test(apiFormatSSN) ||
                        apiHiddenSsnRegex11Digits.test(apiFormatSSN)
                    );
                },
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.invalidSsn',
                ),
            }),
        dateOfBirth: yup
            .mixed<dayjs.Dayjs>()
            .nullable()
            .required(t('labels.required'))
            .typeError(t('labels.required'))
            .test({
                test: (val) => dayjs().diff(val, 'year') >= 18,
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.mustBeOver18',
                ),
            }),
    });

    SchemaGuard<UnwrapSchema<typeof schema, PrimaryRepInfoForm>>();

    return schema as unknown as yup.SchemaOf<PrimaryRepInfoForm>;
};

export const useRepAddressSchema: (
    t: (key: string, obj?: { [key: string]: number }) => string,
) => yup.SchemaOf<PrimaryRepAddressForm> = (t) =>
    yup.object().shape({
        city: yup.string().required(t('labels.required')),
        zip: yup
            .string()
            .required(t('labels.required'))
            .test({
                test: (val) => {
                    return val.length === 5 && !isNaN(Number(val));
                },
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.zipTip',
                ),
            }),
        phoneNumber: yup
            .string()
            .required(t('labels.required'))
            .test({
                test: (val) => {
                    return isValidPhoneNumber(val);
                },
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.phoneTip',
                ),
            }),
        state: yup.string().required(t('labels.required')),
        street: yup
            .string()
            .required(t('labels.required'))
            .test({
                test: (val) => {
                    return isValidStreet(val, true);
                },
                message: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.labels.addressTip',
                ),
            }),
    });
