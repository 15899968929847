import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ProfileMobilePreview } from '@givelify/ui';
import { Grid } from '@mui/material';
import { ImageWithDimensions } from '../@types/assets/onboarding';
import { CustomizeProfileRef } from './CustomizeProfileRef';
import { HideOnMobile } from './CustomizeProfileStepOne/styles';
import { CustomizeProfileStepOneProps } from './CustomizeProfileStepOne/types';
import MissionStatement from './MissionStatement';
import { OrganizationLogoSection } from './OrganizationLogoSection';

export interface CustomizeProfileStepTwoProps
    extends CustomizeProfileStepOneProps {
    organizationName: string;
    onMobilePreviewRequest: () => void;
    className?: string;
}

const CustomizeProfileStepTwoComponent = (
    {
        organizationName,
        appProfile,
        organizationType,
        onMobilePreviewRequest,
        className,
    }: CustomizeProfileStepTwoProps,
    forwardRef: React.ForwardedRef<CustomizeProfileRef>,
) => {
    const [organizationLogo, setOrganizationLogo] = useState<
        ImageWithDimensions | undefined
    >(appProfile.organizationLogo);
    const [missionStatement, setMissionStatement] = useState<string>(
        appProfile.missionStatement,
    );

    useImperativeHandle(forwardRef, () => ({
        submit: () => ({
            ...appProfile,
            missionStatement,
            organizationLogo,
        }),
        refreshData: () => {
            /* do nothing */
        },
    }));

    return (
        <Grid container justifyContent="space-between">
            <Grid item mobile={7} smallMobile={12}>
                <MissionStatement
                    missionStatement={missionStatement}
                    onChange={setMissionStatement}
                    onMobilePreviewRequest={onMobilePreviewRequest}
                />
                <OrganizationLogoSection
                    organizationAddress={appProfile.organizationAddress}
                    organizationLogo={organizationLogo}
                    organizationName={organizationName}
                    organizationType={organizationType}
                    setOrganizationLogo={setOrganizationLogo}
                />
            </Grid>
            <HideOnMobile
                container
                item
                justifyContent="center"
                mobile={5}
                smallMobile={0}
            >
                <ProfileMobilePreview
                    coverUrl={appProfile.bannerImage?.croppedUrl}
                    fullAddress={appProfile.organizationAddress}
                    isNonprofit={organizationType === 'nonprofit'}
                    organizationName={organizationName}
                    profileStatement={missionStatement}
                    representativeAvatar={
                        appProfile.faithLeaderImage?.croppedUrl
                    }
                />
            </HideOnMobile>
        </Grid>
    );
};

export const CustomizeProfileStepTwo = forwardRef<
    CustomizeProfileRef,
    CustomizeProfileStepTwoProps
>(CustomizeProfileStepTwoComponent);
