import * as React from 'react';
import { GivelifyLabel, AddressWithPhone } from '@givelify/givelify-ui';
import {
    I18N_NAMESPACE,
    ImageWithDimensions,
    OrganizationLogoPreview,
} from '@givelify/onboarding';
import { ProfileMobilePreview, ShowAndEditAddress } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { BasicAppProfileViewStyles } from './basicAppProfileViewStyles';

interface BasicAppProfileProps {
    address: AddressWithPhone;
    organizationName: string;
    organizationAddress: AddressWithPhone;
    organizationLogo: ImageWithDimensions | undefined;
    representativeAvatar: string;
    coverUrl: string;
    missionStatement: string;
    isNonprofit: boolean;
    organizationType: string;
}

export const BasicAppProfileView: React.FunctionComponent<
    BasicAppProfileProps
> = (props) => {
    const {
        address,
        organizationName,
        organizationAddress,
        organizationLogo,
        missionStatement,
        representativeAvatar,
        coverUrl,
        isNonprofit,
        organizationType,
    } = props;
    const { t } = useTranslation(I18N_NAMESPACE);
    const {
        locationTitle,
        locationDisclaimer,
        isPOBoxIsNotAllowedText,
        streetPlaceholder,
        statePlaceholder,
        cityPlaceholder,
        zipPlaceholder,
        phonePlaceholder,
    } = React.useMemo(
        () => ({
            heading: t('customizeProfile.heading'),
            descriptionText: t('customizeProfile.description.text'),
            descriptionLink: t('customizeProfile.description.link'),
            locationTitle: t('customizeProfile.locationTitle'),
            locationDisclaimer: t('customizeProfile.locationDisclaimer'),
            streetPlaceholder: t('addressForm.placeholder.street'),
            cityPlaceholder: t('addressForm.placeholder.city'),
            statePlaceholder: t('addressForm.placeholder.state'),
            zipPlaceholder: t('addressForm.placeholder.zip'),
            phonePlaceholder: t('addressForm.placeholder.phone'),
            isPOBoxIsNotAllowedText: t('addressForm.POBoxIsNotAllowed'),
        }),
        [t],
    );
    const { basicAppProfileStyles } = BasicAppProfileViewStyles();
    return (
        <div className={basicAppProfileStyles}>
            <div className="form-section">
                <div className="content">
                    <div>
                        <div className="address-wrapper">
                            <GivelifyLabel
                                className="hide-sm"
                                text={locationTitle}
                                variant="heading4"
                            />
                        </div>
                        <GivelifyLabel
                            className="show-sm"
                            text={locationTitle}
                            variant="heading4"
                        />
                        <GivelifyLabel
                            marginBottom={16}
                            text={locationDisclaimer}
                            variant="body2"
                        />
                    </div>
                    <ShowAndEditAddress
                        address={address}
                        cityPlaceholder={cityPlaceholder}
                        isEdit={false}
                        name="Onboarding Address Editor"
                        phonePlaceholder={phonePlaceholder}
                        poBoxNotAllowedText={isPOBoxIsNotAllowedText}
                        statePlaceholder={statePlaceholder}
                        streetPlaceholder={streetPlaceholder}
                        zipPlaceholder={zipPlaceholder}
                    />
                </div>
                <OrganizationLogoPreview
                    organizationAddress={organizationAddress}
                    organizationLogo={organizationLogo}
                    organizationName={organizationName}
                    organizationType={organizationType}
                />
            </div>
            <div className="image-section">
                <ProfileMobilePreview
                    coverUrl={coverUrl}
                    fullAddress={organizationAddress}
                    isNonprofit={isNonprofit}
                    organizationName={organizationName}
                    profileStatement={missionStatement}
                    representativeAvatar={representativeAvatar}
                />
            </div>
        </div>
    );
};
