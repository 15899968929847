import React from 'react';
import {
    DesignTokens,
    GivelifyAvatar,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyLink,
} from '@givelify/ui';
import { getInitialsFromName, useAvatarOrInitials } from '@givelify/utils';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { webConfig } from 'webConfig';
import { MemberId } from '../memberId';
import {
    Box,
    InfoBox,
    DetailsBox,
    Divider,
    DetailsRow,
    NameBox,
    FlagImg,
} from './styles';

const defaultGlobalFlag = '/flags/global.svg';

export type DonorCardProps = {
    isMemberIdSaved: boolean;
    handleSaveAddEditMemberId: (newMemberId: string) => void;
    avatar?: string;
    name: string;
    email: string;
    phone: string;
    address: string;
    givingStyle: string;
    externalId: string;
    country: string;
    loading?: boolean;
    nonprofit: boolean;
};
const DonorCardComponent: React.FCC<DonorCardProps> = ({
    avatar,
    address,
    email,
    givingStyle,
    name,
    phone,
    country,
    externalId,
    handleSaveAddEditMemberId,
    isMemberIdSaved,
    loading,
    nonprofit,
}) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            givingStyle: t('donors.donorType.givingStyle'),
        }),
        [t],
    );
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const avatarSrc = useAvatarOrInitials(avatar, name);
    const [countryCode, setCountryCode] = React.useState(country);
    const [flagSrc, setFlagSrc] = React.useState(defaultGlobalFlag);
    React.useEffect(() => {
        setExpanded(false);
    }, [isMobile]);
    React.useEffect(() => {
        if (country) {
            const place = country.substring(0, 2);
            setCountryCode(place.toUpperCase());
            setFlagSrc(`${webConfig.flagRepository}${place.toLowerCase()}.png`);
        }
    }, [country]);
    if (loading) {
        return (
            <Box>
                <InfoBox>
                    <Skeleton
                        height={isMobile ? 40 : 64}
                        variant="circular"
                        width={isMobile ? 40 : 64}
                    />
                    <NameBox>
                        <Skeleton
                            height={isMobile ? 20 : 24}
                            variant="text"
                            width={184}
                        />
                        <Skeleton
                            height={isMobile ? 20 : 24}
                            variant="text"
                            width={132}
                        />
                    </NameBox>
                </InfoBox>
                <Divider />
                <DetailsBox>
                    <DetailsRow>
                        <Skeleton height={24} variant="rounded" width={184} />
                    </DetailsRow>
                    <DetailsRow>
                        <Skeleton height={24} variant="rounded" width={184} />
                    </DetailsRow>
                    <DetailsRow className="addr-box">
                        <Skeleton height={80} variant="rounded" width={184} />
                    </DetailsRow>
                </DetailsBox>
            </Box>
        );
    }
    return (
        <Box data-testid="donor-details-card" expanded={expanded}>
            <InfoBox>
                <GivelifyAvatar
                    size={isMobile ? 'small' : 'large'}
                    src={avatarSrc}
                    text={getInitialsFromName(name)}
                />
                <NameBox>
                    <GivelifyLabel
                        text={name}
                        textAlign="center"
                        variant={isMobile ? 'body2B' : 'body1B'}
                    />
                    <GivelifyLabel
                        color={DesignTokens.color.textSecondary}
                        variant={isMobile ? 'componentLabel' : 'caption1'}
                    >
                        {copy.givingStyle}
                        {': '}
                        <GivelifyLabel
                            color="inherit"
                            fontWeight="bold"
                            text={givingStyle}
                            variant={isMobile ? 'componentLabel' : 'caption1B'}
                        />
                    </GivelifyLabel>
                </NameBox>
                <GivelifyButton
                    className="expand"
                    onClick={() => setExpanded(!expanded)}
                    size="small"
                    variant="icon"
                >
                    <GivelifyIcon
                        variant={expanded ? 'up-chevron' : 'down-chevron'}
                    />
                </GivelifyButton>
            </InfoBox>
            <MemberId
                handleSaveAddEditMemberId={handleSaveAddEditMemberId}
                isMemberIdSaved={isMemberIdSaved}
                memberId={externalId}
                nonprofit={nonprofit}
            />
            <DetailsBox>
                <DetailsRow>
                    <GivelifyIcon fontSize={16} variant="email" />
                    <GivelifyLink
                        className="email-link"
                        href={`mailto:${email}`}
                        name="Donor Email"
                        text={email ? email : '-'}
                        variant="caption1"
                    />
                </DetailsRow>
                <DetailsRow>
                    <GivelifyIcon fontSize={16} variant="phone-new" />
                    <GivelifyLabel
                        text={phone ? phone : '-'}
                        variant="caption1"
                    />
                </DetailsRow>
                <DetailsRow className="addr-box">
                    <GivelifyIcon fontSize={16} variant="location" />
                    <GivelifyLabel
                        marginTop={-0.5}
                        text={address ? address : '-'}
                        variant="caption1"
                    >
                        {address && address !== '. , , ' ? address : '-'}{' '}
                        {country ? (
                            <GivelifyLabel
                                alignItems="center"
                                display="flex"
                                gap={0.5}
                                variant="caption1"
                            >
                                {countryCode}{' '}
                                <FlagImg
                                    alt={`${countryCode} flag`}
                                    onError={() =>
                                        setFlagSrc(defaultGlobalFlag)
                                    }
                                    src={flagSrc}
                                />
                            </GivelifyLabel>
                        ) : null}
                    </GivelifyLabel>
                </DetailsRow>
            </DetailsBox>
        </Box>
    );
};

export const DonorCard = React.memo(DonorCardComponent);
