import React, { useMemo, useState, useEffect } from 'react';
import {
    ApiHandler,
    PhysicalAddress,
    UpdateDoneeResponse,
} from '@givelify/api';
import { GivelifyRadio } from '@givelify/ui';
import {
    InvokeApiErrorResponse,
    isFailed,
    isSucceeded,
    useApiRequest,
} from '@givelify/utils';
import { isEqual } from 'lodash-es';
import { EditOption } from 'pages/settings/components/EditOption';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { setDoneeMailingAddress } from 'store/donee/actions';
import { Body, EditButtonContainer, Section, Subtitle } from '../style';
import { AddressSectionEditor } from './AddressSectionEditor';
import { AddressContent, MailValues } from './styles';

type MailSectionProps = {
    doneeId: number;
    mailingAddress: PhysicalAddress | null;
    isReadOnly: boolean;
};

export const MailSection: React.FCC<MailSectionProps> = ({
    doneeId,
    mailingAddress,
    isReadOnly,
}) => {
    const { t } = useTranslation();

    const copy = useMemo(
        () => ({
            title: t('pages.settings.organization-info.mail.title'),
            description: t('pages.settings.organization-info.mail.description'),
            yes: t('labels.yes'),
            no: t('labels.no'),
        }),
        [t],
    );

    const dispatch = useAppDispatch();

    const [addressData, setAddressData] = useState<PhysicalAddress>({
        street: '',
        postal: '',
        state: '',
        city: '',
    });
    const [editorEnabledState, setEditorEnabledState] = useState(false);
    const [sameMail, setSameMail] = useState(true);

    useEffect(() => {
        setSameMail(!mailingAddress);
        if (mailingAddress) {
            setAddressData(mailingAddress);
        }
    }, [mailingAddress]);

    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    const [updateRequestState, updateRequest] =
        useApiRequest<UpdateDoneeResponse>();

    const onSubmit = (newData: PhysicalAddress) => {
        if (!isEqual(addressData, newData)) {
            updateRequest(
                ApiHandler.instance.donees.updateMailingAddress(
                    doneeId,
                    newData,
                ),
            );
        } else {
            setEditorEnabledState(false);
        }
    };

    useEffect(() => {
        if (isSucceeded(updateRequestState)) {
            const { mailingAddress, updatedAt } = updateRequestState.response;
            dispatch(
                setDoneeMailingAddress(
                    doneeId,
                    {
                        city: mailingAddress.city,
                        zip: mailingAddress.zip,
                        state: mailingAddress.state,
                        address: mailingAddress.address,
                        address2: '',
                    },
                    new Date(updatedAt),
                ),
            );
            setEditorEnabledState(false);
        }
        if (isFailed(updateRequestState)) {
            setSaveError(
                (updateRequestState as InvokeApiErrorResponse).error.message,
            );
        }
    }, [updateRequestState, dispatch, doneeId]);

    const onEditClick = () => {
        setSaveError(undefined);
        setEditorEnabledState(true);
    };

    const onCancel = () => {
        setEditorEnabledState(false);
        setSameMail(!mailingAddress);
    };

    const handleSame = (value: boolean) => {
        if (sameMail !== value) {
            setSameMail(value);
            if (value) {
                updateRequest(
                    ApiHandler.instance.donees.updateMailingAddress(
                        doneeId,
                        null,
                    ),
                );
                setEditorEnabledState(false);
            } else {
                setEditorEnabledState(true);
            }
        }
    };

    const saving = updateRequestState.type === 'REQUEST_START';
    const showAddress = !sameMail && !editorEnabledState;
    const showEditor = !sameMail && editorEnabledState;

    return (
        <Section>
            <Subtitle bold text={copy.title} variant="heading2S" />
            <Body text={copy.description} variant="body1B" />

            <MailValues>
                <GivelifyRadio
                    aria-label={copy.yes}
                    checked={sameMail}
                    data-testid="sameAddress"
                    disabled={saving || isReadOnly}
                    label={copy.yes}
                    name="same-yes"
                    onChange={() => handleSame(true)}
                />
                <GivelifyRadio
                    aria-label={copy.no}
                    checked={!sameMail}
                    data-testid="differentAddress"
                    disabled={saving || isReadOnly}
                    label={copy.no}
                    name="same-no"
                    onChange={() => handleSame(false)}
                />
            </MailValues>
            {showAddress && (
                <AddressContent>
                    <Body
                        data-testid="org-mail-str"
                        text={addressData.street}
                        variant="body1"
                    />
                    <Body
                        data-testid="org-mail-loc"
                        text={`${addressData.city}, ${addressData.state} ${addressData.postal}`}
                        variant="body1"
                    />
                </AddressContent>
            )}
            {showEditor && (
                <AddressSectionEditor
                    data={addressData}
                    error={saveError}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    saving={saving}
                />
            )}
            <EditButtonContainer>
                {!isReadOnly && showAddress && (
                    <EditOption
                        onClick={onEditClick}
                        testId="edit-mail-address"
                    />
                )}
            </EditButtonContainer>
        </Section>
    );
};
