import React, { useMemo } from 'react';
import { SocialInfo } from '@givelify/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, styled } from '@mui/material';
import { ErrorText } from 'components/ErrorText';
import { SETTINGS_ASSETS_PATH } from 'pages/settings/SettingsAssetsPath';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { urlRegExp } from 'utils/validationRegExp';
import { AnySchema, object, string } from 'yup';
import EditorButtons from '../EditorButtons';
import {
    EditorContainerForm,
    EditorInputField,
    EditorItemTitle,
    EditorItemValue,
} from './styles';

const ImgContainer = styled('div')({
    width: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
});

interface SocialSectionEditorProps {
    data: SocialInfo;
    onCancel: () => void;
    onSubmit: (data: SocialInfo) => void;
    saving?: boolean;
    error?: string;
}

const SocialSectionEditor: React.FCC<SocialSectionEditorProps> = (
    props: SocialSectionEditorProps,
) => {
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            socialMedia: t(
                'pages.settings.organization-info.social.socialMedia',
            ),
            website: t('pages.settings.organization-info.social.website'),
            facebook: t('pages.settings.organization-info.social.facebook'),
            instagram: t('pages.settings.organization-info.social.instagram'),
            twitter: t('pages.settings.organization-info.social.twitter'),
            invalidURL: t(
                'pages.settings.organization-info.social.invalid-url',
            ),
            required: t('labels.required'),
            save: t('labels.save'),
            cancel: t('labels.cancel'),
        }),
        [t],
    );

    const fieldSchema = string().test(
        'Invalid URL',
        copy.invalidURL,
        function (value) {
            if (!value?.length) return true;
            return urlRegExp.test(value);
        },
    );

    const form = useForm<SocialInfo>({
        mode: 'onBlur',
        resolver: yupResolver(
            object<Record<keyof SocialInfo, AnySchema>>({
                facebook: fieldSchema,
                instagram: fieldSchema,
                twitter: fieldSchema,
                website: fieldSchema.required(copy.required),
            }),
        ),
        defaultValues: {
            facebook: props.data.facebook,
            instagram: props.data.instagram,
            twitter: props.data.twitter,
            website: props.data.website,
        },
    });

    const handleSubmit: SubmitHandler<SocialInfo> = (formData) => {
        const { facebook, instagram, twitter, website } = formData;
        const formatURL = (url: string) => {
            if (!url?.length) return null;
            return !url.match(/^[a-zA-Z]+:\/\//) ? 'http://' + url : url;
        };
        props.onSubmit({
            facebook: formatURL(facebook),
            instagram: formatURL(instagram),
            twitter: formatURL(twitter),
            website: formatURL(website),
        });
    };

    return (
        <FormProvider {...form}>
            <EditorContainerForm onSubmit={form.handleSubmit(handleSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <EditorItemTitle
                            text={copy.socialMedia}
                            variant="heading2S"
                        />
                        <EditorItemValue>
                            <ImgContainer>
                                <img
                                    alt="facebook link"
                                    src={SETTINGS_ASSETS_PATH.FACEBOOK_ICON}
                                />
                            </ImgContainer>
                            <EditorInputField
                                fullWidth
                                aria-label="facebook-input"
                                disabled={props.saving}
                                id="facebook"
                                name="facebook"
                                placeholder={copy.facebook}
                            />
                        </EditorItemValue>
                        <EditorItemValue>
                            <ImgContainer>
                                <img
                                    alt="twitter link"
                                    src={SETTINGS_ASSETS_PATH.TWITTER_ICON}
                                />
                            </ImgContainer>
                            <EditorInputField<SocialInfo>
                                fullWidth
                                aria-label="twitter-input"
                                disabled={props.saving}
                                id="twitter"
                                name="twitter"
                                placeholder={copy.twitter}
                            />
                        </EditorItemValue>
                        <EditorItemValue>
                            <ImgContainer>
                                <img
                                    alt="instagram link"
                                    src={SETTINGS_ASSETS_PATH.INSTAGRAM_ICON}
                                />
                            </ImgContainer>
                            <EditorInputField<SocialInfo>
                                fullWidth
                                aria-label="instagram-input"
                                disabled={props.saving}
                                id="instagram"
                                name="instagram"
                                placeholder={copy.instagram}
                            />
                        </EditorItemValue>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <EditorItemTitle
                            text={copy.website}
                            variant="heading2S"
                        />
                        <EditorItemValue>
                            <ImgContainer>
                                <img
                                    alt="web link"
                                    src={SETTINGS_ASSETS_PATH.WEB_ICON}
                                />
                            </ImgContainer>
                            <EditorInputField<SocialInfo>
                                fullWidth
                                aria-label="website-input"
                                disabled={props.saving}
                                id="website"
                                name="website"
                                placeholder={copy.website}
                            />
                        </EditorItemValue>
                    </Grid>
                </Grid>
                {props.error && <ErrorText text={props.error} />}
                <EditorButtons
                    onCancel={props.onCancel}
                    saving={props.saving}
                />
            </EditorContainerForm>
        </FormProvider>
    );
};

export default SocialSectionEditor;
