import React from 'react';
import { BasicPageWrapper, GivelifyPaper } from '@givelify/ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import roleTypes from '../../constants/roleTypes';
import { AppState } from '../../store';
import { EditProfileForm } from './components/EditProfileForm';
import LeftColumn from './components/LeftColumn';

const LeftColumnGrid = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    borderRight: '1px solid #d8d8d8',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('smallTablet')]: {
        borderRight: 'unset',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(5),
        paddingBottom: 0,
        width: '100%',
        justifyContent: 'center',
    },
}));

const RightColumn = styled(Grid)(({ theme }) => ({
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
}));

export const EditProfile: React.FCC = () => {
    const { t } = useTranslation();
    const { user } = useSelector((state: AppState) => ({
        user: state.User.user,
    }));
    const role = user.role;
    const isAdminPanelRole = role === roleTypes.ADMIN_PANEL_USER;
    return (
        <BasicPageWrapper
            contentWrapper="simple"
            description="Edit Profile"
            title={t('editProfile.title')}
        >
            <TrackingProvider trackPageVisit pageName={PAGE_NAME.EditProfile}>
                <GivelifyPaper>
                    <Grid container>
                        <LeftColumnGrid item smallTablet={3} xs={12}>
                            <LeftColumn disabled={isAdminPanelRole} />
                        </LeftColumnGrid>
                        <RightColumn item smallTablet={9} xs={12}>
                            <EditProfileForm disabled={isAdminPanelRole} />
                        </RightColumn>
                    </Grid>
                </GivelifyPaper>
            </TrackingProvider>
        </BasicPageWrapper>
    );
};
