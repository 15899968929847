import React, { useMemo } from 'react';
import { GivelifyButton, GivelifyIcon } from '@givelify/ui';
import { Grid } from '@material-ui/core';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';

interface OnboardingActionsProps {
    onBack: () => void;
    onCancel: () => void;
    onContinue?: () => void;
    backText?: string;
    cancelText?: string;
    continueText?: string;
    disableContinue?: boolean;
    isLoading?: boolean;
    continueIcon?: React.ReactNode;
    cancelButtonName?: string;
    submitButtonName?: string;
    backButtonName?: string;
}

const WrapperGrid = styled(Grid)(({ theme }) => ({
    marginTop: 32,
    [theme.breakpoints.up('mobile')]: {
        marginTop: 48,
    },
    [theme.breakpoints.up('tablet')]: {
        marginTop: 64,
    },
}));

export const OnboardingActions: React.FCC<OnboardingActionsProps> = ({
    backButtonName = 'back',
    cancelButtonName = 'cancel',
    submitButtonName = 'continue',
    ...props
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const { t } = useTranslation(I18N_NAMESPACE);
    const copy = useMemo(
        () => ({
            back: t('captivePortal.back'),
            cancel: t('captivePortal.cancel'),
            continue: t('captivePortal.continue'),
        }),
        [t],
    );

    const backText = props.backText || copy.back;
    const cancelText = props.cancelText || copy.cancel;
    const continueText = props.continueText || copy.continue;

    return (
        <WrapperGrid
            container
            direction={isMobile ? 'column-reverse' : 'row'}
            justifyContent="space-between"
        >
            <Grid item>
                <GivelifyButton
                    data-testid="Back"
                    disabled={props.isLoading}
                    name={backButtonName}
                    onClick={props.onBack}
                    size="large"
                    startIcon={<GivelifyIcon variant="left-arrow" />}
                    sx={isMobile ? { marginTop: '16px' } : {}}
                    text={backText}
                    variant="ghost"
                />
            </Grid>
            <Grid item>
                <Grid
                    container
                    direction={isMobile ? 'column-reverse' : 'row'}
                    spacing={isMobile ? 2 : 0}
                >
                    <Grid item>
                        <GivelifyButton
                            data-testid="Cancel"
                            disabled={props.isLoading}
                            fullWidth={isMobile}
                            name={cancelButtonName}
                            onClick={props.onCancel}
                            size={isMobile ? 'medium' : 'large'}
                            text={cancelText}
                        />
                    </Grid>
                    <Grid item>
                        <GivelifyButton
                            data-testid="Submit"
                            disabled={props.disableContinue || props.isLoading}
                            fullWidth={isMobile}
                            isLoading={props.isLoading}
                            name={submitButtonName}
                            onClick={props.onContinue}
                            size={isMobile ? 'medium' : 'large'}
                            startIcon={props.continueIcon ?? null}
                            sx={isMobile ? {} : { marginLeft: '16px' }}
                            text={continueText}
                            type="submit"
                            variant="primary"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </WrapperGrid>
    );
};
