import { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyLabelStyles,
    useAdvancedTranslation,
} from '@givelify/givelify-ui';
import { GivelifyFormCheckbox, GivelifyFormTextField } from '@givelify/ui';
import { useFormContext } from 'react-hook-form';
import { webConfig } from 'webConfig';
import { FormProps } from '../types';
import useStyles from './styles';

const CampusSelected = () => {
    const {
        addressWrapper,
        campusNicknameLabel,
        marginBottom16,
        marginBottom24,
        nicknameField,
        checkboxRow,
        link,
    } = useStyles();
    const { body2 } = GivelifyLabelStyles({});

    const { watch } = useFormContext<FormProps>();
    const { name, address, city, state, zip, phone } = watch();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.addCampusModal.addForm',
    });

    const copy = useMemo(
        () => ({
            campusDetails: scopedTranslate('campusDetails'),
            officialName: scopedTranslate('officialName'),
            text1: scopedTranslate('text1'),
            text2: scopedTranslate('text2'),
            text3: scopedTranslate('text3'),
            text4: scopedTranslate('text4'),
            nicknameLabel: scopedTranslate('nicknameLabel'),
            nicknameHelperText: scopedTranslate('nicknameHelperText'),
            nicknameUnique: scopedTranslate('nicknameUnique'),
            haveAuthority: scopedTranslate('haveAuthority'),
            iAgree: scopedTranslate('iAgree'),
            tos: scopedTranslate('tos'),
            required: scopedTranslate('required'),
        }),
        [scopedTranslate],
    );

    return (
        <>
            <GivelifyLabel
                className={marginBottom24}
                text={copy.campusDetails}
                variant="heading3"
            />
            <GivelifyLabel
                className={marginBottom16}
                text={copy.officialName}
                variant="heading5"
            />
            <GivelifyLabel
                className={marginBottom16}
                text={copy.text1}
                variant="body1"
            />
            <div className={addressWrapper}>
                <GivelifyLabel text={name} variant="heading4" />
                <GivelifyLabel text={address} variant="heading4" />
                <GivelifyLabel
                    text={`${city}, ${state} ${zip}`}
                    variant="heading4"
                />
                <GivelifyLabel text={phone} variant="heading4" />
            </div>
            <GivelifyLabel
                color="neutralGrey"
                text={copy.text2}
                variant="body1"
            />
            <GivelifyLabel
                className={campusNicknameLabel}
                text={copy.text3}
                variant="heading5"
            />
            <GivelifyLabel text={copy.text4} variant="body1" />
            <div className={nicknameField}>
                <GivelifyFormTextField
                    countLength
                    fullWidth
                    id="nickname"
                    label={copy.nicknameLabel}
                    leftHelperText={copy.nicknameHelperText}
                    maxLength={50}
                    name="nickname"
                />
            </div>
            <div className={checkboxRow}>
                <GivelifyFormCheckbox
                    aria-label="authorized"
                    name="authorized"
                    style={{
                        marginRight: 16,
                    }}
                />
                <div>
                    <GivelifyLabel text={copy.haveAuthority} variant="body2" />
                    <div className={body2}>
                        {`${copy.iAgree} `}
                        <a
                            className={link}
                            href={webConfig.termsAndConditionsUrl}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            {copy.tos}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampusSelected;
