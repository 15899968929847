import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GivelifyIcon } from '../icon';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { getStateName, phoneFormatter } from '../utils';
import { MOCKUP_PATH } from './paths';
import {
    MobileMap,
    MobilePreviewAvatar,
    MobilePreviewButton,
    MobilePreviewCover,
    MobilePreviewCoverSection,
    MobilePreviewDetailsSection,
    MobilePreviewFav,
    MobilePreviewLocationSec,
    MobilePreviewOrgBadge,
    MobilePreviewOrgSec,
    MobilePreviewPhoneSec,
    MobilePreviewTaxTip,
} from './profileMobilePreviewStyle';
import {
    MobilePreviewBack,
    MobilePreviewCase,
    MobilePreviewContent,
    MobilePreviewShare,
} from './styles';

export type ProfileMobilePreviewProps = {
    isNonprofit?: boolean;
    organizationName?: string;
    profileStatement?: string;
    fullAddress?: {
        street?: string;
        city?: string;
        state?: string;
        zip?: string;
        phone?: string;
    };
    coverUrl?: string;
    representativeName?: string;
    representativeAvatar?: string;
    hideTitle?: boolean;
    isVerified?: boolean;
    title?: string;
};

export const ProfileMobilePreview: React.FCC<ProfileMobilePreviewProps> =
    React.memo((props) => {
        const { t } = useTranslation();
        const copy = useMemo(
            () => ({
                yourOrganization: t(
                    'pages.settings.mobile-preview.your-organization',
                ),
                organizationsRepresentative: t(
                    'pages.settings.mobile-preview.organizations-representative',
                ),
                taxDeductibleOrganization: t(
                    'pages.settings.mobile-preview.tax-deductible-organization',
                ),
                give: t('pages.settings.mobile-preview.give'),
            }),
            [t],
        );
        const {
            coverUrl,
            fullAddress,
            isNonprofit,
            representativeName,
            representativeAvatar,
            profileStatement,
            organizationName,
            isVerified,
        } = props;
        return (
            <MobilePreviewCase>
                <MobilePreviewContent>
                    <MobilePreviewCoverSection>
                        <MobilePreviewCover
                            alt="organization cover"
                            onError={(event) => {
                                event.currentTarget.src =
                                    MOCKUP_PATH.COVER_NORMAL;
                            }}
                            src={coverUrl || MOCKUP_PATH.COVER_NORMAL}
                            title="Organization cover picture"
                        />
                    </MobilePreviewCoverSection>
                    <MobilePreviewDetailsSection>
                        <MobilePreviewOrgSec>
                            <GivelifyLabel
                                text={organizationName || copy.yourOrganization}
                                variant="heading2S"
                            />
                            {isVerified ? (
                                <MobilePreviewOrgBadge
                                    alt="verified"
                                    src={MOCKUP_PATH.ICONS.VERIFIED}
                                    title="Verified badge"
                                />
                            ) : null}
                        </MobilePreviewOrgSec>
                        {!isNonprofit && (
                            <GivelifyLabel
                                color={DesignTokens.color.grey600}
                                text={
                                    representativeName ||
                                    copy.organizationsRepresentative
                                }
                                variant="heading3S"
                            />
                        )}
                        <MobilePreviewTaxTip>
                            {copy.taxDeductibleOrganization}
                        </MobilePreviewTaxTip>
                        {profileStatement ? (
                            <div className="profileStatement">
                                {profileStatement}
                            </div>
                        ) : null}
                        <MobilePreviewButton>{copy.give}</MobilePreviewButton>
                        <MobilePreviewPhoneSec>
                            <img
                                alt="phone"
                                src={MOCKUP_PATH.ICONS.PHONE}
                                title="Phone icon"
                            />
                            <div className="phoneValue">
                                {fullAddress && fullAddress.phone
                                    ? phoneFormatter(fullAddress.phone) ||
                                      '(678) 687-4115'
                                    : '(678) 687-4115'}
                            </div>
                        </MobilePreviewPhoneSec>
                        <MobilePreviewLocationSec>
                            <img
                                alt="location"
                                src={MOCKUP_PATH.ICONS.LOCATION}
                                title="Location icon"
                            />
                            <div className="phoneValue">
                                {`${
                                    fullAddress?.street || '620 Hembree Pkwy'
                                }, ${fullAddress?.city || 'Roswell'}, ${
                                    getStateName(fullAddress?.state) ||
                                    'Georgia'
                                } ${fullAddress?.zip || '30076'}`}
                            </div>
                        </MobilePreviewLocationSec>
                        {!isNonprofit && (
                            <MobilePreviewAvatar
                                alt="avatar"
                                height={72}
                                onError={(event) => {
                                    event.currentTarget.src =
                                        MOCKUP_PATH.AVATAR;
                                }}
                                src={representativeAvatar || MOCKUP_PATH.AVATAR}
                                title="Avatar picture"
                                width={72}
                            />
                        )}
                        <MobilePreviewFav
                            alt="fav"
                            src={MOCKUP_PATH.FAVOURITE}
                            title="fav picture"
                        />
                    </MobilePreviewDetailsSection>
                    <MobileMap
                        alt="map"
                        src={MOCKUP_PATH.MAP_NORMAL}
                        title="map preview"
                    />
                    <MobilePreviewBack>
                        <GivelifyIcon variant="left-arrow" />
                    </MobilePreviewBack>
                    <MobilePreviewShare>
                        <img
                            alt="share"
                            src={MOCKUP_PATH.ICONS.SHARE}
                            title="share"
                        />
                    </MobilePreviewShare>
                </MobilePreviewContent>
            </MobilePreviewCase>
        );
    });
