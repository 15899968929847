import React from 'react';
import { TimeFrameValue } from '@givelify/utils';
import { Skeleton } from '@mui/material';
import { DonationsStatisticType } from 'api/client/endpoints';
import { BasicErrorBoundary } from 'components';
import { WidgetErrorBox } from '../../components';
import { EmptyState } from '../components/EmptyState';
import { TrendData } from '../types';
import { BarChart } from './components/BarChart';
import { ChartHeader } from './components/ChartHeader';
import { BarChartWrapper, WidgetBox } from './styles';

export type DonorTrendChartViewComponentProps = {
    isLoading: boolean;
    data: TrendData;
    showEmptyState: boolean;
    totalDonation: number;
    donationsCount: number;
    rangeDateType: DonationsStatisticType;
    timeFrame: TimeFrameValue;
    dataKey: string;
    avgDonations: number;
};

const DonorTrendChartViewComponent: React.FCC<
    DonorTrendChartViewComponentProps
> = ({
    isLoading,
    data,
    showEmptyState,
    totalDonation,
    donationsCount,
    rangeDateType,
    timeFrame,
    dataKey,
    avgDonations,
}) => {
    return (
        <WidgetBox>
            <BasicErrorBoundary
                fallback={<WidgetErrorBox height="100%" width="100%" />}
            >
                {isLoading ? (
                    <Skeleton
                        data-testid="trend-chart-skeleton"
                        height="100%"
                        sx={{
                            maxHeight: '416px',
                            aspectRatio: '1',
                        }}
                        variant="rectangular"
                        width="100%"
                    />
                ) : (
                    <>
                        <ChartHeader
                            avgDonations={avgDonations}
                            dateEnd={timeFrame.end}
                            dateStart={timeFrame.start}
                            donationsCount={donationsCount}
                            total={totalDonation}
                        />
                        <BarChartWrapper>
                            {showEmptyState ? (
                                <EmptyState
                                    endDate={timeFrame.end}
                                    startDate={timeFrame.start}
                                />
                            ) : null}
                            <BarChart
                                data={data}
                                dataKey={dataKey}
                                rangeDateType={rangeDateType}
                            />
                        </BarChartWrapper>
                    </>
                )}
            </BasicErrorBoundary>
        </WidgetBox>
    );
};

export const DonorTrendChartView = React.memo(DonorTrendChartViewComponent);
