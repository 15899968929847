import { GivelifyButton } from '@givelify/ui';
import { Typography } from '@material-ui/core';
import { styled } from '@mui/material';

export const Form = styled('form')(({ theme }) => ({
    [theme.breakpoints.up('mobile')]: {
        maxWidth: 600,
    },

    width: '100%',
}));

export const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        textAlign: 'center',
    },
    marginBottom: 32,
}));

export const SubmitButtonWrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('mobile')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
    },
}));
export const SubmitButton = styled(GivelifyButton)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: { width: '100%' },
}));

export const EmailWrapper = styled('div')(() => ({
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    marginBottom: 8,
    flexWrap: 'wrap',
}));
