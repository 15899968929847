import { useState, useEffect } from 'react';
import { useInvokeApi } from '@givelify/utils';
import toCamelCase from 'camelcase-keys';
import { DonorMatchingProps } from 'pages/integrations/IntegrationContent/TabsContent/DonorMatching/components/DonorMatchingRow';
import InfiniteScroll from 'react-infinite-scroller';
import {
    PaginatedCamelCaseResponse,
    initialPaginatedResponse,
} from '../../../types/paginationTypes';

interface InfiniteLoaderProps {
    LoadingComponent: JSX.Element;
    ErrorComponent: JSX.Element;
    ZerothComponent: JSX.Element;
    reset: number;
    onReset: () => unknown;
    url: (pageNumber: number) => string;
    renderData: (
        data: PaginatedCamelCaseResponse<DonorMatchingProps>,
    ) => JSX.Element | JSX.Element[];
    className?: string;
    useWindow?: boolean;
    getScrollParent?: () => HTMLElement;
    pauseLoading?: boolean;
}

const Loading = <h4 key="loading">Loading...</h4>;
const InfiniteLoader = (props: InfiniteLoaderProps) => {
    const { url, reset, pauseLoading } = props;
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [dataSet, setData] = useState<
        PaginatedCamelCaseResponse<DonorMatchingProps>
    >(initialPaginatedResponse);
    const [requestState, makeRequest] =
        useInvokeApi<PaginatedCamelCaseResponse<DonorMatchingProps>>();

    const increasePageNumber = () => {
        if (loading) return;
        setLoading(true);
        return setPageNumber((p) => p + 1);
    };

    useEffect(() => {
        if (requestState.type !== 'REQUEST_SUCCESS') return;

        const data = toCamelCase(requestState.response.data, {
            deep: true,
        }) as unknown as DonorMatchingProps[];
        const newData = {
            data,
            pagination: requestState.response.pagination,
        };
        setData((prevDataSet) => {
            if (newData.pagination.currentPage === 1) {
                return newData;
            } else {
                const existingIds = new Set(
                    prevDataSet.data.map((item) => item.id),
                );
                const filteredData = newData.data.filter(
                    (item) => !existingIds.has(item.id),
                );
                return {
                    ...prevDataSet,
                    data: [...prevDataSet.data, ...filteredData],
                };
            }
        });
        setLoading(false);
    }, [requestState]);

    useEffect(() => {
        setPageNumber(1);
        setLoading(false);
    }, [reset, url]);

    useEffect(() => {
        setLoading(true);
        if (pauseLoading) return;
        makeRequest('GET', url(pageNumber));
        //watch only page number and reset
        //eslint-disable-next-line
    }, [pageNumber, reset, url, pauseLoading]);
    if (requestState.type === 'REQUEST_ERROR') return props.ErrorComponent;
    if (
        (requestState.type === 'REQUEST_START' && pageNumber === 1) ||
        requestState.type === 'REQUEST_INIT'
    )
        return props.LoadingComponent;
    if (
        requestState.type === 'REQUEST_SUCCESS' ||
        (requestState.type === 'REQUEST_START' && pageNumber !== 1)
    )
        return dataSet.data.length === 0 ? (
            props.ZerothComponent
        ) : (
            <div className={props.className}>
                <InfiniteScroll
                    getScrollParent={props.getScrollParent}
                    hasMore={pageNumber < dataSet.pagination.totalPages}
                    loadMore={() => increasePageNumber()}
                    loader={Loading}
                    pageStart={0}
                    useWindow={props.useWindow}
                >
                    {props.renderData(dataSet)}
                </InfiniteScroll>
            </div>
        );
};

export default InfiniteLoader;
