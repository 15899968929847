import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { ListTileBase } from './ListTileBase';

const ChildrenWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'height',
})<{
    height: number;
}>(({ height }) => ({
    overflow: 'hidden',
    transition: 'all 0.5s',
    height: height,
}));
type SlidingListTileProps = {
    open: boolean;
    heading: string;
    onClick: (selected: boolean) => void;
    headerContent?: JSX.Element;
    selectId?: string;
    sizeChanged: boolean;
};
const SlidingBox: React.FCC<{ open: boolean; sizeChanged: boolean }> = ({
    open,
    sizeChanged,
    children,
}) => {
    const childWrapperRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {
        if (!childRef.current || !childWrapperRef.current) return;
        setHeight(open ? childRef.current.scrollHeight + 4 : 0);
    }, [open, sizeChanged]);

    return (
        <ChildrenWrapper ref={childWrapperRef} height={height} id="sliding-box">
            <div ref={childRef}>{children}</div>
        </ChildrenWrapper>
    );
};
export const SlidingListTile: React.FCC<SlidingListTileProps> = ({
    open,
    heading,
    children,
    onClick,
    headerContent,
    selectId,
    sizeChanged,
}) => {
    return (
        <ListTileBase
            headerContent={headerContent}
            heading={heading}
            id={selectId}
            onSelect={onClick}
            selected={open}
        >
            {children && (
                <SlidingBox open={open} sizeChanged={sizeChanged}>
                    {children}
                </SlidingBox>
            )}
        </ListTileBase>
    );
};
