import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { GivelifyRadio, GivelifyRadioProps } from '../radio';
import { OmitFields } from './types';

export type GivelifyFormRadioProps<Form = Record<string, unknown>> = Omit<
    GivelifyRadioProps,
    OmitFields
> & {
    name: keyof Form;
    value: string | boolean;
    trackName?: string;
};

export const GivelifyFormRadio = <Form,>(
    props: GivelifyFormRadioProps<Form>,
) => {
    const { control, watch } = useFormContext();

    const watchValue = watch(props.name);

    return (
        <Controller
            control={control}
            name={props.name}
            render={({ field: { name, onChange } }) => {
                return (
                    <GivelifyRadio
                        {...props}
                        checked={props.value === watchValue}
                        name={props?.trackName || name}
                        onChange={() => {
                            onChange(props.value);
                        }}
                    />
                );
            }}
        />
    );
};
