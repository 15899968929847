import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    GivelifyLabel,
    GivelifySnackbar,
    GivelifySelect,
} from '@givelify/givelify-ui';
import { GivelifyFormCheckbox } from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import { SnackbarCloseReason, Typography } from '@material-ui/core';
import Space from 'components/Space';
import { IntegrationTypes } from 'pages/integrations/types';
import {
    ACS,
    formatIntegrationVersionFilter,
    SHELBY,
} from 'pages/integrations/utils/integrationFormatFilterUtils';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState, useAppDispatch } from 'store';
import {
    editScheduledReport,
    newScheduledReport,
} from 'store/integration/thunks';
import {
    BI_WEEKLY,
    DATE_RANGE_TYPE,
    MONDAY,
    MONTHLY,
    parseBaseOnToString,
    parseStringToBaseOn,
    ScheduledReport,
    Weekday,
    WEEKLY,
    DONOR_MATCH_BY,
    NAME,
} from 'store/integration/types';
import { setIntegrationSettingsFunc } from 'store/integrations/actions';
import { ReportType } from 'store/report/types';
import {
    INTEGRATIONS_CREATE,
    INTEGRATIONS_UPDATE,
} from 'utils/clevertapEvents';
import { isFeatureEnabled, Features } from 'utils/featureGating';
import roleTypes from '../../../../constants/roleTypes';
import IntegrationSetupBaseOn from './IntegrationSetupBaseOn';
import IntegrationSetupButton from './IntegrationSetupButton';
import IntegrationSetupFrequency from './IntegrationSetupFrequency';
import SelectIntegrationVersion from './SelectIntegrationVersion';
import { useStyles } from './style';
import determineCadenceSubSelectType from './utils/determineCadenceSubSelectType';
import scheduledReportDefaultValues from './utils/scheduledReportDefaultValues';

export interface IntegrationSetupProps {
    integration?: IntegrationTypes;
    doneCallBack?: (scheduleReport: ScheduledReport) => void;
    savedSetting?: (tab: number) => void;
    closeEditing?: () => void;
}

const IntegrationSetup: React.FCC<IntegrationSetupProps> = ({
    doneCallBack,
    integration,
    savedSetting,
}) => {
    const { trackEvent } = useTrackingContext();
    const dispatch = useAppDispatch();
    const {
        ReportIntegration: { scheduledReport },
        doneeId,
        reportExists,
        enabledFeatures,
        userRole,
    } = useSelector((state: AppState) => ({
        ReportIntegration: state.ReportIntegration,
        doneeId: state.Donee.donee.id,
        reportExists: !!state.ReportIntegration.scheduledReport.id,
        enabledFeatures: state.System.enabledFeatures,
        userRole: state.User.user.role,
    }));

    const [error, setError] = useState<string>('');
    const [showGapMessage, setShowGapMessage] = useState(false);

    const { oftenTitle, checkReceive, notificationCheckbox } = useStyles();

    const { t: trans } = useTranslation();
    const t = useCallback(
        (key, d?, options?) =>
            trans(`pages.integrations.content.setup-dialog.${key}`, d, options),
        [trans],
    );
    const copy = useMemo(
        () => ({
            integrationForm: {
                sendNotifications: t('integration-form.send-notifications'),
                reconcileHowOften: t('integration-form.reconcile_how_often'),
                matchYourDonor: t('integration-form.match-your-donor'),
                currentReportSchedule: t(
                    'integration-form.current_report_schedule',
                ),
                reportSchedule: reportExists
                    ? t(
                          `integration-form.report_schedule.${scheduledReport.frequency.toLowerCase()}`,
                          {
                              frequency: scheduledReport.frequency,
                              scheduledDay: scheduledReport.scheduledDay,
                              scheduledDate: scheduledReport.scheduledDate,
                          },
                      )
                    : '',
            },
            labels: {
                save: trans('labels.save'),
                create: trans('labels.create'),
                cancel: trans('labels.cancel'),
                continue: trans('labels.continue'),
            },
            savedSuccessful: t('integration-form.saveSuccess'),
            gapMessage: t('integration-form.gapMessage'),
            donorMatches: DONOR_MATCH_BY.map((data) => ({
                donorMatch: data,
            })),
            matchDonor: trans('pages.integrations.settings.matchDonor'),
        }),
        [
            t,
            trans,
            reportExists,
            scheduledReport.frequency,
            scheduledReport.scheduledDay,
            scheduledReport.scheduledDate,
        ],
    );

    const [formChange, setFormChange] = useState<boolean>(false);

    const form = useFormContext<ScheduledReport>();
    const {
        setValue,
        trigger,
        formState: { isDirty },
    } = form;

    useEffect(() => {
        if (isDirty) {
            setFormChange(true);
        }
    }, [isDirty]);

    useEffect(() => {
        const formReset = () => {
            form.reset(scheduledReport);
            form.register('reportType');
            form.register('doneeId', { value: doneeId });
            form.register('scheduledDate', { value: null });
            form.register('matchBy', { value: NAME });
            form.register('scheduledDay', { value: MONDAY });
            form.register('frequency', { value: WEEKLY });
            form.register('dateRangeType', {
                value: DATE_RANGE_TYPE.DONATION,
            });
        };

        formReset();
        form.trigger();
        // eslint-disable-next-line
    }, [scheduledReport, doneeId]);

    const [integrationVersion, setIntegrationSubVersion] =
        useState<ReportType>();

    const onChangeType = (value: ACS | SHELBY) => {
        setIntegrationSubVersion(formatIntegrationVersionFilter(value));
    };

    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [matchBySelected, setMatchBySelected] = useState<
        ScheduledReport['matchBy']
    >(scheduledReport.matchBy);
    const [frequencySelected, setFrequencySelected] = useState<
        ScheduledReport['frequency']
    >(scheduledReport.frequency);
    const [daySelected, setDaySelected] = useState<Weekday>(
        scheduledReport.scheduledDay || MONDAY,
    );
    const [dateSelected, setDateSelected] = useState<number>(
        scheduledReport.scheduledDate || 1,
    );

    const onCancel = () => {
        savedSetting && savedSetting(0);
    };

    const cadenceSubSelectType = useMemo(() => {
        if (frequencySelected) {
            return determineCadenceSubSelectType(frequencySelected);
        }
        return 'day';
    }, [frequencySelected]);

    const resetFormDates = useCallback(
        (frequency: ScheduledReport['frequency']) => {
            setFrequencySelected(frequency);
            const date = frequency === MONTHLY ? 1 : null;
            setValue('scheduledDate', date);

            const shouldShowDay =
                frequency === WEEKLY || frequency === BI_WEEKLY;
            if (!shouldShowDay) {
                setValue('scheduledDay', null);
            } else {
                const currentScheduledDay = form.getValues().scheduledDay;
                const shouldSetDefaultValue = !currentScheduledDay;
                if (shouldSetDefaultValue) {
                    setValue(
                        'scheduledDay',
                        scheduledReport?.scheduledDay || MONDAY,
                    );
                }
            }

            trigger();
            setFormChange(true);
        },
        [form, setValue, trigger, setFormChange, scheduledReport],
    );
    const [baseOn, setBaseOn] = useState<DATE_RANGE_TYPE>(
        DATE_RANGE_TYPE.DONATION,
    );
    const onBaseChange = (value: DATE_RANGE_TYPE) => {
        setBaseOn(value);
        setFormChange(true);
    };
    const onFormChange = () => {
        setFormChange(true);
    };
    const onMatchByChange = (value: {
        key: React.ReactText;
        value: string;
    }) => {
        setFormChange(true);
        setMatchBySelected(value.value);
    };
    const onChangeDaySelected = (value: Weekday) => setDaySelected(value);
    const onChangeDateSelected = (value: number) => setDateSelected(value);

    const defaultBaseOn = reportExists
        ? parseStringToBaseOn(scheduledReport.dateRangeType)
        : DATE_RANGE_TYPE.DONATION;

    const isReadOnly =
        userRole !== roleTypes.ADMIN && userRole !== roleTypes.FINANCIAL;

    const convertMatchBy = (value: string) => {
        const optionValue = value?.split('_').join(' ');
        return optionValue?.charAt(0)?.toUpperCase() + optionValue?.slice(1);
    };
    const formSubmit = async (values: ScheduledReport) => {
        setShowNotification(false);
        setShowGapMessage(false);

        try {
            const frequency = frequencySelected || WEEKLY;
            const defaultDay =
                frequency === 'Weekly' || frequency === 'Bi-Weekly'
                    ? MONDAY
                    : null;
            const cleanedValues: ScheduledReport = {
                ...values,
                name: integration.name,
                reportType: integrationVersion || integration.integrationType,
                doneeId: doneeId,
                frequency,
                receivesNotifications:
                    //TODO: strange peace of code
                    //eslint-disable-next-line
                    (values.receivesNotifications as any) === 'true' ||
                    values.receivesNotifications === true,
                dateRangeType: parseBaseOnToString(baseOn),
                scheduledDay:
                    frequency === WEEKLY || frequency === BI_WEEKLY
                        ? daySelected
                        : defaultDay,
                scheduledDate: frequency === MONTHLY ? dateSelected : null,
                matchBy: matchBySelected?.replace(' ', '_')?.toLowerCase(),
            };

            if (reportExists) {
                const frequencyChanged =
                    scheduledReport.frequency !== 'None' &&
                    cleanedValues.frequency !== 'None' &&
                    scheduledReport.frequency !== cleanedValues.frequency;
                const dayChanged =
                    !!cleanedValues.scheduledDay &&
                    scheduledReport.scheduledDay !== cleanedValues.scheduledDay;
                const dateChanged =
                    !!cleanedValues.scheduledDate &&
                    scheduledReport.scheduledDate !==
                        cleanedValues.scheduledDate;

                await editScheduledReport(
                    scheduledReport.id,
                    cleanedValues,
                )(dispatch);

                trackEvent(INTEGRATIONS_UPDATE, cleanedValues);

                setFormChange(false);
                setShowNotification(true);
                setShowGapMessage(
                    frequencyChanged ||
                        dayChanged ||
                        dateChanged ||
                        matchBySelected
                        ? true
                        : false,
                );
                dispatch(setIntegrationSettingsFunc(true));
            } else {
                await newScheduledReport(cleanedValues)(dispatch);
                trackEvent(INTEGRATIONS_CREATE, cleanedValues);
            }
            if (doneCallBack) {
                doneCallBack(values);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <form onSubmit={form.handleSubmit(formSubmit)}>
            <Space gap={2} />
            {error && (
                <Typography color="error" variant="button">
                    {error}
                </Typography>
            )}
            {!reportExists &&
                (integration.generalIntegrationType === 'ACS' ||
                    integration.generalIntegrationType === 'SHELBY') && (
                    <SelectIntegrationVersion
                        integration={integration}
                        isReadOnly={isReadOnly}
                        onChange={onChangeType}
                    />
                )}
            <IntegrationSetupBaseOn
                defaultBaseOn={defaultBaseOn}
                isReadOnly={isReadOnly}
                onBaseChange={onBaseChange}
                reportExists={reportExists}
                scheduledReport={scheduledReport}
            />

            {isFeatureEnabled(
                enabledFeatures,
                Features.INTEGRATION_SCHEDULING,
            ) && (
                <>
                    <GivelifyLabel
                        bold
                        className={oftenTitle}
                        text={copy.integrationForm.reconcileHowOften}
                        variant="body1"
                    />
                    <IntegrationSetupFrequency
                        cadenceSubSelectType={cadenceSubSelectType}
                        isReadOnly={isReadOnly}
                        onChangeDateSelected={onChangeDateSelected}
                        onChangeDaySelected={onChangeDaySelected}
                        onDateChange={onFormChange}
                        reportExists={reportExists}
                        resetFormDates={resetFormDates}
                        scheduledReport={scheduledReport}
                    />

                    {isFeatureEnabled(
                        enabledFeatures,
                        Features.INTEGRATION_ACS_REALM,
                    ) && (
                        <>
                            <GivelifyLabel
                                bold
                                className={oftenTitle}
                                text={copy.integrationForm.matchYourDonor}
                                variant="body1"
                            />
                            <GivelifySelect
                                ariaLabel="Name or Envelope number"
                                defaultValue={
                                    convertMatchBy(scheduledReport?.matchBy) ||
                                    NAME
                                }
                                disabled={isReadOnly}
                                id="matchBy"
                                inputLabel="Name or Envelope number"
                                label={copy.matchDonor}
                                marginTop={8}
                                name="matchBy"
                                onChange={onMatchByChange}
                                options={copy.donorMatches.map((data) => ({
                                    key: data.donorMatch,
                                    value: data.donorMatch,
                                }))}
                                size="dense"
                                width={250}
                            />
                        </>
                    )}

                    <div className={`${checkReceive} ${notificationCheckbox}`}>
                        <GivelifyFormCheckbox<ScheduledReport>
                            disabled={isReadOnly}
                            name="receivesNotifications"
                        />
                        <GivelifyLabel
                            text={copy.integrationForm.sendNotifications}
                            variant="body1"
                        />
                    </div>
                </>
            )}
            <Space gap={5} />

            <IntegrationSetupButton
                cancel={onCancel}
                formChange={formChange}
                isReadOnly={isReadOnly}
                reportExists={reportExists}
            />
            <GivelifySnackbar
                autoHideDuration={10000}
                message={`${copy.savedSuccessful} ${
                    showGapMessage ? copy.gapMessage : ''
                }`}
                onClose={(
                    _: React.SyntheticEvent<unknown, Event>,
                    reason: SnackbarCloseReason,
                ) => {
                    if (reason === 'clickaway') return;
                    setShowNotification(false);
                }}
                open={showNotification}
            />
        </form>
    );
};

const IntegrationSetupFormWrapper: React.FCC<IntegrationSetupProps> = (
    props,
) => {
    const {
        ReportIntegration: { scheduledReport },
        doneeId,
    } = useSelector((state: AppState) => ({
        ReportIntegration: state.ReportIntegration,
        doneeId: state.Donee.donee.id,
    }));

    const defaultValues = useMemo(
        () =>
            scheduledReportDefaultValues(
                scheduledReport,
                doneeId,
                props.integration ? props.integration.integrationType : 'ACS',
            ) as ScheduledReport,
        [scheduledReport, doneeId, props.integration],
    );

    const form = useForm<ScheduledReport>({
        mode: 'onChange',
        defaultValues,
    });

    return (
        <FormProvider {...form}>
            <IntegrationSetup {...props} />
        </FormProvider>
    );
};

export default IntegrationSetupFormWrapper;
