import { GivelifyForm } from '@givelify/ui';
import { styled } from '@mui/material';
import { FormInput } from './hooks';

export const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('mobile')]: {
        padding: 0,
    },
}));

export const Tools = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
    },
}));

export const RoundBox = styled('div')(({ theme }) => ({
    border: '1px solid #E3E3E3',
    borderRadius: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '160px',
    height: '88px',
    [theme.breakpoints.down('mobile')]: {
        width: 279,
    },
    '&:hover': {
        cursor: 'pointer',
        '&link': {
            color: '#104C84',
            textDecoration: 'underline',
        },
    },
}));

export const FormContainer = styled(GivelifyForm<FormInput>)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(3),
}));
