import React from 'react';
import { ApiHandler } from '@givelify/api';
import { useApiRequest, useMountedState } from '@givelify/utils';
import { useTheme, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useBlocker, Location } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setDoneePrimaryRep } from 'store/donee/actions';
import {
    PrimaryRepAddressForm,
    PrimaryRepInfoForm,
    PrimaryRepNameForm,
    PrimaryRepresentativeInfoStatus,
    apiHiddenSsnRegex,
    apiHiddenSsnRegex11Digits,
} from '../types';
import { AddressStepFormRef } from './addressStep/AddressStepForm';
import { InfoStepFormRef } from './infoStep/InfoStepForm';
import { NameStepFormRef } from './nameStep/NameStepForm';

export type RepEditorStep = 'name' | 'information' | 'address' | 'summary';

export type PrimaryRepEditorProps = {
    doneeId: number;
    nameTitle: PrimaryRepNameForm;
    information: PrimaryRepInfoForm;
    userDetails: Partial<PrimaryRepNameForm>;
    address: PrimaryRepAddressForm;
    mode: PrimaryRepresentativeInfoStatus;
    onCancel: (location?: Location | null) => void;
    onEditDone: () => void;
};

export type PrimaryRepEditorRef = {
    isEditing: () => boolean;
    showNotification: (location?: Location | null | string) => void;
};

export const usePrimaryRepEditor = (
    props: PrimaryRepEditorProps,
    ref: React.ForwardedRef<PrimaryRepEditorRef>,
) => {
    const {
        doneeId,
        information,
        nameTitle,
        address,
        mode,
        onCancel: onEditCancel,
        onEditDone,
    } = props;
    const isMounted = useMountedState();
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            name: t(
                'pages.settings.bank-info.customize-rep-tab.editor.steps.name',
            ),
            infomation: t(
                'pages.settings.bank-info.customize-rep-tab.editor.steps.information',
            ),
            address: t(
                'pages.settings.bank-info.customize-rep-tab.editor.steps.address',
            ),
            summary: t(
                'pages.settings.bank-info.customize-rep-tab.editor.steps.summary',
            ),
            back: t('labels.back'),
        }),
        [t],
    );
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const [prevStep, setPrevStep] = React.useState<RepEditorStep>(
        mode === 'add' ? 'name' : 'summary',
    );
    const [activeStep, setActiveStep] = React.useState<RepEditorStep>(
        mode === 'add' ? 'name' : 'summary',
    );
    const [primaryRepSubmitRequest, makePrimaryRepSubmitRequest] =
        useApiRequest<unknown>();
    const [nameStepData, setNameStepData] = React.useState(nameTitle);
    const [infoStepData, setInfoStepData] = React.useState(information);
    const [addressStepData, setAddressStepData] = React.useState(address);
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [loadingState, setLoadingState] = React.useState<
        'loading' | 'success'
    >('loading');
    const [loadingAnimationState, setLoadingAnimationState] = React.useState<
        'idle' | 'playing' | 'completed'
    >('idle');
    const [loadingTimerIsOn, setLoadingTimerIsOn] =
        React.useState<NodeJS.Timeout | null>(null);
    const [cancelLocation, setCancelLocation] = React.useState<null | Location>(
        null,
    );
    const nameFormRef = React.useRef<NameStepFormRef>(null);
    const infoFormRef = React.useRef<InfoStepFormRef>(null);
    const addresFormRef = React.useRef<AddressStepFormRef>(null);
    const onAddressStepBack = React.useCallback(
        (data: PrimaryRepAddressForm) => {
            setAddressStepData(data);
            setActiveStep('name');
            setPrevStep('address');
        },
        [],
    );
    const onInfoStepBack = React.useCallback((data: PrimaryRepInfoForm) => {
        setInfoStepData(data);
        setActiveStep('address');
        setPrevStep('information');
    }, []);
    const onSummaryStepBack = React.useCallback(() => {
        setActiveStep('information');
    }, []);
    const onNameSubmitClick = React.useCallback(
        (data: PrimaryRepNameForm) => {
            setNameStepData(data);
            setActiveStep(prevStep === 'summary' ? 'summary' : 'address');
        },
        [prevStep],
    );
    const onAddressSubmitClick = React.useCallback(
        (data: PrimaryRepAddressForm) => {
            setAddressStepData(data);
            setActiveStep(prevStep === 'summary' ? 'summary' : 'information');
        },
        [prevStep],
    );
    const onInfoSubmitClick = React.useCallback((data: PrimaryRepInfoForm) => {
        setInfoStepData(data);
        setActiveStep('summary');
    }, []);
    const onConfirmDialogClose = React.useCallback(() => {
        setShowConfirmDialog(false);
        setCancelLocation(null);
    }, []);
    const onCancelConfirm = React.useCallback(() => {
        setShowConfirmDialog(false);
        onEditCancel(cancelLocation);
    }, [cancelLocation, onEditCancel]);
    const nameIsDirty = React.useCallback(() => {
        return (
            nameTitle.firstName !== nameStepData.firstName ||
            nameTitle.lastName !== nameStepData.lastName ||
            nameTitle.title !== nameStepData.title
        );
    }, [
        nameStepData.firstName,
        nameStepData.lastName,
        nameStepData.title,
        nameTitle.firstName,
        nameTitle.lastName,
        nameTitle.title,
    ]);
    const infoIsDirty = React.useCallback(() => {
        return (
            information.ssn !== infoStepData.ssn ||
            (infoStepData.dateOfBirth && !information.dateOfBirth) ||
            (infoStepData.dateOfBirth &&
                !dayjs(information.dateOfBirth).isSame(
                    infoStepData.dateOfBirth,
                    'date',
                ))
        );
    }, [
        infoStepData.dateOfBirth,
        infoStepData.ssn,
        information.dateOfBirth,
        information.ssn,
    ]);
    const addressIsDirty = React.useCallback(() => {
        return (
            address.city !== addressStepData.city ||
            address.street !== addressStepData.street ||
            address.state !== addressStepData.state ||
            address.zip !== addressStepData.zip ||
            address.phoneNumber !== addressStepData.phoneNumber
        );
    }, [
        address.city,
        address.phoneNumber,
        address.state,
        address.street,
        address.zip,
        addressStepData.city,
        addressStepData.phoneNumber,
        addressStepData.state,
        addressStepData.street,
        addressStepData.zip,
    ]);
    const isEditing = React.useCallback((): boolean => {
        return (
            nameFormRef.current?.isEditing() ||
            infoFormRef.current?.isEditing() ||
            addresFormRef.current?.isEditing() ||
            nameIsDirty() ||
            infoIsDirty() ||
            addressIsDirty()
        );
    }, [addressIsDirty, infoIsDirty, nameIsDirty]);
    const showNotification = React.useCallback((location: Location) => {
        setCancelLocation(location);
        setShowConfirmDialog(true);
    }, []);
    const onSummaryCancelClick = React.useCallback(() => {
        if (isEditing()) {
            setShowConfirmDialog(true);
        } else {
            onEditCancel();
        }
    }, [isEditing, onEditCancel]);
    const onFormCancelClick = React.useCallback(() => {
        if (isEditing()) {
            setShowConfirmDialog(true);
        } else {
            if (mode === 'update') {
                setActiveStep('summary');
            } else {
                onEditCancel();
            }
        }
    }, [isEditing, mode, onEditCancel]);
    const onNameEditClick = React.useCallback(() => {
        setActiveStep('name');
        setPrevStep('summary');
    }, []);
    const onInfoEditClick = React.useCallback(() => {
        setActiveStep('information');
        setPrevStep('summary');
    }, []);
    const onAddressEditClick = React.useCallback(() => {
        setActiveStep('address');
    }, []);
    const onSubmit = React.useCallback(() => {
        makePrimaryRepSubmitRequest(
            ApiHandler.instance.donees.updatePrimaryRepresentative(doneeId, {
                city: addressStepData.city,
                state: addressStepData.state,
                zip: addressStepData.zip,
                phoneNumber: addressStepData.phoneNumber,
                address: addressStepData.street,
                firstName: nameStepData.firstName,
                lastName: nameStepData.lastName,
                title: nameStepData.title,
                isPrimaryRepresentative: nameStepData.isPrimaryRepresentative
                    ? 1
                    : 0,
                dateOfBirth: infoStepData.dateOfBirth?.format('YYYY-MM-DD'),
                ssn: infoStepData.ssn.replace(/-/g, ''),
            }),
        );
    }, [
        makePrimaryRepSubmitRequest,
        doneeId,
        addressStepData.city,
        addressStepData.state,
        addressStepData.zip,
        addressStepData.phoneNumber,
        addressStepData.street,
        nameStepData.firstName,
        nameStepData.lastName,
        nameStepData.title,
        nameStepData.isPrimaryRepresentative,
        infoStepData.dateOfBirth,
        infoStepData.ssn,
    ]);
    React.useImperativeHandle(ref, () => ({
        isEditing,
        showNotification,
    }));
    useBlocker(({ nextLocation }) => {
        if (nextLocation.state && nextLocation.state.ignoreBlocker)
            return false;
        const shouldBlock = !cancelLocation && isEditing();
        if (shouldBlock) {
            showNotification(nextLocation);
        }
        return shouldBlock;
    });
    React.useEffect(() => {
        if (
            primaryRepSubmitRequest.type === 'REQUEST_START' &&
            loadingTimerIsOn === null
        ) {
            setLoadingAnimationState('playing');
            setLoadingState('loading');
            setLoadingTimerIsOn(
                setTimeout(() => {
                    if (isMounted) {
                        setLoadingAnimationState('completed');
                    }
                }, 4000),
            );
        } else if (
            primaryRepSubmitRequest.type === 'REQUEST_SUCCESS' &&
            loadingAnimationState === 'completed'
        ) {
            setLoadingState('success');
            setLoadingAnimationState('playing');
            setLoadingTimerIsOn(
                setTimeout(() => {
                    if (isMounted()) {
                        onEditDone();
                    }
                }, 600),
            );
            dispatch(
                setDoneePrimaryRep({
                    firstName: nameStepData.firstName,
                    lastName: nameStepData.lastName,
                    title: nameStepData.title,
                    isPrimaryRepresentative:
                        nameStepData.isPrimaryRepresentative,
                    dateOfBirth: infoStepData.dateOfBirth,
                    ssn: infoStepData.ssn,
                    submittedAt: new Date(),
                    address: addressStepData.street,
                    zip: addressStepData.zip,
                    state: addressStepData.state,
                    city: addressStepData.city,
                    phoneNumber: addressStepData.phoneNumber,
                    submittedByOfficialId: doneeId,
                    status: 'verified',
                    retryMessage: '',
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryRepSubmitRequest, loadingTimerIsOn, loadingAnimationState]);
    const isInitialSsn = React.useMemo(
        () =>
            apiHiddenSsnRegex.test(infoStepData.ssn) ||
            apiHiddenSsnRegex11Digits.test(infoStepData.ssn),
        [infoStepData.ssn],
    );

    return {
        activeStep,
        nameStepData,
        infoStepData,
        addressStepData,
        isInitialSsn,
        showConfirmDialog,
        loadingState,
        nameFormRef,
        infoFormRef,
        addresFormRef,
        onNameSubmitClick,
        onInfoSubmitClick,
        onAddressSubmitClick,
        onInfoStepBack,
        onSummaryStepBack,
        onAddressStepBack,
        onNameEditClick,
        onInfoEditClick,
        onAddressEditClick,
        onSummaryCancelClick,
        onFormCancelClick,
        onConfirmDialogClose,
        onCancelConfirm,
        onSubmit,
        nameIsDirty,
        infoIsDirty,
        addressIsDirty,
        copy,
        isSmallScreen,
        requestState: primaryRepSubmitRequest.type,
    } as const;
};
