import { ApiHandler, Donee, Onboarding } from '@givelify/api';
import {
    convertToDate,
    setDefaultStartDate,
    defaultStartDate,
    getAxiosClient,
    makeApiRequest,
    ApiResponse,
} from '@givelify/utils';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { ThunkAction } from 'redux-thunk';
import { AppActions, AppState } from 'store';
import { EditUserInfo } from 'types/userTypes';
import { httpPatch } from '../thunks';
import {
    setDoneeAccountOwner,
    setDoneeAndCampuses,
    setDoneeFaithLeader,
} from './actions';
import { AccountOwnerInfo } from './types';

const identifyUserToFullStory = (donee: Donee) => {
    const fs = window.FS;
    if (!fs) return;
    fs.identify(donee.id.toString(), {
        displayName: `${donee.id} | ${donee.name}`,
        email: donee.email,
        // suffix (_bool, _str) is required by FullStory, not really sure why!
        // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Recording-custom-user-data
        onboardingCompleted_bool: donee.onboarding.hasCompleted,
        organizationType_str: donee.type,
        signupDate_date: new Date(donee.signupDate),
        city_str: donee.city,
        state_str: donee.state,
        country_str: donee.country,
        platform_str: 'studio',
    });
};

const mapResponseToDoneeOnboarding = (onboarding: Onboarding): Onboarding => {
    return {
        ...onboarding,
        midDateAdded: onboarding.midDateAdded
            ? convertToDate(onboarding.midDateAdded)
            : null,
        bankInfo: {
            ...onboarding.bankInfo,
            submittedAt: onboarding.bankInfo?.submittedAt
                ? convertToDate(onboarding.bankInfo.submittedAt)
                : null,
        },
        ein: {
            ...onboarding.ein,
            submittedAt: onboarding.ein.submittedAt
                ? convertToDate(onboarding.ein.submittedAt)
                : null,
        },
        primaryRepresentative: {
            ...onboarding.primaryRepresentative,
            submittedAt: onboarding.primaryRepresentative.submittedAt
                ? convertToDate(onboarding.primaryRepresentative.submittedAt)
                : null,
            dateOfBirth: onboarding.primaryRepresentative.dateOfBirth
                ? dayjs(onboarding.primaryRepresentative.dateOfBirth)
                : null,
        },
    };
};

const mapResponseToDonee = (donee: Donee): Donee => {
    return {
        ...donee,
        onboarding: mapResponseToDoneeOnboarding(donee['onboarding']),
        createdAt: donee.createdAt
            ? convertToDate(donee.createdAt)
            : defaultStartDate,
        updatedAt: donee.updatedAt
            ? convertToDate(donee.updatedAt)
            : defaultStartDate,
        signupDate: donee.signupDate
            ? convertToDate(donee.signupDate)
            : defaultStartDate,
        lastDonationDate: donee.lastDonationDate
            ? convertToDate(donee.lastDonationDate)
            : null,
    };
};

const mapResponseToCampuses = (donee: Donee): Donee[] => {
    const rawCampuses = donee.campuses;
    let result = [mapResponseToDonee(donee)];
    if (rawCampuses) {
        const campuses = rawCampuses.map((campus) => {
            return mapResponseToDonee(campus);
        });
        result = result.concat(campuses);
    }
    return result;
};

export const loadDonee =
    (
        keepSelectedCampus = false,
        defaultDoneeId?: number,
    ): ThunkAction<Promise<Donee | null>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const selectedDoneeId = getState().Donee.donee?.id;
        const doneeResponse =
            await ApiHandler.instance.donees.getCurrentDonee();
        if (!doneeResponse.success) return null;
        const campuses = mapResponseToCampuses(doneeResponse.response);
        let donee = keepSelectedCampus
            ? campuses.find((c) => c.id === selectedDoneeId)
            : campuses[0];
        if (!keepSelectedCampus && defaultDoneeId && defaultDoneeId > 0) {
            const defaultDonee = campuses.find((c) => c.id === defaultDoneeId);
            if (defaultDonee) {
                donee = defaultDonee;
            }
        }
        setDefaultStartDate(donee.createdAt.toString());
        dispatch(setDoneeAndCampuses(donee, campuses));
        identifyUserToFullStory(donee);
        return donee;
    };

export const resendInviteEmail =
    (
        doneeId: number,
        userId: number,
    ): ThunkAction<
        Promise<ApiResponse<Donee>>,
        AppState,
        undefined,
        AppActions
    > =>
    async () => {
        const httpRequest = getAxiosClient().post(
            `/donees/${doneeId}/users/${userId}/resend-invite`,
        );

        return makeApiRequest<Donee>(httpRequest);
    };

export const makeFaithLeader =
    (
        userId: number,
    ): ThunkAction<Promise<void>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const state = getState();
        const doneeId = state.Donee?.donee?.id;
        const response =
            await ApiHandler.instance.donees.updateDoneeFaithLeader(
                doneeId,
                userId,
            );
        if (response.success) {
            const responseFaithLeader =
                response.response.onboarding.appProfile.faithLeader;
            dispatch(
                setDoneeFaithLeader(
                    responseFaithLeader,
                    new Date(response.response.updatedAt),
                ),
            );
        } else {
            throw new Error(response.error.message);
        }
    };

export const changeAccountOwner =
    (
        userData: EditUserInfo,
    ): ThunkAction<Promise<void>, AppState, undefined, AppActions> =>
    async (dispatch, getState) => {
        const state = getState();
        const doneeId = state.Donee?.donee?.id;

        /* eslint-disable-next-line */
        const response: AxiosResponse = await httpPatch(
            `/donees/${doneeId}/account-owner`,
            {
                account_owner_id: userData.id,
            },
        )(dispatch);

        if (response) {
            const responseAccountOwnerInfo = response.data.data;
            const accountOwnerInfo: AccountOwnerInfo = {
                userId: responseAccountOwnerInfo['id'],
                avatar: responseAccountOwnerInfo['avatar'],
                avatarCroppedCoordinates: null,
                avatarOriginal: responseAccountOwnerInfo['avatar'],
                firstName: responseAccountOwnerInfo['firstName'],
                lastName: responseAccountOwnerInfo['lastName'],
                title: responseAccountOwnerInfo['title'],
            };

            dispatch(
                setDoneeAccountOwner(
                    accountOwnerInfo,
                    new Date(response.data['updatedAt']),
                ),
            );
        }
    };
