import React, { useMemo } from 'react';
import {
    TableVirtuoso,
    TableComponents,
    TableProps,
    TableBodyProps,
} from 'react-virtuoso';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { mergeClassNames } from '../utils/classNameUtils';
import Pagination from './Pagination';
import RenderHeaderCell from './RenderHeaderCell';
import RenderRow, { RenderRowPure } from './RenderRow';
import RowStyles from './RenderRow/styles';
import { GivelifyTableStyles } from './styles';
import { GivelifyTableColumn, GivelifyTablePagination } from './types';
import { SortDirection } from '@mui/material';

// How many rows should we display when loading data
const DEFAULT_LOADING_ROWS_NUMBER = 8;

export interface GivelifyTableInnerProps<T> extends GivelifyBoxMarginProps {
    keySelector: (item: T) => any;
    dataLoaded: boolean;
    data: T[];
    columns: GivelifyTableColumn<T>[];
    sortColumn?: keyof T;
    sortDirection?: SortDirection;
    onSort?: (sortDirection: SortDirection, column?: keyof T) => void;
    pagination?: GivelifyTablePagination;
    classes?: {
        root?: string;
        thead?: string;
        tbody?: string;
        tr?: string;
        th?: string;
        td?: string;
        trHead?: string;
    };
    className?: string;
    useVirtualList?: boolean;
    rowId?: string;
}

function TableInner<T>(props: GivelifyTableInnerProps<T>) {
    const {
        dataLoaded,
        data,
        keySelector,
        columns,
        onSort,
        sortColumn,
        sortDirection,
        pagination,
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        classes,
        className,
        useVirtualList,
        rowId,
    } = props;

    const { boxMargin, tbTable, tbScroller } = GivelifyTableStyles({
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
    });

    const tableClassName = mergeClassNames(
        boxMargin,
        tbTable,
        classes?.root,
        className,
    );

    // To make fixed columns work me need to set left offset
    // It should be the sum of all previous fixed columns width
    // So for the 1st fiexd column it should be 0
    // For the 2nd fixed columns it should be width of the 1st
    // For the 3rd it should be the sum of 1st and 2nd
    const offset = useMemo(() => {
        const result: number[] = [];
        columns.reduce((accumulator, currentValue) => {
            if (!currentValue.fixed) {
                result.push(0);
                return accumulator;
            }

            result.push(accumulator);
            const sum = accumulator + Number(currentValue.width);
            return sum;
        }, 0);

        return result;
    }, [columns]);

    const loadingRowsStub = useMemo(() => {
        const ids = Array(
            pagination?.rowsPerPage || DEFAULT_LOADING_ROWS_NUMBER,
        ).keys();
        return Array.from(ids).map((item) => {
            const rowKey = `row-loading-${item}`;
            return (
                <RenderRow<T>
                    key={rowKey}
                    rowKey={rowKey}
                    item={undefined}
                    offset={offset}
                    columns={columns}
                    loading={true}
                    rowId={rowId ? `${rowId}-${item}` : ''}
                />
            );
        });
    }, [offset, rowId, columns, pagination?.rowsPerPage]);

    const items = useMemo(() => {
        if (useVirtualList) return null;
        if (!dataLoaded) {
            return loadingRowsStub;
        }

        return data.map((item) => {
            const rowKey = `row-${keySelector(item)}`;
            return (
                <RenderRow
                    key={rowKey}
                    rowKey={rowKey}
                    item={item}
                    offset={offset}
                    columns={columns}
                    rowId={rowId ? `${rowId}-${keySelector(item)}` : ''}
                />
            );
        });
    }, [
        data,
        keySelector,
        dataLoaded,
        offset,
        columns,
        loadingRowsStub,
        useVirtualList,
        rowId,
    ]);
    if (useVirtualList) {
        const Table: any = React.forwardRef<any, TableProps>(
            ({ children, ...tableProps }, ref) => {
                return (
                    <table ref={ref} {...tableProps} className={tableClassName}>
                        {children}
                    </table>
                );
            },
        );
        const TableBody: any = React.forwardRef<any, TableBodyProps>(
            ({ children }, ref) => {
                return (
                    <tbody className={classes?.tbody} ref={ref}>
                        {children}
                    </tbody>
                );
            },
        );
        const TableRow: any = React.forwardRef<
            any,
            TableComponents['TableRow']
        >((props, ref) => {
            const { tbRow } = RowStyles();
            return <tr className={tbRow} ref={ref} {...props} />;
        });
        const TableHead: any = React.forwardRef<
            HTMLTableSectionElement,
            TableComponents['TableHead']
        >((props, ref) => (
            <thead className={classes?.thead} {...props} ref={ref} />
        ));
        const Scroller: any = React.forwardRef<
            any,
            TableComponents['Scroller']
        >((props, ref) => {
            return <div className={tbScroller} {...props} ref={ref} />;
        });
        return (
            <>
                <TableVirtuoso
                    style={{ overflow: 'auto' }}
                    components={{
                        Table: Table,
                        TableHead: TableHead,
                        TableBody: TableBody,
                        TableRow: TableRow,
                        Scroller: Scroller,
                    }}
                    data={data}
                    useWindowScroll
                    fixedHeaderContent={() => (
                        <tr>
                            {columns.map((column, columnIndex) => (
                                <RenderHeaderCell<T>
                                    key={`head-${columnIndex}`}
                                    column={column}
                                    columnIndex={columnIndex}
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    onSort={onSort}
                                    offset={offset}
                                    className={classes?.th}
                                />
                            ))}
                        </tr>
                    )}
                    itemContent={(_index, item) => {
                        if (!dataLoaded) {
                            return loadingRowsStub;
                        }
                        const rowKey = `row-${keySelector(item)}`;
                        return (
                            <RenderRowPure
                                key={rowKey}
                                rowKey={rowKey}
                                item={item}
                                offset={offset}
                                columns={columns}
                            />
                        );
                    }}
                />
                {pagination && <Pagination pagination={pagination} />}
            </>
        );
    }
    return (
        <>
            <div style={{ overflow: 'auto' }}>
                <table className={tableClassName}>
                    <thead className={classes?.thead}>
                        <tr>
                            {columns.map((column, columnIndex) => (
                                <RenderHeaderCell<T>
                                    key={`head-${columnIndex}`}
                                    column={column}
                                    columnIndex={columnIndex}
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    onSort={onSort}
                                    offset={offset}
                                    className={classes?.th}
                                />
                            ))}
                        </tr>
                    </thead>
                    <tbody className={classes?.tbody}>{items}</tbody>
                </table>
            </div>
            {pagination && <Pagination pagination={pagination} />}
        </>
    );
}
export default TableInner;
