import { useMemo } from 'react';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { Typography, } from '@material-ui/core';
import { styled } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { webConfig } from 'webConfig';
import logo from '../../assets/givelify-logo.svg';
import ResetPasswordForm from '../../components/forms/resetPassword/ResetPasswordForm';
import PageTitle from '../../components/PageTitle';
import { AppState } from '../../store';
import { isLoggedIn } from '../../store/user/types';

const PageWrapper = styled('div')(({ theme }) => ({
    marginLeft: 40,
    marginRight: 40,
    height: '100vh',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateColumns: 'minmax(max-content, 1200px)',
    justifyContent: 'center',
    [theme.breakpoints.down('mobile')]: {
        gridTemplateColumns: '1fr',
    },
}));
const Paper = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('mobile')]: {
        boxShadow: '0 0 4px rgba(48, 51, 52, 0.2)',
    },
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Logo = styled('img')(({ theme }) => ({
    width: 120,
    paddingTop: 24,
    paddingBottom: 24,
    [theme.breakpoints.down('mobile')]: {
        margin: '0 auto',
    },
}));

const Footer = styled('footer')(({ theme }) => ({
    marginTop: 32,
    marginBottom: 16,
    [theme.breakpoints.down('mobile')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const ResetPassword = () => {
    const { t } = useTranslation();
    const loggedIn = useSelector((state: AppState) => isLoggedIn(state));
    const { PATH } = useGasRouterContext();

    const resetPasswordText = useMemo(
        () => ({
            text: {
                page: t('reset_password.text.page'),
                givelifyLlc: t('login.text.givelify_llc'),
            },
            link: {
                signup: t('login.link.signup'),
            },
            copyright: t('footer.copyright'),
        }),
        [t],
    );

    if (loggedIn) {
        return <Navigate to={PATH.OVERVIEW} />;
    }

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.ResetPassword}>
            <GoogleReCaptchaProvider reCaptchaKey={webConfig.googleSiteKey}>
                <PageTitle
                    description={resetPasswordText.text.page}
                    title={resetPasswordText.text.page}
                />
                <PageWrapper>
                    <Logo alt="logo" src={logo} />
                    <Paper>
                        <ResetPasswordForm />
                    </Paper>
                    <Footer>
                        <Typography variant="caption">
                            {resetPasswordText.copyright}{' '}
                            {new Date().getFullYear()}{' '}
                            {resetPasswordText.text.givelifyLlc}
                        </Typography>
                    </Footer>
                </PageWrapper>
            </GoogleReCaptchaProvider>
        </TrackingProvider>
    );
};

export default ResetPassword;
