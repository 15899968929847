import React from 'react';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import { ReceiptTabPage } from './ReceiptTabPage';
import { useReceiptTab } from './useReceiptTab';

export const CustomizeReceiptTab: React.FCC = () => {
    const {
        hasFullAccess,
        onCancel,
        onChange,
        onSave,
        error,
        value,
        loading,
        dirty,
        doneeName,
        doneeType,
    } = useReceiptTab();
    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.SettingsAppProfileCustomizeReceipt}
        >
            <ReceiptTabPage
                dirty={dirty}
                disableActions={!hasFullAccess}
                doneeName={doneeName}
                doneeType={doneeType}
                error={error}
                isOn={value}
                onCancel={onCancel}
                onChange={onChange}
                onSave={onSave}
                saving={loading}
            />
        </TrackingProvider>
    );
};
