import React from 'react';
import { GivelifyFormDatePicker } from '@givelify/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SocialNumberInput } from '../../socialNumberInput';
import { PrimaryRepInfoForm } from '../../types';
import { PrimaryRepEditorFooter } from '../editorFooter';
import { InputsBox } from '../styles';
import { InfoStepNote } from './InfoStepNote';

export type InfoStepFormRef = {
    isEditing: () => boolean;
};

export type InfoStepFormProps = {
    onCancelClick: () => void;
    onBackClick: (data: PrimaryRepInfoForm) => void;
    replace?: boolean;
};

export const InfoStepForm = React.forwardRef<
    InfoStepFormRef,
    InfoStepFormProps
>(({ onCancelClick, onBackClick: onBack, replace }, ref) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            placeholder1: t(
                'pages.settings.bank-info.customize-rep-tab.editor.stepInfo.placeholder1',
            ),
            note: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.note',
            ),
        }),
        [t],
    );
    const {
        setValue,
        formState: { isValid, isDirty },
        getValues,
    } = useFormContext<PrimaryRepInfoForm>();
    React.useImperativeHandle(ref, () => ({
        isEditing: () => isDirty,
    }));
    const clearSsn = React.useCallback(() => {
        setValue('ssn', '', { shouldValidate: true });
    }, [setValue]);
    const onBackClick = React.useCallback(() => {
        onBack(getValues());
    }, [getValues, onBack]);
    return (
        <>
            <InfoStepNote>
                <InputsBox>
                    <GivelifyFormDatePicker<PrimaryRepInfoForm>
                        fullwidth
                        aria-label="BirthDate editor"
                        id="admin-form-birthDate"
                        label={copy.placeholder1}
                        name="dateOfBirth"
                        placeholder={copy.placeholder1}
                        size="large"
                    />
                    <SocialNumberInput clearSsn={clearSsn} replace={replace} />
                </InputsBox>
            </InfoStepNote>
            <PrimaryRepEditorFooter
                showBackButton
                canSubmit={isValid}
                onBackClick={onBackClick}
                onCancelClick={onCancelClick}
            />
        </>
    );
});
