import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { Stack } from '@mui/material';
import { FaithLeaderEditorInfo } from '../../../@types/assets/onboarding';
import { ListTileBase } from './ListTileBase';
import { ConstrainedLabel, HederContentWrapper } from './styles';

const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
};

type ListTileNamedProps = {
    faithLeader: FaithLeaderEditorInfo;
    uploadButton?: JSX.Element;
    selected: boolean;
    onSelect?: () => void;
};

export const ListTileNamed: React.FCC<ListTileNamedProps> = ({
    faithLeader,
    uploadButton,
    selected,
    onSelect,
}) => {
    const fullName = `${faithLeader.firstName} ${faithLeader.lastName}`;
    const truncatedName = truncateText(fullName, 28);
    const fullTitle = faithLeader.title;
    const truncatedTitle = truncateText(fullTitle, 28);
    const NameRow = () => (
        <ConstrainedLabel
            data-testid={`faith-name-${faithLeader.userId}`}
            display="flex"
            flexShrink={0}
            id={`faith-name-${faithLeader.userId}`}
            overflow="hidden"
            title={fullName}
            variant="body1B"
            whiteSpace="nowrap"
        >
            {truncatedName}
            {faithLeader.isYou && (
                <GivelifyLabel variant="body2">&nbsp;(you)</GivelifyLabel>
            )}
        </ConstrainedLabel>
    );
    return (
        <ListTileBase
            headerContent={
                <HederContentWrapper>
                    <Stack
                        display="flex"
                        flex={1}
                        justifyContent="center"
                        sx={{ overflow: 'hidden' }}
                    >
                        <NameRow />
                        <ConstrainedLabel
                            color={DesignTokens.color.neutralGrey}
                            data-testid={`faith-title-${faithLeader.userId}`}
                            id={`faith-title-${faithLeader.userId}`}
                            text={truncatedTitle}
                            title={fullTitle}
                            variant="caption1"
                        />
                    </Stack>
                    {uploadButton}
                </HederContentWrapper>
            }
            id={`select-faith-${faithLeader.userId}`}
            onSelect={onSelect}
            selected={selected}
        />
    );
};
