import { DesignTokens, GivelifyButton, GivelifyLabel } from '@givelify/ui';
import { Backdrop, Divider, Slide, styled } from '@mui/material';

export const FilterSlide = styled(Slide, {
    shouldForwardProp: (propName) => propName !== 'vh',
})<{ vh: number }>(({ vh, theme }) => ({
    background: DesignTokens.color.backgroundComponentToolTip,
    height: `${vh}px`,
    position: 'fixed',
    top: 0,
    right: 0,
    width: '420px',
    zIndex: 1203,
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
    },
}));

export const FilterBackdrop = styled(Backdrop)({
    zIndex: 1202,
});

export const FilterFormWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

export const FilterHeader = styled('div')(({ theme }) => ({
    padding: '24px 32px 32px',
    display: 'flex',
    minHeight: '90px',
    alignItems: 'center',
    borderBottom: `2px solid ${DesignTokens.color.globalNeutral800}`,
    boxSizing: 'border-box',
    [theme.breakpoints.down('mobile')]: {
        padding: '12px 16px',
        minHeight: '48px',
    },
}));

export const FilterBody = styled('div')(({ theme }) => ({
    height: '100%',
    overflow: 'auto',
    padding: '14px 32px',
    [theme.breakpoints.down('mobile')]: {
        padding: '8px 16px',
    },
    '&:hover': {
        scrollbarWidth: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
            backgroundColor: DesignTokens.color.backgroundComponentToolTip,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: DesignTokens.color.backgroundComponentQuaternary,
        },
    },
    '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        backgroundColor: DesignTokens.color.backgroundComponentToolTip,
    },
    overflowY: 'scroll',
}));

export const FilterBottom = styled('div')(({ theme }) => ({
    padding: '24px 32px',
    borderTop: `2px solid ${DesignTokens.color.globalNeutral800}`,
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('mobile')]: {
        padding: '16px',
    },
}));

export const CloseButton = styled(GivelifyButton)({
    marginLeft: 'auto',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
});

export const UndoButton = styled(GivelifyButton)({
    padding: 0,
    display: 'flex',
    alignItems: 'center',
});

export const UndoButtonText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalButton1M.font.size,
    },
}));

export const CloseButtonText = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalButton1M.font.size,
    },
}));

export const DividerLine = styled(Divider)(({ theme }) => ({
    borderColor: DesignTokens.color.iconHover,
    margin: '0 16px',
    [theme.breakpoints.down('mobile')]: {
        margin: '0 12px',
    },
}));

export const ClearFilterButton = styled(GivelifyButton)(({ theme }) => ({
    color: DesignTokens.color.textWhite,
    '&:hover': {
        color: DesignTokens.color.textWhite,
    },
    '&:active': {
        color: DesignTokens.color.textWhite,
    },
    '&:disabled': {
        background: 'none',
        color: DesignTokens.color.textSecondary,
    },
    [theme.breakpoints.down('mobile')]: {
        color: DesignTokens.color.textWhite,
    },
}));

export const FilterPanelInputsWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'wrap',
})<{ wrap?: boolean }>(({ wrap, theme }) => ({
    width: '302px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    padding: '0 8px',
    boxSizing: 'content-box',
    flexWrap: wrap ? 'wrap' : 'nowrap',
    [theme.breakpoints.down('mobile')]: {
        gap: '8px',
        '&.giving-style-wrapper': {
            flexDirection: 'column',
        },
    },
    flexShrink: 0,
    flexGrow: 0,
}));

export const FilterPanelInputWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    '&.extended': {},
    [theme.breakpoints.down('mobile')]: {
        '&.extended': {
            width: '152px',
        },
        '& .MuiInputLabel-root': {
            fontSize: DesignTokens.textStyle.globalComponentLabel.font.size,
            lineHeight: `${DesignTokens.textStyle.globalComponentLabel.font.lineHeight}px`,
        },
        '& .MuiInputBase-root': {
            height: '40px',
            '& .MuiInputBase-input': {
                padding: '8px 0 8px 4px',
                fontSize: DesignTokens.textStyle.globalCaption1.font.size,
            },
        },
    },
    flex: 1,
}));

export const FilterPanelMessageInputsWrapper = styled('div')(({ theme }) => ({
    width: '305px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto auto',
    gap: '16px',
    padding: '0 8px',
    boxSizing: 'content-box',
    '&.filter-r1-c1': {
        gridRow: '1',
        gridColumn: '1',
    },
    '&.filter-r1-c2': {
        gridRow: '1',
        gridColumn: '2',
    },
    '&.filter-r2-c2': {
        gridRow: '2',
        gridColumn: '1 / span 2',
    },
    [theme.breakpoints.down('mobile')]: {
        gap: '8px',
        '&.filter-r1-c1': {
            gridRow: '1',
            gridColumn: '1 / span 2',
        },
        '&.filter-r1-c2': {
            gridRow: '2',
            gridColumn: '1 / span 2',
        },
        '&.filter-r2-c2': {
            gridRow: '3',
            gridColumn: '1 / span 2',
        },
    },
    flexShrink: 0,
    flexGrow: 0,
}));

export const FilterPanelInputBlock = styled('div')(({ theme }) => ({
    marginBottom: '30px',
    [theme.breakpoints.down('mobile')]: {
        marginBottom: '16px',
    },
}));

export const FilterPanelInputLabel = styled(GivelifyLabel)(({ theme }) => ({
    fontSize: '18px',
    marginBottom: '14px',
    [theme.breakpoints.down('mobile')]: {
        fontSize: DesignTokens.textStyle.globalComponentLabel.font.size,
        marginBottom: '8px',
    },
}));

export const FilterPanelDateInputsWrapper = styled(FilterPanelInputsWrapper)({
    width: '300px',
    '& .Donors-DatePicker': {
        minWidth: '100%',
    },
});

export const FilterPanelCheckboxWrapper = styled('div')({
    width: 'calc(45% - 8px)',
});

export const FilterPanelMessageFilterWrapper = styled('div')({
    width: '100%',
});

export const FilterPanelCheckboxWrapperFull = styled('div')({
    width: '100%',
    display: 'flex',
    gap: '12px',
    flexDirection: 'column',
});

export const FilterExpandButton = styled(GivelifyButton)(({ theme }) => ({
    padding: 0,
    marginRight: 'auto',
    width: 'auto',
}));
