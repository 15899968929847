import React, { useMemo } from 'react';
import { FaithLeader } from '@givelify/api';
import {
    GivelifyButton,
    GivelifyErrorBanner,
    GivelifyLabel,
    GivelifyModal,
} from '@givelify/givelify-ui';
import { GivelifyAvatar, GivelifyNotification } from '@givelify/ui';
import { useTranslation } from 'react-i18next';
import { EditUserInfo } from 'types/userTypes';
import { confirmationModalStyle } from './ConfirmationModalStyle';

interface MakeFaithLeaderModalProps {
    onMakeFaithLeaderModalClose: () => void;
    onMakeFaithLeader: () => void;
    userInfo: EditUserInfo;
    userAvatar: string;
    faithLeaderInfo: FaithLeader;
    makeFaithLeaderError?: string;
    isMakingFaithLeader?: boolean;
}

const MakeFaithLeaderModal: React.FCC<MakeFaithLeaderModalProps> = (
    props: MakeFaithLeaderModalProps,
) => {
    const { faithLeaderInfo, userInfo, userAvatar } = props;

    const style = confirmationModalStyle();

    const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
    const curFaithLeaderFullName = `${faithLeaderInfo.firstName} ${faithLeaderInfo.lastName}`;
    const isFaithLeader = faithLeaderInfo.userId === null;
    const { t } = useTranslation();
    const copy = useMemo(
        () => ({
            makeFaithLeader: t('pages.settings.users.make-faith-leader'),
            confirm: t('pages.settings.users.make-faith-leader-modal.confirm'),
            currentLeader: t(
                'pages.settings.users.make-faith-leader-modal.current-leader',
            ),
            newLeader: t(
                'pages.settings.users.make-faith-leader-modal.new-leader',
            ),
            confirmTip: t(
                'pages.settings.users.make-faith-leader-modal.confirm-tip',
            ),
            confirmDesc: t(
                'pages.settings.users.make-faith-leader-modal.confirm-desc',
            ),
            cancel: t('labels.cancel'),
            save: t('labels.change'),
        }),
        [t],
    );
    return (
        <GivelifyModal
            autoFullscreen
            open
            showCloseButton
            contentClassName={style.confirmationModal}
            name="delete-user-modal"
            onClose={props.onMakeFaithLeaderModalClose}
            size="small"
            width={702}
        >
            <div className={style.modalContainer}>
                <GivelifyLabel
                    className={style.statement}
                    marginTop={32}
                    text={copy.confirm}
                    variant="heading3"
                />
                <GivelifyLabel
                    className={style.desc}
                    marginTop={16}
                    text={copy.confirmDesc}
                    variant="body1"
                />
                {props.makeFaithLeaderError && (
                    <GivelifyErrorBanner
                        className={style.errorBanner}
                        marginTop={32}
                        text={props.makeFaithLeaderError}
                    />
                )}
                <div
                    style={{
                        display: isFaithLeader ? 'none' : 'block',
                    }}
                >
                    <GivelifyLabel
                        className={style.statement}
                        marginTop={32}
                        text={copy.currentLeader}
                        variant="heading4"
                    />
                    <div aria-label="User" className={style.usernameContainer}>
                        <div className={style.userAvatarWrapper}>
                            <GivelifyAvatar
                                color="grey"
                                src={faithLeaderInfo.avatar}
                            />
                        </div>
                        <div className={style.userNameContent}>
                            <GivelifyLabel
                                bold
                                className={style.fullName}
                                text={curFaithLeaderFullName}
                                variant="heading5"
                            />
                            <GivelifyLabel
                                className={style.title}
                                text={faithLeaderInfo.title}
                                variant="small"
                            />
                        </div>
                    </div>
                </div>

                <GivelifyLabel
                    className={style.statement}
                    marginTop={16}
                    text={copy.newLeader}
                    variant="heading4"
                />
                <div aria-label="User" className={style.usernameContainer}>
                    <div className={style.userAvatarWrapper}>
                        <GivelifyAvatar
                            color="grey"
                            size="medium"
                            src={userAvatar}
                        />
                    </div>
                    <div className={style.userNameContent}>
                        <GivelifyLabel
                            bold
                            className={style.fullName}
                            text={fullName}
                            variant="heading5"
                        />
                        <GivelifyLabel
                            className={style.title}
                            text={userInfo.title}
                            variant="small"
                        />
                    </div>
                </div>

                <GivelifyNotification
                    open
                    text={copy.confirmTip}
                    variant="warning"
                />

                <div className={style.modalFooter}>
                    <GivelifyButton
                        className={style.cancelButton}
                        disabled={props.isMakingFaithLeader}
                        marginRight={8}
                        name="cancelMakingFaithLeader"
                        onClick={props.onMakeFaithLeaderModalClose}
                        size="xLarge"
                        text={copy.cancel}
                        variant="secondary"
                    />
                    <GivelifyButton
                        className={style.saveButton}
                        disabled={props.isMakingFaithLeader}
                        isLoading={props.isMakingFaithLeader}
                        marginLeft={8}
                        name="saveMakingFaithLeader"
                        onClick={props.onMakeFaithLeader}
                        size="xLarge"
                        text={copy.save}
                        variant="primary"
                    />
                </div>
            </div>
        </GivelifyModal>
    );
};

export default MakeFaithLeaderModal;
