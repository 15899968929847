import React, { useMemo, useEffect, useState } from 'react';
import { AddressWithPhone } from '@givelify/givelify-ui';
import { GivelifyLabel } from '@givelify/ui';
import { mergeClassNames } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { ImageWithDimensions } from '../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../consts';
import { webConfig } from '../utils/webConfig';
import { profileFormGridStyles } from './CustomizeProfileStepOne/BannerImageSection';

interface OrganizationLogoPreviewProps {
    organizationName: string;
    organizationAddress: AddressWithPhone;
    organizationLogo: ImageWithDimensions | undefined;
    className?: string;
    organizationType: string;
}

export const OrganizationLogoPreview: React.FCC<
    OrganizationLogoPreviewProps
> = ({
    organizationName,
    organizationAddress,
    organizationLogo,
    className,
    organizationType,
}) => {
    const [defaultLogo, setDefaultLogo] = useState('');
    const { t } = useTranslation(I18N_NAMESPACE);
    const { organizationPreviewStyles } = profileFormGridStyles({
        top: true,
        bottom: false,
    });

    const { previewTopText } = useMemo(
        () => ({
            previewTopText: t(
                'customizeProfile.organizationLogoSection.searchPreview',
            ),
        }),
        [t],
    );

    useEffect(() => {
        const orgLogo =
            organizationType === 'nonprofit'
                ? `${webConfig.imageBaseUrl}/nonprofit-registered-logo@2x.png`
                : `${webConfig.imageBaseUrl}/worship-registered-logo@2x.png`;
        setDefaultLogo(orgLogo);
    }, [organizationType]);

    return (
        <div className={mergeClassNames(organizationPreviewStyles, className)}>
            <GivelifyLabel
                color="grey"
                marginBottom={1}
                text={previewTopText}
                variant="caption1"
            />
            <div className="preview">
                <img
                    alt="Logo"
                    className="organization-logo"
                    onError={(event) => {
                        event.currentTarget.src = defaultLogo;
                    }}
                    src={organizationLogo?.croppedUrl || defaultLogo}
                    title="Logo"
                />
                <div>
                    <GivelifyLabel text={organizationName} variant="body1" />
                    <GivelifyLabel
                        color="grey"
                        text={`${organizationAddress.street},`}
                        variant="body2"
                    />
                    <GivelifyLabel
                        className="inline"
                        color="grey"
                        text={`${organizationAddress.city}, `}
                        variant="body2"
                        whiteSpace="pre"
                    />
                    <GivelifyLabel
                        className="inline"
                        color="grey"
                        text={`${organizationAddress.state}, `}
                        variant="body2"
                        whiteSpace="pre"
                    />
                    <GivelifyLabel
                        className="inline"
                        color="grey"
                        text={organizationAddress.zip}
                        variant="body2"
                    />
                </div>
            </div>
        </div>
    );
};
