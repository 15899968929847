import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Sorting, SortingDirection } from '@devexpress/dx-react-grid';
import { FacebookLoading } from '@givelify/givelify-ui';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core';
import ContentCard from 'components/ContentCard';
import { TableColumnProps } from 'components/Tables/TableColumnTitle';
import { useAllIntegrations } from 'pages/integrations/IntegrationSetup';
import { useSelector } from 'react-redux';
import { AppState, useAppDispatch } from 'store';
import { GetMatchProgressAsync } from 'store/integrations/thunks';
import { useAdvancedTranslation } from 'utils/i18n';
import roleTypes from '../../../../../constants/roleTypes';
import { matchSelect } from '../../../utils/integrationFormatFilterUtils';
import MatchBottomContent from '../common/MatchBottomContent';
import MatchingHeader from '../common/MatchingHeader';
import DonorMatchingHeader from './components/DonorMatchingHeader';
import DonorMatchingInfiniteLoader from './DonorMatchingInfiniteLoader';

interface Prop {
    reportId?: number;
    handleClose?: () => void;
    doneMatch?: () => void;
}

/**
 * Desired page size for pagination
 */
const pageSize = 15;

const TRANSLATION_KEY = 'pages.integration_donor_matching';

export const Loading = (
    <FacebookLoading borderRadius={0} height={128} width="100%" />
);

export const useMatchingStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorRow: {
            padding: theme.spacing(3, 4),
        },
        odd: {
            backgroundColor: '#f9f9f9',
        },
        even: {
            backgroundColor: '#ffffff',
        },
        heightLoader: {
            maxHeight: 480,
            overflowX: 'auto',
            msOverflowStyle: 'none',
            [theme.breakpoints.between('md', 'lg')]: {
                maxHeight: '45vh',
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'calc(100vh - 345px)',
            },
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'calc(100vh - 375px)',
            },
        },
    }),
);

const DonorMatching: React.FCC<Prop> = ({
    reportId,
    handleClose,
    doneMatch,
}) => {
    const { t, scopedTranslate } = useAdvancedTranslation(TRANSLATION_KEY);
    const [filter, setFilter] = useState<string>('');
    const [successful, setSuccessful] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const [sortColumn, setSortColumn] = useState('last_donation_date');
    const [sortDirection, setSortDirection] =
        useState<SortingDirection>('desc');
    const { doneeId, reportType, userRole } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.campuses[0].id,
            reportType: state.ReportIntegration.scheduledReport.reportType,
            userRole: state.User.user.role,
        }),
    );

    const allIntegrations = useAllIntegrations();
    const integration = useMemo(
        () =>
            allIntegrations.find(
                (integration) => integration.integrationType === reportType,
            ),
        [reportType, allIntegrations],
    );

    const onChangeFilter = (value: string) => {
        setFilter(value);
    };

    const onSuccess = useCallback(() => {
        setSuccessful(true);
        setTimeout(() => {
            setSuccessful(false);
        }, 1500);
    }, []);

    const [matchFilter, setMatchFilter] = useState<matchSelect>('Unmatched');

    useEffect(() => {
        dispatch(
            GetMatchProgressAsync(
                'donor',
                filter,
                doneeId,
                reportId,
                matchFilter === 'Unmatched',
            ),
        );
    }, [dispatch, filter, doneeId, reportId, matchFilter]);

    const onSortingChange = (sorting: Sorting[]) => {
        setSortColumn(sorting[0].columnName);
        setSortDirection(sorting[0].direction);
    };

    const url = useCallback(
        (pageNum: number) => {
            const params = {
                pageSize: pageSize.toString(),
                page: pageNum.toString(),
                unmatched: (matchFilter === 'Unmatched').toString(),
                ...(filter?.length > 0 && { filter }),
                ...(sortColumn && {
                    sort: sortColumn,
                    [sortDirection]: sortDirection,
                }),
            };
            const paramsString = new URLSearchParams(params).toString();

            const url = reportId
                ? `/reports/${reportId}/unmatched-donors`
                : `/donees/${doneeId}/donors/campus`;

            return `${url}?${paramsString}`;
        },
        [doneeId, reportId, filter, sortColumn, sortDirection, matchFilter],
    );

    const copy = useMemo(
        () => ({
            instructions: scopedTranslate(`content.instructions`, {
                reportType: integration.abbreviation,
                memberIdLabel: integration.memberIdLabel,
            }),
            toolTipInfoText: scopedTranslate(
                `content.matching_tooltip.info_text`,
            ),
            toolTipMessage: scopedTranslate(
                `content.matching_tooltip.almost_done`,
            ),
            donorName: scopedTranslate(`content.table.heading.name`),
            phone: scopedTranslate(`content.table.heading.phone`),
            lastDonationsDate: scopedTranslate(
                `content.table.heading.last_donations_date`,
            ),
            memberId: scopedTranslate(`content.table.heading.member_id`, {
                reportType: integration.abbreviation,
                memberIdLabel: integration.memberIdLabel,
            }),
            doneLabel: t('labels.done'),
            success: t('pages.integration_donor_matching.success', {
                reportType: integration.abbreviation,
                memberIdLabel: integration.memberIdLabel,
            }),
            sort: t('labels.sort'),
        }),
        [scopedTranslate, integration, t],
    );

    const handleDoneClick = () => {
        if (handleClose) {
            handleClose();
        } else if (doneMatch) {
            doneMatch();
        }
    };

    const columns: TableColumnProps[] = useMemo(
        () => [
            {
                name: 'name',
                title: copy.donorName,
                sortable: true,
            },
            {
                name: 'email',
                title: 'Email',
                sortable: true,
            },
            {
                name: 'phone',
                title: copy.phone,
            },
            {
                name: 'last_donation_date',
                title: copy.lastDonationsDate,
                sortable: true,
            },
            {
                name: 'memberId',
                title: copy.memberId,
            },
        ],
        [copy],
    );

    const isReadOnly =
        userRole !== roleTypes.ADMIN && userRole !== roleTypes.FINANCIAL;

    return (
        <>
            <MatchingHeader
                columns={columns}
                instructions={copy.instructions}
                integrationType={integration.integrationType}
                matchFilter={matchFilter}
                onChangeFilter={onChangeFilter}
                onMatchFilterChange={setMatchFilter}
                onSortingChange={onSortingChange}
                reportId={reportId}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            />
            <ContentCard maxWidth="100%">
                <DonorMatchingHeader
                    copy={{
                        donorName: copy.donorName,
                        lastDonationsDate: copy.lastDonationsDate,
                        memberId: copy.memberId,
                        phone: copy.phone,
                        sort: copy.sort,
                    }}
                    onOrderClick={onSortingChange}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                />
                <DonorMatchingInfiniteLoader
                    integration={integration}
                    isReadOnly={isReadOnly}
                    onSuccess={onSuccess}
                    reportId={reportId}
                    url={url}
                />
                <MatchBottomContent
                    doneClick={handleDoneClick}
                    doneLabel={copy.doneLabel}
                    infoText={copy.toolTipInfoText}
                    success={successful}
                    successText={copy.success}
                    tooltipMessage={copy.toolTipMessage}
                />
            </ContentCard>
        </>
    );
};

export default DonorMatching;
