import * as React from 'react';
import { useMemo } from 'react';
import {
    GivelifyLabel,
    GivelifyRadio,
    ReceiptMobilePreview,
    SubmitButtons,
} from '@givelify/ui';
import { Grid, Stack } from '@mui/material';
import { ErrorText } from 'components/ErrorText';
import { useAdvancedTranslation } from 'utils/i18n';
import { HideOnMobile } from '../style';

type ReceiptTabPageProps = {
    isOn?: boolean;
    doneeName?: string;
    doneeType?: 'church' | 'nonprofit';
    onCancel: () => void;
    onSave: () => void;
    onChange: (isOn: boolean) => void;
    saving?: boolean;
    error?: string;
    dirty: boolean;
    disableActions?: boolean;
};

export const ReceiptTabPage: React.FunctionComponent<ReceiptTabPageProps> = (
    props,
) => {
    const { at, t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            on: t('labels.on'),
            off: t('labels.off'),
            descriptionShort: at(
                'pages.settings.app-profile.customize-receipt-tab.description-short',
            ),
            descriptionLong: at(
                'pages.settings.app-profile.customize-receipt-tab.description-long',
            ),
        }),
        [t, at],
    );
    return (
        <Grid container justifyContent="space-between">
            <Grid item mobile={7} smallMobile={12}>
                <GivelifyLabel text={copy.descriptionShort} variant="body1B" />
                <GivelifyLabel
                    marginTop={1}
                    text={copy.descriptionLong}
                    variant="body1"
                />
                <Stack
                    display="flex"
                    flexDirection="row"
                    gap={6}
                    justifyContent="flex-start"
                    marginTop={6}
                >
                    <GivelifyRadio
                        aria-label={copy.on}
                        checked={props.isOn}
                        disabled={props.disableActions}
                        label={copy.on}
                        name={copy.on}
                        onChange={() => {
                            if (props.onChange) {
                                props.onChange(true);
                            }
                        }}
                        width="auto"
                    />
                    <GivelifyRadio
                        aria-label={copy.off}
                        checked={!props.isOn}
                        disabled={props.disableActions}
                        label={copy.off}
                        name={copy.off}
                        onChange={() => {
                            if (props.onChange) {
                                props.onChange(false);
                            }
                        }}
                        width="auto"
                    />
                </Stack>
                {props.error ? <ErrorText text={props.error} /> : null}
                {props.disableActions ? null : (
                    <SubmitButtons
                        cancelDisabled={props.saving}
                        onCancel={props.onCancel}
                        onSubmit={props.onSave}
                        submitDisabled={props.saving || !props.dirty}
                    />
                )}
            </Grid>
            <HideOnMobile
                container
                item
                justifyContent="center"
                mobile={5}
                smallMobile={0}
            >
                <ReceiptMobilePreview
                    doneeName={props.doneeName}
                    doneeType={props.doneeType}
                    isOn={props.isOn}
                />
            </HideOnMobile>
        </Grid>
    );
};
