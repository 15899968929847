import React from 'react';
import { GivelifyFormTextField } from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryRepNameForm, primaryRepManualEvents } from '../../types';
import { PrimaryRepEditorFooter } from '../editorFooter';
import { NameFields, InputsBox } from '../styles';
import { StyledCheckbox } from './styles';

export type NameStepFormRef = {
    isEditing: () => boolean;
};

export type NameStepFormProps = {
    userDetails: Partial<PrimaryRepNameForm>;
    onCancelClick: () => void;
};

export const NameStepForm = React.forwardRef<
    NameStepFormRef,
    NameStepFormProps
>(({ userDetails, onCancelClick }, ref) => {
    const { t } = useTranslation();
    const TRANSLATION_KEY =
        'pages.settings.bank-info.customize-rep-tab.editor.stepName';
    const scopedTranslate = React.useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );
    const copy = React.useMemo(
        () => ({
            placeholder1: scopedTranslate('placeholder1'),
            placeholder2: scopedTranslate('placeholder2'),
            placeholder3: scopedTranslate('placeholder3'),
            placeholder4: scopedTranslate('placeholder4'),
            note: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.note',
            ),
        }),
        [scopedTranslate, t],
    );
    const {
        watch,
        setValue,
        formState: { isValid, isDirty },
    } = useFormContext<PrimaryRepNameForm>();
    const { trackEvent } = useTrackingContext();
    const isPrimaryRep = watch('isPrimaryRepresentative');
    React.useEffect(() => {
        if (isDirty) {
            if (isPrimaryRep && isDirty) {
                setValue('firstName', userDetails.firstName, {
                    shouldValidate: true,
                });
                setValue('lastName', userDetails.lastName, {
                    shouldValidate: true,
                });
                setValue('title', userDetails.title, { shouldValidate: true });
            } else {
                setValue('firstName', '', { shouldValidate: true });
                setValue('lastName', '', { shouldValidate: true });
                setValue('title', '', { shouldValidate: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrimaryRep]);
    React.useImperativeHandle(ref, () => ({
        isEditing: () => isDirty,
    }));
    const trackPrimaryRepClick = React.useCallback(() => {
        trackEvent(
            isPrimaryRep
                ? primaryRepManualEvents.uncheckRepClick
                : primaryRepManualEvents.checkRepClick,
        );
    }, [isPrimaryRep, trackEvent]);
    return (
        <>
            <StyledCheckbox<PrimaryRepNameForm>
                aria-label="I am the primary representative"
                className="rep-box"
                id="primary-rep"
                label={copy.placeholder1}
                name="isPrimaryRepresentative"
                onClick={trackPrimaryRepClick}
            />
            <InputsBox>
                <NameFields>
                    <GivelifyFormTextField<PrimaryRepNameForm>
                        fullWidth
                        aria-label="First name editor"
                        data-testid="firstName"
                        id="admin-form-firstName"
                        label={copy.placeholder2}
                        name="firstName"
                        placeholder={copy.placeholder2}
                        size="large"
                    />
                    <GivelifyFormTextField<PrimaryRepNameForm>
                        fullWidth
                        aria-label="Last name editor"
                        data-testid="lastName"
                        id="admin-form-lastName"
                        label={copy.placeholder3}
                        name="lastName"
                        placeholder={copy.placeholder3}
                        size="large"
                    />
                </NameFields>
                <GivelifyFormTextField<PrimaryRepNameForm>
                    fullWidth
                    aria-label="Title editor"
                    data-testid="title"
                    id="admin-form-title"
                    label={copy.placeholder4}
                    name="title"
                    placeholder={copy.placeholder4}
                    size="large"
                />
            </InputsBox>
            <PrimaryRepEditorFooter
                canSubmit={isValid}
                onCancelClick={onCancelClick}
            />
        </>
    );
});
