import { Grid, styled } from '@mui/material';

export const HideOnMobile = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(4),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    '& > :not(:first-child)::before': {
        content: `''`,
        backgroundColor: '#CAD2DB',
        height: 1,
        width: '100%',
        position: 'absolute',
        left: 0,
        transform: 'translateY(-32px)',
    },
}));
