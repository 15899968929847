import React, { useMemo, useState } from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/ui';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import { Grid } from '@mui/material';
import CalendlyWidget, {
    useCalendly,
} from 'layout/components/header/Dropdowns/CalendlyModal';
import Img1 from 'layout/components/header/Dropdowns/CalendlyModal/img/1.png';
import Img2 from 'layout/components/header/Dropdowns/CalendlyModal/img/2.png';
import Img3 from 'layout/components/header/Dropdowns/CalendlyModal/img/3.png';
import {
    CalendlyAvatar,
    CalendlyAvatarGroup,
} from 'layout/components/header/Dropdowns/CalendlyModal/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState, useAppDispatch } from 'store';
import { updateNotification } from 'store/notifications/thunks';
import { StyledModal, WelcomeWrapper } from './style';

export const TalkWithExpertModal: React.FCC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { calendlyModalDismissed } = useSelector((state: AppState) => ({
        calendlyModalDismissed: state.Notifications.calendlyModalDismissed,
    }));

    const { isCalendlyEnabled, openCalendly, setOpenCalendly } = useCalendly();

    const [open, setOpen] = useState<boolean>(!calendlyModalDismissed);

    const copy = useMemo(
        () => ({
            title1: t('calendly.welcomeModal.title1'),
            title2: t('calendly.welcomeModal.title2'),
            description: t('calendly.welcomeModal.description'),
            itsFree: t('calendly.welcomeModal.itsFree'),
            cancel: t('calendly.welcomeModal.cancel'),
            accept: t('calendly.welcomeModal.accept'),
        }),
        [t],
    );

    const dismissModal = () => {
        dispatch(updateNotification('calendlyModalDismissed', true));
    };

    if (!isCalendlyEnabled || calendlyModalDismissed) return null;

    const onClose = () => {
        setOpen(false);
        dismissModal();
    };

    const onAccept = () => {
        setOpenCalendly(true);
        dismissModal();
        setOpen(false);
    };

    return (
        <>
            {openCalendly && (
                <CalendlyWidget onClose={() => setOpenCalendly(false)} />
            )}
            <TrackingProvider
                pageName={PAGE_NAME.CaptivePortal}
                trackPageVisit={false}
            >
                <StyledModal
                    disableBackdropClose
                    showCloseButton
                    trackCloseButton
                    CloseButton={<GivelifyIcon variant="close-outlined" />}
                    name="Welcome"
                    onClose={onClose}
                    open={open}
                >
                    <WelcomeWrapper>
                        <CalendlyAvatarGroup>
                            <CalendlyAvatar src={Img1} />
                            <CalendlyAvatar src={Img2} />
                            <CalendlyAvatar src={Img3} />
                        </CalendlyAvatarGroup>
                        <GivelifyLabel
                            className="title"
                            text={copy.title1}
                            variant="heading2S"
                        />
                        <GivelifyLabel text={copy.title2} variant="heading2S" />

                        <div className="description">
                            <GivelifyLabel
                                color={DesignTokens.color.textSecondary}
                                text={copy.description}
                                variant="body1"
                            />
                            <GivelifyLabel
                                color={DesignTokens.color.textPrimary}
                                fontWeight={800}
                                text={copy.itsFree}
                                variant="body1"
                            />
                        </div>

                        <Grid
                            container
                            className="action-button-wrapper"
                            gap={4}
                            justifyContent="center"
                        >
                            <Grid item>
                                <GivelifyButton
                                    className="action-button"
                                    name="Not right now"
                                    onClick={onClose}
                                    text={copy.cancel}
                                    variant="secondary"
                                />
                            </Grid>
                            <Grid item>
                                <GivelifyButton
                                    className="action-button"
                                    name="Talk with an expert"
                                    onClick={onAccept}
                                    text={copy.accept}
                                    variant="primary"
                                />
                            </Grid>
                        </Grid>
                    </WelcomeWrapper>
                </StyledModal>
            </TrackingProvider>
        </>
    );
};
