import React, { useEffect, useMemo } from 'react';
import { FullWidthModal, useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyForm } from '@givelify/ui';
import { useInvokeApi } from '@givelify/utils';
import { useCalendlyCTAButtonContext } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useCampusesContext } from '../../../CampusesProvider';
import { CampusItem } from '../../types';
import EditForm from './EditForm';
import { FormProps } from './types';

interface EditModalProps {
    open: boolean;
    onClose: () => void;
    data: CampusItem;
}

const EditModal: React.FCC<EditModalProps> = ({ open, onClose, data }) => {
    const [editCampusRequestState, makeEditCampusRequest] = useInvokeApi<
        unknown,
        { nickname: string }
    >();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.list.listItem.editModal',
    });

    const copy = useMemo(
        () => ({
            title: scopedTranslate('title'),
            cancel: scopedTranslate('cancel'),
            save: scopedTranslate('save'),
            nicknameUniqueValidation: scopedTranslate(
                'nicknameUniqueValidation',
            ),
        }),
        [scopedTranslate],
    );

    const { doneeId, getList } = useCampusesContext();
    const { setError, handleSubmit } = useFormContext<FormProps>();

    const formHandleSubmit = async (values: FormProps) => {
        const campusId = data.id;
        const nickname = values.shortName;
        makeEditCampusRequest(
            'PATCH',
            `/donees/${doneeId}/campus/${campusId}`,
            {
                nickname,
            },
        );
    };

    useEffect(() => {
        if (editCampusRequestState.type === 'REQUEST_SUCCESS') {
            getList();
            onClose();
        }
        if (
            editCampusRequestState.type === 'REQUEST_ERROR' &&
            editCampusRequestState.error.status === 422
        ) {
            setError('shortName', {
                type: 'unique',
                message: copy.nicknameUniqueValidation,
            });
        }
    }, [
        editCampusRequestState,
        setError,
        getList,
        onClose,
        copy.nicknameUniqueValidation,
    ]);

    const { setButtonBringToFront } = useCalendlyCTAButtonContext();
    useEffect(() => {
        setButtonBringToFront(!open);
        return () => setButtonBringToFront(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <FullWidthModal
            errorMessage={null}
            footer={{
                cancelText: copy.cancel,
                submitText: copy.save,
                disableSubmit: false,
                isLoading: editCampusRequestState.type === 'REQUEST_START',
                onCancel: onClose,
                onSubmit: () => {
                    handleSubmit(formHandleSubmit)();
                },
            }}
            heading={copy.title}
            name="modal"
            onClose={onClose}
            open={open}
            retryMessage={null}
        >
            <EditForm item={data} />
        </FullWidthModal>
    );
};

const EditModalFormWrapper: React.FCC<EditModalProps> = ({
    open,
    onClose,
    data,
}) => {
    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.list.listItem.editModal',
    });

    const copy = useMemo(
        () => ({
            nicknameHelper: scopedTranslate('nicknameHelper'),
        }),
        [scopedTranslate],
    );

    const formSchema: yup.SchemaOf<FormProps> = yup.object().shape({
        shortName: yup.string().required(copy.nicknameHelper),
    });

    return (
        <GivelifyForm<FormProps>
            defaultValues={data}
            onSubmit={() => undefined}
            schema={formSchema}
        >
            <EditModal data={data} onClose={onClose} open={open} />
        </GivelifyForm>
    );
};

export default EditModalFormWrapper;
