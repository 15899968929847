import React, { useMemo } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { Stack } from '@mui/material';
import { GivelifyLabel } from '../label';
import { DesignTokens } from '../specify';
import { MOCKUP_PATH } from './paths';
import {
    MobilePreviewBackArrow,
    MobilePreviewCase,
    MobilePreviewContent,
    MobilePreviewDetails,
    MobilePreviewDetails2,
    MobilePreviewDetailsRowLined,
    MobilePreviewGreyContent,
    MobilePreviewTabBar,
    MobilePreviewTabBarIconActive,
    MobilePreviewTabBarIconNormal,
    MobilePreviewVisa,
} from './styles';

export type ReceiptMobilePreviewProps = {
    doneeName: string;
    doneeType: 'church' | 'nonprofit';
    isOn?: boolean;
};

export const ReceiptMobilePreview: React.FCC<ReceiptMobilePreviewProps> = ({
    doneeName,
    doneeType,
    isOn = false,
}) => {
    const { t } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            title: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.title',
            ),
            general: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.general',
            ),
            childrens: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.childrens',
            ),
            receiptN: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.receiptN',
            ),
            editMemberId: t(
                `pages.settings.app-profile.customize-receipt-tab.preview.edit-member.${doneeType}`,
            ),
            caption: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.caption',
            ),
            creditCard: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.credit-card',
            ),
            search: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.search',
            ),
            give: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.give',
            ),
            home: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.home',
            ),
            profile: t(
                'pages.settings.app-profile.customize-receipt-tab.preview.profile',
            ),
        }),
        [doneeType, t],
    );
    return (
        <MobilePreviewCase>
            <MobilePreviewContent>
                <MobilePreviewGreyContent>
                    <MobilePreviewDetails>
                        <div className="white">
                            <div className="inner">
                                <Stack
                                    alignItems="center"
                                    display="flex"
                                    height={45}
                                >
                                    <MobilePreviewBackArrow
                                        alt="back"
                                        src={
                                            MOCKUP_PATH.ICONS.BACK_ARROW_ACTIVE
                                        }
                                        title="Back arrow"
                                    />
                                    <GivelifyLabel
                                        color={
                                            DesignTokens.color
                                                .globalGuidingViolet700
                                        }
                                        marginLeft="auto"
                                        text={copy.title}
                                        variant="heading3S"
                                    />
                                </Stack>
                                <Stack
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="center"
                                    marginBottom={1.5}
                                    marginTop={5.4}
                                >
                                    <img
                                        alt="Amount"
                                        src={MOCKUP_PATH.ICONS.AMOUNT}
                                        title="Amount"
                                    />
                                </Stack>
                                <GivelifyLabel
                                    text={doneeName}
                                    textAlign="center"
                                    variant="heading3S"
                                />
                                <GivelifyLabel
                                    color={DesignTokens.color.chartGrey700}
                                    marginBottom={3}
                                    marginTop={0.5}
                                    text="Oct 22, 2019 6:32 PM EDT"
                                    textAlign="center"
                                    variant="body2"
                                />
                                <MobilePreviewDetailsRowLined>
                                    <GivelifyLabel
                                        color={DesignTokens.color.chartGrey700}
                                        text={copy.general}
                                        variant="body2"
                                    />
                                    <GivelifyLabel
                                        marginLeft="auto"
                                        text="$100.00"
                                        variant="body2B"
                                    />
                                </MobilePreviewDetailsRowLined>
                                <MobilePreviewDetailsRowLined>
                                    <GivelifyLabel
                                        color={DesignTokens.color.chartGrey700}
                                        text={copy.childrens}
                                        variant="body2"
                                    />
                                    <GivelifyLabel
                                        marginLeft="auto"
                                        text="$25.00"
                                        variant="body2B"
                                    />
                                </MobilePreviewDetailsRowLined>
                                <MobilePreviewDetailsRowLined>
                                    <GivelifyLabel
                                        marginLeft="auto"
                                        text="Total: $125.00"
                                        variant="body1B"
                                    />
                                </MobilePreviewDetailsRowLined>
                            </div>
                        </div>
                    </MobilePreviewDetails>
                    <MobilePreviewDetails2>
                        <Stack display="flex" flexDirection="row">
                            <GivelifyLabel
                                color={DesignTokens.color.chartGrey700}
                                text={copy.creditCard}
                                variant="body2"
                            />
                            <Stack
                                alignItems="center"
                                display="flex"
                                flexDirection="row"
                                marginLeft="auto"
                            >
                                <MobilePreviewVisa
                                    alt="visa"
                                    src={MOCKUP_PATH.ICONS.VISA}
                                    title="Visa Card Logo"
                                />
                                <GivelifyLabel
                                    marginLeft="auto"
                                    text="**** 2345"
                                    textAlign="center"
                                    variant="body2B"
                                />
                            </Stack>
                        </Stack>
                        <Stack display="flex" flexDirection="row">
                            <GivelifyLabel
                                color={DesignTokens.color.chartGrey700}
                                text={copy.receiptN}
                                variant="body2"
                            />
                            <GivelifyLabel
                                marginLeft="auto"
                                text="2108030724"
                                textAlign="center"
                                variant="body2B"
                            />
                        </Stack>
                    </MobilePreviewDetails2>
                    {isOn ? (
                        <MobilePreviewDetails2>
                            <GivelifyLabel
                                color={
                                    DesignTokens.color.globalGuidingViolet700
                                }
                                text={copy.editMemberId}
                                variant="body2"
                            />
                            <GivelifyLabel
                                color={DesignTokens.color.chartGrey700}
                                text={copy.caption}
                                variant="body2"
                            />
                        </MobilePreviewDetails2>
                    ) : null}
                    <MobilePreviewTabBar>
                        <MobilePreviewTabBarIconNormal>
                            <img
                                alt="home"
                                src={MOCKUP_PATH.ICONS.HOME}
                                title="Home icon"
                            />
                            <div className="text">{copy.home}</div>
                        </MobilePreviewTabBarIconNormal>
                        <MobilePreviewTabBarIconNormal>
                            <img
                                alt="search"
                                src={MOCKUP_PATH.ICONS.SEARCH}
                                title="Search icon"
                            />
                            <div className="text">{copy.search}</div>
                        </MobilePreviewTabBarIconNormal>
                        <MobilePreviewTabBarIconNormal>
                            <img
                                alt="give"
                                src={MOCKUP_PATH.ICONS.GIVE}
                                title="Give icon"
                            />
                            <div className="text">{copy.give}</div>
                        </MobilePreviewTabBarIconNormal>
                        <MobilePreviewTabBarIconActive>
                            <img
                                alt="profile"
                                src={MOCKUP_PATH.ICONS.PROFILE}
                                title="Profile icon"
                            />
                            <div className="text">{copy.profile}</div>
                        </MobilePreviewTabBarIconActive>
                    </MobilePreviewTabBar>
                </MobilePreviewGreyContent>
            </MobilePreviewContent>
        </MobilePreviewCase>
    );
};
