import React, { useMemo } from 'react';
import { GivelifyTooltip, GivelifyLabel, DesignTokens } from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import { CLICK_OLD_DASHBOARD_REPORT_TYPE } from 'pages/reports/GenerateReports/legacy/cleverTapEvents';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Define,
    GetReport,
    GetReportLink,
    InfoIcon,
    InfoIconWrapper,
    ReportItemBody,
    ReportItemContainer,
    ReportItemDefine,
    ReportItemHeader,
} from './style';

interface LegacyReportItemProps {
    name: string;
    icon: JSX.Element;
    define: string;
    include: string;
    link: string;
    quickbook?: boolean;
    id?: string;
}

const LegacyReportItem: React.FCC<LegacyReportItemProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { trackEvent } = useTrackingContext();
    const copy = useMemo(
        () => ({
            quickbooksCompatible: t('reports.item.quickbooksCompatible'),
            getReport: t('reports.getReport'),
        }),
        [t],
    );

    const handleClick = () => {
        trackEvent(CLICK_OLD_DASHBOARD_REPORT_TYPE(props.name));
        navigate(props.link);
    };
    return (
        <ReportItemContainer item onClick={handleClick}>
            <ReportItemHeader data-testid={props.id}>
                <GivelifyLabel
                    color="#000"
                    fontWeight={600}
                    lineHeight="initial"
                    marginRight="8px"
                    text={props.name}
                    variant="heading3S"
                />
                <GivelifyTooltip
                    arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    placement="bottom"
                    title={props.include}
                >
                    <InfoIconWrapper>
                        <InfoIcon fontSize={20} variant="info-filled" />
                    </InfoIconWrapper>
                </GivelifyTooltip>
            </ReportItemHeader>
            <ReportItemBody>
                <ReportItemDefine>
                    <Define
                        color={DesignTokens.color.globalNeutral600}
                        text={props.define}
                        variant="body2"
                    />
                </ReportItemDefine>
                <GetReport>
                    {props.icon}
                    <GetReportLink
                        className="reportLink"
                        onClick={handleClick}
                        text={copy.getReport}
                        variant="ghost"
                    />
                </GetReport>
            </ReportItemBody>
        </ReportItemContainer>
    );
};

export default LegacyReportItem;
