import React, { lazy, useEffect, useMemo, useState } from 'react';
import { GivelifySnackbar } from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { useTranslation } from 'react-i18next';
import { yodleeAccessTokenAPI } from '../api/requests/api';
import { I18N_NAMESPACE } from '../consts';
import { webConfig } from '../utils/webConfig';
import { YodleeBankOnClick } from './usaBankList';
import { closeFastLink } from './yodleeConfig';
import YodleeLoginModal from './YodleeLoginModal';
import YodleeSearchField from './YodleeSearchField';

const YodleeBanks = lazy(() => import('./YodleeBanks'));

const YodleeWrapper: React.FCC<YodleeBankOnClick> = React.memo(
    ({ onClick }) => {
        const { t } = useTranslation(I18N_NAMESPACE);
        const [debouncedTerm, setDebouncedTerm] = useState<string>('');
        const { searchBankText, searchBankNotFound } = useMemo(
            () => ({
                searchBankText: t('directDeposit.searchBankText'),
                searchBankNotFound: t('directDeposit.searchBankNotFound'),
            }),
            [t],
        );
        return (
            <>
                <YodleeSearchField
                    onChange={setDebouncedTerm}
                    placeholder={searchBankText}
                />
                <YodleeBanks
                    notFoundText={searchBankNotFound}
                    onClick={onClick}
                    searchTerm={debouncedTerm}
                />
            </>
        );
    },
);

YodleeWrapper.displayName = 'YodleeWrapper';

export interface YodleeProps {
    doneeId: number;
    onSubmit: (account: number, routing: number) => unknown;
    onManualEntry: () => unknown;
    onRefresh: () => unknown;
}

const Yodlee: React.FCC<YodleeProps> = ({
    doneeId,
    onSubmit,
    onManualEntry,
    onRefresh,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const [errorKey, setError] = useState<string>('');
    const [providerId, setProviderId] = useState<number | undefined>(undefined);
    const [requestState, makeRequest] = useApiRequest<string>();
    useEffect(() => {
        makeRequest(yodleeAccessTokenAPI(doneeId));
        const script = document.createElement('script');
        script.src = webConfig.yodleeInitialize;
        script.type = 'text/javascript';
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
        //eslint-disable-next-line
    }, []);

    const onCloseSnackbar = () => setError('');
    const onClick = (id: number) => setProviderId(id);
    const onClose = () => {
        closeFastLink();
        setProviderId(undefined);
    };
    const handleSubmit = (account: number, routing: number) => {
        onSubmit(account, routing);
    };

    return (
        <>
            <GivelifySnackbar
                hasCloseButton
                message={errorKey && t(errorKey)}
                onClose={onCloseSnackbar}
                open={errorKey.length > 0}
                title={errorKey}
            />
            <YodleeWrapper onClick={onClick} />
            {providerId && (
                <YodleeLoginModal
                    accessToken={
                        requestState.type === 'REQUEST_SUCCESS'
                            ? requestState.response
                            : undefined
                    }
                    doneeId={doneeId}
                    onClose={onClose}
                    onError={setError}
                    onManualEntry={onManualEntry}
                    onRefresh={onRefresh}
                    onSubmit={handleSubmit}
                    providerId={providerId}
                />
            )}
        </>
    );
};

export default React.memo(Yodlee);
