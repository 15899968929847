import React, { useMemo } from 'react';
import {
    GivelifyLabel,
    DesignTokens,
    GivelifyTooltip,
    GivelifyIcon,
} from '@givelify/ui';
import { useTrackingContext } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import quickbookIcon from '../../../assets/quickbooks-logo.png';
import {
    DefineLabel,
    GetReport,
    GetReportLink,
    QuickbookIcon,
    ReportItemBody,
    ReportItemContainer,
    ReportItemDefine,
    ReportItemHeader,
    ReportTooltipIconWrapper,
} from '../components/style';

interface ReportItemProps {
    name: string;
    icon: JSX.Element;
    define: string;
    include: string;
    link: string;
    quickbook?: boolean;
    testId?: string;
    isLegacy?: boolean;
}

const ReportItem: React.FCC<ReportItemProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { trackEvent } = useTrackingContext();
    const copy = useMemo(
        () => ({
            quickbooksCompatible: t('reports.item.quickbooksCompatible'),
            getReport: t('reports.getReport'),
        }),
        [t],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClick = () => {
        trackEvent(`${props.isLegacy ? 'Legacy ' : ''}${props.name} Report`);
        navigate(props.link);
    };
    return (
        <ReportItemContainer item onClick={handleClick}>
            <ReportItemHeader data-testid={props.testId}>
                <GivelifyLabel
                    lineHeight="initial"
                    marginRight="8px"
                    text={props.name}
                    variant="heading3S"
                />
                <GivelifyTooltip
                    arrow
                    PopperProps={{ disablePortal: true }}
                    placement="top"
                    title={props.include}
                >
                    <ReportTooltipIconWrapper>
                        <GivelifyIcon
                            color={DesignTokens.color.iconAccentDefault}
                            fontSize={20}
                            variant="info-filled"
                        />
                    </ReportTooltipIconWrapper>
                </GivelifyTooltip>
            </ReportItemHeader>
            <ReportItemBody>
                <ReportItemDefine>
                    <DefineLabel
                        color={DesignTokens.color.neutralGrey}
                        text={props.define}
                        variant="body2"
                    />
                    {!isMobile && props.quickbook && (
                        <GivelifyLabel
                            color={DesignTokens.color.primaryDarkGray}
                            lineHeight="initial"
                            marginBottom="6px"
                            marginTop="auto"
                            text={copy.quickbooksCompatible}
                            variant="body2"
                        />
                    )}
                </ReportItemDefine>
                <GetReport>
                    <GetReportLink
                        className="reportLink"
                        onClick={handleClick}
                        text={copy.getReport}
                        variant="ghost"
                    />
                    {isMobile && props.quickbook && (
                        <QuickbookIcon alt="Quicksbooks" src={quickbookIcon} />
                    )}
                    {props.icon}
                </GetReport>
            </ReportItemBody>
        </ReportItemContainer>
    );
};

export default ReportItem;
