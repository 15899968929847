import React, { useMemo } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import {
    GivelifyButton,
    CloseIcon,
    GivelifyLabel,
    DesignTokens,
    WarningIcon,
} from '@givelify/ui';
import { pushEvent } from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { OrganizationType } from '../../../@types/assets/onboarding';
import { ReactComponent as MidDocumentsIcon } from '../../../assets/mid-documents.svg';
import PhoneChurch from '../../../assets/profile-module-mockup-v2-1.png';
import PhoneNonprofit from '../../../assets/profile-module-mockup-v2-2.png';
import { ReactComponent as ReadyForDonationsIcon } from '../../../assets/ready-for-donations.svg';
import { I18N_NAMESPACE } from '../../../consts';
import { ProgressBadge } from '../../components/ProgressBadge';
import {
    Content,
    Button,
    BannerLink,
    ErrorBanner,
    Wrapper,
    Main,
    Misc,
    Header,
    List,
    ImageWrapper,
} from './styles';

export interface CardCompleteProps {
    progress?: number;
    onCompleteClick?: () => void;
    onCloseClick?: () => void;
    onReviewClick?: () => void;
    hasMid?: boolean;

    headerText: string;
    descriptionText: string;
    showList: boolean;
    item1Text: string;
    item2Text: string;
    item3Text: string;
    submitText: string;
    showSampleOrganization?: boolean;
    showRequired?: boolean;
    organizationType?: OrganizationType;

    submitButtonEventName?: string;
    closeButtonEventName?: string;
    pageVisitEventName?: string;
}

const ReviewBar: React.FCC<{ onClick?: () => void }> = ({ onClick }) => {
    const { scopedTranslate } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
        TRANSLATION_KEY: 'onboardingCards.completeToRecieve',
    });
    const copy = {
        tipDone1: scopedTranslate('reviewTip'),
        tipDone2: scopedTranslate('review'),
    };

    return (
        <ErrorBanner>
            <span
                style={{
                    color: DesignTokens.color.textPrimary,
                    fontSize: 24,
                    height: 24,
                }}
            >
                <WarningIcon />
            </span>
            <GivelifyLabel variant="body1">
                {copy.tipDone1}{' '}
                <BannerLink onClick={onClick} target="blank">
                    {copy.tipDone2}
                </BannerLink>
            </GivelifyLabel>
        </ErrorBanner>
    );
};

const ReviewBarMemo = React.memo(ReviewBar);

const Card: React.FCC<CardCompleteProps> = ({
    onCloseClick,
    onCompleteClick,
    progress = 0,
    onReviewClick,
    hasMid,
    headerText,
    descriptionText,
    showList,
    item1Text,
    item2Text,
    item3Text,
    submitText,
    showSampleOrganization,
    showRequired,
    organizationType,
    submitButtonEventName,
    closeButtonEventName,
    pageVisitEventName,
}) => {
    const { t } = useAdvancedTranslation({
        namespace: I18N_NAMESPACE,
    });
    const copy = React.useMemo(
        () => ({
            sampleOrganization: t(
                'onboardingCards.customizeYourProfile.sampleOrganization',
            ),
            required: t('labels.required'),
        }),
        [t],
    );

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    useMemo(() => {
        if (pageVisitEventName) {
            pushEvent(pageVisitEventName);
        }
    }, [pageVisitEventName]);

    const onCompleteClickInternal = () => {
        if (submitButtonEventName) {
            pushEvent(submitButtonEventName);
        }

        onCompleteClick?.();
    };

    const onCloseClickInternal = () => {
        if (closeButtonEventName) {
            pushEvent(closeButtonEventName);
        }
        onCloseClick?.();
    };

    const onReviewClickInternal = () => {
        pushEvent('Click Onboarding Set up Acc Modal Review_lnk');
        onReviewClick?.();
    };

    return (
        <Wrapper>
            <Content>
                <Main>
                    <div style={{ marginBottom: onCloseClick ? 16 : 24 }}>
                        <GivelifyLabel
                            bold
                            text={headerText}
                            variant={isMobile ? 'heading2S' : 'heading1M'}
                        />
                    </div>
                    <GivelifyLabel text={descriptionText} variant="body1" />
                    {showList && (
                        <List>
                            {[item1Text, item2Text, item3Text].map(
                                (itemText, index) => (
                                    <li key={index}>
                                        <GivelifyLabel
                                            text={itemText}
                                            variant="body1"
                                        />
                                    </li>
                                ),
                            )}
                        </List>
                    )}
                    {onCompleteClick && (
                        <Button
                            data-testid="open"
                            id="complete-onboarding-btn"
                            onClick={onCompleteClickInternal}
                            size="large"
                            style={{
                                marginTop:
                                    (isMobile && 24) || (onReviewClick && 50),
                            }}
                            text={submitText}
                            variant="primary"
                        />
                    )}
                </Main>
                <Misc>
                    {showSampleOrganization ? (
                        <ImageWrapper>
                            <GivelifyLabel
                                text={copy.sampleOrganization}
                                variant="caption1B"
                            />
                            <img
                                alt="profile example"
                                src={
                                    organizationType === 'church'
                                        ? PhoneChurch
                                        : PhoneNonprofit
                                }
                                style={{
                                    width:
                                        (isMobile && '70%') ||
                                        (onCloseClick ? 161 : 317),
                                }}
                                title="profile example"
                            />
                        </ImageWrapper>
                    ) : hasMid ? (
                        <ReadyForDonationsIcon />
                    ) : progress < 100 ? (
                        <ProgressBadge
                            size="normal"
                            v2={true}
                            value={progress ? Math.min(100, progress) : 0}
                        />
                    ) : (
                        <MidDocumentsIcon />
                    )}
                </Misc>
                <Header>
                    {showRequired ? (
                        <GivelifyLabel
                            bold
                            color={DesignTokens.color.textSecondary}
                            text={copy.required}
                            variant="body2"
                        />
                    ) : (
                        onCloseClick && (
                            <GivelifyButton
                                onClick={onCloseClickInternal}
                                variant="icon"
                            >
                                <CloseIcon />
                            </GivelifyButton>
                        )
                    )}
                </Header>
            </Content>
            {onReviewClick && <ReviewBarMemo onClick={onReviewClickInternal} />}
        </Wrapper>
    );
};

export default Card;
