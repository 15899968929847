import { ImageWithDimensions } from '../../models';

export type FaithLeaderEditorProps = {
    userId: number | null;
    firstName: string;
    lastName: string;
    title: string;
    avatar: string | null;
    avatarCroppedCoordinates: string | null;
    avatarOriginal: string | null;
    isYou?: boolean;
};

export type UpdateFaithLeaderRequest = {
    data: {
        faithLeader: FaithLeaderEditorProps;
        faithLeaderImage: ImageWithDimensions | undefined;
    };
    doNotDisplayFaithLeader: boolean;
};

export const toFaithLeaderPayload = (data: {
    faithLeader: FaithLeaderEditorProps;
    faithLeaderImage: ImageWithDimensions | undefined;
}) => {
    const isNewFaithLeader = Number(data.faithLeader?.userId) === 0;
    const faithLeaderId = isNewFaithLeader
        ? undefined
        : data.faithLeader?.userId;

    return {
        userId: faithLeaderId,
        ...(isNewFaithLeader && {
            firstName: data.faithLeader?.firstName,
        }),
        ...(isNewFaithLeader && {
            lastName: data.faithLeader?.lastName,
        }),
        ...(isNewFaithLeader && {
            title: data.faithLeader?.title,
        }),
        avatar: data.faithLeaderImage?.url,
        avatarCroppedCoordinates:
            data.faithLeaderImage?.dimensions.croppedAreaPixels,
    };
};
