import * as React from 'react';
import { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

export const SvgInstitutionNewCheckmark = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="64"
        width="64"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 64 64"
    >
        <path
            d="M64 31.9565C63.9353 29.877 63.8058 27.829 63.3526 25.8125C60.8923 14.5958 54.3209 6.52986 43.3794 2.21332C35.1894 -1.00046 26.9023 -0.685399 18.8094 2.96949C14.0832 5.11201 10.1339 8.13675 6.99383 12.1382C1.00511 19.7946 -1.19616 28.4277 0.616648 37.8484C2.91502 49.6008 9.93963 57.8558 21.4315 62.0778C29.2007 64.945 37.0993 64.5669 44.7714 61.1956C49.6271 59.0846 53.6735 55.9969 56.943 51.9324C61.6693 46.0404 63.8705 39.3293 64 31.9565Z"
            fill="white"
        />
        <path
            d="M64 31.9565C63.8705 39.3608 61.6693 46.0404 56.943 51.9324C53.6735 55.9969 49.6271 59.0846 44.7714 61.1956C37.0993 64.5669 29.2007 64.9135 21.4315 62.0778C9.93963 57.8558 2.91502 49.6008 0.616648 37.8484C-1.19616 28.4277 1.00511 19.7946 6.99383 12.1382C10.1339 8.10524 14.1156 5.0805 18.8094 2.96949C26.9023 -0.685399 35.1894 -1.00046 43.3794 2.21332C54.2886 6.52986 60.8923 14.5643 63.3526 25.8125C63.8058 27.7975 63.9353 29.877 64 31.9565ZM0.810888 31.736C0.84326 33.6894 0.940362 35.3593 1.26408 36.9977C3.6272 49.3172 10.8137 57.6983 22.9854 61.7312C29.4273 63.8738 35.9987 63.5902 42.4406 61.2586C48.2027 59.1476 52.9937 55.7763 56.6517 50.9871C62.3814 43.4568 64.4532 35.0128 62.5109 25.844C60.0507 14.1862 52.8642 6.2778 41.3723 2.37085C34.7686 0.133811 28.0029 0.385872 21.3991 2.84347C16.0255 4.82845 11.5258 7.9792 7.96498 12.3588C3.174 18.0616 0.940374 24.6782 0.810888 31.736Z"
            fill="#161616"
        />
        <path
            d="M13.6946 27.9541C15.8311 27.4814 17.4821 28.1431 18.8417 29.876C20.4926 31.9555 22.3378 33.909 24.0859 35.894C24.2153 36.0515 24.3448 36.2406 24.4743 36.3981C25.3484 37.3433 25.3484 37.3748 26.2224 36.4296C28.7797 33.72 31.3371 30.9788 33.8944 28.2691C36.4194 25.5595 38.9444 22.8498 41.4694 20.2032C42.6995 18.9114 43.8001 17.462 45.5482 16.7373C46.1632 16.7373 46.7783 16.4853 47.4257 16.5798C50.0802 16.9579 51.5369 19.4155 50.7923 22.1567C50.3391 23.732 49.109 24.7088 48.0731 25.8115C44.1886 30.0021 40.2392 34.1296 36.3223 38.2886C33.7326 41.0297 31.1105 43.7394 28.6179 46.5751C27.2259 48.119 25.7044 48.8751 23.6327 48.2765C22.7263 47.6463 21.8846 46.9847 21.1724 46.134C18.1619 42.4161 15.1837 38.6982 12.076 35.0748C10.0366 32.6802 10.7812 28.9308 13.6946 27.9541ZM23.4384 36.5241C21.4638 34.2241 19.4891 31.8925 17.4821 29.624C16.9965 29.0568 16.3167 28.7102 15.5398 28.7102C13.9212 28.5212 12.9501 28.9623 12.2055 30.2541C11.2991 31.8295 11.6552 33.2473 12.7558 34.5707C15.993 38.5091 19.1978 42.4161 22.4673 46.323C23.1795 47.1737 24.1182 47.6148 25.2836 47.5518C26.1576 47.5833 26.8374 47.2367 27.4201 46.6066C28.5855 45.3778 29.7509 44.149 30.8839 42.9202C35.254 38.194 39.6889 33.4994 44.1238 28.8048C45.7748 27.0403 47.4581 25.3074 49.0767 23.5115C50.1773 22.3142 50.4686 20.8648 49.8859 19.3525C49.4651 18.2182 48.5911 17.525 47.2962 17.462C45.9366 17.273 44.9007 17.9031 44.0267 18.7538C43.2498 19.51 42.5376 20.3607 41.7931 21.1799C36.8402 26.4417 31.8874 31.672 26.9345 36.9337C25.4131 38.5406 25.3807 38.5406 23.8593 37.0283C23.8269 36.9968 23.7945 36.9967 23.7621 36.9967C23.7298 36.9337 23.665 36.8707 23.6327 36.8077C23.6003 36.7762 23.4384 36.5241 23.4384 36.5241Z"
            fill="#161616"
        />
        <path
            d="M13.6947 27.9549C10.7813 28.9317 10.0367 32.6811 12.0761 35.0441C15.1838 38.6675 18.1943 42.3854 21.1725 46.1033C21.8847 46.9856 22.7263 47.6157 23.6327 48.2459C22.3703 48.9075 20.3632 47.9308 19.4244 46.7965C16.1226 42.795 12.9178 38.7305 9.51876 34.7921C8.38576 33.5003 8.28865 31.0742 9.19505 29.5618C10.0691 28.1755 11.72 27.5768 13.6947 27.9549Z"
            fill="#DBD6FF"
        />
        <path
            d="M45.5159 16.7369C43.7679 17.4616 42.6673 18.9109 41.4371 20.2028C38.8798 22.8809 36.3872 25.5906 33.8622 28.2687C31.3049 30.9784 28.7475 33.7195 26.1902 36.4292C25.3161 37.3429 25.3162 37.3429 24.4421 36.3977C24.3126 36.2402 24.2479 36.1456 24.1184 35.9881C24.3774 35.799 25.1219 35.1374 25.5751 34.6963C27.8088 32.3332 30.0424 29.9386 32.2436 27.5756C34.9305 24.6769 37.6173 21.8097 40.3041 18.911C41.3077 17.8082 42.4407 16.8945 43.9945 16.6424C44.5448 16.5164 45.0304 16.4849 45.5159 16.7369Z"
            fill="#DBD6FF"
        />
        <path
            d="M25.2838 47.5195C24.1184 47.6141 23.1796 47.173 22.4675 46.2907C19.2303 42.3838 15.9932 38.4769 12.756 34.5384C11.6878 33.2151 11.3317 31.7657 12.2057 30.2218C12.9502 28.93 13.9214 28.4574 15.54 28.678C17.6117 31.4507 20.0396 33.9398 22.2409 36.5864C22.6941 37.122 22.7264 36.7754 23.3415 36.3974C23.3739 36.3974 23.8271 36.933 23.8271 36.9645C25.3485 38.4769 25.3809 38.4769 26.9024 36.87C31.8552 31.6397 36.808 26.3779 41.7609 21.1162C42.5054 20.3285 43.2176 19.4778 43.9945 18.6901C44.8685 17.8078 45.9044 17.2092 47.264 17.3982C47.2964 17.4928 47.3288 17.6188 47.3611 17.7133C49.1416 20.1709 48.9473 22.2819 46.8432 24.4245C45.257 26.0313 43.7355 27.7327 42.1817 29.3711C38.459 33.3411 34.7363 37.3426 31.0135 41.3125C29.136 43.4236 27.1937 45.4716 25.2838 47.5195Z"
            fill="#DBD6FF"
        />
    </svg>
);

export const InstitutionNewCheckmarkIcon = ({
    fontSize = 'inherit',
    color = 'inherit',
    ...props
}: React.ComponentProps<typeof SvgIcon>) => (
    <SvgIcon
        {...props}
        color={color}
        component={SvgInstitutionNewCheckmark}
        fontSize={fontSize}
    />
);
