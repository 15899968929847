import React, { useEffect, useMemo, useState } from 'react';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLink,
    GivelifyModal,
    GivelifyNotification,
} from '@givelify/ui';
import { isFailed, isSucceeded, useApiRequest } from '@givelify/utils';
import { Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import { styled } from '@mui/material';
import { PCIntegrationResponse } from 'api/models/Integration';
import { setupPCIntegration } from 'api/requests/Integration';
import { IntegrationCallback } from 'pages/integrations/utils/useIntegrationCallback';
import { useSelector } from 'react-redux';
import { setIntegration } from 'store/automatedIntegration/action';
import { useAdvancedTranslation } from 'utils/i18n';
import { webConfig } from 'webConfig';
import PCLoginExample from '../../../../assets/pc_example.png';
import { AppState, useAppDispatch } from '../../../../store';

const ModalContent = styled('div')(() => ({
    width: '568px',
    marginBottom: '24px',

    '& .info': {
        marginTop: '24px',
    },

    '& .description': {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        paddingRight: 12,
    },

    '& .bold': {
        fontWeight: 800,
    },
}));

const SupportSection = styled('div')(() => ({
    color: DesignTokens.color.textSecondary,
    fontSize: '14px',
}));

const ModalActions = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    marginTop: '24px',

    '& button': {
        height: '48px',
    },
}));

const AuthWarning = styled(GivelifyNotification)(() => ({
    position: 'fixed',
    top: 32,
    left: '50%',
    transform: 'translateX(-50%)',

    '& button': {
        minHeight: 'initial',
    },
}));

interface PCIntegrationSetupDialogProps {
    integrationCallback?: IntegrationCallback;
    open: boolean;
    onDone: () => void;
    handleClose: () => void;
    setShowBanner: () => void;
}

const PCIntegrationSetupDialog: React.FCC<PCIntegrationSetupDialogProps> = ({
    integrationCallback,
    open,
    onDone,
    handleClose,
    setShowBanner,
}) => {
    const { doneeId } = useSelector((state: AppState) => ({
        doneeId: state.Donee.donee.id,
    }));
    const dispatch = useAppDispatch();
    const [showError, setShowError] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    const { scopedTranslate } = useAdvancedTranslation(
        'pages.integrations.content.setup-dialog',
    );
    const [requestStatus, makeApiRequest] =
        useApiRequest<PCIntegrationResponse>();

    const copy = useMemo(
        () => ({
            title: scopedTranslate('planningCenterSetup.title'),
            content: scopedTranslate('planningCenterSetup.content'),
            content2: scopedTranslate('planningCenterSetup.content2'),
            content3: scopedTranslate('planningCenterSetup.content3'),
            content4: scopedTranslate('planningCenterSetup.content4'),
            support: scopedTranslate('planningCenterSetup.support'),
            submitRequest: scopedTranslate('planningCenterSetup.submitRequest'),
            integrationError: scopedTranslate(
                'planningCenterSetup.integrationError',
            ),
        }),
        [scopedTranslate],
    );

    useEffect(() => {
        if (!integrationCallback) {
            setProcessing(false);
            return;
        }

        if (integrationCallback.error) {
            setShowError(true);
            return;
        }
        if (
            integrationCallback.code &&
            doneeId &&
            requestStatus.type === 'REQUEST_INIT'
        ) {
            setProcessing(true);
            makeApiRequest(
                setupPCIntegration(doneeId, integrationCallback.code),
            );
        }
        // listen only for integrationCallback changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrationCallback]);

    useEffect(() => {
        if (isSucceeded(requestStatus)) {
            dispatch(
                setIntegration({
                    data: {
                        doneeId,
                        initialSetupStatus:
                            requestStatus.response.data.initialSetupStatus,
                        type: requestStatus.response.data.type,
                    },
                }),
            );
            onDone();
            setShowBanner();
            return;
        }
        if (isFailed(requestStatus)) {
            setShowError(true);
            return;
        }
        // listen only for requestStatus changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestStatus]);

    const onClose = () => {
        handleClose();
        setProcessing(false);
        setShowError(false);
    };

    return (
        <GivelifyModal
            disableBackdropClose
            showCloseButton
            CloseButton={<GivelifyIcon variant="close-outlined" />}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            onClose={onClose}
            open={open}
        >
            <ModalContent>
                <AuthWarning
                    onClose={() => setShowError(false)}
                    open={showError}
                    text={copy.integrationError}
                    variant="error"
                />
                <Typography align="center" variant="h2">
                    {copy.title}
                </Typography>
                <Grid container className="info">
                    <Grid item className="description" xs={7}>
                        <p>{copy.content}</p>
                        <p>
                            {copy.content2}{' '}
                            <b className="bold">{copy.content3}</b>{' '}
                            {copy.content4}{' '}
                        </p>
                    </Grid>
                    <Grid item textAlign="right" xs={5}>
                        <img
                            alt="Planning Center Login Example"
                            src={PCLoginExample}
                            width="222px"
                        />
                    </Grid>
                </Grid>
            </ModalContent>
            <SupportSection>
                {copy.support}{' '}
                <GivelifyLink
                    href={webConfig.supportUrl + '/hc/en-us/requests/new'}
                    target="_blank"
                    text={copy.submitRequest}
                />
            </SupportSection>
            <ModalActions>
                <GivelifyButton
                    disabled={processing}
                    onClick={onClose}
                    text="Close"
                    variant="secondary"
                />
                <GivelifyButton
                    disabled={processing}
                    onClick={() =>
                        (window.location.href = webConfig.planningCenterAuthURL)
                    }
                    text="Proceed to Planning Center"
                    variant="primary"
                />
            </ModalActions>
        </GivelifyModal>
    );
};

export default PCIntegrationSetupDialog;
