import React from 'react';
import { MESSAGE_FILTER_VALUE } from '@givelify/api';
import { useCaptivePortalContext } from '@givelify/onboarding';
import {
    BUTTON_IDENTIFIER,
    PAGE_NAME,
    TrackingProvider,
    useTrackingContext,
} from '@givelify/utils';
import {
    FilterProvider,
    useAllEnvelopesFilter,
    useAllMessagesFilter,
    useSearchFilter,
    useSortFilter,
    useTimeframeFilter,
} from 'api/hooks';
import { isEqual } from 'lodash-es';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { useDonationsContext } from '../donationsPage/DonationsPage';
import { DonationsFilter } from './components/donationsFilterForm';
import { DonationActivityView } from './DonationActivityView';

const DonationActivityPage: React.FCC<{
    totalDonationAmountRef: React.MutableRefObject<HTMLDivElement>;
}> = ({ totalDonationAmountRef }) => {
    const { trackEvent } = useTrackingContext();
    const { doneeId, doneeSignupDate, donee } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            doneeSignupDate: state.Donee.donee.signupDate,
            donee: state.Donee.donee,
        }),
    );
    const { directDeposit, primaryRepresentative, taxId } =
        useCaptivePortalContext();

    const [openFilterPanel, setOpenFilterPanel] = React.useState(false);
    const [timeFrame, setTimeFrame] = useTimeframeFilter(undefined, true);
    const [messageFilterValue, setMessageFilterValue] = useAllMessagesFilter();
    const [envelopesFilterValue, setEnvelopesFilterValue] =
        useAllEnvelopesFilter(doneeId);
    const [sortFilter, setSortFilter] = useSortFilter({
        sort: 'date-desc',
    });
    const [searchFilter, setSearchFilter] = useSearchFilter();
    const onSearchSubmit = React.useCallback(
        (search: string) => {
            setSearchFilter({
                search,
            });
        },
        [setSearchFilter],
    );
    const onFilterSubmit = React.useCallback(
        (filter: DonationsFilter, isSubmitButton?: boolean) => {
            if (!filter.timeFrame || !filter.timeFrame.start) {
                setTimeFrame(undefined);
            } else {
                setTimeFrame(filter.timeFrame);
            }
            if (!isEqual(filter.messages, messageFilterValue)) {
                setMessageFilterValue(filter.messages);
            }
            if (
                !isEqual(
                    filter.envelopes.values.sort(),
                    envelopesFilterValue.values.sort(),
                )
            ) {
                setEnvelopesFilterValue(filter.envelopes.values);
            }
            if (!isEqual(filter.timeFrame, timeFrame)) {
                setTimeFrame(filter.timeFrame);
            }
            setOpenFilterPanel(false);

            if (isSubmitButton === true) {
                trackEvent(`<Submit filters>_${BUTTON_IDENTIFIER}`, {
                    dateRangeApplied: !!filter.timeFrame,
                    selectedEnvelopesAmount: filter.envelopes.values.length,
                    repliedMessages: filter.messages.includes(
                        MESSAGE_FILTER_VALUE.REPLIED,
                    ),
                    unRepliedMessages: filter.messages.includes(
                        MESSAGE_FILTER_VALUE.UN_REPLIED,
                    ),
                    noMessages: filter.messages.includes(
                        MESSAGE_FILTER_VALUE.NO_MESSAGES,
                    ),
                });
            }
        },
        [
            setEnvelopesFilterValue,
            setMessageFilterValue,
            envelopesFilterValue,
            trackEvent,
            messageFilterValue,
            timeFrame,
            setTimeFrame,
        ],
    );
    const onFilterButtonClick = React.useCallback(() => {
        setOpenFilterPanel((prev) => !prev);
    }, []);
    const onSortRequest = React.useCallback(
        (field: string, direction: string) => {
            const sort = `${field}-${direction}`;
            setSortFilter({
                sort,
            });
        },
        [setSortFilter],
    );

    const hasMid = donee.onboarding.hasMid;
    const orgName = donee.name;
    const city = donee.city;

    const statuses = [
        taxId.status,
        directDeposit.status,
        primaryRepresentative.status,
    ];

    const allDocumentsInReview = statuses.every(
        (status) =>
            status === 'in_progress' ||
            status === 'request_callback' ||
            status === 'verified',
    );

    return (
        <DonationActivityView
            allDocumentsInReview={allDocumentsInReview}
            city={city}
            doneeId={doneeId}
            doneeSignupDate={doneeSignupDate}
            filters={{
                envelopes: envelopesFilterValue,
                messages: messageFilterValue,
                timeFrame,
            }}
            hasMid={hasMid}
            isFilterPanelOpen={openFilterPanel}
            onFilterButtonClick={onFilterButtonClick}
            onFilterSubmit={onFilterSubmit}
            onSearchSubmit={onSearchSubmit}
            onSortRequest={onSortRequest}
            orgName={orgName}
            search={searchFilter.search}
            sort={sortFilter.sort}
            totalDonationAmountRef={totalDonationAmountRef}
        />
    );
};

export const DonationActivity = () => {
    const totalDonationAmountRef = useDonationsContext();

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.DonationActivity}>
            <FilterProvider>
                <DonationActivityPage
                    totalDonationAmountRef={totalDonationAmountRef}
                />
            </FilterProvider>
        </TrackingProvider>
    );
};
