import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
    GivelifyNotification,
} from '@givelify/ui';
import { Divider, styled } from '@mui/material';

export const NotificationRoot = styled(GivelifyNotification)(() => ({
    '& .MuiSnackbarContent-message': {
        padding: 0,
        width: '100%',
    },
}));

export const StyledMessage = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('smallTablet')]: {
        marginBottom: 0,
    },
}));

export const MessageRow1 = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));

export const MessageRow1Left = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
}));

export const MessageRow1Right = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    [theme.breakpoints.down('mobile')]: {
        display: 'none',
    },
}));

export const MessageRow2 = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
}));

export const MessageRow3 = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('mobile')]: {
        display: 'initial',
    },
}));

export const MessageDivider = styled(Divider)(() => ({
    borderBottomWidth: 2,
    borderColor: DesignTokens.color.illustrationSand600,
}));

export const MessageTitle = styled(GivelifyLabel)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
}));

export const IdentedLabel = styled(GivelifyLabel)(({ theme }) => ({
    marginLeft: theme.spacing(4),
}));

export const MessageIcon = styled(GivelifyIcon)(() => ({
    fontSize: '24px',
    lineHeight: '24px',
    color: DesignTokens.color.textInfoDefault,
}));

export const MessageTipBox = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(4),
}));

export const MessageTipMarker = styled('span')(({ theme }) => ({
    margin: theme.spacing(0, 1),
}));

export const ActionButton = styled(GivelifyButton)(({ theme }) => ({
    color: DesignTokens.color.textWhite,
    borderColor: DesignTokens.color.utilityWarning,
    backgroundColor: DesignTokens.color.utilityWarning,
    '&:hover': {
        color: DesignTokens.color.textWhite,
        borderColor: DesignTokens.color.globalWarning600,
        backgroundColor: DesignTokens.color.globalWarning600,
    },
    [theme.breakpoints.down('mobile')]: {
        fontSize: 16,
        lineHeight: '24px',
    },
}));
