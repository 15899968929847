import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { AddressWithPhone, isValidAddress } from '@givelify/givelify-ui';
import { useApiRequest } from '@givelify/utils';
import { isEqual } from 'lodash-es';
import {
    FaithLeaderEditorInfo,
    ImageWithDimensions,
    RepresentativeWithImage,
} from '../../@types/assets/onboarding';
import { getRepresentativesAPI } from '../../api/requests/api';
import { isImageChanged } from '../../utils/compareImages';
import { CustomizeProfileRef } from '../CustomizeProfileRef';
import { ValidateFaithLeader } from './FaithLeaderSection';
import {
    CustomizeProfileStepOneProps,
    CustomizeProfileStepOneWithDataProps,
} from './types';
import View from './view';

const CustomizeProfileStepOneComponent: React.FCC<
    CustomizeProfileStepOneWithDataProps
> = ({
    userId,
    appProfile,
    organizationType,
    organizationName,
    setIsValid,
    onMobilePreviewRequest,
    className,
    hideFullScreenPreviewOption,
    settingsUsersPath,
    data,
    isLoading,
    forwardRef,
    setIsDataChanged,
    formProps,
}) => {
    const [bannerImage, setBannerImg] = useState(appProfile.bannerImage);
    const [faithLeaderImage, setFaithLeaderImg] = useState(
        appProfile.faithLeaderImage,
    );
    const [faithLeader, setFaithLeaderInfo] = useState(
        appProfile.faithLeader
            ? appProfile.faithLeader
            : {
                  userId: 0,
                  firstName: '',
                  lastName: '',
                  title: '',
                  avatar: '',
                  avatarCroppedCoordinates: '',
                  avatarOriginal: '',
              },
    );
    const [address, setAddressInfo] = useState(appProfile.organizationAddress);
    const [isAddressValid, setIsAddressValid] = useState(
        isValidAddress(appProfile.organizationAddress, false),
    );
    const setFaithLeader = (faithLeaderInfo: FaithLeaderEditorInfo) => {
        setFaithLeaderInfo(faithLeaderInfo);
        setIsDataChanged &&
            setIsDataChanged(isEqual(faithLeaderInfo, appProfile.faithLeader));
    };

    const setFaithLeaderImage = (image?: ImageWithDimensions) => {
        setFaithLeaderImg(image);
        setIsDataChanged &&
            setIsDataChanged(isEqual(image, appProfile.faithLeaderImage));
    };

    const setBannerImage = (image?: ImageWithDimensions) => {
        setBannerImg(image);
        setIsDataChanged &&
            setIsDataChanged(isEqual(image, appProfile.bannerImage));
    };

    const setAddress = (organizationAddress: AddressWithPhone) => {
        setAddressInfo(organizationAddress);
        setIsDataChanged &&
            setIsDataChanged(isEqual(address, appProfile.organizationAddress));
    };

    const isFaithLeaderValid = useMemo(() => {
        if (faithLeader.userId === 0) return true;

        return ValidateFaithLeader({
            firstName: faithLeader.firstName || '',
            lastName: faithLeader.lastName || '',
            title: faithLeader.title || '',
        }).isValid;
    }, [faithLeader]);

    useEffect(() => {
        if (organizationType === 'church') {
            if (setIsValid) setIsValid(isAddressValid && isFaithLeaderValid);
        } else {
            if (setIsValid) setIsValid(isAddressValid);
        }
    }, [isAddressValid, isFaithLeaderValid, setIsValid, organizationType]);

    useEffect(() => {
        setBannerImage(appProfile.bannerImage);
        //eslint-disable-next-line
    }, [appProfile.bannerImage]);

    useEffect(() => {
        setFaithLeaderInfo(
            appProfile.faithLeader
                ? appProfile.faithLeader
                : {
                      userId: 0,
                      firstName: '',
                      lastName: '',
                      title: '',
                      avatar: '',
                      avatarCroppedCoordinates: '',
                      avatarOriginal: '',
                  },
        );
    }, [appProfile.faithLeader]);

    useImperativeHandle(forwardRef, () => ({
        submit: () => {
            return {
                ...appProfile,
                organizationAddress: address,
                faithLeaderImage,
                bannerImage: isImageChanged(
                    appProfile.bannerImage,
                    bannerImage,
                ),
                faithLeader,
            };
        },
    }));
    return (
        <View
            {...{
                address,
                organizationName,
                setIsAddressValid,
                data,
                onMobilePreviewRequest,
                hideFullScreenPreviewOption,
                faithLeaderImage,
                faithLeader,
                bannerImage,
                userId,
                settingsUsersPath,
                setFaithLeaderImage,
                setFaithLeader,
                setBannerImage,
                setAddress,
                organizationType,
                isLoading,
                className,
                appProfilebannerCroppedUrl: appProfile.bannerImage?.croppedUrl,
                initialFiathLeader: appProfile.faithLeader
                    ? appProfile.faithLeader
                    : {
                          userId: 0,
                          firstName: '',
                          lastName: '',
                          title: '',
                          avatar: '',
                          avatarCroppedCoordinates: '',
                          avatarOriginal: '',
                      },
                initialFaithLeaderImage: appProfile.faithLeaderImage,
                formProps: formProps,
            }}
        />
    );
};

const CustomizeProfileDataLoaderWrapper = (
    props: CustomizeProfileStepOneProps,
    forwardRef: React.ForwardedRef<CustomizeProfileRef>,
) => {
    const { doneeId } = props;
    const [getRepresentativesStatus, makeGetRepresentativesRequest] =
        useApiRequest<{ data: RepresentativeWithImage[] }>();

    useEffect(() => {
        makeGetRepresentativesRequest(getRepresentativesAPI(doneeId));
    }, [doneeId, makeGetRepresentativesRequest]);

    const isLoading = useMemo(
        () => getRepresentativesStatus.type === 'REQUEST_START',
        [getRepresentativesStatus],
    );
    return (
        <CustomizeProfileStepOneComponent
            {...{
                ...props,
                data: getRepresentativesStatus.response?.data || [],
                isLoading,
                forwardRef,
            }}
        />
    );
};

export const CustomizeProfileStepOne = forwardRef<
    CustomizeProfileRef,
    CustomizeProfileStepOneProps
>(CustomizeProfileDataLoaderWrapper);
