import React, { useCallback, useMemo } from 'react';
import { convertToDate, formatWithTimezone } from '@givelify/utils';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { DonationRow } from 'api/models';
import { TFunction } from 'i18next';
import { useAdvancedTranslation } from 'utils/i18n';
import { formatMoneyWithCommas } from '../../../../../utils/formatMoneyWithCommas';
import { EnvelopeDetailProps } from '../../../components/DonationDetails';
import { donationsRowTableStyles } from '../../../components/DonationsRowTable';
import { DonationItemRowProps } from '../../../donationActivity/components/DonationActivityInfiniteLoader/DonationActivityRow';
import { mapDonationRowToDonationItemRowProps } from '../../../utils/DonationsRowUtils';

const isRefunded = (isRefunded: boolean | undefined, value: string) =>
    isRefunded ? `($${value})` : `$${value}`;

const getEnvelopeName = (envelopes?: EnvelopeDetailProps[]): string => {
    if (envelopes.length === 1) {
        return envelopes[0].name;
    }
    if (!envelopes || envelopes.length === 0) {
        return 'Unknown';
    }
    return 'Multiple';
};

const TableHeader = (t: TFunction, at: TFunction) => {
    const { firstColumn, lastColumn } = donationsRowTableStyles();

    const TRANSLATION_KEY = 'bankDeposits.table';
    const scopedTranslate = useCallback(
        (key: string) => t(`${TRANSLATION_KEY}.${key}`),
        [t],
    );

    const scopedATranslate = useCallback(
        (key: string) => at(`${TRANSLATION_KEY}.${key}`),
        [at],
    );
    const copy = useMemo(
        () => ({
            date: scopedTranslate('date'),
            time: scopedTranslate('time'),
            donor: scopedTranslate('donor'),
            total: scopedTranslate('total'),
            fees: scopedTranslate('fees'),
            net: scopedTranslate('net'),
            envelope: scopedATranslate('envelope'),
        }),
        [scopedTranslate, scopedATranslate],
    );

    return (
        <TableHead className="table-head">
            <TableRow>
                <TableCell className={firstColumn}>{copy.date}</TableCell>
                <TableCell>{copy.time}</TableCell>
                <TableCell>{copy.donor}</TableCell>
                <TableCell align="right">{copy.total}</TableCell>
                <TableCell align="right">{copy.fees}</TableCell>
                <TableCell align="right">{copy.net}</TableCell>
                <TableCell className={lastColumn}>{copy.envelope}</TableCell>
            </TableRow>
        </TableHead>
    );
};

interface BankDepositTableProps {
    donationRows: DonationRow[];
    total?: number;
    currentPage?: number;
    handleChangePage: (e: unknown, pageNumber: number) => void;
}

const rowsPerPage = 10;
const BankDepositTable: React.FCC<BankDepositTableProps> = ({
    donationRows,
    total,
    currentPage,
    handleChangePage,
}) => {
    const { t, at } = useAdvancedTranslation();
    const {
        textEllipsis,
        firstColumn,
        lastColumn,
        row: rowClassName,
    } = donationsRowTableStyles();
    // NOTE: this table styles are defined in useModalTableStyles
    return (
        <>
            <TableContainer className="table-container">
                <Table aria-label="Donation Details" stickyHeader={true}>
                    {TableHeader(t, at)}
                    <TableBody>
                        {donationRows.map((row, i) => {
                            const data: DonationItemRowProps =
                                mapDonationRowToDonationItemRowProps(row);
                            const convertedDate = convertToDate(row.createdAt);
                            const formattedDate =
                                formatWithTimezone(convertedDate);
                            const formattedTime = formatWithTimezone(
                                convertedDate,
                                'hh:mm A z',
                            );
                            return (
                                <TableRow key={i} className={rowClassName}>
                                    <TableCell className={firstColumn}>
                                        {formattedDate}
                                    </TableCell>
                                    <TableCell>{formattedTime}</TableCell>
                                    <TableCell className={textEllipsis}>
                                        {row.donor?.name || t('Unknown')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {isRefunded(
                                            data.isRefund,
                                            formatMoneyWithCommas(
                                                data.donation,
                                            ),
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        ${formatMoneyWithCommas(data.fees)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {isRefunded(
                                            data.isRefund,
                                            formatMoneyWithCommas(
                                                data.donation - data.fees,
                                            ),
                                        )}
                                    </TableCell>
                                    <TableCell className={lastColumn}>
                                        {getEnvelopeName(data.envelopes)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <table className="pagination">
                <tbody>
                    <tr>
                        <TablePagination
                            count={total}
                            labelDisplayedRows={({ page }) =>
                                `Page ${page + 1} of ${Math.ceil(
                                    total / rowsPerPage,
                                )}`
                            }
                            onPageChange={handleChangePage}
                            page={currentPage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[]}
                        />
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default BankDepositTable;
