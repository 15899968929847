import React, { useMemo, useState } from 'react';
import { GivelifyLoading } from '@givelify/givelify-ui';
import {
    GivelifyLabel,
    GivelifyLink,
    ProfileMobilePreview,
} from '@givelify/ui';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACE } from '../../consts';
import AddressEditor from './AddressEditor';
import BannerImageSection from './BannerImageSection';
import FaithLeaderSection from './FaithLeaderSection';
import PopupTip from './PopupTip';
import { ContentWrapper, HideOnMobile } from './styles';
import { CustomizeProfileStepOneViewProps } from './types';
import ViewProfilePreviewLink from './ViewProfilePreviewLink';

const CustomizeProfileStepOneComponent: React.FCC<
    CustomizeProfileStepOneViewProps
> = ({
    isLoading,
    data,
    address,
    organizationType,
    setAddress,
    setBannerImage,
    setFaithLeader,
    setFaithLeaderImage,
    settingsUsersPath,
    userId,
    bannerImage,
    faithLeader,
    faithLeaderImage,
    hideFullScreenPreviewOption,
    onMobilePreviewRequest,
    setIsAddressValid,
    organizationName,
    appProfilebannerCroppedUrl,
    initialFaithLeaderImage,
    initialFiathLeader,
    formProps,
}) => {
    const { t } = useTranslation(I18N_NAMESPACE);

    const [openPopupTip, setPopupTip] = useState(false);
    const onOpenPopupTip = () => setPopupTip(true);
    const onClosePopupTip = () => setPopupTip(false);
    const { titleText, descriptionText, descriptionLink } = useMemo(
        () => ({
            titleText: t('customizeProfile.title'),
            descriptionText: t('customizeProfile.description.text'),
            descriptionLink: t('customizeProfile.description.link'),
        }),
        [t],
    );
    const onBunnerImageSaveRequest = React.useCallback(() => {
        if (formProps?.bunnerImageFormSubmitProps) {
            formProps.bunnerImageFormSubmitProps.onSave(bannerImage);
        }
    }, [formProps, bannerImage]);
    const onAddressSaveRequest = React.useCallback(() => {
        if (formProps?.addressFormSubmitProps) {
            formProps.addressFormSubmitProps.onSave(address);
        }
    }, [formProps, address]);
    const onFaithLeaderSaveRequest = React.useCallback(() => {
        if (formProps?.faithLeadeFormSubmitProps) {
            formProps.faithLeadeFormSubmitProps.onSave(
                faithLeader,
                faithLeaderImage,
            );
        }
    }, [formProps, faithLeader, faithLeaderImage]);
    return (
        <Grid container justifyContent="space-between">
            <Grid item mobile={7} smallMobile={12}>
                {isLoading && <GivelifyLoading type="topLoadingBar" />}
                <GivelifyLabel
                    fontWeight="bold"
                    marginBottom={1}
                    text={titleText}
                    variant="heading2S"
                />
                <GivelifyLabel variant="body1">
                    {descriptionText}
                    <GivelifyLink
                        className="link"
                        data-testid="customize-profile-description-link"
                        fontSize="inherit"
                        id="customize-profile-description-link"
                        onClick={onOpenPopupTip}
                        text={descriptionLink}
                    />
                    .
                </GivelifyLabel>
                <ContentWrapper>
                    <BannerImageSection
                        bannerImage={bannerImage}
                        setBannerImage={setBannerImage}
                        submitProps={
                            formProps?.bunnerImageFormSubmitProps
                                ? {
                                      onSave: onBunnerImageSaveRequest,
                                      isSaving:
                                          formProps?.bunnerImageFormSubmitProps
                                              .isSaving,
                                      disable:
                                          formProps?.bunnerImageFormSubmitProps
                                              .disable,
                                  }
                                : undefined
                        }
                    />
                    <ViewProfilePreviewLink
                        hideFullScreenPreviewOption={
                            hideFullScreenPreviewOption
                        }
                        onClick={onMobilePreviewRequest}
                    />

                    {organizationType === 'church' && !isLoading && (
                        <>
                            <FaithLeaderSection
                                faithLeader={faithLeader}
                                faithLeaderImage={faithLeaderImage}
                                initialFaithLeader={initialFiathLeader}
                                initialfaithLeaderImage={
                                    initialFaithLeaderImage
                                }
                                onChangeFaithLeader={setFaithLeader}
                                representatives={data}
                                setFaithLeaderImage={setFaithLeaderImage}
                                settingsUsersPath={settingsUsersPath}
                                submitProps={
                                    formProps?.faithLeadeFormSubmitProps
                                        ? {
                                              onSave: onFaithLeaderSaveRequest,
                                              isSaving:
                                                  formProps
                                                      ?.faithLeadeFormSubmitProps
                                                      .isSaving,
                                          }
                                        : undefined
                                }
                                userId={userId}
                            />
                            <ViewProfilePreviewLink
                                hideFullScreenPreviewOption={
                                    hideFullScreenPreviewOption
                                }
                                onClick={onMobilePreviewRequest}
                            />
                        </>
                    )}
                    <AddressEditor
                        ref={formProps?.addressFormSubmitProps?.formRef}
                        address={address}
                        isAddressValid={setIsAddressValid}
                        onChange={setAddress}
                        submitProps={
                            formProps?.addressFormSubmitProps
                                ? {
                                      onSave: onAddressSaveRequest,
                                      isSaving:
                                          formProps?.addressFormSubmitProps
                                              .isSaving,
                                      disable:
                                          formProps?.addressFormSubmitProps
                                              .disable,
                                  }
                                : undefined
                        }
                    />
                    <ViewProfilePreviewLink
                        hideFullScreenPreviewOption={
                            hideFullScreenPreviewOption
                        }
                        onClick={onMobilePreviewRequest}
                    />
                </ContentWrapper>
            </Grid>
            <HideOnMobile
                container
                item
                justifyContent="center"
                mobile={5}
                smallMobile={0}
            >
                <ProfileMobilePreview
                    coverUrl={
                        bannerImage?.croppedUrl || appProfilebannerCroppedUrl
                    }
                    fullAddress={address}
                    isNonprofit={organizationType === 'nonprofit'}
                    organizationName={organizationName}
                    representativeAvatar={faithLeaderImage?.croppedUrl}
                />
            </HideOnMobile>
            <PopupTip onClose={onClosePopupTip} open={openPopupTip} />
        </Grid>
    );
};

export default CustomizeProfileStepOneComponent;
