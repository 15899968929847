import React from 'react';
import { GivelifyModal, GivelifyNotification } from '@givelify/ui';
import { SnackbarCloseReason } from '@mui/material';
import { SNACKBAR_TIMEOUT } from '../../../types/const';
import { useTranslations } from '../../../utils/translations';
import { ShareModalContent, ShareActionResult } from './ShareModalContent';

export const useShareModal = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const { linkCopied, emailSent } = useTranslations('general.notifications', [
        'linkCopied',
        'emailSent',
    ]);

    const handleClose = (result: ShareActionResult) => {
        setIsModalOpen(false);
        setIsSnackbarOpen(true);
        setSnackbarMessage(
            result === 'CopyLink'
                ? linkCopied
                : result === 'SendEmail'
                ? emailSent
                : '',
        );
    };

    const handleCloseSnackbar = (
        _: React.SyntheticEvent<any> | Event,
        reason: SnackbarCloseReason | 'dismiss',
    ) => {
        if (reason === 'clickaway') return;
        setIsSnackbarOpen(false);
    };

    const modalToRender = () => (
        <>
            <GivelifyNotification
                global
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={SNACKBAR_TIMEOUT}
                data-testid="givelify-snackbar"
                onClose={handleCloseSnackbar}
                open={isSnackbarOpen && !!snackbarMessage}
                text={snackbarMessage}
                transitionDuration={0}
                variant="success"
            />
            <GivelifyModal
                disableBackdropClose
                showCloseButton
                data-testid="share-modal"
                onClose={() => {
                    setIsModalOpen(false);
                }}
                open={isModalOpen}
            >
                <ShareModalContent onClose={handleClose} />
            </GivelifyModal>
        </>
    );

    return [() => setIsModalOpen(true), modalToRender] as const;
};
