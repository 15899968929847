import { GivelifyColorPalette } from '@givelify/givelify-ui';
import { makeStyles, Theme } from '@material-ui/core';

export const givelithonLaunchModalStyle = makeStyles((theme: Theme) => ({
    launchGivelithon: {
        width: '100%',
        height: '100vh',
        boxSizing: 'border-box',
        position: 'relative',
        background: GivelifyColorPalette.primaryWhite,
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
        '& *': {
            boxSizing: 'border-box',
        },
    },
    settingLaunch: {
        position: 'absolute',
        top: 8,
        left: 32,
        display: 'flex',
        zIndex: 10,
    },
    settingButton: {
        width: 40,
        height: 40,
        background: 'rgba(55, 55, 57, 0.05)',
        marginRight: theme.spacing(4),
    },
    launchSettingContainer: {
        position: 'relative',
    },
    menuLaunch: {
        position: 'absolute',
        top: '-20%',
        left: '80%',
        boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
        padding: theme.spacing(2, 0),
    },
    menuLaunchItem: {
        fontSize: 16,
    },
    modalContent: {
        textAlign: 'center',
        display: 'flex',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        },
    },
    envelopeName: {
        fontSize: 48,
        marginBottom: 65,
        lineHeight: '56px',
        padding: theme.spacing(0, 8),
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            marginBottom: 35,
            lineHeight: '30px',
        },
    },
    countAmount: {
        fontSize: 120,
        lineHeight: '140px',
        fontWeight: 'bold',
        color: GivelifyColorPalette.primary,
        fontFamily: theme.gas?.font,
        [theme.breakpoints.down('sm')]: {
            fontSize: 50,
            lineHeight: '60px',
        },
    },
    totalDonation: {
        marginTop: theme.spacing(3),
    },
    thankYou: {
        fontSize: 80,
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            marginBottom: theme.spacing(4),
        },
    },
    logo: {
        position: 'absolute',
        bottom: '0',
        left: 48,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    progressBarContent: {
        height: '80vh',
        position: 'relative',
    },
    progressGoalBar: {
        marginTop: theme.spacing(12),
        height: '100%',
    },
    progressBar: {
        height: '100%',
        width: 24,
        borderRadius: 25,
        background: GivelifyColorPalette.neutralHoverGrey,
        transform: 'rotateZ(180deg)',
        overflow: 'hidden',
    },
    progressGoal: {
        position: 'absolute',
        right: '-3px',
        top: '10%',
        display: 'flex',
        alignItems: 'center',
        height: 4,
    },
    goalNumber: {
        marginTop: 0,
    },
    goalLine: {
        border: '2px solid #00AC31',
        width: 30,
        marginLeft: theme.spacing(3),
    },
    totalContent: {
        width: '70%',
        maxHeight: '90vh',
        paddingTop: 172,
        position: 'relative',
        transition: 'all 1s ease',
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            paddingTop: 72,
        },
        '&.complete-goal': {
            flexGrow: 1,
        },
        '&.hide-donors': {
            flexGrow: 1,
        },
    },
    progressContent: {
        width: 24,
        position: 'relative',
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(5),
        paddingTop: 36,
        [theme.breakpoints.down('sm')]: {
            height: '60%',
        },
    },
    listDonorContent: {
        flexGrow: 1,
        padding: theme.spacing(5, 0, 0),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '&.complete-goal': {
            display: 'none',
        },
        '&.hide-donors': {
            display: 'none !important',
        },
    },
    listDonorHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(4),
        },
    },
    listDonor: {
        marginTop: theme.spacing(3),
        overflow: 'hidden',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'none',
            height: '100%',
        },
        '& > div': {
            [theme.breakpoints.up('md')]: {
                maxHeight: 'calc(100vh - 72px)',
                overflowY: 'auto',
            },
        },
    },
    noDonor: {
        padding: theme.spacing(4),
        background: 'rgba(55, 55, 57, 0.05)',
        borderRadius: '4px 0 0 4px',
        marginBottom: theme.spacing(3),
        height: 128,
    },
    donorItem: {
        padding: 28,
        background: 'rgba(55, 55, 57, 0.05)',
        borderRadius: '4px 0 0 4px',
        marginBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
    },
    donorAvatar: {
        borderRadius: '50%',
        padding: theme.spacing(1),
        background: GivelifyColorPalette.primaryWhite,
    },
    donorInfo: {
        marginLeft: theme.spacing(3),
        textAlign: 'left',
    },
    noBackground: {
        background: 'transparent',
    },
}));
