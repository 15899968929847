import { Donee } from '@givelify/api';
import { Reducer } from 'redux';
import { addDoneeQueryInUrl, removeDoneeQueryFromUrl } from 'router/queryUtil';
import {
    DoneeState,
    DoneeActions,
    SET_DONEE_AND_CAMPUSES,
    SET_DONEE,
    SET_DONEE_CUSTOM_RECEIPT,
    SET_DONEE_MISSION_STATEMENT,
    SET_DONEE_ADDRESS,
    SET_DONEE_MAILING_ADDRESS,
    SET_DONEE_CONTACT,
    SET_DONEE_SOCIAL,
    SET_DONEE_FAITH_LEADER,
    REMOVE_DONEE_FAITH_LEADER,
    SET_DONEE_ACCOUNT_OWNER,
    SET_DONEE_ORGANIZATION_LOGO,
    SET_DONEE_PRIMARY_REP,
    SET_DONEE_BANNER_IMAGE,
} from './types';

let donee: Donee;
let campuses: Donee[];

export const initialDefaultDoneeState: DoneeState = {
    donee,
    campuses,
};

const DoneeReducer: Reducer<DoneeState, DoneeActions> = (
    state = initialDefaultDoneeState,
    action,
) => {
    switch (action.type) {
        case SET_DONEE_AND_CAMPUSES: {
            return {
                ...state,
                donee: action.donee,
                campuses: action.campuses,
            };
        }
        case SET_DONEE: {
            const searchParams = new URLSearchParams(window.location.search);

            if (
                action.donee &&
                state.campuses &&
                action.donee.id === state.campuses[0].id
            ) {
                removeDoneeQueryFromUrl(searchParams);
            } else {
                const doneeQuery = searchParams.get('donee');
                if (doneeQuery !== action.donee.id.toString()) {
                    addDoneeQueryInUrl(action.donee.id, searchParams);
                }
            }
            return {
                ...state,
                donee: action.donee,
            };
        }
        case SET_DONEE_CUSTOM_RECEIPT: {
            const { donee, campuses } = state;
            const currentCampus = campuses.find(
                (campus) => campus.id === action.doneeId,
            );
            return {
                ...state,
                donee: {
                    ...donee,
                    customReceipt: action.customReceipt ? 1 : 0,
                    updatedAt: action.updatedAt,
                },
                campuses: [
                    ...campuses.filter(
                        (campus) => campus.id !== action.doneeId,
                    ),
                    {
                        ...currentCampus,
                        customReceipt: action.customReceipt ? 1 : 0,
                        updatedAt: action.updatedAt,
                    },
                ].sort((a, b) => a.id - b.id),
            };
        }
        case SET_DONEE_MISSION_STATEMENT: {
            const { donee, campuses } = state;
            const currentCampus = campuses.find(
                (campus) => campus.id === action.doneeId,
            );
            return {
                ...state,
                donee: {
                    ...donee,
                    onboarding: {
                        ...donee.onboarding,
                        appProfile: {
                            ...donee.onboarding.appProfile,
                            missionStatement: action.missionStatement,
                        },
                    },
                    missionStatement: action.missionStatement,
                    updatedAt: action.updatedAt,
                },
                campuses: [
                    ...campuses.filter(
                        (campus) => campus.id !== action.doneeId,
                    ),
                    {
                        ...currentCampus,
                        missionStatement: action.missionStatement,
                        onboarding: {
                            ...currentCampus.onboarding,
                            appProfile: {
                                ...currentCampus.onboarding.appProfile,
                                missionStatement: action.missionStatement,
                            },
                        },
                    },
                ].sort((a, b) => a.id - b.id),
            };
        }
        case SET_DONEE_ADDRESS: {
            const { donee, campuses } = state;
            const currentCampus = campuses.find(
                (campus) => campus.id === action.doneeId,
            );
            return {
                ...state,
                donee: {
                    ...donee,
                    address: action.address,
                    city: action.city,
                    state: action.state,
                    zip: action.zip,
                    phone: action.phone,
                    updatedAt: action.updatedAt,
                    timezoneLong: action.timezoneLong,
                    timezoneShort: action.timezoneShort,
                    timezone: action.timezone,
                    appProfile: {
                        ...donee.onboarding.appProfile,
                        organizationAddress: {
                            phone: action.phone,
                            address: action.address,
                            city: action.city,
                            state: action.state,
                            zip: action.zip,
                            updatedAt: action.updatedAt,
                        },
                    },
                },
                campuses: [
                    ...campuses.filter(
                        (campus) => campus.id !== action.doneeId,
                    ),
                    {
                        ...currentCampus,
                        address: action.address,
                        city: action.city,
                        state: action.state,
                        zip: action.zip,
                        updatedAt: action.updatedAt,
                        appProfile: {
                            ...donee.onboarding.appProfile,
                            organizationAddress: {
                                ...donee.onboarding.appProfile
                                    .organizationAddress,
                                address: action.address,
                                city: action.city,
                                state: action.state,
                                zip: action.zip,
                                updatedAt: action.updatedAt,
                            },
                        },
                    },
                ].sort((a, b) => a.id - b.id),
            };
        }
        case SET_DONEE_MAILING_ADDRESS: {
            const { donee, campuses } = state;
            const currentCampus = campuses.find(
                (campus) => campus.id === action.doneeId,
            );
            return {
                ...state,
                donee: {
                    ...donee,
                    updatedAt: action.updatedAt,
                    mailingAddress: action.mailingAddress
                        ? {
                              ...donee.mailingAddress,
                              ...action.mailingAddress,
                          }
                        : null,
                },
                campuses: [
                    ...campuses.filter(
                        (campus) => campus.id !== action.doneeId,
                    ),
                    {
                        ...currentCampus,
                        updatedAt: action.updatedAt,
                        mailingAddress: action.mailingAddress
                            ? {
                                  ...donee.mailingAddress,
                                  ...action.mailingAddress,
                              }
                            : null,
                    },
                ].sort((a, b) => a.id - b.id),
            };
        }
        case SET_DONEE_CONTACT: {
            const { donee, campuses } = state;
            const currentCampus = campuses.find(
                (campus) => campus.id === action.doneeId,
            );
            return {
                ...state,
                donee: {
                    ...donee,
                    phone: action.phone,
                    email: action.email,
                    established: action.established,
                    congregationSize: action.congregationSize,
                    denomination: action.denomination,
                    updatedAt: action.updatedAt,
                },
                campuses: [
                    ...campuses.filter(
                        (campus) => campus.id !== action.doneeId,
                    ),
                    {
                        ...currentCampus,
                        phone: action.phone,
                        email: action.email,
                        established: action.established,
                        congregationSize: action.congregationSize,
                        denomination: action.denomination,
                        updatedAt: action.updatedAt,
                    },
                ].sort((a, b) => a.id - b.id),
            };
        }
        case SET_DONEE_SOCIAL: {
            const { donee, campuses } = state;
            const currentCampus = campuses.find(
                (campus) => campus.id === action.doneeId,
            );
            return {
                ...state,
                donee: {
                    ...donee,
                    facebook: action.facebook,
                    instagram: action.instagram,
                    twitter: action.twitter,
                    website: action.website,
                    updatedAt: action.updatedAt,
                },
                campuses: [
                    ...campuses.filter(
                        (campus) => campus.id !== action.doneeId,
                    ),
                    {
                        ...currentCampus,
                        facebook: action.facebook,
                        instagram: action.instagram,
                        twitter: action.twitter,
                        website: action.website,
                        updatedAt: action.updatedAt,
                    },
                ].sort((a, b) => a.id - b.id),
            };
        }
        case SET_DONEE_FAITH_LEADER:
            return {
                ...state,
                donee: {
                    ...state.donee,
                    onboarding: {
                        ...state.donee.onboarding,
                        appProfile: {
                            ...state.donee.onboarding.appProfile,
                            faithLeader: action.faithLeaderInfo
                                ? {
                                      ...action.faithLeaderInfo,
                                  }
                                : undefined,
                            doNotDisplayFaithLeader: action.faithLeaderInfo
                                ? false
                                : true,
                        },
                    },
                    updatedAt: action.updatedAt,
                },
            };

        case REMOVE_DONEE_FAITH_LEADER:
            return {
                ...state,
                donee: {
                    ...state.donee,
                    onboarding: {
                        ...state.donee.onboarding,
                        appProfile: {
                            ...state.donee.onboarding.appProfile,
                            faithLeader: {
                                avatar: null,
                                avatarCroppedCoordinates: null,
                                avatarOriginal: null,
                                firstName: null,
                                isFaithLeader: null,
                                lastName: null,
                                title: null,
                                userId: null,
                                active: null,
                                email: null,
                                invitationEmailSent: null,
                            },
                            doNotDisplayFaithLeader: true,
                        },
                    },
                    updatedAt: action.updatedAt,
                },
            };

        case SET_DONEE_ACCOUNT_OWNER:
            return {
                ...state,
                donee: {
                    ...state.donee,
                    onboarding: {
                        ...state.donee.onboarding,
                        appProfile: {
                            ...state.donee.onboarding.appProfile,
                            accountOwner: {
                                ...state.donee.onboarding.appProfile
                                    .accountOwner,
                                userId: action.accountOwnerInfo.userId,
                                avatar: action.accountOwnerInfo.avatar,
                                avatarCroppedCoordinates:
                                    action.accountOwnerInfo
                                        .avatarCroppedCoordinates,
                                avatarOriginal:
                                    action.accountOwnerInfo.avatarOriginal,
                                firstName: action.accountOwnerInfo.firstName,
                                lastName: action.accountOwnerInfo.lastName,
                                title: action.accountOwnerInfo.title,
                            },
                        },
                    },
                    updatedAt: action.updatedAt,
                },
            };

        case SET_DONEE_ORGANIZATION_LOGO:
            return {
                ...state,
                donee: {
                    ...state.donee,
                    onboarding: {
                        ...state.donee.onboarding,
                        appProfile: {
                            ...state.donee.onboarding.appProfile,
                            organizationLogo: {
                                logo: action.logo?.croppedUrl,
                                logoOriginal: action.logo?.url,
                                logoCroppedCoordinates: JSON.stringify(
                                    action.logo?.dimensions.croppedAreaPixels,
                                ),
                            },
                        },
                    },
                },
            };
        case SET_DONEE_BANNER_IMAGE:
            return {
                ...state,
                donee: {
                    ...state.donee,
                    onboarding: {
                        ...state.donee.onboarding,
                        appProfile: {
                            ...state.donee.onboarding.appProfile,
                            coverPhoto: {
                                coverPhoto: action.image?.url,
                                coverPhotoCroppedCoordinates: JSON.stringify(
                                    action.image?.dimensions.croppedAreaPixels,
                                ),
                                coverPhotoOriginal: action.image?.url,
                            },
                        },
                    },
                },
            };
        case SET_DONEE_PRIMARY_REP:
            return {
                ...state,
                donee: {
                    ...state.donee,
                    onboarding: {
                        ...state.donee.onboarding,
                        primaryRepresentative: {
                            ...action.primaryRepInfo,
                        },
                    },
                },
            };

        default:
            return state;
    }
};

/**
 * Factory for easily producing a DoneeReducer with a different initial state
 * Ideal for testing and cosmos
 *
 * @param initialState
 */
export const DoneeReducerFactory =
    (initialState: DoneeState) =>
    (state = initialState, action: DoneeActions) =>
        DoneeReducer(state, action);

export default DoneeReducer;
