import React from 'react';
import { mergeClassNames } from '../utils/classNameUtils';
import { GivelifyBoxMarginProps } from '../GivelifyBaseProps';
import { GivelifyTextFieldStyles } from '../textField/styles';
import { mergeRefs } from '../utils/mergeRefs';
import { GivelifyButton } from '../button';

export interface GivelifyTextAreaBaseProps
    extends Omit<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        'onChange'
    > {
    id: string;
    ariaLabel: string;
    name?: string;
    placeholder?: string;
    value?: string;
    inputRef?:
        | ((instance: HTMLTextAreaElement) => void)
        | React.RefObject<HTMLTextAreaElement | null>
        | null;
    onChange?: (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        value: string,
    ) => void;
    boxSize?: 'large' | 'medium' | 'dense';
    state?: 'normal' | 'error' | 'warning' | 'success';
    variant?: 'default' | 'message';
    classes?: {
        root?: string;
        inputWrapper?: string;
        input?: string;
        helperRoot?: string;
        helperLeft?: string;
        helperRight?: string;
        sendButton?: string;
        sendIcon?: string;
        passwordButton?: string;
        passwordIcon?: string;
        prefix?: string;
        label?: string;
        labelFocused?: string;
        labelFocusedRoot?: string;
        labelTop?: string;
    };
    disabled?: boolean;
    leftHelperText?: string;
    rightHelperText?: string;
    countLength?: boolean;
    maxLength?: number;
    onEnterSubmit?: (value: string) => void;
    submitting?: boolean;
    required?: boolean;
    defaultValue?: string;
    richHelperText?: boolean;
    width?: number | 'auto' | '100%';
    label?: string;
    prefix?: string;
}
export type GivelifyTextAreaProps = GivelifyBoxMarginProps &
    GivelifyTextAreaBaseProps;

export const GivelifyTextArea: React.FCC<GivelifyTextAreaProps> = (props) => {
    const {
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        classes,
        id,
        ariaLabel,
        boxSize = 'medium',
        state = 'normal',
        variant = 'default',
        placeholder,
        disabled,
        leftHelperText,
        rightHelperText,
        countLength,
        maxLength,
        submitting,
        value: passedValue,
        required,
        richHelperText,
        width = 'auto',
        onChange,
        name,
        label,
        className,
        onEnterSubmit,
        inputRef,
        defaultValue,
        prefix,
        rows,
        ...inputProps
    } = props;
    const ref = React.useRef<HTMLTextAreaElement | null>(null);
    const [value, setValue] = React.useState(defaultValue ? defaultValue : '');
    const [focused, setFocused] = React.useState(false);
    const [curRows, setCurRows] = React.useState(rows ? rows : 1);

    const {
        boxMargin,
        tfRoot,
        tfInput,
        tfWrapper,
        tfInputMessage,
        tfLarge,
        tfMedium,
        tfDense,
        tfBorderNormal,
        tfBorderError,
        tfBorderSuccess,
        tfBorderWarning,
        tfDisabled,
        tfHelper,
        tfHelperLeft,
        tfHelperRight,
        tfSendButton,
        tfLabel,
        tfLabelFocused,
        tfLabelFocusedRoot,
        tfLabelHide,
        tfTopBorder,
        tfPrefix,
    } = GivelifyTextFieldStyles({
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        state,
        variant,
        width: width,
        type: 'text',
        focused: focused,
        prefixLength: prefix ? prefix.length : undefined,
        multiline: true,
        rows: curRows,
    });

    React.useEffect(() => {
        const par = value.split('\n');
        if (rows) {
            if (par.length > rows) {
                setCurRows(par.length);
            } else {
                setCurRows(rows);
            }
        } else {
            setCurRows(par.length);
        }
        // eslint-disable-next-line
    }, [value, rows, setCurRows]);

    const onInputValueChange = React.useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (submitting) return;
            const newValue = event.target.value;
            if (onChange !== undefined) {
                onChange(event, newValue);
            } else {
                setValue(newValue);
            }
        },
        [submitting, onChange, setValue],
    );

    const rootClassName = mergeClassNames(
        boxMargin,
        classes ? (classes.root ? classes.root : '') : '',
        tfRoot,
    );

    const holderClassName = mergeClassNames(
        classes ? (classes.inputWrapper ? classes.inputWrapper : '') : '',
        tfWrapper,
    );

    const labelNormalClassName = mergeClassNames(
        tfLabel,
        classes ? (classes.label ? classes.label : '') : '',
    );
    const labelFocusedClassName = mergeClassNames(
        tfLabelFocused,
        classes ? (classes.labelFocused ? classes.labelFocused : '') : '',
    );
    const labelFocusedRootClassName = mergeClassNames(
        tfLabelFocusedRoot,
        classes
            ? classes.labelFocusedRoot
                ? classes.labelFocusedRoot
                : ''
            : '',
    );
    const prefixClassName = mergeClassNames(
        tfPrefix,
        classes ? (classes.prefix ? classes.prefix : '') : '',
    );

    const helperClassName = mergeClassNames(
        tfHelper,
        classes ? (classes.helperRoot ? classes.helperRoot : '') : '',
    );

    const helperLeftClassName = mergeClassNames(
        tfHelperLeft,
        classes ? (classes.helperLeft ? classes.helperLeft : '') : '',
    );

    const helperRightClassName = mergeClassNames(
        tfHelperRight,
        classes ? (classes.helperRight ? classes.helperRight : '') : '',
    );

    const sendButtonClassName = mergeClassNames(
        tfSendButton,
        classes ? (classes.sendButton ? classes.sendButton : '') : '',
    );

    const inputClassName = mergeClassNames(
        props.className,
        tfInput,
        variant === 'message' && tfInputMessage,
        variant === 'default' && boxSize === 'large' && tfLarge,
        variant === 'default' && boxSize === 'medium' && tfMedium,
        variant === 'default' && boxSize === 'dense' && tfDense,
        state === 'normal' && tfBorderNormal,
        state === 'error' && !disabled && tfBorderError,
        state === 'success' && !disabled && tfBorderSuccess,
        state === 'warning' && !disabled && tfBorderWarning,
        disabled && tfDisabled,
        label && (focused || value) && tfTopBorder,
        classes ? (classes.input ? classes.input : '') : '',
    );

    const onSubmit = React.useCallback(() => {
        if (onEnterSubmit) {
            onEnterSubmit(value);
        }
        if (ref) {
            ref.current?.blur();
        }
        // eslint-disable-next-line
    }, [value, ref, onEnterSubmit]);

    const onKeyDown = (event: any) => {
        if (event.defaultPrevented) {
            return;
        }
        let handled = false;
        if (event.key === 'Enter') {
            handled = true;
        } else if (event.keyCode === 13) {
            handled = true;
        }
        if (handled) {
            if (!event.shiftKey) {
                onSubmit();
            }
        }
    };

    const renderRichText = React.useCallback((content: string) => {
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }, []);

    if (
        onChange !== undefined &&
        passedValue !== undefined &&
        passedValue !== value
    ) {
        setValue(passedValue);
    }

    return (
        <div id={`${id}-root`} className={rootClassName}>
            <div id={`${id}-holder`} className={holderClassName}>
                <textarea
                    {...inputProps}
                    id={id}
                    name={name}
                    aria-label={ariaLabel}
                    className={inputClassName}
                    placeholder={label ? undefined : placeholder}
                    disabled={disabled}
                    value={value}
                    onChange={onInputValueChange}
                    maxLength={maxLength}
                    onKeyDown={onKeyDown}
                    ref={inputRef ? mergeRefs([ref, inputRef]) : ref}
                    required={required}
                    draggable={false}
                    style={{ resize: 'none' }}
                    onFocus={(event) => {
                        setFocused(true);
                        if (inputProps?.onFocus) inputProps.onFocus(event);
                    }}
                    onBlur={(event) => {
                        setFocused(false);
                        if (inputProps?.onBlur) inputProps.onBlur(event);
                    }}
                    rows={curRows}
                />
                {prefix && variant !== 'message' ? (
                    <div className={prefixClassName}>{prefix}</div>
                ) : null}
                {label && variant !== 'message' && !value && (
                    <div
                        className={`${labelNormalClassName} ${
                            focused ? tfLabelHide : ''
                        }`}
                    >
                        {label}
                    </div>
                )}
                {label && variant !== 'message' && (focused || value) && (
                    <div className={labelFocusedRootClassName}>
                        <div className={labelFocusedClassName}>{label}</div>
                    </div>
                )}
                {variant === 'message' ? (
                    <div className={sendButtonClassName}>
                        <GivelifyButton
                            name={`${name}_send`}
                            onClick={onSubmit}
                            variant="icon"
                            iconVariant="send"
                            isLoading={submitting}
                        />
                    </div>
                ) : null}
            </div>
            {leftHelperText || rightHelperText || countLength ? (
                <div id={`${id}-helper`} className={helperClassName}>
                    {!leftHelperText ? null : (
                        <div
                            id={`${id}-helper-left`}
                            className={helperLeftClassName}
                        >
                            {richHelperText && leftHelperText
                                ? renderRichText(leftHelperText)
                                : leftHelperText}
                        </div>
                    )}
                    {rightHelperText || countLength ? (
                        <div
                            id={`${id}-helper-right`}
                            className={helperRightClassName}
                        >
                            {countLength
                                ? maxLength
                                    ? `${value.length}/${maxLength}`
                                    : value.length
                                : rightHelperText
                                ? richHelperText
                                    ? renderRichText(rightHelperText)
                                    : rightHelperText
                                : ''}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};
