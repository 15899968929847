import React from 'react';
import { DesignTokens, GivelifyLabel } from '@givelify/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NoteAccent, NoteAccentRow, NoteWrapper } from '../styles';

export const InfoStepNote: React.FCC = ({ children }) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t(
                'pages.settings.bank-info.customize-rep-tab.editor.note.title',
            ),
            description1: t(
                'pages.settings.bank-info.customize-rep-tab.editor.note.description1',
            ),
            list1: t(
                'pages.settings.bank-info.customize-rep-tab.editor.note.list.1',
            ),
            list2: t(
                'pages.settings.bank-info.customize-rep-tab.editor.note.list.2',
            ),
        }),
        [t],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    if (isMobile)
        return (
            <NoteWrapper>
                <NoteAccent>
                    <NoteAccentRow>
                        <img alt="Lock icon" src="/commonIcons/secure.svg" />
                        <GivelifyLabel variant="body2B">
                            {copy.title}
                        </GivelifyLabel>
                    </NoteAccentRow>

                    <div>
                        <GivelifyLabel
                            color={DesignTokens.color.globalNeutral700}
                            variant="body2"
                        >
                            {copy.description1}
                        </GivelifyLabel>
                        <GivelifyLabel
                            color={DesignTokens.color.globalNeutral700}
                            display="flex"
                            paddingLeft={1}
                            paddingRight={2}
                            variant="body2"
                        >
                            &bull;
                            <GivelifyLabel
                                color={DesignTokens.color.globalNeutral700}
                                paddingLeft={1}
                                variant="body2"
                            >
                                {copy.list1}
                            </GivelifyLabel>
                        </GivelifyLabel>
                        <GivelifyLabel
                            color={DesignTokens.color.globalNeutral700}
                            display="flex"
                            paddingLeft={1}
                            paddingRight={2}
                            variant="body2"
                        >
                            &bull;
                            <GivelifyLabel
                                color={DesignTokens.color.globalNeutral700}
                                paddingLeft={1}
                                variant="body2"
                            >
                                {copy.list2}
                            </GivelifyLabel>
                        </GivelifyLabel>
                    </div>
                </NoteAccent>
                {children}
            </NoteWrapper>
        );
    return (
        <NoteWrapper>
            <NoteAccent>
                <img alt="Lock icon" src="/commonIcons/secure.svg" />
                <div>
                    <GivelifyLabel
                        color={DesignTokens.color.globalNeutral700}
                        variant="body2B"
                    >
                        {copy.title}
                    </GivelifyLabel>
                    <GivelifyLabel
                        color={DesignTokens.color.globalNeutral700}
                        paddingLeft={1}
                        variant="body2"
                    >
                        &bull; {copy.list1}
                    </GivelifyLabel>
                    <GivelifyLabel
                        color={DesignTokens.color.globalNeutral700}
                        paddingLeft={1}
                        variant="body2"
                    >
                        &bull; {copy.list2}
                    </GivelifyLabel>
                </div>
            </NoteAccent>
            {children}
        </NoteWrapper>
    );
};
