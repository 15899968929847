import { useEffect, RefObject } from 'react';

export const useScrollToCenterActiveItem = (
    scrollableList: RefObject<HTMLDivElement | null>,
    activeItem: number,
    optionsLength: number,
) => {
    useEffect(() => {
        if (scrollableList.current) {
            const container = scrollableList.current;
            const listElement = container.children[
                activeItem
            ] as HTMLDivElement;
            if (listElement === undefined || container === undefined) return;
            const listLeft = listElement.offsetLeft;
            const listRight = listLeft + listElement.clientWidth;
            const containerLeft = container.scrollLeft;
            const containerRight = container.scrollLeft + container.clientWidth;
            const isTabFullyVisible =
                listLeft >= containerLeft && listRight <= containerRight;
            if (!isTabFullyVisible) {
                const containerVisibleWidth = container.clientWidth;
                const totalWidthBeforeActiveItem = Array.from(
                    container.children,
                )
                    .slice(0, activeItem)
                    .reduce(
                        (totalWidth, child) =>
                            totalWidth + (child as HTMLDivElement).clientWidth,
                        0,
                    );
                const activeItemWidth = listElement.clientWidth;
                const scrollPosition =
                    totalWidthBeforeActiveItem +
                    activeItemWidth / 2 -
                    containerVisibleWidth / 2;
                container.scrollTo({
                    left: scrollPosition,
                    behavior: 'smooth',
                });
            }
        }
    }, [scrollableList, optionsLength, activeItem]);
};
