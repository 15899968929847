import React from 'react';
import { GivelifyLabel } from '@givelify/ui';
import { Grid } from '@mui/material';
import { ReportInfoHeader } from 'pages/reports/LegacyReports/components/style';

interface LegacyReportInfoProps {
    icon: JSX.Element | string;
    name: string;
    description: string;
}

const LegacyReportInfo: React.FCC<LegacyReportInfoProps> = (props) => {
    return (
        <ReportInfoHeader container spacing={0.5} wrap="nowrap">
            <Grid item>{props.icon}</Grid>
            <Grid item>
                <GivelifyLabel
                    fontWeight={700}
                    marginBottom={0}
                    text={props.name}
                    variant="heading2S"
                />
                <GivelifyLabel
                    fontWeight={600}
                    text={props.description}
                    variant="body1"
                />
            </Grid>
        </ReportInfoHeader>
    );
};

export default LegacyReportInfo;
