import React, { useMemo, useState } from 'react';
import {
    GivelifyLabel,
    useAdvancedTranslation,
    GivelifyTextField,
} from '@givelify/givelify-ui';
import { GivelifyButton, GivelifyNotification } from '@givelify/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAutoIntegrationType } from 'pages/integrations/utils/autoIntegrationType';
import { Controller, useForm } from 'react-hook-form';
import { AnySchema, object, string } from 'yup';
import { Donor } from '../../../../../../../types/donorTypes';
import { IntegrationTypes } from '../../../../../types';
import { AutoIntegrationEnterDonorIdData } from '../../types';
import { unMatchDonorTabStyle } from '../style';
import AutoIntegrationDonorCurrentInfo from './AutoIntegrationDonorCurrentInfo';

interface EnterIntegrationDonorIdProps {
    isReadOnly: boolean;
    integration: IntegrationTypes;
    donor?: Donor;
    isLoading: boolean;
    matchDonor: (doneeId: number, chmsDonorId: number) => void;
    errorMessage: string;
}

const EnterIntegrationDonorId: React.FCC<EnterIntegrationDonorIdProps> = ({
    isReadOnly,
    integration,
    donor,
    isLoading,
    matchDonor,
    errorMessage,
}) => {
    const classes = unMatchDonorTabStyle();
    const { t } = useAdvancedTranslation();
    const [f1Id, setF1Id] = useState<string>('');
    const translationPrefix =
        integration.integrationType === 'F1GO'
            ? 'f1GoDonorPage'
            : 'f1DonorPage';
    const copy = useMemo(
        () => ({
            enterF1IdManually: t(`${translationPrefix}.enterF1IdManually`),
            enterCCBIdManually: t(
                'pages.integrations.ccbIntegration.enterTheCCBManually',
            ),
            enterF1GoIdManually: t(
                'pages.integrations.f1goIntegration.enterManually',
            ),
            f1Id: t(`${translationPrefix}.f1Id`),
            ccbId: t('pages.integrations.ccbIntegration.ccbId'),
            f1GoId: t('pages.integrations.f1goIntegration.id'),
            match: t(`${translationPrefix}.match`),
            integrationIdRequired: t('f1DonorPage.integrationIdRequired', {
                integration: integration.integrationType,
            }),
            enterPCIdMannually: t(
                'pages.integrations.planningCenter.enterPCIdMannually',
            ),
            PCId: t('pages.integrations.planningCenter.PCId'),
            PCIdRequired: t('pages.integrations.planningCenter.PCIdRequired'),
        }),
        [t, translationPrefix, integration.integrationType],
    );

    const { isCCB, isPlanningCenter, isF1, isF1Go } = useMemo(
        () => getAutoIntegrationType(integration),
        [integration],
    );

    const form = useForm<AutoIntegrationEnterDonorIdData>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldFocusError: true,
        resolver: yupResolver(
            object<Record<keyof AutoIntegrationEnterDonorIdData, AnySchema>>({
                donorId: string()
                    .required(
                        isPlanningCenter
                            ? copy.PCIdRequired
                            : copy.integrationIdRequired,
                    )
                    .min(1),
            }),
        ),
    });
    const {
        formState: { errors },
        control,
    } = form;

    const onSubmit = () => {
        const formValues = form.getValues();
        setF1Id(formValues.donorId);
        matchDonor(donor?.id, parseInt(formValues.donorId));
    };

    return (
        <div className={classes.enterF1IdContent}>
            <GivelifyNotification
                className={classes.errorNotification}
                open={!!errorMessage && errorMessage.length > 0}
                text={errorMessage}
                variant="error"
            />
            <AutoIntegrationDonorCurrentInfo
                donor={donor}
                isLoading={isLoading}
            />
            <div className={classes.enterF1IdForm}>
                <GivelifyLabel
                    bold
                    text={
                        isF1
                            ? copy.enterF1IdManually
                            : isCCB
                            ? copy.enterCCBIdManually
                            : isF1Go
                            ? copy.enterF1GoIdManually
                            : copy.enterPCIdMannually
                    }
                    variant="body2"
                />
                <div className={classes.enterIdField}>
                    <form
                        className={classes.flexCenter}
                        onSubmit={form.handleSubmit(onSubmit)}
                    >
                        <Controller
                            control={control}
                            name="donorId"
                            render={({
                                field: { name, onBlur, value, onChange, ref },
                            }) => (
                                <GivelifyTextField
                                    ariaLabel="donorId"
                                    boxSize="medium"
                                    id="donorId"
                                    inputRef={ref}
                                    label={
                                        isF1
                                            ? copy.f1Id
                                            : isCCB
                                            ? copy.ccbId
                                            : isF1Go
                                            ? copy.f1GoId
                                            : copy.PCId
                                    }
                                    leftHelperText={errors.donorId?.message}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={(value) => {
                                        onChange(value);
                                    }}
                                    state={errors.donorId ? 'error' : 'normal'}
                                    value={value}
                                    width={335}
                                />
                            )}
                        />
                        <GivelifyButton
                            disabled={
                                isReadOnly ||
                                Object.keys(form.formState.errors).length > 0 ||
                                (f1Id && f1Id.length === 0)
                            }
                            name="match"
                            onClick={onSubmit}
                            size="large"
                            text={copy.match}
                            variant="primary"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EnterIntegrationDonorId;
