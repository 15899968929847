import React, { useEffect, useMemo, useState } from 'react';
import { FullWidthModal } from '@givelify/givelify-ui';
import {
    MODAL_NAME,
    isNumber,
    toISODate,
    useTrackingContext,
} from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { useAdvancedTranslation } from 'utils/i18n';
import * as yup from 'yup';
import { useEnvelopesContext } from '../../../context/EnvelopesProvider';
import CancelConfirmModal from './CancelConfirmModal';
import CantDeleteModal from './CantDeleteModal';
import DeleteConfirmModal from './DeleteConfirmModal';
import {
    FormEnvelope,
    HasGoalValue,
    hasGoalValues,
    IsAlwaysValue,
    isAlwaysValues,
} from './types';
import View from './view';
dayjs.extend(minMax);
dayjs.extend(isSameOrAfter);

const EnvelopeForm: React.FCC = () => {
    const { trackOpenCloseEvent } = useTrackingContext();
    const { organizationType } = useSelector((state: AppState) => ({
        organizationType: state?.Donee?.donee?.onboarding?.organizationType,
    }));
    const { scopedTranslate, scopedATranslate } = useAdvancedTranslation(
        'pages.settings.envelopes2.envelopes-editor',
    );
    const copy = useMemo(
        () => ({
            create: scopedATranslate('create'),
            edit: scopedATranslate('edit'),
            requireName: scopedATranslate('require-envelope-name'),
            duplicatedName: scopedATranslate('duplicated-envelope-name'),
            invalidGoal: scopedTranslate('invalid-goal-amount'),
            requireGoal: scopedTranslate('require-goal-amount'),
            goalInteger: scopedTranslate('goalInteger'),
            goalMax: scopedTranslate('goalMax'),
            invalidDate: scopedTranslate('invalidDate'),
            requireStartDate: scopedTranslate('require-start-date'),
            startDateAfterToday: scopedTranslate('start-date-after-today'),
            startDateBeforeEndDate: scopedTranslate(
                'start-date-before-end-date',
            ),
            requireEndDate: scopedTranslate('require-end-date'),
            endDateAfterStartDate: scopedTranslate('end-date-after-start-date'),
            requireExternalId: scopedTranslate('require-external-id'),
        }),
        [scopedTranslate, scopedATranslate],
    );

    const {
        activeEnvelopes,
        inactiveEnvelopes,
        createEnvelope,
        updateEnvelope,
        editTarget,
        closeEditForm,
        createEnvelopeRequestState,
        updateEnvelopeRequestState,
        deleteEnvelope,
        deleteEnvelopeRequestState,
        hideEnvelope,
        hideEnvelopeRequestState,
    } = useEnvelopesContext();

    const testDuplicatedEnvelopeName = (id: number, title: string) => {
        if (!title) return true;
        const lowerTitle = title.toLowerCase();

        const activeEnvelopeNames = activeEnvelopes
            .filter((e) => e.id !== id)
            .map((e) => e.name.toLowerCase());
        if (activeEnvelopeNames.indexOf(lowerTitle) >= 0) return false;

        const inactiveEnvelopeNames = inactiveEnvelopes.data
            .filter((e) => e.id !== id)
            .map((e) => e.name.toLowerCase());
        if (inactiveEnvelopeNames.indexOf(lowerTitle) >= 0) return false;

        return true;
    };

    const today = dayjs().tz().startOf('day');

    const [defaultValues] = useState<FormEnvelope>({
        id: editTarget.id,
        goal: editTarget.goal,
        description: editTarget.detail || '',
        endDate:
            editTarget.alwaysOn === 'Timed' ? dayjs.tz(editTarget.end) : null,
        externalId: editTarget.externalId || '',
        hasGoal:
            !editTarget.isDefault && !!editTarget.goal
                ? hasGoalValues.YES
                : hasGoalValues.NO,
        isAlways: editTarget.alwaysOn,
        name: editTarget.name,
        startDate:
            editTarget.alwaysOn === 'Timed' ? dayjs.tz(editTarget.start) : null,
        isDefault: editTarget.isDefault,
        hasExternalId: !!editTarget.externalId,
    });

    const form = useForm<FormEnvelope>({
        mode: 'onChange',
        shouldFocusError: true,
        resolver: yupResolver(
            yup.object<Record<keyof FormEnvelope, yup.AnySchema>>().shape(
                {
                    id: yup.number().nullable(),
                    name: yup
                        .string()
                        .required(copy.requireName)
                        .test('duplicate', copy.duplicatedName, (value) =>
                            testDuplicatedEnvelopeName(defaultValues.id, value),
                        ),
                    description: yup.string(),
                    externalId: yup.string().when('hasExternalId', {
                        is: true,
                        then: yup.string().required(copy.requireExternalId),
                        otherwise: yup.string().nullable(),
                    }),
                    hasExternalId: yup.boolean(),
                    isAlways: yup.mixed<IsAlwaysValue>().required(),
                    hasGoal: yup.mixed<HasGoalValue>().required(),
                    goal: yup
                        .number()
                        .transform((value, originalValue) => {
                            if (typeof originalValue === 'number')
                                return originalValue;

                            const parsedValue = originalValue?.replace(
                                /,/g,
                                '',
                            );
                            return isNumber(parsedValue)
                                ? Number(parsedValue)
                                : null;
                        })
                        .nullable()
                        .typeError(copy.requireGoal)
                        .when('hasGoal', {
                            is: hasGoalValues.YES,
                            then: (schema) =>
                                schema
                                    .required(copy.requireGoal)
                                    .max(999000000000000, copy.goalMax)
                                    .test({
                                        name: 'min',
                                        exclusive: true,
                                        message: copy.invalidGoal,
                                        test: (value) => value >= 1,
                                    }),
                        }),
                    startDate: yup
                        .mixed<dayjs.Dayjs>()
                        .nullable()
                        .typeError(copy.invalidDate)
                        .when('isAlways', {
                            is: isAlwaysValues.TIMED,
                            then: (schema) =>
                                schema
                                    .required(copy.requireStartDate)
                                    .test(
                                        'min',
                                        copy.startDateAfterToday,
                                        (value) =>
                                            value !== null &&
                                            (dayjs(value).isSame(
                                                defaultValues.startDate,
                                                'day',
                                            ) ||
                                                dayjs(value).isSameOrAfter(
                                                    today,
                                                )),
                                    )
                                    .when('endDate', (endDate, schema) =>
                                        endDate !== null &&
                                        dayjs(endDate).isValid()
                                            ? schema.test(
                                                  'end-date',
                                                  copy.startDateBeforeEndDate,
                                                  (startDate) =>
                                                      dayjs(endDate).isAfter(
                                                          dayjs(startDate),
                                                      ),
                                              )
                                            : schema,
                                    ),
                        }),
                    endDate: yup
                        .mixed<dayjs.Dayjs>()
                        .nullable()
                        .typeError(copy.invalidDate)
                        .when('isAlways', {
                            is: isAlwaysValues.TIMED,
                            then: (schema) =>
                                schema
                                    .required(copy.requireEndDate)
                                    .when('startDate', (startDate, schema) =>
                                        startDate !== null &&
                                        dayjs(startDate).isValid()
                                            ? schema.test(
                                                  'start-date',
                                                  copy.endDateAfterStartDate,
                                                  (endDate) =>
                                                      dayjs(endDate).isAfter(
                                                          startDate,
                                                      ),
                                              )
                                            : schema,
                                    ),
                        }),
                },
                [['startDate', 'endDate']],
            ),
        ),
        defaultValues,
    });

    const { watch, clearErrors } = form;
    const [isAlways, hasGoal] = watch(['isAlways', 'hasGoal']);

    useEffect(() => {
        if (isAlways === isAlwaysValues.ALWAYS) {
            clearErrors(['startDate', 'endDate']);
        }

        if (hasGoal === hasGoalValues.NO) {
            clearErrors('goal');
        }
    }, [isAlways, hasGoal, clearErrors]);

    const handleSubmit = (values: FormEnvelope) => {
        if (editTarget.id) {
            return updateEnvelope({
                active: editTarget.active,
                alwaysOn: values.isAlways,
                detail: values.description || null,
                externalId: values.hasExternalId
                    ? values.externalId || null
                    : null,
                goal: values.hasGoal === hasGoalValues.YES ? values.goal : null,
                id: editTarget.id,
                isDefault: defaultValues.isDefault,
                name: values.name,
                end:
                    values.isAlways === isAlwaysValues.TIMED &&
                    values.endDate &&
                    toISODate(values.endDate),
                start:
                    values.isAlways === isAlwaysValues.TIMED &&
                    values.startDate &&
                    toISODate(values.startDate),
                isDetailPublic: editTarget.isDetailPublic,
            });
        } else {
            return createEnvelope({
                active: true,
                alwaysOn: values.isAlways,
                detail: values.description || null,
                externalId: values.externalId || null,
                goal: values.hasGoal === hasGoalValues.YES ? values.goal : null,
                id: editTarget.id,
                isDefault: false,
                isDetailPublic: editTarget.isDetailPublic,
                name: values.name,
                end:
                    values.isAlways === isAlwaysValues.TIMED &&
                    values.endDate &&
                    toISODate(values.endDate),
                start:
                    values.isAlways === isAlwaysValues.TIMED &&
                    values.startDate &&
                    toISODate(values.startDate),
                priority: null,
            });
        }
    };

    const canDelete = !editTarget.isDefault && !!editTarget.id;

    const {
        formState: { isDirty },
    } = form;

    const [showCancelConfirmDialog, setShowCancelConfirmDialog] =
        useState(false);
    const onCancelClick = () => {
        if (isDirty) {
            setShowCancelConfirmDialog(true);
            trackOpenCloseEvent(true, MODAL_NAME.ConfirmCancelBox);
        } else closeEditForm();
    };
    const onCancelYes = closeEditForm;
    const onCancelNo = () => {
        setShowCancelConfirmDialog(false);
        trackOpenCloseEvent(false, MODAL_NAME.ConfirmCancelBox);
    };

    useEffect(() => {
        if (
            createEnvelopeRequestState === 'REQUEST_SUCCESS' ||
            updateEnvelopeRequestState === 'REQUEST_SUCCESS'
        )
            closeEditForm();
    }, [createEnvelopeRequestState, updateEnvelopeRequestState, closeEditForm]);

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
        useState(false);

    const [showCantDeleteDialog, setShowCantDeleteDialog] = useState(false);

    const onDeleteClick = () => {
        if (editTarget.donationCount === 0) {
            trackOpenCloseEvent(true, MODAL_NAME.ConfirmDeleteBox);
            setShowDeleteConfirmDialog(true);
        } else {
            trackOpenCloseEvent(true, MODAL_NAME.CanNotDeleteBox);
            setShowCantDeleteDialog(true);
        }
    };

    const closeCanNotDeleteBox = () => {
        trackOpenCloseEvent(false, MODAL_NAME.CanNotDeleteBox);
        setShowCantDeleteDialog(false);
    };

    const closeConfirmDeleteBox = () => {
        trackOpenCloseEvent(false, MODAL_NAME.ConfirmDeleteBox);
        setShowDeleteConfirmDialog(false);
    };

    useEffect(() => {
        if (deleteEnvelopeRequestState === 'REQUEST_SUCCESS') closeEditForm();
    }, [deleteEnvelopeRequestState, closeEditForm]);

    useEffect(() => {
        if (hideEnvelopeRequestState === 'REQUEST_SUCCESS') closeEditForm();
    }, [hideEnvelopeRequestState, closeEditForm]);

    return (
        <FormProvider {...form}>
            <FullWidthModal
                open
                heading={defaultValues.id ? copy.edit : copy.create}
                name="editEnvelopeModal"
                onClose={onCancelClick}
            >
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <View
                        canDelete={canDelete}
                        onCancelClick={onCancelClick}
                        onDeleteClick={onDeleteClick}
                        organizationType={organizationType}
                    />
                </form>
            </FullWidthModal>
            <CancelConfirmModal
                onCancel={onCancelNo}
                onOk={onCancelYes}
                open={showCancelConfirmDialog}
            />
            <DeleteConfirmModal
                onCancel={closeConfirmDeleteBox}
                onOk={() => deleteEnvelope(defaultValues.id)}
                open={showDeleteConfirmDialog}
            />
            <CantDeleteModal
                onCancel={closeCanNotDeleteBox}
                onOk={() => hideEnvelope(editTarget.id)}
                open={showCantDeleteDialog}
            />
        </FormProvider>
    );
};

export default EnvelopeForm;
