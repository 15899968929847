import React from 'react';
import { BasicPageWrapper } from '@givelify/ui';
import { MainFilterRow } from 'components/filters/MainFilterRow';
import { BankDepositInfiniteLoader } from './BankDepositInfiniteLoader';
import BankDepositModal from './BankDepositModal';
import { useBankDeposits } from './useBankDeposits';

export const BankDepositsView: React.FCC = () => {
    const {
        copy,
        doneeId,
        donation,
        handleClose,
        handleOpen,
        handleTabCahnge,
        setTimeFrame,
        setTransactionFilterValue,
        tabOptions,
        timeFrame,
        transactionFilterValue,
    } = useBankDeposits();
    return (
        <BasicPageWrapper
            contentWrapper="legacy"
            description="Bank Deposits"
            tabProps={{
                value: 0,
                options: tabOptions,
                onChange: handleTabCahnge,
            }}
            title={copy.bankTitle}
        >
            <MainFilterRow
                page="bankDeposit"
                timeFrameFilterProps={{
                    value: timeFrame,
                    onChange: setTimeFrame,
                }}
                totalBannerProps={{
                    position: 'row',
                    size: 'small',
                    mode: 'real',
                    topText: copy.total,
                    bottomText: copy.deposits,
                }}
                transactionFilterProps={{
                    value: transactionFilterValue,
                    onChange: setTransactionFilterValue,
                }}
            />
            <BankDepositModal donation={donation} handleClose={handleClose} />
            <BankDepositInfiniteLoader
                doneeId={doneeId}
                handleOpen={handleOpen}
                timeFrame={timeFrame}
                transactionFilter={transactionFilterValue}
            />
        </BasicPageWrapper>
    );
};
