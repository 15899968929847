import React from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { BasicPageWrapper } from '@givelify/ui';
import { TrackingProvider, PAGE_NAME } from '@givelify/utils';
import LegacyReportDonations from 'pages/reports/GenerateReports/legacy/LegacyReportDonations';
import LegacyReports from 'pages/reports/LegacyReports';
import { useSelector } from 'react-redux';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import LoadingBar from '../components/system/LoadingBar';
import Report from '../pages/reports/GenerateReports';
import ReportBankDeposits from '../pages/reports/GenerateReports/report/ReportBankDeposits';
import ReportDisbursements from '../pages/reports/GenerateReports/report/ReportDisbursements';
import ReportDonations from '../pages/reports/GenerateReports/report/ReportDonations';
import ReportDonationsByDonors from '../pages/reports/GenerateReports/report/ReportDonationsByDonors';
import ReportDonationsByEnvelope from '../pages/reports/GenerateReports/report/ReportDonationsByEnvelope';
import ReportDonationsLegacy from '../pages/reports/GenerateReports/report/ReportDonationsLegacy';
import ReportDonors from '../pages/reports/GenerateReports/report/ReportDonors';
import ReportHistory from '../pages/reports/ReportHistory';
import { ReportHistoryDownloadWrapper } from '../pages/reports/ReportHistory/ReportsHistoryDownloadWrapper';
import { AppState } from '../store';
import { Features, isFeatureEnabled } from '../utils/featureGating';
import { useGasRouterContext } from './GasRouterProvider';
import { PATH } from './routes';

export const REPORTS_ROUTES = (
    <>
        <Route element={<Report />} path={PATH.REPORTS.GENERATE_REPORTS} />
        <Route element={<LegacyReports />} path={PATH.REPORTS.LEGACY_REPORTS} />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_DONATIONS}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_DISBURSMENTS}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_DONORS}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_IMPORT_FRIENDLY}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_BY_ENVELOPE}
        />
        <Route
            element={<LegacyReportDonations />}
            path={PATH.REPORTS.LEGACY_REPORTS_BY_DONOR}
        />
        <Route element={<ReportHistory />} path={PATH.REPORTS.REPORT_HISTORY} />
        <Route
            element={<ReportHistoryDownloadWrapper />}
            path={PATH.REPORTS.REPORT_HISTORY_DOWNLOAD}
        />
        <Route
            element={
                <TrackingProvider
                    pageName={PAGE_NAME.ReportGenerate}
                    trackPageVisit={false}
                >
                    <Outlet />
                </TrackingProvider>
            }
        >
            <Route
                element={<ReportDonations />}
                path={PATH.REPORTS.REPORT_DONATIONS}
            />
            <Route
                element={<ReportBankDeposits />}
                path={PATH.REPORTS.REPORT_BANK_DEPOSITS}
            />
            <Route
                element={<ReportDonors />}
                path={PATH.REPORTS.REPORT_DONORS}
            />
            <Route
                element={<ReportDisbursements />}
                path={PATH.REPORTS.REPORT_DISBURSEMENTS}
            />
            <Route
                element={<ReportDonationsLegacy />}
                path={PATH.REPORTS.REPORT_DONATIONS_LEGACY}
            />
            <Route
                element={<ReportDonationsByEnvelope />}
                path={PATH.REPORTS.DONATION_BY_ENVELOPE}
            />
            <Route
                element={<ReportDonationsByDonors />}
                path={PATH.REPORTS.DONATIONS_BY_DONOR}
            />
        </Route>
    </>
);

const TAB_PATH = {
    reports: 'reports-tab',
    reportsLegacy: 'reports-legacy-tab',
    reportsHistory: 'reports-history-tab',
} as const;

const ReportsRoute = () => {
    const { PATH } = useGasRouterContext();
    const { t } = useAdvancedTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [tab, setTab] = React.useState<{ tab: string }>({
        tab: TAB_PATH.reports,
    });
    const copy = React.useMemo(
        () => ({
            reports: t('reports.reports'),
            generateReports: t('reports.root.generateReports.title'),
            legacyReports: t('reports.root.legacyReports.title'),
            reportHistory: t('reports.root.reportHistory.title'),
        }),
        [t],
    );
    const enabledFeatures = useSelector(
        (state: AppState) => state.System.enabledFeatures,
    );

    const { legacyReportsDashboard } = React.useMemo(
        () => ({
            legacyReportsDashboard: isFeatureEnabled(
                enabledFeatures,
                Features.LEGACY_REPORTS_DASHBOARD,
                false,
            ),
        }),
        [enabledFeatures],
    );

    const tabOptions = React.useMemo(() => {
        const hrefs = [
            {
                tabHref: PATH.REPORTS.GENERATE_REPORTS(),
                label: copy.generateReports,
                id: TAB_PATH.reports,
            },
            {
                tabHref: PATH.REPORTS.LEGACY_REPORTS,
                label: copy.legacyReports,
                id: TAB_PATH.reportsLegacy,
            },
            {
                tabHref: PATH.REPORTS.REPORT_HISTORY,
                label: copy.reportHistory,
                id: TAB_PATH.reportsHistory,
            },
        ];

        if (!legacyReportsDashboard) {
            hrefs.splice(1, 1);
        }

        return hrefs;
    }, [copy, legacyReportsDashboard, PATH.REPORTS]);

    const onChange = React.useCallback(
        (tabIndex) => {
            const tab = tabOptions[tabIndex];
            navigate(tab.tabHref);
        },
        [navigate, tabOptions],
    );
    React.useEffect(() => {
        if (location.pathname.startsWith(PATH.REPORTS.GENERATE_REPORTS())) {
            setTab({ tab: TAB_PATH.reports });
        } else if (location.pathname.startsWith(PATH.REPORTS.LEGACY_REPORTS)) {
            setTab({ tab: TAB_PATH.reportsLegacy });
        } else if (location.pathname.startsWith(PATH.REPORTS.REPORT_HISTORY)) {
            setTab({ tab: TAB_PATH.reportsHistory });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        PATH.REPORTS.GENERATE_REPORTS,
        PATH.REPORTS.LEGACY_REPORTS,
        PATH.REPORTS.REPORT_HISTORY,
        location.pathname,
    ]);

    const isLegacyContentWrapper = React.useMemo(() => {
        const legacyContent = [
            PATH.REPORTS.DONATIONS_BY_DONOR,
            PATH.REPORTS.DONATION_BY_ENVELOPE,
            PATH.REPORTS.REPORT_DONATIONS_LEGACY,
            PATH.REPORTS.REPORT_DISBURSEMENTS,
            PATH.REPORTS.REPORT_DONORS,
            PATH.REPORTS.REPORT_BANK_DEPOSITS,
            PATH.REPORTS.REPORT_DONATIONS,
            PATH.REPORTS.LEGACY_REPORTS_DONATIONS,
            PATH.REPORTS.LEGACY_REPORTS_DISBURSMENTS,
            PATH.REPORTS.LEGACY_REPORTS_DONORS,
            PATH.REPORTS.LEGACY_REPORTS_IMPORT_FRIENDLY,
            PATH.REPORTS.LEGACY_REPORTS_BY_ENVELOPE,
            PATH.REPORTS.LEGACY_REPORTS_BY_DONOR,
        ];
        return legacyContent.some((contentPath) =>
            location.pathname.startsWith(contentPath),
        );
    }, [PATH.REPORTS, location.pathname]);

    if (enabledFeatures.length === 0) {
        return <LoadingBar show />;
    }

    return (
        <BasicPageWrapper
            contentWrapper={isLegacyContentWrapper ? 'legacy' : 'simple'}
            tabProps={{
                options: tabOptions,
                onChange,
                value:
                    tab.tab === TAB_PATH.reports
                        ? 0
                        : tab.tab === TAB_PATH.reportsLegacy
                        ? 1
                        : 2,
            }}
            title={copy.reports}
        >
            <TrackingProvider
                pageName={PAGE_NAME.Reports}
                trackPageVisit={false}
            >
                <PageTitle description="Reports" title="Reports" />
                <Outlet />
            </TrackingProvider>
        </BasicPageWrapper>
    );
};

export default ReportsRoute;
