import { styled } from '@mui/material';
import { GivelifyIcon } from '../icon';
import { DesignTokens } from '../specify';

export const BreadcrumbsContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
}));

export const BreadcrumbsItem = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));

export const BreadcrumbLabel = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 600,

    '& .badge': {
        width: 24,
        height: 24,
        borderRadius: '50%',
        padding: '4px 8px',
        background: DesignTokens.color.iconPrimary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: DesignTokens.color.textWhite,
        fontSize: 12,
        fontWeight: 600,
        marginRight: 16,
    },

    '&.active': {
        color: DesignTokens.color.textAccentDefault,
        fontWeight: 800,

        '& .badge': {
            background: DesignTokens.color.textAccentDefault,
        },
    },

    '&.completed': {
        color: DesignTokens.color.textSecondaryAccent,
        fontWeight: 800,
        '& .badge': {
            background: DesignTokens.color.textSecondaryAccent,
        },
    },

    [theme.breakpoints.down('mobile')]: {
        fontSize: 14,
        fontWeight: 600,

        '& .badge': {
            marginRight: 8,
        },

        '&.active, &.completed': {
            fontWeight: 600,
        },
    },
}));

export const Separator = styled(GivelifyIcon)(() => ({
    margin: '0 16px',
    fontSize: 24,
}));
