import { GivelifyButton, GivelifyLabel, GivelifyPaper } from '@givelify/ui';
import { Divider, Grid, styled } from '@mui/material';

export const IntroBox = styled(GivelifyPaper)(({ theme }) => ({
    '& > div': {
        textAlign: 'center',
        maxWidth: '66%',
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
}));

export const LineDivider = styled(Divider)({
    margin: '24px 0',
});

export const PreviewImage = styled('img')({
    maxWidth: '357px',
    width: '100%',
    height: 'auto',
});

export const PreviewImageBox = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
    },
}));

export const PreviewArrowsContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    float: 'right',
    marginTop: '16%',
    paddingLeft: theme.spacing(2),

    '& > div': {
        position: 'absolute',
        top: '40%',
    },
}));

export const PreviewArrowText = styled(GivelifyLabel)({
    textAlign: 'center',
    width: '115px',
    marginBottom: 4,
});

export const ShareButton = styled(GivelifyButton)(({ theme }) => ({
    marginTop: '16px',
    padding: '8px 42px',
    [theme.breakpoints.down('mobile')]: {
        width: '100%',
    },
}));

export const BuildIcon = styled('img')({
    marginRight: 8,
    verticalAlign: 'middle',
});

export const TopArrow = styled('img')({
    marginBottom: 8,
});

export const HideOnTablet = styled('div')(({ theme }) => ({
    display: 'initial',
    [theme.breakpoints.down('smallTablet')]: {
        display: 'none',
    },
}));

export const ShowOnTablet = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('smallTablet')]: {
        display: 'initial',
    },
}));
