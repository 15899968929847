import React from 'react';
import {
    DesignTokens,
    GivelifyForm,
    GivelifyLabel,
    GivelifyLink,
} from '@givelify/ui';
import { TrackingProvider } from '@givelify/utils';
import { useTheme, useMediaQuery } from '@mui/material';
import { SUPPORT_WHY_PRIMARY_REP_URL } from 'api/constants';
import { useTranslation } from 'react-i18next';
import { useRepNameSchema } from '../../schema';
import { primaryRepEditorViews, PrimaryRepNameForm } from '../../types';
import { NameStepForm, NameStepFormRef } from './NameStepForm';

export type PrimaryRepEditorStepNameProps = {
    userDetails: Partial<PrimaryRepNameForm>;
    value: PrimaryRepNameForm;
    onCancelClick: () => void;
    onSubmit: (data: PrimaryRepNameForm) => void;
    formRef: React.RefObject<NameStepFormRef>;
};

export const PrimaryRepEditorStepName: React.FCC<
    PrimaryRepEditorStepNameProps
> = ({ userDetails, value, onCancelClick, onSubmit, formRef }) => {
    const { t } = useTranslation();
    const copy = React.useMemo(
        () => ({
            title: t(
                'pages.settings.bank-info.customize-rep-tab.editor.stepName.title',
            ),
            description: t(
                'pages.settings.bank-info.customize-rep-tab.editor.stepName.description',
            ),
            learnMore: t(
                'pages.settings.bank-info.customize-rep-tab.editor.labels.learnMore',
            ),
        }),
        [t],
    );
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('smallTablet'));
    const schemaA = useRepNameSchema(t);
    return (
        <TrackingProvider
            trackPageVisit
            pageName={primaryRepEditorViews.infoStep}
            viewType="other"
        >
            <GivelifyLabel variant={isSmallScreen ? 'heading2S' : 'heading1S'}>
                {copy.title}
            </GivelifyLabel>
            <GivelifyLabel
                className="description"
                color={DesignTokens.color.textSecondary}
                variant="body2"
            >
                {copy.description}
                <GivelifyLink
                    fontSize="inherit"
                    href={SUPPORT_WHY_PRIMARY_REP_URL}
                    marginLeft={0.5}
                    name="Primary rep why"
                    target="_blank"
                    text={copy.learnMore}
                />
            </GivelifyLabel>
            <GivelifyForm<PrimaryRepNameForm>
                defaultValues={value}
                onSubmit={onSubmit}
                schema={schemaA}
            >
                <NameStepForm
                    ref={formRef}
                    onCancelClick={onCancelClick}
                    userDetails={{
                        firstName: userDetails.firstName,
                        lastName: userDetails.lastName,
                        title: userDetails.title,
                    }}
                />
            </GivelifyForm>
        </TrackingProvider>
    );
};
